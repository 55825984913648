import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { getListAllAPI, api } from '../../../services';
import {
  formatTelefone,
  formatCNPJ,
  formatCPF,
  formatCEP,
  formatIE,
  SECONDARY_COLOR,
  formatMoeda,
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  formatPeso,
  getLabelNivelCaixaria,
  disablePeso,
  getTotal2,
  POR_PESO_FIXO,
  PRIMARY_COLOR,
  TIPO_UNIDADE_KG,
  TIPO_UNIDADE_UN,
  TIPO_UNIDADE_CX1,
  TIPO_UNIDADE_CX2,
  TIPO_UNIDADE_CX3,
  STATUS_ATIVO,
  formatPreco,
  TIPO_PEDIDO_MINIMO_COMPRA_KG,
  TIPO_PEDIDO_MINIMO_COMPRA_VALOR,
  TIPO_PEDIDO_MINIMO_COMPRA_CAIXAS,
  formatPorcentagem,
  formatDateDDMMYYYYHHmm,
  formatDateDDMMYYYY,
} from '../../../utils';
import Relatorio from './Relatorio';
import Document from './Relatorio/Document';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'black',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, onBaixar, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        endIcon={<GetAppIcon>send</GetAppIcon>}
        size="small"
        onClick={() => onBaixar()}
      >
        Baixar
      </Button>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const colunasTemplate = {
  id: {
    label: 'Nº',
    name: 'id',
    show: true,
    dica: 'Ordem',
    align: 'center',
    flex: 0.35,
    //labelTotal: 'TOTAIS',
  },
  descricao: {
    label: 'Descrição do Produto',
    name: 'descricao',
    show: true,
    dica: 'Descrição do Produto',
    align: 'left',
    hasSort: true,
    labelTotal: 'TOTAIS',
    flex: 2,
    styleTotal: {
      fontSize: '12',
    },
  },
  rs_kg: {
    label: 'R$/kg',
    name: 'rs_kg',
    show: true,
    dica: 'Preço do Kg',
    align: 'center',
    flex: 0.85,
  },
  rs_un: {
    label: 'R$/un',
    name: 'rs_un',
    show: true,
    dica: 'Preço da Unidade',
    align: 'center',
    flex: 0.85,
  },
  rs_cx_1: {
    label: 'R$/cx1',
    name: 'rs_cx_1',
    show: true,
    dica: 'Preço da Caixa Nível 1',
    align: 'center',
    flex: 0.85,
  },
  rs_cx_2: {
    label: 'R$/cx2',
    name: 'rs_cx_2',
    show: true,
    dica: 'Preço da Caixa Nível 2',
    align: 'center',
    flex: 0.85,
  },
  rs_cx_3: {
    label: 'R$/cx3',
    name: 'rs_cx_3',
    show: true,
    dica: 'Preço da Caixa Nível 3',
    align: 'center',
    flex: 0.85,
  },
  pedido: {
    label: 'Pedido',
    name: 'pedido',
    show: true,
    dica: 'Pedido feito ao Fornecedor',
    align: 'center',
    style: {
      color: 'red',
    },
    hasSort: true,
    flex: 0.7,
  },
  unidade_adotada_na_compra: {
    label: 'un',
    name: 'unidade_adotada_na_compra',
    show: true,
    dica: 'Unidade adotada na compra',
    align: 'center',
    hasSort: true,
    flex: 0.5,
  },
  subtotal: {
    label: 'Subtotal',
    name: 'subtotal',
    show: true,
    dica: 'Subtotal',
    align: 'center',
    hasSort: true,
    flex: 1,
  },
  bonificacao_em_cxs: {
    label: 'Bon cx',
    name: 'bonificacao_em_cxs',
    show: true,
    dica: 'Bonificação em Caixas',
    align: 'center',
    flex: 0.7,
  },
  bonificacao_subtotal: {
    label: 'Bon Sub',
    name: 'bonificacao_subtotal',
    show: true,
    dica: 'Bonificação Subtotal',
    align: 'center',
    flex: 1,
  },
  rs_un_mais_bonificacao: {
    label: 'R$ + Bon',
    name: 'rs_un_mais_bonificacao',
    show: true,
    dica: 'Preço Final incluindo a precificação',
    align: 'center',
    flex: 0.85,
  },
  peso_subtotal: {
    label: 'Peso Sub (kg)',
    name: 'peso_subtotal',
    show: false,
    dica: 'Peso Subtotal',
    align: 'center',
    flex: 1,
  },
  peso_subtotal_liquido: {
    label: 'Peso Sub (kg)',
    name: 'peso_subtotal_liquido',
    show: true,
    dica: 'Peso Subtotal',
    align: 'center',
    hasSort: true,
    flex: 1.2,
  },
  volume_subtotal: {
    label: 'Vol Sub (m³)',
    name: 'volume_subtotal',
    show: true,
    dica: 'Volume Subtotal',
    align: 'center',
    flex: 1,
  },
  estoque_atual: {
    label: 'Est. Atual',
    name: 'estoque_atual',
    show: false,
    dica: 'Estoque Atual',
    align: 'center',
    flex: 1,
  },
  estoque_ideal: {
    label: 'Est. Ideal',
    name: 'estoque_ideal',
    show: false,
    dica: 'Estoque Ideal calculado pelo sistema',
    align: 'center',
    flex: 1,
  },
  vtu_30: {
    label: 'VTU30',
    name: 'vtu_30',
    show: false,
    dica: 'Venda Total dos últimos 30 dias',
    align: 'center',
    flex: 1,
  },
  status: {
    label: 'Status',
    name: 'status',
    show: false,
    dica: 'Status do Item, se está inativo ou ativo',
    align: 'center',
    hasSort: true,
    flex: 1,
  },
  vmd_na_maior_unidade_possivel: {
    label: 'VMD',
    name: 'vmd_na_maior_unidade_possivel',
    show: false,
    dica: 'Venda Média Diária',
    align: 'center',
    flex: 1,
  },
  peso_padrao: {
    label: 'PP (kg)',
    name: 'peso_padrao',
    show: false,
    dica: 'Peso Padrão (Kg)',
    align: 'center',
    flex: 1,
  },
  volume_unitario: {
    label: 'VU (m³)',
    name: 'volume_unitario',
    show: false,
    dica: 'Volume Unitário de uma caixa (m³)',
    align: 'center',
    flex: 1,
  },
  peso_bruto: {
    label: 'PB (kg)',
    name: 'peso_bruto',
    show: false,
    dica: 'Peso Bruto da caixa (kg)',
    align: 'center',
    flex: 1,
  },
  regra_de_arredondamento: {
    label: 'RA',
    name: 'regra_de_arredondamento',
    show: false,
    dica: 'Regra de Arredondamento',
    align: 'center',
    flex: 1,
  },
  caixaria_nivel_1: {
    label: 'cx1',
    name: 'caixaria_nivel_1',
    show: false,
    dica: 'Caixaria nível 1',
    align: 'center',
    flex: 1,
  },
  caixaria_nivel_2: {
    label: 'cx2',
    name: 'caixaria_nivel_2',
    show: false,
    dica: 'Caixaria nível 2',
    align: 'center',
    flex: 1,
  },
  caixaria_nivel_3: {
    label: 'cx3',
    name: 'caixaria_nivel_3',
    show: false,
    dica: 'Caixaria nível 3',
    align: 'center',
    flex: 1,
  },
};

const DialogoTrocoInicial = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [dados, setDados] = useState(null);

  ///////////////////////////////////

  function getTotaisFormatados(dados) {
    const totais = {
      pedido: 0,
      subtotal: 0,
      bonificacao_em_cxs: 0,
      bonificacao_subtotal: 0,
      peso_subtotal: 0,
      volume_subtotal: 0,
      rs_un_mais_bonificacao: 0,
      peso_subtotal_liquido: 0,
    };

    totais.pedido = dados.total_qtde;
    totais.subtotal = formatMoeda(dados.total);
    totais.bonificacao_em_cxs = dados.total_bonificado;
    totais.bonificacao_subtotal = formatMoeda(dados.total_bonificacao);
    totais.peso_subtotal = formatPeso(dados.peso_total);
    totais.volume_subtotal = `${formatPreco(dados.volume_total, 2, '')}m³`;
    totais.rs_un_mais_bonificacao = formatMoeda(
      dados.total - dados.total_bonificacao,
    );
    totais.peso_subtotal_liquido = formatPeso(dados.peso_total_liquido);

    return totais;
  }

  function getVtu30(item, unidade_adotada_na_compra) {
    const qtde =
      item.produto.unidade.tipo === POR_UNIDADE
        ? item.produto.unidades_vendidas_ultimos_30_dias
        : item.produto.peso_vendido_ultimos_30_dias;

    switch (unidade_adotada_na_compra) {
      case TIPO_UNIDADE_KG: {
        return qtde;
      }
      case TIPO_UNIDADE_UN: {
        const fator =
          item.produto.unidade.tipo === POR_PESO_FIXO
            ? item.produto.peso_medio
            : 1;
        return qtde / fator;
      }
      case TIPO_UNIDADE_CX1: {
        const fator =
          item.produto.unidade.tipo === POR_PESO_FIXO
            ? item.produto.peso_medio
            : 1;
        return qtde / (fator * item.caixaria_nivel_1);
      }
      case TIPO_UNIDADE_CX2: {
        const fator =
          item.produto.unidade.tipo === POR_PESO_FIXO
            ? item.produto.peso_medio
            : 1;
        return qtde / (fator * item.caixaria_nivel_1 * item.caixaria_nivel_2);
      }
      case TIPO_UNIDADE_CX3: {
        const fator =
          item.produto.unidade.tipo === POR_PESO_FIXO
            ? item.produto.peso_medio
            : 1;
        return (
          qtde /
          (fator *
            item.caixaria_nivel_1 *
            item.caixaria_nivel_2 *
            item.caixaria_nivel_3)
        );
      }
      default: {
        return Math.round(qtde);
      }
    }
  }

  function getEstoque(item, unidade_adotada_na_compra) {
    const qtde =
      item.produto.unidade.tipo === POR_UNIDADE
        ? item.produto.unidadesDisponivel
        : item.produto.pesoDisponivel;
    switch (unidade_adotada_na_compra) {
      case TIPO_UNIDADE_KG: {
        return qtde;
      }
      case TIPO_UNIDADE_UN: {
        const fator =
          item.produto.unidade.tipo === POR_PESO_FIXO
            ? item.produto.peso_medio
            : 1;
        return qtde / fator;
      }
      case TIPO_UNIDADE_CX1: {
        const fator =
          item.produto.unidade.tipo === POR_PESO_FIXO
            ? item.produto.peso_medio
            : 1;
        return qtde / (fator * item.caixaria_nivel_1);
      }
      case TIPO_UNIDADE_CX2: {
        const fator =
          item.produto.unidade.tipo === POR_PESO_FIXO
            ? item.produto.peso_medio
            : 1;
        return qtde / (fator * item.caixaria_nivel_1 * item.caixaria_nivel_2);
      }
      case TIPO_UNIDADE_CX3: {
        const fator =
          item.produto.unidade.tipo === POR_PESO_FIXO
            ? item.produto.peso_medio
            : 1;
        return (
          qtde /
          (fator *
            item.caixaria_nivel_1 *
            item.caixaria_nivel_2 *
            item.caixaria_nivel_3)
        );
      }
      default: {
        return qtde;
      }
    }
  }

  function formatNegativo(value) {
    return `- (${value})`;
  }

  function getTextEstoque(item, unidades2, peso) {
    const qtde =
      item.produto.unidade.tipo === POR_UNIDADE ||
      item.produto.unidade.tipo === POR_PESO_FIXO
        ? unidades2
        : peso;

    const isNegativa = qtde < 0;

    const pesoUtilizadasPositivas = isNegativa ? qtde * -1 : qtde;

    const objQtde = [];

    let caixaMae = 0;

    let resto1 = pesoUtilizadasPositivas;

    if (item.caixaria_nivel_3) {
      caixaMae = parseInt(
        pesoUtilizadasPositivas /
          (item.caixaria_nivel_3 *
            item.caixaria_nivel_2 *
            item.caixaria_nivel_1),
        10,
      );

      objQtde.push({
        qtde: caixaMae,
        label: `${caixaMae}cx3`,
      });

      resto1 =
        pesoUtilizadasPositivas %
        (item.caixaria_nivel_3 * item.caixaria_nivel_2 * item.caixaria_nivel_1);
    }

    let caixetas = 0;

    let resto2 = pesoUtilizadasPositivas;

    if (item.caixaria_nivel_2) {
      caixetas = parseInt(
        resto1 / (item.caixaria_nivel_2 * item.caixaria_nivel_1),
        10,
      );

      objQtde.push({
        qtde: caixetas,
        label: `${caixetas}cx2`,
      });

      resto2 = resto1 % (item.caixaria_nivel_2 * item.caixaria_nivel_1);
    }

    let caixas = 0;

    let unidades = pesoUtilizadasPositivas;

    if (item.caixaria_nivel_1) {
      caixas = parseInt(resto2 / item.caixaria_nivel_1, 10);

      objQtde.push({
        qtde: caixas,
        label: `${caixas}cx1`,
      });

      unidades = resto2 % item.caixaria_nivel_1;

      if (unidades) {
        if (item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
          objQtde.push({
            qtde: unidades,
            label: `${formatPreco(unidades, 3, '')}`,
          });
        } else {
          objQtde.push({
            qtde: unidades,
            label: `${unidades}un`,
          });
        }
      }
    }

    let labelRetorno = objQtde
      .filter((item) => item.qtde > 0)
      .map((item) => item.label)
      .join(' + ')
      .trim();

    labelRetorno = labelRetorno
      ? isNegativa
        ? formatNegativo(labelRetorno)
        : labelRetorno
      : '-';

    return labelRetorno;
  }

  function getEstoqueLabel(item, unidade_adotada_na_compra, unidades, peso) {
    switch (unidade_adotada_na_compra) {
      case TIPO_UNIDADE_KG: {
        return peso ? `${formatPreco(peso, 0, '')}kg` : '-';
      }
      case TIPO_UNIDADE_UN: {
        return unidades ? `${unidades}un` : '-';
      }
      case TIPO_UNIDADE_CX1: {
        return getTextEstoque(
          {
            ...item,
            caixaria_nivel_1: item.caixaria_nivel_1,
            caixaria_nivel_2: 0,
            caixaria_nivel_3: 0,
          },
          unidades,
          peso,
        );
      }
      case TIPO_UNIDADE_CX2: {
        return getTextEstoque(
          {
            ...item,
            caixaria_nivel_1: item.caixaria_nivel_1,
            caixaria_nivel_2: item.caixaria_nivel_2,
            caixaria_nivel_3: 0,
          },
          unidades,
          peso,
        );
      }
      case TIPO_UNIDADE_CX3: {
        return getTextEstoque(
          {
            ...item,
            caixaria_nivel_1: item.caixaria_nivel_1,
            caixaria_nivel_2: item.caixaria_nivel_2,
            caixaria_nivel_3: item.caixaria_nivel_3,
          },
          unidades,
          peso,
        );
      }
      default: {
        return '-';
      }
    }
  }

  function getPrecos(dados) {
    const valor = dados.unitario;
    const item = dados;
    switch (dados.tipo_unidade_preco) {
      case TIPO_UNIDADE_KG: {
        const rs_kg = valor;
        let fator = 1;
        if (item.produto.unidade.tipo === POR_PESO_FIXO)
          fator = item.produto.peso_medio;
        let rs_un = valor * fator;
        if (item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE)
          rs_un = 0;
        const rs_cx_1 = rs_kg * fator * item.caixaria_nivel_1;

        const rs_cx_2 =
          rs_kg * fator * item.caixaria_nivel_1 * item.caixaria_nivel_2;

        const rs_cx_3 =
          rs_kg *
          fator *
          item.caixaria_nivel_1 *
          item.caixaria_nivel_2 *
          item.caixaria_nivel_3;

        return {
          rs_kg,
          rs_un,
          rs_cx_1,
          rs_cx_2,
          rs_cx_3,
        };
      }
      case TIPO_UNIDADE_UN: {
        let fator = 1;
        if (item.produto.unidade.tipo === POR_PESO_FIXO)
          fator = item.produto.peso_medio;
        let rs_kg = valor / fator;

        const rs_un = valor;
        const rs_cx_1 = rs_kg * fator * item.caixaria_nivel_1;

        const rs_cx_2 =
          rs_kg * fator * item.caixaria_nivel_1 * item.caixaria_nivel_2;

        const rs_cx_3 =
          rs_kg *
          fator *
          item.caixaria_nivel_1 *
          item.caixaria_nivel_2 *
          item.caixaria_nivel_3;

        if (item.produto.unidade.tipo === POR_UNIDADE) rs_kg = 0;

        return {
          rs_kg,
          rs_un,
          rs_cx_1,
          rs_cx_2,
          rs_cx_3,
        };
      }
      case TIPO_UNIDADE_CX1: {
        let fator = 1;
        if (item.produto.unidade.tipo === POR_PESO_FIXO)
          fator = item.produto.peso_medio;
        let rs_kg = valor / (fator * item.caixaria_nivel_1);

        let rs_un = valor / item.caixaria_nivel_1;

        if (item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE)
          rs_un = 0;
        const rs_cx_1 = valor;

        const rs_cx_2 =
          rs_kg * fator * item.caixaria_nivel_1 * item.caixaria_nivel_2;

        const rs_cx_3 =
          rs_kg *
          fator *
          item.caixaria_nivel_1 *
          item.caixaria_nivel_2 *
          item.caixaria_nivel_3;

        if (item.produto.unidade.tipo === POR_UNIDADE) rs_kg = 0;

        return {
          rs_kg,
          rs_un,
          rs_cx_1,
          rs_cx_2,
          rs_cx_3,
        };
      }
      case TIPO_UNIDADE_CX2: {
        let fator = 1;
        if (item.produto.unidade.tipo === POR_PESO_FIXO)
          fator = item.produto.peso_medio;
        let rs_kg =
          valor / (fator * item.caixaria_nivel_1 * item.caixaria_nivel_2);

        let rs_un = valor / (item.caixaria_nivel_1 * item.caixaria_nivel_2);

        if (item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE)
          rs_un = 0;

        const rs_cx_1 = valor / item.caixaria_nivel_2;

        const rs_cx_2 = valor;

        const rs_cx_3 =
          rs_kg *
          fator *
          item.caixaria_nivel_1 *
          item.caixaria_nivel_2 *
          item.caixaria_nivel_3;

        if (item.produto.unidade.tipo === POR_UNIDADE) rs_kg = 0;

        return {
          rs_kg,
          rs_un,
          rs_cx_1,
          rs_cx_2,
          rs_cx_3,
        };
      }
      case TIPO_UNIDADE_CX3: {
        let fator = 1;
        if (item.produto.unidade.tipo === POR_PESO_FIXO)
          fator = item.produto.peso_medio;
        let rs_kg =
          valor /
          (fator *
            item.caixaria_nivel_1 *
            item.caixaria_nivel_2 *
            item.caixaria_nivel_3);

        let rs_un =
          valor /
          (item.caixaria_nivel_1 *
            item.caixaria_nivel_2 *
            item.caixaria_nivel_3);

        if (item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE)
          rs_un = 0;

        const rs_cx_1 = valor / (item.caixaria_nivel_2 * item.caixaria_nivel_3);

        const rs_cx_2 = valor / item.caixaria_nivel_3;

        const rs_cx_3 = valor;

        if (item.produto.unidade.tipo === POR_UNIDADE) rs_kg = 0;
        return {
          rs_kg,
          rs_un,
          rs_cx_1,
          rs_cx_2,
          rs_cx_3,
        };
      }
      default:
        return { rs_kg: 0, rs_un: 0, rs_cx_1: 0, rs_cx_2: 0, rs_cx_3: 0 };
    }
  }

  function getPrecoParaCalculos({
    rs_kg,
    rs_un,
    rs_cx_1,
    rs_cx_2,
    rs_cx_3,
    unidade_adotada_na_compra,
  }) {
    switch (unidade_adotada_na_compra) {
      case TIPO_UNIDADE_KG:
        return rs_kg;
      case TIPO_UNIDADE_UN:
        return rs_un;
      case TIPO_UNIDADE_CX1:
        return rs_cx_1;
      case TIPO_UNIDADE_CX2:
        return rs_cx_2;
      case TIPO_UNIDADE_CX3:
        return rs_cx_3;
      default:
        return rs_kg;
    }
  }

  function getFatorCaixariaUnidadeAdotadaNaCompra(
    item,
    unidade_adotada_na_compra,
  ) {
    switch (unidade_adotada_na_compra) {
      case TIPO_UNIDADE_KG: {
        return 1;
      }
      case TIPO_UNIDADE_UN: {
        return 1;
      }
      case TIPO_UNIDADE_CX1: {
        return item.caixaria_nivel_1;
      }
      case TIPO_UNIDADE_CX2: {
        return item.caixaria_nivel_1 * item.caixaria_nivel_2;
      }
      case TIPO_UNIDADE_CX3: {
        return (
          item.caixaria_nivel_1 * item.caixaria_nivel_2 * item.caixaria_nivel_3
        );
      }
      default: {
        return 0;
      }
    }
  }

  function getPeso(item, pedido, unidade_adotada_na_compra) {
    const fator = getFatorCaixariaUnidadeAdotadaNaCompra(
      item,
      unidade_adotada_na_compra,
    );
    switch (item.unidade.tipo) {
      case POR_UNIDADE:
        return pedido * fator * item.peso_padrao_na_compra;
      case POR_PESO_FIXO:
        return pedido * fator * item.peso_medio;
      case POR_PESO_VARIAVEL_SEM_UNIDADE:
        return pedido * fator;
      default:
        return 0;
    }
  }

  function getItensFormatados(dados) {
    const itensFormatados = [];
    for (let i = 0; i < dados.itens.length; i++) {
      const item = { ...dados.itens[i], ...dados.itens[i].produto };
      const prazo_medio_pagamento = dados.fornecedor.prazo_medio_pagamento;
      /////////////
      item.caixaria_nivel_1 =
        item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
          ? item.produto.peso_caixa_nivel_1
          : item.produto.unidades_caixa_nivel_1;
      item.caixaria_nivel_2 = item.produto.unidades_caixa_nivel_2;
      item.caixaria_nivel_3 = item.produto.unidades_caixa_nivel_3;

      const { rs_kg, rs_un, rs_cx_1, rs_cx_2, rs_cx_3 } = getPrecos(item);

      const unidade_preco_editado = item.tipo_unidade_preco;

      const unidade_adotada_na_compra = item.tipo_unidade_qtde;

      const regra_de_arredondamento =
        item.produto.arredondamento_no_pedido_de_compra;

      const pedido = item.qtde;

      const estoque_atual = getEstoque(item, unidade_adotada_na_compra);

      const vtu_30 = getVtu30(item, unidade_adotada_na_compra);

      const vmd_na_maior_unidade_possivel = vtu_30 / 30;

      const estoque_ideal =
        vmd_na_maior_unidade_possivel * prazo_medio_pagamento;

      const precoParaCalculos = getPrecoParaCalculos({
        rs_kg,
        rs_un,
        rs_cx_1,
        rs_cx_2,
        rs_cx_3,
        unidade_adotada_na_compra,
      });

      let bonificacao_em_cxs = 0;

      if (item.produto.ativar_bonificacao) {
        bonificacao_em_cxs = pedido / regra_de_arredondamento;
      }

      const bonificacao_subtotal = bonificacao_em_cxs * precoParaCalculos;

      const volume_unitario = item.produto.volume_unitario_vu;

      const peso_bruto = item.produto.peso_unitario_pu;

      const peso_subtotal = pedido * peso_bruto;

      const volume_subtotal = pedido * volume_unitario;

      const subtotal = pedido * precoParaCalculos;

      const peso_subtotal_liquido = getPeso(
        item,
        pedido,
        unidade_adotada_na_compra,
      );

      const rs_un_mais_bonificacao =
        (pedido * precoParaCalculos) / (pedido + bonificacao_em_cxs);

      const is_estoque_negativo =
        item.produto.status !== STATUS_ATIVO
          ? true
          : estoque_atual < 0
          ? true
          : false;
      /////////////
      const estoque_ideal_label = estoque_ideal
        ? `${formatPreco(estoque_ideal, 0, '')}${unidade_adotada_na_compra}`
        : '-';

      const estoque_atual_label = getEstoqueLabel(
        item,
        unidade_adotada_na_compra,
        item.produto.unidadesDisponivel,
        item.produto.pesoDisponivel,
      );

      const vtu_30_label = getEstoqueLabel(
        item,
        unidade_adotada_na_compra,
        item.produto.unidades_vendidas_ultimos_30_dias,
        item.produto.peso_vendido_ultimos_30_dias,
      );

      const vmd_na_maior_unidade_possivel_label = vtu_30
        ? `${formatPreco(vtu_30 / 30, 2, '')}${unidade_adotada_na_compra}`
        : '-';
      /////////////

      itensFormatados.push({
        id: item.id,
        descricao: item.produto.nome,
        rs_kg:
          item.produto.unidade.tipo === POR_UNIDADE
            ? '-'
            : formatPreco(rs_kg, 2, ''),
        rs_un:
          item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
            ? '-'
            : formatPreco(rs_un, 2, ''),
        rs_cx_1: item.caixaria_nivel_1 <= 0 ? '-' : formatPreco(rs_cx_1, 2, ''),
        rs_cx_2: item.caixaria_nivel_1 <= 0 ? '-' : formatPreco(rs_cx_2, 2, ''),
        rs_cx_3: item.caixaria_nivel_1 <= 0 ? '-' : formatPreco(rs_cx_3, 2, ''),
        pedido, //ok
        unidade_preco_editado, //ok
        unidade_adotada_na_compra, //ok
        subtotal: formatPreco(subtotal, 2, ''), //ok
        bonificacao_em_cxs: bonificacao_em_cxs ? bonificacao_em_cxs : '-', //ok
        bonificacao_subtotal: bonificacao_subtotal
          ? formatPreco(bonificacao_subtotal, 2, '')
          : '-',
        rs_un_mais_bonificacao: bonificacao_subtotal
          ? formatPreco(rs_un_mais_bonificacao, 2, '')
          : '-',
        peso_subtotal: peso_subtotal ? formatPeso(peso_subtotal, 3, '') : '-',
        volume_subtotal: volume_subtotal
          ? `${formatPreco(volume_subtotal, 2, '')}m³`
          : '-',
        estoque_atual: estoque_atual_label,
        estoque_ideal: estoque_ideal_label,
        vtu_30: vtu_30_label,
        vmd_na_maior_unidade_possivel: vmd_na_maior_unidade_possivel_label,
        peso_padrao: formatPreco(item.produto.peso_medio, 3, ''),
        volume_unitario: formatPreco(item.produto.volume_unitario, 2, ''),
        peso_bruto: formatPreco(item.produto.peso_bruto, 3, ''),
        regra_de_arredondamento,
        caixaria_nivel_1: item.caixaria_nivel_1,
        caixaria_nivel_2: item.caixaria_nivel_2,
        caixaria_nivel_3: item.caixaria_nivel_3,
        styles: {
          opacity: is_estoque_negativo ? 0.5 : 1,
        },
        status: item.produto.status === 'inativo' ? 'Inativo' : '',
        //
        show_rs_kg_original:
          item.produto.unidade.tipo === POR_UNIDADE ? false : true,
        show_rs_un_original:
          item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
            ? false
            : true,
        show_rs_cx_1_original: item.caixaria_nivel_1 <= 0 ? false : true,
        show_rs_cx_2_original: item.caixaria_nivel_2 <= 0 ? false : true,
        show_rs_cx_3_original: item.caixaria_nivel_3 <= 0 ? false : true,
        peso_subtotal_liquido: peso_subtotal_liquido
          ? formatPeso(peso_subtotal_liquido, 3, '')
          : '-',
      });
    }
    return itensFormatados;
    return [
      {
        id: 999,
        descricao: '0123456789',
        rs_kg: '123,45',
        rs_un: '123,45',
        rs_cx_1: '123,45',
        rs_cx_2: '123,45',
        rs_cx_3: '123,45',
        pedido: 123,
        unidade_preco_editado: 'cx1',
        unidade_adotada_na_compra: 'cx3',
        subtotal: '92345,54',
        bonificacao_em_cxs: 999,
        bonificacao_subtotal: '92345,54',
        rs_un_mais_bonificacao: '123,45',
        peso_subtotal: '123456,34',
        volume_subtotal: `12345,67`,

        estoque_atual: '',
        estoque_ideal: '',
        vtu_30: '',
        vmd_na_maior_unidade_possivel: '',
        peso_padrao: '',
        volume_unitario: '',
        peso_bruto: '',
        regra_de_arredondamento: 10,
        caixaria_nivel_1: 10,
        caixaria_nivel_2: 10,
        caixaria_nivel_3: 10,
        styles: {
          opacity: 1,
        },
        status: '',
        //
        show_rs_kg_original: true,
        show_rs_un_original: true,
        show_rs_cx_1_original: true,
        show_rs_cx_2_original: true,
        show_rs_cx_3_original: true,
        peso_subtotal_liquido: '123456,34',
      },
    ];
  }

  ////////////////////////////////CABEÇALHO

  function gerLabelPrazosFornecedor(fornecedor) {
    let prazoLabel = 'Nenhum prazo cadastrado!';
    if (fornecedor) {
      if (fornecedor.prazos) {
        if (fornecedor.prazos.length > 0) {
          prazoLabel = fornecedor.prazos
            .sort((a, b) => a.dias - b.dias)
            .map((item) => item.dias)
            .join(', ');
        }
      }
    }
    return prazoLabel;
  }

  function getLabelPedidoMinimo(fornecedor) {
    switch (fornecedor.tipo_pedido_minimo_de_compra) {
      case TIPO_PEDIDO_MINIMO_COMPRA_KG:
        return formatPeso(fornecedor.pedido_minimo_de_compra);
      case TIPO_PEDIDO_MINIMO_COMPRA_CAIXAS:
        return `${Math.round(fornecedor.pedido_minimo_de_compra)} Caixas`;
      case TIPO_PEDIDO_MINIMO_COMPRA_VALOR:
        return formatMoeda(fornecedor.pedido_minimo_de_compra);
      default:
        return '';
    }
  }

  function getDiasSemComprar(dataUltimoPedido) {
    if (!dataUltimoPedido) return '';
    const dataUltimoPedido2 = new Date(
      moment(dataUltimoPedido).endOf('day').format(),
    );

    const dataAtual = new Date(moment(new Date()).endOf('day').format());

    const delta = dataAtual.getTime() - dataUltimoPedido2.getTime();

    return parseInt(delta / (24 * 60 * 60 * 1000), 10);
  }

  function getDadosCabecalho(
    fornecedor,
    config,
    pesoTotal,
    data,
    totalPedido,
    verbaJornal,
    verbaBackligth,
    bonificacao,
    comprador,
  ) {
    const descarrego = (pesoTotal / 1000) * fornecedor.frete_valor_por_tonelada;
    const descontoBoletoPorcentagem = fornecedor.desconto_em_boleto;
    const descontoBoleto = (descontoBoletoPorcentagem / 100) * totalPedido;

    const verbaTotal =
      verbaJornal + verbaBackligth + bonificacao + descarrego + descontoBoleto;

    const dataUltimoPedido = formatDateDDMMYYYYHHmm(
      fornecedor.dataUltimoPedido,
    );

    const diasSemComprar = getDiasSemComprar(fornecedor.dataUltimoPedido);

    return {
      fornecedor: {
        razao_social: fornecedor.razao_social,
        nome_fantasia: fornecedor.nome_fantasia,
        prazo_de_entrega: `${fornecedor.tempo_entrega} dias`,
        prazo_fornecedor: gerLabelPrazosFornecedor(fornecedor),
        prazo_medio_pagamento: `${fornecedor.prazo_medio_pagamento} dias`,
        restricao_pedido_minimo: getLabelPedidoMinimo(fornecedor),
        estado_onde_fatura: fornecedor.estado,
        credito_icms_aliquota_interna: formatPorcentagem(
          fornecedor.icms_ai_compra,
        ),
        limite_credito_total: formatMoeda(fornecedor.limite),
        limite_credito_utilizado: '',
        limite_credito_disponivel: '',
        penalizacao_juros_atrazo: formatPorcentagem(fornecedor.juros),
        penalizacao_multa_atrazo: formatPorcentagem(fornecedor.multa),
        perde_desconto_atrazo: fornecedor.is_perde_desconto_no_atrazo
          ? 'SIM'
          : 'NÃO',
        tarifa_boleto: formatMoeda(fornecedor.tarifa_boleto),
        desconto_menor_prazo: formatPorcentagem(
          fornecedor.desconto_menor_Preco,
        ),
        funcionarios: fornecedor.funcionarios,
      },
      comprador: {
        comprador,
        razao_social: config.razao_social,
        nome_fantasia: config.nome_fantasia,
        cnpj: config.cnpj ? formatCNPJ(config.cnpj) : '-',
        horario_recebimento: '',
        dias_recebimento: '',
        descarrego_tonelada: fornecedor.frete_valor_por_tonelada
          ? `${fornecedor.frete_valor_por_tonelada} / t`
          : '-',
      },
      pedido: {
        peso_total: formatPeso(pesoTotal),
        data_hora: formatDateDDMMYYYYHHmm(data),
        data_hora_ultimo_pedido: dataUltimoPedido,
        numero_dias_sem_compra: diasSemComprar,
        data_previsao_chegada: formatDateDDMMYYYY(
          new Date(moment(data).add('days', fornecedor.tempo_entrega).format()),
        ),
        total_pedido: formatMoeda(totalPedido),
        estimativa_total_encargos_1_dia_atrazo: formatMoeda(
          ((fornecedor.juros + fornecedor.multa) / 100) * totalPedido,
        ),
        estimativa_total_encargos_1_dia_atrazo_mais_perda_desconto_boleto: formatMoeda(
          ((fornecedor.juros +
            fornecedor.multa +
            fornecedor.desconto_menor_Preco) /
            100) *
            totalPedido,
        ),
        desconto_menor_prazo: formatMoeda(
          (fornecedor.desconto_menor_Preco / 100) * totalPedido,
        ),
      },
      verbas: {
        bonificacao: formatMoeda(bonificacao),
        jornal_ofertas: formatMoeda(verbaJornal),
        descarrego: formatMoeda(descarrego),
        desconto_boleto_porcentagem: formatPorcentagem(
          descontoBoletoPorcentagem,
        ),
        desconto_boleto: formatMoeda(descontoBoleto),
        desconto_backlight: formatMoeda(verbaBackligth),
        total: formatMoeda(verbaTotal),
      },
    };
  }

  function formatDadosCabecalho(dados) {
    const dadosFormatados = {
      dadosFonecedor: [],
      dadosComprador: [],
      dadosPedido: [],
      dadosVerbas: [],
    };

    if (dados) {
      dadosFormatados.dadosFonecedor.push({
        label: 'Razão Social:',
        value: dados.fornecedor.razao_social,
      });

      dadosFormatados.dadosFonecedor.push({
        label: 'Nome Fantasia:',
        value: dados.fornecedor.nome_fantasia,
      });

      for (let i = 0; i < dados.fornecedor.funcionarios.length; i++) {
        dadosFormatados.dadosFonecedor.push({
          label: dados.fornecedor.funcionarios[i].funcao,
          value: dados.fornecedor.funcionarios[i].nome,
        });
      }

      dadosFormatados.dadosFonecedor.push({
        label: 'Prazo de Entrega (dias):',
        value: dados.fornecedor.prazo_de_entrega,
      });

      dadosFormatados.dadosFonecedor.push({
        label: 'Prazo de Pagamento do Fornecedor (dias):',
        value: dados.fornecedor.prazo_fornecedor,
      });

      dadosFormatados.dadosFonecedor.push({
        label: 'Prazo médio de pagamento (dias):',
        value: dados.fornecedor.prazo_medio_pagamento,
      });

      dadosFormatados.dadosFonecedor.push({
        label: 'Restrição de Pedido Mínimo (kg)/R$/cxs:',
        value: dados.fornecedor.restricao_pedido_minimo,
      });

      dadosFormatados.dadosFonecedor.push({
        label: 'Estado onde Fatura:',
        value: dados.fornecedor.estado_onde_fatura,
      });

      dadosFormatados.dadosFonecedor.push({
        label: 'Crédito de ICMS Alíquota Interna / Normal:',
        value: dados.fornecedor.credito_icms_aliquota_interna,
      });

      dadosFormatados.dadosFonecedor.push({
        label: 'Limite de Crédito Total:',
        value: dados.fornecedor.limite_credito_total,
      });

      dadosFormatados.dadosFonecedor.push({
        label: 'Limite de Crédito Utilizado:',
        value: dados.fornecedor.limite_credito_utilizado,
      });

      dadosFormatados.dadosFonecedor.push({
        label: 'Limite de Crédito Disponível:',
        value: dados.fornecedor.limite_credito_disponivel,
      });

      dadosFormatados.dadosFonecedor.push({
        label: 'Penalização de Juros de atraso (% ao mês):',
        value: dados.fornecedor.penalizacao_juros_atrazo,
      });

      dadosFormatados.dadosFonecedor.push({
        label: 'Penalização de Multa de atraso (% único):',
        value: dados.fornecedor.penalizacao_multa_atrazo,
      });

      dadosFormatados.dadosFonecedor.push({
        label: 'Perde o Desconto caso haja atraso (Sim/Não)?',
        value: dados.fornecedor.perde_desconto_atrazo,
      });

      dadosFormatados.dadosFonecedor.push({
        label: 'Tarifa de cada Boleto:',
        value: dados.fornecedor.tarifa_boleto,
      });

      dadosFormatados.dadosFonecedor.push({
        label: 'Desconto em caso de menor prazo (%):',
        value: dados.fornecedor.desconto_menor_prazo,
      });

      /////////////////////////////////////////

      dadosFormatados.dadosComprador.push({
        label: 'Comprador:',
        value: dados.comprador.comprador,
      });

      dadosFormatados.dadosComprador.push({
        label: 'Razão Social:',
        value: dados.comprador.razao_social,
      });

      dadosFormatados.dadosComprador.push({
        label: 'Nome Fantasia:',
        value: dados.comprador.nome_fantasia,
      });

      dadosFormatados.dadosComprador.push({
        label: 'CNPJ:',
        value: dados.comprador.cnpj,
      });

      dadosFormatados.dadosComprador.push({
        label: 'Horário de Recebimento:',
        value: '',
      });

      dadosFormatados.dadosComprador.push({
        label: 'Dias de Recebimento:',
        value: '',
      });

      dadosFormatados.dadosComprador.push({
        label: 'Não Recebemos em Feriados.',
        value: '',
      });

      dadosFormatados.dadosComprador.push({
        label: 'Não Recebemos Cargas acima de 10 toneladas após 17:00.',
        value: '',
      });

      dadosFormatados.dadosComprador.push({
        label: 'Não recebemos veículos de altura superior a 3,90 m',
        value: '',
      });

      dadosFormatados.dadosComprador.push({
        label: 'Descarrego/Tonelada:',
        value: dados.comprador.descarrego_tonelada,
      });

      ////////////

      dadosFormatados.dadosPedido.push({
        label: 'Peso Total do pedido (toneladas):',
        value: dados.pedido.peso_total,
      });

      dadosFormatados.dadosPedido.push({
        label: 'Data e Hora do pedido:',
        value: dados.pedido.data_hora,
      });

      dadosFormatados.dadosPedido.push({
        label: 'Data e Hora do último pedido:',
        value: dados.pedido.data_hora_ultimo_pedido,
      });

      dadosFormatados.dadosPedido.push({
        label: 'Número de dias sem compra',
        value: dados.pedido.numero_dias_sem_compra,
      });

      dadosFormatados.dadosPedido.push({
        label: 'Data de previsão de chegada:',
        value: dados.pedido.data_previsao_chegada,
      });

      dadosFormatados.dadosPedido.push({
        label: 'R$ Total do Pedido',
        value: dados.pedido.total_pedido,
      });

      dadosFormatados.dadosPedido.push({
        label: 'Estimativa de Total de Encargos (1 dia de atraso):',
        value: dados.pedido.estimativa_total_encargos_1_dia_atrazo,
      });

      dadosFormatados.dadosPedido.push({
        label:
          'Estimativa de Total de Encargos (1 dia de atraso) + Perda de Desconto no Boleto:',
        value:
          dados.pedido
            .estimativa_total_encargos_1_dia_atrazo_mais_perda_desconto_boleto,
      });

      dadosFormatados.dadosPedido.push({
        label: 'Desconto em caso de menor prazo (R$):',
        value: dados.pedido.desconto_menor_prazo,
      });

      /////////////////////////

      dadosFormatados.dadosVerbas.push({
        label: 'Verba de Bonificação:',
        value: dados.verbas.bonificacao,
      });

      dadosFormatados.dadosVerbas.push({
        label: 'Verba de Jornal de Ofertas:',
        value: dados.verbas.jornal_ofertas,
      });

      dadosFormatados.dadosVerbas.push({
        label: 'Verba de Descarrego:',
        value: dados.verbas.descarrego,
      });

      dadosFormatados.dadosVerbas.push({
        label: 'Verba de Desconto em Boleto (%):',
        value: dados.verbas.desconto_boleto_porcentagem,
      });

      dadosFormatados.dadosVerbas.push({
        label: 'Verba de Desconto em Boleto:',
        value: dados.verbas.desconto_boleto,
      });

      dadosFormatados.dadosVerbas.push({
        label: 'Verba de Backlight:',
        value: dados.verbas.desconto_backlight,
      });

      dadosFormatados.dadosVerbas.push({
        label: 'Verba Total',
        value: dados.verbas.total,
      });
    }

    return dadosFormatados;
  }

  ////////////////////////////////CABEÇALHO

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'pedidos-compras',
        ['id', 'asc'],
        { id: [id] },
        [
          'fornecedor.cidade.estado',
          'itens.produto.unidade',
          'pasta',
          'comprador',
        ],
      );
      if (data.data.length > 0) {
        const itens = getItensFormatados(data.data[0]);
        const showsColunas = getShows(itens);
        const valuesChows = Object.keys(showsColunas);

        const colunas = { ...colunasTemplate };

        for (let i = 0; i < valuesChows.length; i++) {
          const key = valuesChows[i];
          colunas[key].show = showsColunas[key]
            ? colunas[key].show
            : showsColunas[key];
        }

        const response = await api.get(
          `/dados-cabecalho-pedido-compra/${data.data[0].fornecedor.id}`,
        );

        const data2 = response.data;
        const { config, fornecedor: forn } = data2.data;

        const pesoTotal = data.data[0].peso_total_liquido;

        const totalPedido = data.data[0].total;

        const dataPedido = new Date(data.data[0].data);

        const verbaJornal = data.data[0].verba_jornal;

        const verbaBackligth = data.data[0].verba_backlight;

        const bonificacao = data.data[0].total_bonificacao;

        const comprador = data.data[0].comprador
          ? data.data[0].comprador.username
          : '';

        setDados({
          fornecedor: data.data[0].fornecedor,
          itens,
          totais: getTotaisFormatados(data.data[0]),
          pasta: data.data[0].pasta ? data.data[0].pasta.nome : '-',
          shows: valuesChows,
          colunas,
          id,
          cabecalho: formatDadosCabecalho(
            getDadosCabecalho(
              forn,
              config,
              pesoTotal,
              dataPedido,
              totalPedido,
              verbaJornal,
              verbaBackligth,
              bonificacao,
              comprador,
            ),
          ),
          dataPedido: formatDateDDMMYYYYHHmm(dataPedido),
          dataPedidoOriginal: dataPedido,
        });
      } else {
        throw 'Este Pedido de Compra não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleClose();
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setOpen(true);
      getDataResource(id);
    },
  }));

  function getShows(itensFormatados) {
    const shows = {
      rs_kg: false,
      rs_un: false,
      rs_cx_1: false,
      rs_cx_2: false,
      rs_cx_3: false,
      bonificacao_em_cxs: false,
      bonificacao_subtotal: false,
      rs_un_mais_bonificacao: false,
      peso_subtotal: false,
      volume_subtotal: false,
    };

    for (let i = 0; i < itensFormatados.length; i++) {
      const item = itensFormatados[i];
      if (item.show_rs_kg_original) shows.rs_kg = true;
      if (item.show_rs_un_original) shows.rs_un = true;
      if (item.show_rs_cx_1_original) shows.rs_cx_1 = true;
      if (item.show_rs_cx_2_original) shows.rs_cx_2 = true;
      if (item.show_rs_cx_3_original) shows.rs_cx_3 = true;
      if (item.bonificacao_em_cxs !== '-') {
        shows.bonificacao_em_cxs = true;
        shows.bonificacao_subtotal = true;
        shows.rs_un_mais_bonificacao = true;
      }

      if (item.peso_subtotal !== '-') shows.peso_subtotal = true;
      if (item.volume_subtotal !== '-') shows.volume_subtotal = true;
    }

    return shows;
  }

  /////////////////////////////////////////

  const handleClose = () => {
    setOpen(false);
    setDados(null);
  };

  function getNomeArquivo() {
    return `Pedido Nº ${dados.id} ${
      dados.fornecedor.nome_fantasia
    } ${formatDateDDMMYYYY(dados.dataPedidoOriginal, '.')}.pdf`;
  }

  const handleDownload = async () => {
    const blob = await pdf(<Document dados={dados} />).toBlob();
    console.log(getNomeArquivo());
    saveAs(blob, getNomeArquivo());
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        scroll="paper"
        fullScreen
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleClose}
          onBaixar={handleDownload}
        >
          {`Pedido_Nº_${dados ? dados.id : 0}`}
        </DialogTitle>
        <DialogContent dividers style={{ padding: 0, height: '100%' }}>
          <div style={{ height: '100%' }}>
            <Box
              display={{ xs: 'block', sm: 'flex' }}
              flexDirection="column"
              marginBottom="0px"
              height="100%"
            >
              <Relatorio open={open} dados={dados} />
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
});

export default DialogoTrocoInicial;
