import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
  TextField,
  ReferenceField,
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
  DateField,
  ListContextProvider,
  NumberField,
} from 'react-admin';
import { useHistory } from 'react-router-dom';

import { Card, Divider, Tabs, Tab } from '@material-ui/core';
import moment from 'moment';

import { DialogoConfirmacao } from '../../Components';
import { api } from '../../services';
import { formatMoeda } from '../../utils';
import ActionsField from './ActionsField';
import Actions from './ActionsList';
import Filter from './Filter';

const PostPanel = (props) => (
  <div>
    <div>{`Abertura: ${moment(new Date(props.record.abertura)).format(
      'DD-MM-YYYY HH:mm',
    )}`}</div>
    {props.record.fechamento && (
      <div>{`Fechamento: ${moment(new Date(props.record.fechamento)).format(
        'DD-MM-YYYY HH:mm',
      )}`}</div>
    )}
  </div>
);

const tabs = [
  { id: 'abertas', name: 'Não Conferidas' },
  { id: 'fechadas', name: 'Conferidas' },
];

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const refDialogoConfirmacao = useRef(null);
  const [abertas, setAbertas] = useState([]);
  const [fechadas, setFechadas] = useState([]);
  const history = useHistory();

  function handleShow({ id }) {
    history.push(`/sessoes/${id}/show`);
  }

  function handleFechar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Conferir Sessão',
        'Deseja marcar essa sessão como conferida?',
        { id, codigo: 1 },
      );
    }
  }

  async function fechar(id) {
    await api.put(`/sessao_fechar/fechar/${id}`);
    handleClose();
  }

  async function handleConfirma({ id, codigo }) {
    switch (codigo) {
      case 1:
        fechar(id);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (ids && ids !== filterValues.is_fechamento) {
      switch (filterValues.is_fechamento) {
        case true:
          setFechadas(ids);
          break;
        case false:
          setAbertas(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.status]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          {
            ...filterValues,
            is_fechamento: value === 'fechadas',
          },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <Title title="Sessões" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Divider />
        <Tabs
          variant="fullWidth"
          centered
          value={!filterValues.is_fechamento ? tabs[0].id : tabs[1].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.is_fechamento === false && (
            <ListContextProvider value={{ ...listContext, ids: abertas }}>
              <Datagrid {...props}>
                <TextField source="id" sortable={false} />
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Adm"
                  allowEmpty={false}
                  sortable={false}
                  linkType={false}
                >
                  <TextField source="username" />
                </ReferenceField>
                <ReferenceField
                  source="conta_id"
                  reference="contas"
                  label="Caixa"
                  allowEmpty={false}
                  sortable={false}
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <NumberField
                  source="saldoAbertura"
                  label="Troco Inicial"
                  locales="pt-BR"
                  sortable={false}
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                />
                <NumberField
                  source="saldoAntesFechamento"
                  label="Saldo"
                  locales="pt-BR"
                  sortable={false}
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                />
                <NumberField
                  source="saldoFechamento"
                  label="Saldo Corrigido"
                  locales="pt-BR"
                  sortable={false}
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                />
                <DateField
                  source="abertura"
                  label="Abertura"
                  showTime
                  sortable={false}
                />
                <DateField
                  source="fechamento"
                  label="Fechamento"
                  showTime
                  sortable={false}
                />
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleShow={handleShow}
                  handleFechar={handleFechar}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.is_fechamento === true && (
            <ListContextProvider value={{ ...listContext, ids: fechadas }}>
              <Datagrid {...props}>
                <TextField source="id" sortable={false} />
                <ReferenceField
                  source="user_id"
                  reference="users"
                  label="Adm"
                  allowEmpty={false}
                  sortable={false}
                  linkType={false}
                >
                  <TextField source="username" />
                </ReferenceField>
                <ReferenceField
                  source="conta_id"
                  reference="contas"
                  label="Caixa"
                  allowEmpty={false}
                  sortable={false}
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <NumberField
                  source="saldoAbertura"
                  label="Troco Inicial"
                  locales="pt-BR"
                  sortable={false}
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                />
                <NumberField
                  source="saldoAntesFechamento"
                  label="Saldo"
                  locales="pt-BR"
                  sortable={false}
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                />
                <NumberField
                  source="saldoFechamento"
                  label="Saldo Corrigido"
                  locales="pt-BR"
                  sortable={false}
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                />
                <DateField
                  source="abertura"
                  label="Abertura"
                  showTime
                  sortable={false}
                />
                <DateField
                  source="fechamento"
                  label="Fechamento"
                  showTime
                  sortable={false}
                />
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleShow={handleShow}
                  handleFechar={handleFechar}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={10}
      filters={<Filter />}
      title="Sessões"
      sort={{ field: 'id', order: 'desc' }}
      filterDefaultValues={{ is_fechamento: false }}
      component="div"
    />
  </>
);

export default List;
