import * as React from 'react';
import { useRecordContext } from 'react-admin';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { STATUS_ATIVO } from '../../utils';

const ActionsField = ({
  handleEdit,
  handleShow,
  handleDelete = () => {},
  handleChangeStatus = () => {},
  openPdf = () => {},
  openNotaCega = () => {},
  isComprador,
  ...rest
}) => {
  const record = useRecordContext(rest);
  const statusValue = record
    ? record.record
      ? record.record.status === STATUS_ATIVO
      : false
    : false;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {isComprador ? (
        <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="check"
            size="small"
            onClick={() => handleEdit(record.record)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : null}

      <Tooltip title="Detalhes" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => handleShow(record.record)}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>

      {isComprador ? (
        <Tooltip title="Excluir" placement="bottom" aria-label="excluir2">
          <IconButton
            aria-label="excluir"
            size="small"
            onClick={() => handleDelete(record.record)}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {isComprador ? (
        <Tooltip title="Status" placement="bottom" aria-label="add2">
          <IconButton
            aria-label="check"
            size="small"
            onClick={() => handleChangeStatus(record.record)}
          >
            {!statusValue ? <CheckCircleOutlineIcon /> : <CancelIcon />}
          </IconButton>
        </Tooltip>
      ) : null}
      <Tooltip title="PDF" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => openPdf(record.record)}
        >
          <AssignmentIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Nota Cega" placement="bottom" aria-label="add2">
        <IconButton
          aria-label="check"
          size="small"
          onClick={() => openNotaCega(record.record)}
        >
          <AssignmentIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ActionsField;
