import * as React from 'react';
import { useRecordContext } from 'react-admin';

import { Typography } from '@material-ui/core';

import { formatMoeda } from '../../../utils';

const ValorField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const valor = record ? (record.record ? record.record[source] : 0) : 0;
  return <Typography variant="subtitle1">{formatMoeda(valor)}</Typography>;
};

export default ValorField;
