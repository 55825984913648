import React, { useRef } from 'react';
import {
  ListBase,
  ListToolbar,
  useRefresh,
  Datagrid,
  Title,
  TextField,
  SelectField,
  ReferenceField,
  FunctionField,
  BooleanField,
  Pagination,
} from 'react-admin';

import { Card } from '@material-ui/core';

import { A_VISTA } from '../../utils';
import ActionsField from './ActionsField';
import Actions from './ActionsList';
import choices from './choices';
import Dialogo from './DialogoCreateEdit';
import Filter from './Filter';

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const refDialogo = useRef(null);

  const handleEdit = (data) => {
    if (refDialogo.current) {
      refDialogo.current.handleEdit(data.id);
    }
  };

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <Title title="Meios de Pagamento" />
      <ListToolbar filters={<Filter />} actions={<Actions />} {...props} />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <TextField source="nome" label="Descrição" />
          <SelectField
            source="modalidade"
            label="Forma de Pagamento"
            choices={choices}
            sortable={false}
          />
          <FunctionField label="Taxa" render={(record) => `${record.taxa}%`} />
          <FunctionField
            label="Multa"
            render={(record) =>
              record.modalidade === A_VISTA ? '-' : `${record.multa}%`
            }
          />
          <FunctionField
            label="Juros"
            render={(record) =>
              record.modalidade === A_VISTA ? '-' : `${record.juros}%`
            }
          />
          <ReferenceField
            label="Conta"
            source="conta_id"
            reference="contas"
            sortable={false}
            linkType={false}
          >
            <TextField source="nome" />
          </ReferenceField>
          <BooleanField label="Gerencianet" source="boleto_gerencianet" />
          <BooleanField label="Dinheiro vivo" source="dinheiro_vivo" />
          <BooleanField label="Pag. Automático" source="pagamento_automatico" />
          <ActionsField
            source="lalala"
            handleEdit={handleEdit}
            textAlign="right"
            label="Ações"
            sortable={false}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <Dialogo ref={refDialogo} handleClose={handleClose} />
    </>
  );
};

const ContainerMyList = ({ ...props }) => (
  <ListBase {...props}>
    <MyList {...props} />
  </ListBase>
);

const CategoryList = (props) => (
  <>
    <ContainerMyList
      {...props}
      title=""
      component="div"
      bulkActionButtons={false}
      sort={{ field: 'nome', order: 'asc' }}
      perPage={20}
      pagination={false}
      filter={{ ativo: true }}
    />
  </>
);

export default CategoryList;
