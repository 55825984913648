import React, { useCallback } from 'react';
import {
  ListBase,
  ListToolbar,
  Pagination,
  Title,
  Datagrid,
  TextField,
  DateField,
  SelectField,
  useListContext,
  ReferenceField,
} from 'react-admin';

import { Card } from '@material-ui/core';

import DataField from '../Feriados/DataField';
import Actions from './ActionsList';
import {
  choicesTipo,
  choicesUniverso,
  choicesStatusLoja,
  choicesTipoHorario,
} from './choices';
import DateInputMobills from './DateInputMobills';
import Filter from './Filter';

const MyList = ({ ...props }) => {
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;

  const handleChangeAno = useCallback(
    (ano) => {
      if (setFilters) {
        setFilters({ ...filterValues, ano }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  return (
    <>
      <Title title="Feriados" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <div
          style={{
            margin: '10px 0px',
          }}
        >
          <DateInputMobills handleAno={(value) => handleChangeAno(value)} />
        </div>
        <Datagrid {...props}>
          <TextField source="id" label="ID" />
          <TextField source="nome" label="Descrição" />
          <DataField source="data" label="Data" />
          <SelectField
            sortable={false}
            source="tipo"
            label="Tipo"
            choices={choicesTipo}
          />
          <SelectField
            sortable={false}
            source="universo"
            label="Universo"
            choices={choicesUniverso}
          />
          <SelectField
            sortable={false}
            source="status_loja"
            label="Status da Loja"
            choices={choicesStatusLoja}
          />
          <SelectField
            sortable={false}
            source="tipo_horario"
            label="Tipo de Horário"
            choices={choicesTipoHorario}
          />
          <ReferenceField
            label="Equipe"
            source="equipe_colaborador_id"
            reference="equipes_colaborador"
            linkType={false}
          >
            <TextField source="nome" />
          </ReferenceField>
        </Datagrid>
        <TransacoesPagination />
      </Card>
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Feriados"
      component="div"
    />
  </>
);

export default List2;
