import React, { useRef } from 'react';
import {
  TextField,
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  ReferenceField,
  Datagrid,
} from 'react-admin';

import { Card, makeStyles, Box } from '@material-ui/core';

import Actions from './ActionsList';
import DialogoDeletar from './DialogoDeletar';
import DialogoEdit from './DialogoEdit';
import ActionsField from './Fields/ActionsField';
import DataFormatadaField from './Fields/DataFormatadaField';
import Filter from './Filter';

const useStyles = makeStyles((theme) => ({
  dataHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const classes = useStyles();
  const refDialogoDeletar = useRef(null);
  const refDialogoEdit = useRef(null);

  const handleClose = () => {
    refresh();
  };

  function handleOpenDialogoEdit({ id }) {
    if (refDialogoEdit.current) {
      refDialogoEdit.current.handleOpen(id);
    }
  }

  function handleOpenDialogoDeletar({ id }) {
    if (refDialogoDeletar.current) {
      refDialogoDeletar.current.handleOpen(id);
    }
  }

  return (
    <>
      <Title title="Compensação de Feriados" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <ReferenceField
            label="Colaborador"
            source="colaborador_id"
            reference="colaboradores"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            linkType={false}
          >
            <TextField source="nome" />
          </ReferenceField>
          <DataFormatadaField
            source="feriado_referente"
            label="Feriado Referente"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            sortable={false}
          />
          <ActionsField
            source="valor"
            textAlign="right"
            label="Ações"
            sortable={false}
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            handleEdit={handleOpenDialogoEdit}
            handleDelete={handleOpenDialogoDeletar}
          />
        </Datagrid>
        <TransacoesPagination />
        <DialogoDeletar ref={refDialogoDeletar} handleClose={handleClose} />
        <DialogoEdit ref={refDialogoEdit} handleClose={handleClose} />
      </Card>
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const DIA_ATUAL = new Date().getDate();
const MES_ATUAL = new Date().getMonth();
const ANO_ATUAL = new Date().getFullYear();

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Compensação de Feriados"
      filterDefaultValues={{
        periodo: { dia: DIA_ATUAL, mes: MES_ATUAL, ano: ANO_ATUAL },
      }}
    />
  </>
);

export default List2;
