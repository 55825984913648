import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  Datagrid,
  TextField,
  DateField,
  useListContext,
  ListContextProvider,
} from 'react-admin';

import { Card, makeStyles, Divider, Tabs, Tab } from '@material-ui/core';

import { DialogoConfirmacao } from '../../Components';
import { deleteAPI, api, getUserId } from '../../services';
import { STATUS_ATIVO, STATUS_INATIVO } from '../../utils';
import ActionsField from './ActionsField';
import Actions from './ActionsList';
import DialogoNotaCega from './DialogoNotaCega';
import DialogoPDF from './DialogoPDF';
import DialogoPedidoCompra from './DialogoPedidoCompra';
import DialogoShow from './DialogoShow';
import FornecedorField from './Fields/FornecedorField';
import ValorField from './Fields/ValorField';
import Filter from './Filter';

const useStyles = makeStyles((theme) => ({
  dataHeader: {
    flex: 1,
    backgroundColor: theme.palette.secondary.main,
    padding: '10px',
    color: 'white',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: 'red !important', // Altere a cor desejada
      fontWeight: 'bold',
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon ': {
      color: 'red !important', // Altere a cor desejada
      fontWeight: 'bold',
    },
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const tabs = [
  { id: STATUS_ATIVO, name: 'ativo' },
  { id: STATUS_INATIVO, name: 'inativo' },
];

const MyList = ({ ...props }) => {
  const refDialogoPedidoCompra = useRef(null);
  const refDialogoShowPedidoCompra = useRef(null);
  const refresh = useRefresh();
  const classes = useStyles();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [ativos, setAtivos] = useState([]);
  const [inativos, setInativos] = useState([]);
  const refDialogoConfirmacao = useRef(null);
  const [isComprador, setIsComprador] = useState(false);
  const refDialogoPDF = useRef(null);
  const refDialogoNotaCega = useRef(null);

  async function getPermissaoAbaComprador() {
    try {
      const response = await api.get(`/obter-cargo-usuario/${getUserId()}`);
      const { status, cargo } = response.data;
      if (status) {
        return cargo.sistema_acoes_pedido_compra ? true : false;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  async function definePermissao() {
    setIsComprador(await getPermissaoAbaComprador());
  }

  useEffect(() => {
    definePermissao();
  }, []);

  function handleShow({ id }) {
    if (refDialogoShowPedidoCompra.current)
      refDialogoShowPedidoCompra.current.handleOpen(id);
  }

  function handleEdit({ id }) {
    if (refDialogoPedidoCompra.current)
      refDialogoPedidoCompra.current.handleUpdate(id);
  }

  function handleCreate() {
    if (refDialogoPedidoCompra.current)
      refDialogoPedidoCompra.current.handleCreate();
  }

  async function handleDelete({ id }) {
    refDialogoConfirmacao.current.handleOpen(
      'Deletar Pedido',
      `Tem certeza que deseja deletar O Pedido de Compra nº ${id}?`,
      {
        tipo: 'deletar',
        id,
      },
    );
  }

  async function handlePermitidoDeletado(id) {
    await deleteAPI('pedidos-compras', id);
    refresh();
  }

  const handleClose = () => {
    refresh();
  };

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters({ ...filterValues, status: value }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case STATUS_ATIVO:
          setAtivos(ids);
          break;
        case STATUS_INATIVO:
          setInativos(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.status]);

  function handleChangeStatus({ id, status }) {
    switch (status) {
      case STATUS_ATIVO:
        refDialogoConfirmacao.current.handleOpen(
          'Inativar Pedido de Compra',
          `Tem certeza que deseja inativar O Pedido de Compra nº ${id}?`,
          {
            tipo: 'inativar',
            id,
          },
        );
        break;
      case STATUS_INATIVO:
        refDialogoConfirmacao.current.handleOpen(
          'Reativar Pedido de Compra',
          `Tem certeza que deseja deletar O Pedido de Compra nº ${id}?`,
          {
            tipo: 'ativar',
            id,
          },
        );
        break;
      default:
        break;
    }
  }

  async function handlePermitidoInativar(id) {
    await api.put(`/pedidos-compras/status/inativar/${id}`);
    refresh();
  }

  async function handlePermitidoAtivar(id) {
    await api.put(`/pedidos-compras/status/ativar/${id}`);
    refresh();
  }

  function openPdf({ id }) {
    if (refDialogoPDF.current) {
      refDialogoPDF.current.handleOpen(id);
    }
  }

  function openNotaCega({ id }) {
    if (refDialogoNotaCega.current) {
      refDialogoNotaCega.current.handleOpen(id);
    }
  }

  return (
    <>
      <Title title="Pedidos de Compras" />
      <ListToolbar
        filters={props.filters}
        actions={
          <Actions
            {...props}
            handleCriar={handleCreate}
            isComprador={isComprador}
          />
        }
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status === STATUS_ATIVO ? tabs[0].id : tabs[1].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === STATUS_ATIVO && (
            <ListContextProvider value={{ ...listContext, ids: ativos }}>
              <Datagrid {...props}>
                <TextField
                  source="id"
                  label="Id"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <FornecedorField
                  source="nome"
                  label="Fornecedor"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <ValorField
                  source="total"
                  label="R$ Pedido"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <DateField
                  source="data"
                  label="Data da Solicitação"
                  showTime
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <DateField
                  source="data_entrega"
                  label="Data de Entrega"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  handleEdit={handleEdit}
                  handleShow={handleShow}
                  handleDelete={handleDelete}
                  handleChangeStatus={handleChangeStatus}
                  isComprador={isComprador}
                  openPdf={openPdf}
                  openNotaCega={openNotaCega}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.status === STATUS_INATIVO && (
            <ListContextProvider value={{ ...listContext, ids: inativos }}>
              <Datagrid {...props}>
                <TextField
                  source="id"
                  label="Id"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <FornecedorField
                  source="nome"
                  label="Fornecedor"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <ValorField
                  source="total"
                  label="R$ Pedido"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <DateField
                  source="data"
                  label="Data"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                />
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  handleEdit={handleEdit}
                  handleShow={handleShow}
                  handleDelete={handleDelete}
                  handleChangeStatus={handleChangeStatus}
                  isComprador={isComprador}
                  openPdf={openPdf}
                  openNotaCega={openNotaCega}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>

        <TransacoesPagination />
      </Card>
      <DialogoPedidoCompra
        ref={refDialogoPedidoCompra}
        handleClose={handleClose}
      />
      <DialogoShow ref={refDialogoShowPedidoCompra} />
      <DialogoPDF ref={refDialogoPDF} />
      <DialogoNotaCega ref={refDialogoNotaCega} />
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={(dados) => {
          switch (dados.tipo) {
            case 'deletar':
              handlePermitidoDeletado(dados.id);
              break;
            case 'inativar':
              handlePermitidoInativar(dados.id);
              break;
            case 'ativar':
              handlePermitidoAtivar(dados.id);
              break;
            default:
              break;
          }
        }}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      component="div"
      filterDefaultValues={{
        status: STATUS_ATIVO,
      }}
      sort={{ field: 'data', order: 'DESC' }}
    />
  </>
);

export default List2;
