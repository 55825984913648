import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { AutoCompleteRemoto } from '../../../Components';
import {
  SECONDARY_COLOR,
  PRIMARY_COLOR,
  formatCPF,
  formatCNPJ,
  STATUS_ATIVO,
} from '../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogoSelecionarFornecedor = forwardRef(
  ({ handleClose, handleSalvar, fornecedores }, ref) => {
    const [open, setOpen] = useState(false);
    const [fornecedor, setFornecedor] = useState(null);

    const classes = useStyles();

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    async function handleSalvarLocal() {
      setOpen(false);
      handleSalvar(fornecedor);
    }

    useImperativeHandle(ref, () => ({
      handleOpen() {
        setFornecedor(null);
        setOpen(true);
      },
    }));

    const idExclusos = [1, 2, ...fornecedores.map((item) => item.id)];

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="sm"
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{
              backgroundColor: SECONDARY_COLOR,
              color: 'white',
            }}
          >
            Adicionar Fornecedor
          </DialogTitle>
          <DialogContent
            style={{
              padding: '20px 36px 10px',
              margin: '0',
            }}
          >
            <div>
              <Box
                display="flex"
                alignItems="center"
                flex={1}
                style={{
                  gap: '10px',
                }}
              >
                <AutoCompleteRemoto
                  id="fornecedor"
                  name="fornecedor"
                  label="Responsável"
                  value={fornecedor}
                  onChange={(value) => {
                    setFornecedor(value);
                  }}
                  handleKey={(keyCode, keyName) => {}}
                  handleEnter={() => {}}
                  resource="fornecedores"
                  nested={[]}
                  toString={false}
                  style={{}}
                  fullwidth={false}
                  autoFocus
                  filters={{ status: STATUS_ATIVO, ids_excluso: idExclusos }}
                  getOptionSelected={(option, value) =>
                    option.nome === value.nome
                  }
                  getOptionLabel={(option) =>
                    `${option.nome}${
                      option.nome_fantasia ? ` - (${option.nome_fantasia})` : ''
                    }`
                  }
                />
                <AutoCompleteRemoto
                  id="fornecedor"
                  name="fornecedor"
                  label="Nome Fantasia"
                  value={fornecedor}
                  onChange={(value) => {
                    setFornecedor(value);
                  }}
                  handleKey={(keyCode, keyName) => {}}
                  handleEnter={() => {}}
                  resource="fornecedores"
                  campo="nome_fantasia"
                  nested={[]}
                  toString={false}
                  style={{}}
                  fullwidth={false}
                  filters={{ status: STATUS_ATIVO, ids_excluso: idExclusos }}
                  getOptionSelected={(option, value) =>
                    option.nome_fantasia === value.nome_fantasia
                  }
                  getOptionLabel={(option) =>
                    `${option.nome_fantasia ? `(${option.nome_fantasia})` : ''}`
                  }
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                flex={1}
                style={{
                  gap: '10px',
                }}
              >
                <AutoCompleteRemoto
                  id="fornecedor"
                  name="fornecedor"
                  label="Razão Social"
                  value={fornecedor}
                  onChange={(value) => {
                    setFornecedor(value);
                  }}
                  handleKey={(keyCode, keyName) => {}}
                  handleEnter={() => {}}
                  resource="fornecedores"
                  campo="razao_social"
                  nested={[]}
                  toString={false}
                  style={{}}
                  fullwidth={false}
                  filters={{ status: STATUS_ATIVO, ids_excluso: idExclusos }}
                  getOptionSelected={(option, value) =>
                    option.razao_social === value.razao_social
                  }
                  getOptionLabel={(option) =>
                    `${option.razao_social ? `(${option.razao_social})` : ''}`
                  }
                />
                <AutoCompleteRemoto
                  id="fornecedor"
                  name="fornecedor"
                  label="CNPJ"
                  value={fornecedor}
                  onChange={(value) => {
                    setFornecedor(value);
                  }}
                  handleKey={(keyCode, keyName) => {}}
                  handleEnter={() => {}}
                  resource="fornecedores"
                  campo="cnpj"
                  nested={[]}
                  campoPesquisa="cnpj"
                  toString={false}
                  style={{}}
                  fullwidth={false}
                  filters={{ status: STATUS_ATIVO, ids_excluso: idExclusos }}
                  getOptionSelected={(option, value) =>
                    option.cnpj === value.cnpj
                  }
                  getOptionLabel={(option) =>
                    `${option.cnpj ? `(${option.cnpj})` : ''}`
                  }
                />
              </Box>
              {fornecedor && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flex={1}
                  style={{
                    //border: `1px solid ${PRIMARY_COLOR}`,
                    marginLeft: '0px',
                    padding: '5px',
                    borderRadius: '5px',
                    marginBottom: '10px',
                  }}
                >
                  <Box
                    marginLeft="10px"
                    display="flex"
                    flexDirection="column"
                    flex={1}
                  >
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        Responsável:
                      </p>
                      {fornecedor.nome}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                      justifyContent="flex-start"
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        Nome Fantasia:
                      </p>
                      {fornecedor.nome_fantasia}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        Razão Social:
                      </p>
                      {fornecedor.razao_social}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        CPF:
                      </p>
                      {formatCPF(fornecedor.cpf)}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                    >
                      <p
                        className={classes.textOverflow}
                        style={{
                          marginBottom: '0px',
                          color: PRIMARY_COLOR,
                          marginRight: '10px',
                        }}
                      >
                        CNPJ:
                      </p>
                      {formatCNPJ(fornecedor.cnpj)}
                    </Box>
                    <Box
                      marginLeft="0px"
                      fontSize="15px"
                      fontWeight="bold"
                      display="flex"
                      flex={1}
                    />
                  </Box>
                </Box>
              )}
            </div>
          </DialogContent>
          <DialogActions
            style={{
              padding: '32px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={handleCloseDialog}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="outlined"
              color="primary"
            >
              Cancelar
            </Button>
            <Box>
              <div className={classes.wrapper}>
                <Button
                  onClick={handleSalvarLocal}
                  classes={{
                    root: classes.btnAtions,
                    disabled: classes.btnAtionsDisabled,
                  }}
                  variant="contained"
                  color="primary"
                >
                  Adicionar
                </Button>
              </div>
            </Box>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoSelecionarFornecedor;
