import React from 'react';
import {
  TextField,
  DateField,
  FunctionField,
  SimpleShowLayout,
  ReferenceField,
} from 'react-admin';

import { Box, Typography } from '@material-ui/core';

import ShowTemplate from '../../Components/Show';
import {
  formatCEP,
  formatCNPJ,
  formatCPF,
  formatTelefone,
  formatIE,
} from '../../utils';
import DicaPix from './DicaPix';

const Title = ({ record }) => (
  <span>Fornecedor: {record ? `"${record.nome}"` : ''}</span>
);

const Show = (props) => (
  <ShowTemplate {...props} title={<Title />} isBack>
    <SimpleShowLayout>
      <TextField label="Nome" source="nome" />
      <TextField label="Razão Social" source="razao_social" />
      <FunctionField
        label="CPF"
        render={(record) => (
          <Box display="flex">
            <div> {formatCPF(record.cpf)}</div>
            {record.is_pix_cpf && (
              <Box ml="5px">
                <DicaPix title="Pix CPF" value={record.is_pix_cpf} />
              </Box>
            )}
          </Box>
        )}
      />
      <TextField label="RG" source="rg" />
      <FunctionField
        label="CNPJ"
        render={(record) => (
          <Box display="flex">
            <div> {formatCNPJ(record.cnpj)}</div>
            {record.is_pix_cnpj && (
              <Box ml="5px">
                <DicaPix title="Pix CPF" value={record.is_pix_cnpj} />
              </Box>
            )}
          </Box>
        )}
      />
      <FunctionField
        label="Inscrição Estadual"
        render={(record) => formatIE(record.inscricao_estadual)}
      />
      <TextField label="Nome Fantasia" source="nome_fantasia" />
      <DateField label="Aniversário" source="dataAniversario" />
      <TextField label="Logradouro" source="logradouro" />
      <TextField label="Nº" source="numero" />
      <TextField label="Complemento" source="complemento" multiline />
      <TextField label="Bairro" source="bairro" />
      <FunctionField label="CEP" render={(record) => formatCEP(record.cep)} />
      <ReferenceField
        source="cidade_id"
        reference="cidades"
        label="Cidade"
        sortable={false}
        linkType={false}
      >
        <TextField source="nome" />
      </ReferenceField>
      <FunctionField
        label="Telefone"
        render={(record) => (
          <Box display="flex">
            <div> {formatTelefone(record.telefone)}</div>
            {record.is_pix_telefone && (
              <Box ml="5px">
                <DicaPix title="Pix Telefone" value={record.is_pix_telefone} />
              </Box>
            )}
          </Box>
        )}
      />
      <TextField label="Email" source="email" type="email" />
      <FunctionField
        label="Email"
        render={(record) => (
          <Box display="flex">
            <div> {record.email}</div>
            {record.is_pix_email && (
              <Box ml="5px">
                <DicaPix title="Pix Email" value={record.is_pix_email} />
              </Box>
            )}
          </Box>
        )}
      />
      <FunctionField
        label="Chave Pix Aleatória"
        render={(record) => (
          <Box display="flex">
            <div> {record.pix_aleatoria}</div>
            <Box ml="5px">
              <DicaPix title="Chave Pix Aleatória" value />
            </Box>
          </Box>
        )}
      />
    </SimpleShowLayout>
  </ShowTemplate>
);

export default Show;
