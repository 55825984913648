import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
  ReferenceField,
  TextField,
  FunctionField,
  BooleanField,
  ListContextProvider,
} from 'react-admin';

import { Card, makeStyles, Divider, Tabs, Tab } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { DialogoConfirmacao } from '../../Components';
import { api } from '../../services';
import { SECONDARY_COLOR } from '../../utils';
import {
  STATUS_ATIVO,
  STATUS_INATIVO,
  TURNO_MANHA,
  TURNO_NOITE,
  TURNO_12_HORAS,
} from '../../utils/constants';
import Actions from './ActionsList';
import Dialogo from './DialogoCreateEdit';
import DialogoDeleteResource from './DialogoDeletar';
import ActionsField from './Fields/ActionsField';
import ColaboradorField from './Fields/ColaboradorField';
import DescricaoField from './Fields/DescricaoField';
import Filter from './Filter';

const optionsTurno = {
  [TURNO_MANHA]: 'Manhã',
  [TURNO_NOITE]: 'Noite',
  [TURNO_12_HORAS]: '12H',
};

const useStyles = makeStyles((theme) => ({
  statusHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 1,
    backgroundColor: SECONDARY_COLOR,
    padding: '10px',
    color: 'white',
    fontWeight: 'bold',
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusCell: {
    // marginLeft: '30px',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    // paddingLeft: '30px',
  },
  statusContent: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 5,
    marginLeft: '30px',
    maxWidth: '100px',
  },
  dataHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const tabs = [
  { id: 'ativos', name: 'Ativos' },
  { id: 'inativos', name: 'Inativos' },
];

const MyList = ({ ...props }) => {
  const refDialogoCompra = useRef(null);
  const refDialogoDeleteResource = useRef(null);
  const refresh = useRefresh();
  const listContext = useListContext();
  const classes = useStyles();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [ativos, setAtivos] = useState([]);
  const [inativos, setInativos] = useState([]);
  const refDialogoConfirmacao = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  function handleCriar() {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleCreate();
    }
  }

  function handleEdit({ id }) {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleEdit(id);
    }
  }

  function deleteHundle({ id }) {
    if (refDialogoDeleteResource.current)
      refDialogoDeleteResource.current.handleOpen(id);
  }

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case STATUS_ATIVO:
          setAtivos(ids);
          break;
        case STATUS_INATIVO:
          setInativos(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.status]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          {
            ...filterValues,
            status: value === 'ativos' ? STATUS_ATIVO : STATUS_INATIVO,
          },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const handleClose = () => {
    refresh();
  };

  function handleAtivar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Reativação de Codinome',
        'Deseja realmente reativar este codinome?',
        { id, codigo: 1 },
      );
    }
  }

  function handleDesativar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Desativação de Codinome',
        'Deseja realmente desativar este codinome?',
        { id, codigo: 2 },
      );
    }
  }

  /*async function ativar(id) {
    await api.put(`/codinomes_colaborador/status/ativar/${id}`);
    handleClose();
  }*/

  async function ativar(id) {
    try {
      const response = await api.put(
        `/codinomes_colaborador/status/ativar/${id}`,
      );
      const { status, message, data } = response.data;
      if (!status) throw message;
      else {
        enqueueSnackbar(`Codinome ativado com sucesso!`, {
          variant: 'success',
        });
      }
      handleClose();
    } catch (e) {
      enqueueSnackbar(`${e}`, {
        variant: 'error',
      });
    }
  }

  async function desativar(id) {
    try {
      const response = await api.put(
        `/codinomes_colaborador/status/inativar/${id}`,
      );
      const { status, message, data } = response.data;
      if (!status) throw message;
      else {
        enqueueSnackbar(`Codinome inativado com sucesso!`, {
          variant: 'success',
        });
      }
      handleClose();
    } catch (e) {
      enqueueSnackbar(`${e}`, {
        variant: 'error',
      });
    }
  }

  /*async function desativar(id) {
    try {
      const response = await api.put(
        `/codinomes_colaborador/status/inativar/${id}`,
      );
      const { message } = response.data;
      if (message) throw message;
      handleClose();
    } catch (e) {
      enqueueSnackbar(`${e}`, {
        variant: 'error',
      });
    }
  }*/

  /*async function deletar(id) {
    await api.put(`/codinomes_colaborador/status/deletar/${id}`);
    handleClose();
  }*/

  async function deletar(id) {
    try {
      const response = await api.put(
        `/codinomes_colaborador/status/deletar/${id}`,
      );
      const { status, message, data } = response.data;
      if (!status) throw message;
      else {
        enqueueSnackbar(`Codinome deletado com sucesso!`, {
          variant: 'success',
        });
      }
      handleClose();
    } catch (e) {
      enqueueSnackbar(`${e}`, {
        variant: 'error',
      });
    }
  }

  async function destravar(id) {
    try {
      const response = await api.put(
        `/codinomes_colaborador/status/destravar/${id}`,
      );
      const { status, message, data } = response.data;
      if (!status) throw message;
      else {
        enqueueSnackbar(`Codinome liberado para uso com sucesso!`, {
          variant: 'success',
        });
      }
      handleClose();
    } catch (e) {
      enqueueSnackbar(`${e}`, {
        variant: 'error',
      });
    }
  }

  async function travar(id) {
    try {
      const response = await api.put(
        `/codinomes_colaborador/status/travar/${id}`,
      );
      const { status, message, data } = response.data;
      if (!status) throw message;
      else {
        enqueueSnackbar(`Codinome travado para uso com sucesso!`, {
          variant: 'success',
        });
      }
      handleClose();
    } catch (e) {
      enqueueSnackbar(`${e}`, {
        variant: 'error',
      });
    }
  }

  async function handleConfirma({ id, codigo }) {
    switch (codigo) {
      case 1:
        ativar(id);
        break;
      case 2:
        desativar(id);
        break;
      case 3:
        deletar(id);
        break;
      case 4:
        destravar(id);
        break;
      case 5:
        travar(id);
        break;
      default:
        break;
    }
  }

  async function handleDestravar({ id, nome }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        `Liberar Uso desse Codinome (${nome})`,
        'Deseja realmente liberar o uso desse codinome?',
        { id, codigo: 4 },
      );
    }
  }

  async function handleTravar({ id, nome }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        `Travar Uso desse Codinome (${nome})`,
        'Deseja realmente travar o uso desse codinome?',
        { id, codigo: 5 },
      );
    }
  }

  return (
    <>
      <Title title="Codinomes Colaboradores" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status === STATUS_ATIVO ? tabs[0].id : tabs[1].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === STATUS_ATIVO && (
            <ListContextProvider value={{ ...listContext, ids: ativos }}>
              <Datagrid {...props}>
                <DescricaoField
                  source="nome"
                  label="Descrição"
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.dataCell}
                />
                <ReferenceField
                  label="Função"
                  source="funcao_colaborador_id"
                  reference="funcoes_colaboradores"
                  sortable={false}
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.dataCell}
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <ReferenceField
                  label="Grupo"
                  source="grupo_colaborador_id"
                  reference="grupos_colaborador"
                  sortable={false}
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.dataCell}
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                {/*<ReferenceField
                  label="Loja"
                  source="empresa_id"
                  reference="empresa-loja"
                  sortable={false}
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.dataCell}
                  linkType={false}
                >
                  <TextField source="nome_fantasia" />
          </ReferenceField>*/}
                <FunctionField
                  label="Turno"
                  render={(record) => optionsTurno[record.turno]}
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.dataCell}
                />
                {/*<BooleanField
                  label="Funcionário Interno"
                  source="is_funcionario_interno"
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.dataCell}
        />*/}
                <BooleanField
                  label="Em uso"
                  source="usado"
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.dataCell}
                />
                <ColaboradorField
                  label="Colaborador"
                  source="colaborador"
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.dataCell}
                  sortable={false}
                />
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.dataCell}
                  handleDeletar={deleteHundle}
                  handleEdit={handleEdit}
                  ativo={filterValues.ativo}
                  handleAtivar={handleAtivar}
                  handleDesativar={handleDesativar}
                  handleDestravar={handleDestravar}
                  handleTravar={handleTravar}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.status === STATUS_INATIVO && (
            <ListContextProvider value={{ ...listContext, ids: inativos }}>
              <Datagrid {...props}>
                <DescricaoField
                  source="nome"
                  label="Descrição"
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.dataCell}
                />
                <ReferenceField
                  label="Função"
                  source="funcao_colaborador_id"
                  reference="funcoes_colaboradores"
                  sortable={false}
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.dataCell}
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <ReferenceField
                  label="Grupo"
                  source="grupo_colaborador_id"
                  reference="grupos_colaborador"
                  sortable={false}
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.dataCell}
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <ReferenceField
                  label="Loja"
                  source="empresa_id"
                  reference="empresa-loja"
                  sortable={false}
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.dataCell}
                  linkType={false}
                >
                  <TextField source="nome_fantasia" />
                </ReferenceField>
                <FunctionField
                  label="Turno"
                  render={(record) => optionsTurno[record.turno]}
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.dataCell}
                />
                <BooleanField
                  label="Funcionário Interno"
                  source="is_funcionario_interno"
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.dataCell}
                />
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.dataCell}
                  handleDeletar={deleteHundle}
                  handleEdit={handleEdit}
                  ativo={filterValues.ativo}
                  handleAtivar={handleAtivar}
                  handleDesativar={handleDesativar}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <Dialogo ref={refDialogoCompra} handleClose={handleClose} />
      <DialogoDeleteResource
        ref={refDialogoDeleteResource}
        handleClose={handleClose}
      />
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Codinomes Colaboradores"
      component="div"
      filterDefaultValues={{
        status: STATUS_ATIVO,
      }}
      filter={{ isColaborador: true }}
    />
  </>
);

export default List2;
