import React, { useRef } from 'react';
import {
  TextField,
  ReferenceField,
  ShowButton,
  NumberField,
  ListBase,
  ListToolbar,
  Pagination,
  FunctionField,
  useRefresh,
  DateField,
} from 'react-admin';

import { Card, Box } from '@material-ui/core';
import moment from 'moment';

import Datagrid from '../../Components/Datagrid';
import DialogoDeleteResource from '../../Components/DialogoDeleteResource';
import EditButton from '../../Components/EditButton2';
import Actions from './ActionsList';
import Dialogo from './DialogoCreateEdit';
import DialogoCriarListaConferenciasHoje from './DialogoCriarListaConferenciasHoje';
import DateInputMobills from './Inputs/DateInputMobills';
import StatusField from './StatusField';

function formatHorario(data) {
  return moment(new Date(data)).format('DD/MM/YYYYY - HH:mm');
}

const PostPanel = ({ record }) => (
  <div>
    <div>
      <h3>Manhã</h3>
      <div>
        <div>
          <span style={{ marginRight: '20px' }}>Horário:</span>
          <span>{formatHorario(record.horario_manha)}</span>
        </div>
      </div>
    </div>
    <div style={{ marginTop: '20px' }}>
      <h3>Tarde</h3>
      <div>
        <div>
          <span style={{ marginRight: '20px' }}>Horário:</span>
          <span>{formatHorario(record.horario_tarde)}</span>
        </div>
      </div>
    </div>
  </div>
);

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const refDialogoCompra = useRef(null);
  const refDialogoDeleteResource = useRef(null);
  const refDialogoCriarListaConferenciasHoje = useRef(null);

  function handleCriar() {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleCreate();
    }
  }
  function handleEdit({ id }) {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleEdit(id);
    }
  }
  function deleteHundle(record) {
    if (refDialogoDeleteResource.current)
      refDialogoDeleteResource.current.handleOpen(record);
  }

  function handleClose() {
    refresh();
  }

  function handleCriarConferencias() {
    if (refDialogoCriarListaConferenciasHoje.current) {
      refDialogoCriarListaConferenciasHoje.current.handleOpen();
    }
  }

  return (
    <>
      <ListToolbar
        actions={
          <Actions
            handleCriar={handleCriar}
            handleCriarConferencias={handleCriarConferencias}
            {...props}
          />
        }
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Box flex={1} margin="0px 0 20px">
          <DateInputMobills />
        </Box>
        <Datagrid {...props}>
          <StatusField
            source="status_manha"
            label="Manhã"
            /*  headerClassName={classes.header}
            cellClassName={classes.cell} */
            sortable={false}
          />
          <StatusField
            source="status_tarde"
            label="Tarde"
            /* headerClassName={classes.header}
            cellClassName={classes.cell} */
            sortable={false}
          />
          <ReferenceField
            label="Freezer"
            source="equipamento_freezer_id"
            reference="equipamentos_freezer"
            sortable={false}
            linkType={false}
          >
            <TextField source="nome" />
          </ReferenceField>
          <NumberField
            source="temperatura_manha"
            locales="pt-BR"
            options={{
              maximumFractionDigits: 1,
              minimumFractionDigits: 1,
              currency: 'BRL',
            }}
            label="Temperatura Manhã (°C)"
          />
          <NumberField
            source="temperatura_tarde"
            locales="pt-BR"
            options={{
              maximumFractionDigits: 1,
              minimumFractionDigits: 1,
              currency: 'BRL',
            }}
            label="Temperatura Tarde (°C)"
          />
          <ReferenceField
            label="Usuário Manhã"
            source="user_id_1"
            reference="users"
            sortable={false}
            linkType={false}
          >
            <TextField source="username" />
          </ReferenceField>
          <ReferenceField
            label="Usuário Tarde"
            source="user_id_2"
            reference="users"
            sortable={false}
            linkType={false}
          >
            <TextField source="username" />
          </ReferenceField>
          <EditButton editHundle={handleEdit} />
          {/* <DeleteButton
            label="Excluir"
            deleteHundle={deleteHundle}
            disabled={() => false}
          /> */}
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <Dialogo ref={refDialogoCompra} handleClose={handleClose} />
      <DialogoDeleteResource ref={refDialogoDeleteResource} />
      <DialogoCriarListaConferenciasHoje
        ref={refDialogoCriarListaConferenciasHoje}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const DIA_ATUAL = new Date().getDate();
const MES_ATUAL = new Date().getMonth();
const ANO_ATUAL = new Date().getFullYear();

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      title="Conf. Temperaturas Freezers"
      filterDefaultValues={{
        periodo: { dia: DIA_ATUAL, mes: MES_ATUAL, ano: ANO_ATUAL },
      }}
    />
  </>
);

export default List2;
