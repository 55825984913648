import React, { useRef } from 'react';
import {
  TextField,
  ReferenceField,
  Button,
  ListButton,
  RefreshButton,
  TabbedShowLayout,
  Tab,
  DateField,
  ReferenceManyField,
  NumberField,
  TabbedShowLayoutTabs,
  Pagination,
} from 'react-admin';

import { Box } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import AssignmentIcon from '@material-ui/icons/Assignment';

import { DialogoNota } from '../../Components';
import Datagrid from '../../Components/Datagrid/index2';
import ShowTemplate from '../../Components/Show';

const Title = ({ record }) => (
  <span>Venda Nº: {record ? `"${record.numero}"` : ''}</span>
);

const Actions = (props) => {
  const { basePath, data } = props;
  const refDialogoNota = useRef();
  function openNota() {
    if (refDialogoNota.current)
      refDialogoNota.current.handleOpen(data.id, 'venda');
  }
  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" justifyContent="flex-end">
        <CardActions>
          <ListButton basePath={basePath} />
          <RefreshButton />
          <Button onClick={openNota} label="Nota">
            <AssignmentIcon />
          </Button>
          <DialogoNota ref={refDialogoNota} />
        </CardActions>
      </Box>
    </Box>
  );
};

const VendasShow = (props) => (
  <ShowTemplate
    {...props}
    actions={<Actions />}
    title={<Title />}
    isBack
    routeBack="/vendas"
  >
    <TabbedShowLayout
      tabs={<TabbedShowLayoutTabs variant="fullWidth" centered {...props} />}
    >
      <Tab label="Geral">
        <TextField source="numero" label="Nº venda" />
        <ReferenceField
          label="Cliente"
          source="cliente_id"
          reference="clientes"
          linkType={false}
        >
          <TextField source="nome" />
        </ReferenceField>
        <NumberField
          source="valor"
          locales="pt-BR"
          options={{
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
          }}
          label="Valor Total"
        />
        <DateField source="data" label="Data da Venda" />
      </Tab>
      <Tab label="Itens">
        <ReferenceManyField
          addLabel={false}
          reference="itensvenda"
          target="venda_id"
          pagination={<Pagination />}
        >
          <Datagrid>
            <ReferenceField
              label="Produto"
              source="produto_id"
              reference="produtos"
              sortable={false}
              linkType={false}
            >
              <TextField source="nome" />
            </ReferenceField>
            <TextField source="unidades" label="Unidades" />
            <TextField source="peso" label="Peso (Kg)" />
            <NumberField
              source="preco_venda"
              locales="pt-BR"
              options={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'BRL',
              }}
              label="Preço Unitário Venda"
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </ShowTemplate>
);

export default VendasShow;
