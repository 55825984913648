import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';

import {
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow as TableRowMUI,
  TableSortLabel,
  Table,
  TableContainer,
  Paper,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Remove';
import { useSnackbar } from 'notistack';
import { useDebounce } from 'use-debounce';

import { DialogoProduto2 as DialogoProduto } from '../../../Components';
import { api } from '../../../services';
import { SECONDARY_COLOR, createQueryURL } from '../../../utils';
import DialogoCreateCorrecao from '../../Produtos/DialogoCreateCorrecao';

const paddingTableCell = '3px';

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: 'black',
    whiteSpace: 'nowrap',
    border: '1px solid black',
    padding: paddingTableCell,
    fontWeight: 'bold',
    //width: '10px !important',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    border: '1px solid black',
    padding: paddingTableCell,
    //width: '10px !important',
    /* '&:nth-of-type(odd)': {
        backgroundColor: 'white',
      },
      '&:nth-of-type(even)': {
        backgroundColor: 'grey',
      }, */
  },
}))(TableCell);

const TableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'white',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#dbd5d5',
    },
  },
}))(TableRowMUI);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    // backgroundColor: (props) => props.cor,
    // color: 'white',
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    // backgroundColor: 'rgba(0, 0, 0, 0.12)',
    // color: 'red',
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function InputSearch({ handleChange }) {
  const [nome, setNome] = useState('');
  const [nomeDebounce] = useDebounce(nome, 500);

  useEffect(() => {
    handleChange(nomeDebounce);
  }, [nomeDebounce]);

  return (
    <TextField
      id="outlined-basic"
      label="Pesquisar"
      variant="outlined"
      //fullWidth
      value={nome}
      onChange={(e) => {
        setNome(e.target.value);
      }}
    />
  );
}

const DialogoCategoriaTransacao = forwardRef(
  ({ fornecedor, handleSalvar, pasta }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const carregando = useRef(false);
    const [dados, setDados] = useState({
      produtos: [],
      page: 1,
      perPage: 10,
      total: 0,
    });
    const [search, setSearch] = useState('');
    const [pageDebounce] = useDebounce(dados.page, 500);
    const [adicionar, setAdicionar] = useState({});
    const refDialogoProduto = useRef(null);
    const refDialogoCreateCorrecao = useRef(null);

    const classes = useStyles();

    const handleCloseDialog = () => {
      setOpen(false);
      setSearch('');
      setAdicionar({});
      setDados({
        produtos: [],
        page: 1,
        perPage: 10,
        total: 0,
      });
    };

    async function getListAPI(resorce, sort, range, filter, nested) {
      const url = `/${resorce}?${createQueryURL(sort, range, filter, nested)}`;
      const data = await api.get(url);
      return {
        data: data.data,
      };
    }

    async function getDataResource() {
      try {
        carregando.current = true;
        const response = await getListAPI(
          `get-produtos-fornecedor-fora-mix`,
          ['nome', 'asc'],
          [dados.page, dados.perPage],
          {
            fornecedor_id: fornecedor.id,
            search,
            pasta_fornecedor_id: pasta ? pasta.id : null,
          },
          [],
        );
        const { total, perPage, page, lastPage, data } = response.data;
        carregando.current = false;
        setDados({
          produtos: data,
          page,
          perPage,
          total,
        });
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      } finally {
        carregando.current = false;
      }
    }

    async function handleSalvarLocal() {
      const dadosRetorno = [];
      const keys = Object.keys(adicionar);
      for (let i = 0; i < keys.length; i++) {
        if (adicionar[keys[i]]) {
          dadosRetorno.push(parseInt(keys[i], 10));
        }
      }
      handleSalvar(dadosRetorno);
      setOpen(false);
    }

    useImperativeHandle(ref, () => ({
      handleOpen() {
        if (!carregando.current) {
          getDataResource();
          setOpen(true);
          setSearch('');
          setAdicionar({});
        }
      },
    }));

    function handleChangePage(event, newPage) {
      setDados({
        ...dados,
        page: newPage + 1,
      });
    }

    useEffect(() => {
      if (open) {
        getDataResource();
      }
    }, [pageDebounce]);

    useEffect(() => {
      if (open) {
        if (dados.page === 1) {
          getDataResource();
        } else {
          setDados({
            ...dados,
            page: 1,
          });
        }
      }
    }, [search]);

    function handleCreateCorrecao(id) {
      if (refDialogoCreateCorrecao.current) {
        refDialogoCreateCorrecao.current.handleOpen(id);
      }
    }

    function handleEditProduto(id) {
      if (refDialogoProduto.current) {
        refDialogoProduto.current.handleEdit(id);
      }
    }

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="md"
          TransitionComponent={Transition}
          keepMounted
        >
          <MuiDialogTitle
            id="form-dialog-title"
            style={{
              backgroundColor: SECONDARY_COLOR,
              color: 'white',
              textAlign: 'center',
            }}
          >
            Produtos fora do Mix
          </MuiDialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            {carregando.current ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    fontSize: '20px',
                    flexDirection: 'column',
                    marginTop: '20px',
                  }}
                >
                  <InputSearch handleChange={(nome) => setSearch(nome)} />
                  <TableContainer
                    component={Paper}
                    style={{ marginTop: '15px' }}
                  >
                    <Table aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">ID</StyledTableCell>
                          <StyledTableCell align="left">
                            Descrição
                          </StyledTableCell>
                          <StyledTableCell align="right">Ações</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dados.produtos.map(({ id, nome }) => (
                          <TableRow>
                            <StyledTableCell align="left">{id}</StyledTableCell>
                            <StyledTableCell align="left">
                              {nome}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <Tooltip
                                title="Adicionar ao Mix"
                                aria-label="adicionar"
                              >
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    const dados = { ...adicionar };
                                    dados[id] = dados[id] ? false : true;
                                    setAdicionar(dados);
                                  }}
                                  style={
                                    adicionar[id]
                                      ? {
                                          backgroundColor: 'green',
                                          color: 'white',
                                        }
                                      : {}
                                  }
                                >
                                  {adicionar[id] ? <RemoveIcon /> : <AddIcon />}
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title="Editar Produto"
                                aria-label="adicionar"
                              >
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    handleEditProduto(id);
                                  }}
                                  disabled={false}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title="Corrigir Estoque"
                                aria-label="adicionar"
                              >
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    handleCreateCorrecao(id);
                                  }}
                                  disabled={false}
                                >
                                  <AssessmentIcon />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      count={dados.total}
                      rowsPerPage={dados.perPage}
                      page={dados.page - 1}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={() => {}}
                      labelRowsPerPage="Por página"
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} de ${
                          count !== -1 ? count : `more than ${to}`
                        }`
                      }
                      style={{ marginTop: '10px' }}
                    />
                  </TableContainer>
                </div>
              </>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '32px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={handleCloseDialog}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="outlined"
              color="primary"
            >
              Cancelar
            </Button>
            <Box>
              <div className={classes.wrapper}>
                <Button
                  onClick={handleSalvarLocal}
                  classes={{
                    root: classes.btnAtions,
                    disabled: classes.btnAtionsDisabled,
                  }}
                  variant="contained"
                  color="primary"
                  disabled={carregando.current || dados.produtos.length === 0}
                >
                  Salvar
                </Button>
                {carregando.current && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Box>
          </DialogActions>
        </Dialog>
        <DialogoProduto
          ref={refDialogoProduto}
          handleClose={(idUpdate) => {}}
          isImprimir={false}
        />
        <DialogoCreateCorrecao
          ref={refDialogoCreateCorrecao}
          handleClose={(status, id) => {}}
        />
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
