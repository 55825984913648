import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
  TextField,
  ReferenceField,
  ShowButton,
  NumberField,
  ListBase,
  ListToolbar,
  Pagination,
  FunctionField,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
  DateField,
  ListContextProvider,
} from 'react-admin';
import { useHistory, useLocation } from 'react-router-dom';

import { Card, makeStyles, Divider, Tabs, Tab } from '@material-ui/core';
import { stringify } from 'query-string';

import { DialogoFornecedor, DialogoConfirmacao } from '../../Components';
import { api } from '../../services';
import {
  STATUS_ATIVO,
  STATUS_INATIVO,
  STATUS_DELETADO,
} from '../../utils/constants';
import ActionsField from './ActionsField';
import Actions from './ActionsList';
// import DialogoFornecedor from './DialogoCreateEdit';
import DialogoShow from './DialogoShow';
import Filter from './Filter';

const tabs = [
  { id: 'ativos', name: 'Ativos' },
  { id: 'inativos', name: 'Inativos' },
];

const MyList = ({ ...props }) => {
  const refDialogoCompra = useRef(null);
  const refresh = useRefresh();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const refDialogoShow = useRef(null);
  const history = useHistory();
  const refDialogoConfirmacao = useRef(null);
  const [ativos, setAtivos] = useState([]);
  const [inativos, setInativos] = useState([]);

  function handleCriar() {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleCreate(1);
    }
  }

  function handleEdit({ id }) {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleEdit(id);
    }
  }

  function handleShow({ id }) {
    if (refDialogoShow.current) {
      refDialogoShow.current.handleOpen(id);
    }
  }

  function handleParcelasPagas({ id }) {
    const queryOj = {};

    queryOj.displayedFilters = JSON.stringify({
      fornecedor_id: true,
    });
    queryOj.filter = JSON.stringify({
      fornecedor_id: id,
    });

    history.push(
      `/parcelas_pagas_fornecedores?${stringify(
        queryOj,
      )}&order=DESC&page=1&perPage=50&sort=data_pagamento`,
    );
  }

  function handleParcelasNaoPagas({ id }) {
    const queryOj = {};

    queryOj.displayedFilters = JSON.stringify({
      fornecedor_id: true,
    });
    queryOj.filter = JSON.stringify({
      fornecedor_id: id,
    });

    history.push(
      `/parcelas_nao_pagas_fornecedores?${stringify(
        queryOj,
      )}&order=DESC&page=1&perPage=50&sort=data_pagamento`,
    );
  }

  function handleAtivar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Reativação de Fornecedor',
        'Deseja realmente reativar este fornecedor?',
        { id, codigo: 1 },
      );
    }
  }

  function handleDesativar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Desativação de Fornecedor',
        'Deseja realmente desativar este fornecedor?',
        { id, codigo: 2 },
      );
    }
  }

  function handleDeletar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Exclusão de Fornecedor',
        'Deseja deletar permanentemente este fornecedor?',
        { id, codigo: 3 },
      );
    }
  }

  async function ativar(id) {
    await api.put(`/fornecedores/status/ativar/${id}`);
    handleClose();
  }

  async function desativar(id) {
    await api.put(`/fornecedores/status/inativar/${id}`);
    handleClose();
  }

  async function deletar(id) {
    await api.put(`/fornecedores/status/deletar/${id}`);
    handleClose();
  }

  async function handleConfirma({ id, codigo }) {
    switch (codigo) {
      case 1:
        ativar(id);
        break;
      case 2:
        desativar(id);
        break;
      case 3:
        deletar(id);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case STATUS_ATIVO:
          setAtivos(ids);
          break;
        case STATUS_INATIVO:
          setInativos(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.status]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          {
            ...filterValues,
            status: value === 'ativos' ? STATUS_ATIVO : STATUS_INATIVO,
          },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <Title title="Fornecedores Mercadorias" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Divider />
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status === STATUS_ATIVO ? tabs[0].id : tabs[1].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === STATUS_ATIVO && (
            <ListContextProvider value={{ ...listContext, ids: ativos }}>
              <Datagrid {...props}>
                <TextField source="nome" label="Nome" />
                <TextField source="nome_fantasia" label="Nome Fantasia" />
                <TextField source="razao_social" label="Razão Social" />
                <ReferenceField
                  source="cidade_id"
                  reference="cidades"
                  label="Cidade"
                  sortable={false}
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <TextField source="bairro" label="Bairro" />
                <TextField source="tempo_entrega" label="Tempo Entrega" />
                <DateField source="dataAniversario" label="Aniversário" />
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleEdit={handleEdit}
                  handleShow={handleShow}
                  handleParcelasPagas={handleParcelasPagas}
                  handleParcelasNaoPagas={handleParcelasNaoPagas}
                  ativo={filterValues.ativo}
                  handleAtivar={handleAtivar}
                  handleDesativar={handleDesativar}
                  handleDeletar={handleDeletar}
                />
                {/* <ShowButton /> */}
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.status === STATUS_INATIVO && (
            <ListContextProvider value={{ ...listContext, ids: inativos }}>
              <Datagrid {...props}>
                <TextField source="nome" label="Nome" />
                <TextField source="nome_fantasia" label="Nome Fantasia" />
                <TextField source="razao_social" label="Razão Social" />
                <ReferenceField
                  source="cidade_id"
                  reference="cidades"
                  label="Cidade"
                  sortable={false}
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <TextField source="bairro" label="Bairro" />
                <TextField source="tempo_entrega" label="Tempo Entrega" />
                <DateField source="dataAniversario" label="Aniversário" />
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleEdit={handleEdit}
                  handleShow={handleShow}
                  handleParcelasPagas={handleParcelasPagas}
                  handleParcelasNaoPagas={handleParcelasNaoPagas}
                  ativo={filterValues.ativo}
                  handleAtivar={handleAtivar}
                  handleDesativar={handleDesativar}
                  handleDeletar={handleDeletar}
                />
                {/* <ShowButton /> */}
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoFornecedor ref={refDialogoCompra} handleClose={handleClose} />
      <DialogoShow ref={refDialogoShow} handleClose={handleClose} />
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Fornecedores Mercadorias"
      filterDefaultValues={{ status: STATUS_ATIVO }}
      // sort={{ field: 'urgencia', order: 'DESC' }}
      component="div"
    />
  </>
);

export default List;
