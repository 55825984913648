import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
  TextField,
  ReferenceField,
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
  DateField,
  ListContextProvider,
  BooleanField,
  FunctionField,
} from 'react-admin';
import { useHistory } from 'react-router-dom';

import { Card, Divider, Tabs, Tab } from '@material-ui/core';

import { DialogoFornecedor, DialogoConfirmacao } from '../../Components';
import { api, imprimirQrCodeGerente } from '../../services';
import { formatMoeda } from '../../utils';
import { STATUS_ATIVO, STATUS_INATIVO } from '../../utils/constants';
import ActionsField from './ActionsField';
import Actions from './ActionsList';
import DateMobillsInput from './DateInputMobills';
import DialogoDescontos from './DialogoDescontos';
import Filter from './Filter';
import IconField from './IconField';

const MES_ATUAL = new Date().getMonth();
const ANO_ATUAL = new Date().getFullYear();

const tabs = [
  { id: 'ativos', name: 'Ativos' },
  { id: 'inativos', name: 'Inativos' },
];

const MyList = ({ ...props }) => {
  const refDialogoCompra = useRef(null);
  const refDialogoDescontos = useRef(null);
  const refresh = useRefresh();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const refDialogoConfirmacao = useRef(null);
  const [ativos, setAtivos] = useState([]);
  const [inativos, setInativos] = useState([]);
  const history = useHistory();

  function handleCriar() {
    history.push(`/users/create`);
  }

  function handleEdit({ id }) {
    history.push(`/users/${id}`);
  }

  function handleAtivar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Reativação de Usuário',
        'Deseja realmente reativar este usuário?',
        { id, codigo: 1 },
      );
    }
  }

  function handleDesativar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Desativação de Usuário',
        'Deseja realmente desativar este usuário?',
        { id, codigo: 2 },
      );
    }
  }

  function handleDeletar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Exclusão de Usuário',
        'Deseja deletar permanentemente este usuário?',
        { id, codigo: 3 },
      );
    }
  }

  function handleDesconto({ id }) {
    if (refDialogoDescontos.current) {
      refDialogoDescontos.current.handleOpen(id);
    }
  }

  async function ativar(id) {
    await api.put(`/users/status/ativar/${id}`);
    handleClose();
  }

  async function desativar(id) {
    await api.put(`/users/status/inativar/${id}`);
    handleClose();
  }

  async function deletar(id) {
    await api.put(`/users/status/deletar/${id}`);
    handleClose();
  }

  async function handleConfirma({ id, codigo }) {
    switch (codigo) {
      case 1:
        ativar(id);
        break;
      case 2:
        desativar(id);
        break;
      case 3:
        deletar(id);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case STATUS_ATIVO:
          setAtivos(ids);
          break;
        case STATUS_INATIVO:
          setInativos(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.status]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          {
            ...filterValues,
            status: value === 'ativos' ? STATUS_ATIVO : STATUS_INATIVO,
          },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const handleClose = () => {
    refresh();
  };

  async function handleImprimirQrCodeGerente({ id }) {
    await imprimirQrCodeGerente(id);
  }

  return (
    <>
      <Title title="Admnistradores" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <div style={{ marginBottom: '20px' }}>
        <DateMobillsInput />
      </div>
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Divider />
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status === STATUS_ATIVO ? tabs[0].id : tabs[1].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === STATUS_ATIVO && (
            <ListContextProvider value={{ ...listContext, ids: ativos }}>
              <Datagrid {...props}>
                <IconField source="is_colaborador" label="" />
                <TextField source="username" label="Username" />
                <ReferenceField
                  label="Cargo"
                  source="cargo_id"
                  reference="cargos"
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <DateField source="dataAniversario" label="Aniversário" />
                <BooleanField
                  label="Gerente de Caixa"
                  source="is_responsavel"
                />
                <FunctionField
                  label="Total Descontos"
                  render={(record) =>
                    record.desconto > 0
                      ? `${formatMoeda(record.desconto)}`
                      : '-'
                  }
                />
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleEdit={handleEdit}
                  handleAtivar={handleAtivar}
                  handleDesativar={handleDesativar}
                  handleDeletar={handleDeletar}
                  handleDescontos={handleDesconto}
                  handleImprimirQrCodeGerente={handleImprimirQrCodeGerente}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.status === STATUS_INATIVO && (
            <ListContextProvider value={{ ...listContext, ids: inativos }}>
              <Datagrid {...props}>
                <IconField source="is_colaborador" label="" />
                <TextField source="username" label="Username" />
                <ReferenceField
                  label="Meio Cargos"
                  source="cargo_id"
                  reference="cargos"
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <DateField source="dataAniversario" label="Aniversário" />
                <BooleanField
                  label="Gerente de Caixa"
                  source="is_responsavel"
                />
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleEdit={handleEdit}
                  handleAtivar={handleAtivar}
                  handleDesativar={handleDesativar}
                  handleDeletar={handleDeletar}
                  handleDescontos={() => {}}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoFornecedor ref={refDialogoCompra} handleClose={handleClose} />
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
      <DialogoDescontos ref={refDialogoDescontos} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Administradores"
      filterDefaultValues={{
        status: STATUS_ATIVO,
        periodo2: { mes: MES_ATUAL, ano: ANO_ATUAL },
      }}
      filter={{ desconto_field: true }}
      component="div"
    />
  </>
);

export default List;
