import React, { useEffect } from 'react';
import {
  TextField,
  TextInput,
  Filter as FilterAdmin,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  ShowButton,
  FunctionField,
  AutocompleteInput,
  useListContext,
} from 'react-admin';

import { FornecedorPastaInput } from '../../Components/InputsReactAdmin';
import {
  STATUS_ATIVO,
  ESTADO_FISICO_CONGELADO,
  ESTADO_FISICO_RESFRIADO,
  ESTADO_FISICO_SECO,
} from '../../utils/constants';

const choices = [
  { id: ESTADO_FISICO_CONGELADO, name: 'Congelado' },
  { id: ESTADO_FISICO_RESFRIADO, name: 'Resfriado' },
  { id: ESTADO_FISICO_SECO, name: 'Seco' },
];

const Filter = (props) => {
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;

  useEffect(() => {
    const filtersCopy = filterValues;
    delete filtersCopy.pasta_id;
    setFilters(
      {
        ...filtersCopy,
      },
      displayedFilters,
    );
  }, [filterValues.fornecedor_id]);

  return (
    <FilterAdmin {...props}>
      <TextInput label="Descrição" source="nome" />
      <TextInput label="Código de barras" source="codigo" />
      {/* <BooleanInput label="Ativos" source="ativo" alwaysOn /> */}
      <BooleanInput label="Tem código de barras" source="isCodigoBarras" />
      <BooleanInput label="Impostos Verificados" source="is_impostos" />
      <BooleanInput label="Incluir na pesquesa do FC" source="is_search" />
      <BooleanInput label="Em Ruptura" source="is_ruptura" />
      <BooleanInput label="Importado" source="importado" />
      {/* <ReferenceInput
      source="categoria_id"
      reference="categorias"
      label="Categoria"
      allowEmpty={false}
    >
      <SelectInput optionText="nome" allowEmpty={false} />
    </ReferenceInput> */}
      <ReferenceInput
        fullWidth
        label="Categoria"
        source="categoria_id"
        reference="categorias"
        sort={{ field: 'nome', order: 'ASC' }}
        filterToQuery={(searchText) => ({ nome: `${searchText}` })}
        perPage={30}
      >
        <AutocompleteInput optionText="nome" />
      </ReferenceInput>
      <ReferenceInput
        fullWidth
        label="Local"
        source="local_id"
        reference="locais_setores"
        sort={{ field: 'nome', order: 'ASC' }}
        filterToQuery={(searchText) => ({ nome: `${searchText}` })}
        filter={{ status: STATUS_ATIVO }}
        perPage={30}
      >
        <AutocompleteInput optionText="nome" />
      </ReferenceInput>
      <ReferenceInput
        fullWidth
        label="Fornecedor"
        source="fornecedor_id"
        reference="fornecedores"
        sort={{ field: 'nome', order: 'ASC' }}
        filterToQuery={(searchText) => ({ nome: `${searchText}` })}
        perPage={30}
        filter={{ status: STATUS_ATIVO }}
      >
        <AutocompleteInput optionText="nome" />
      </ReferenceInput>
      {/* <ReferenceInput
      fullWidth
      label="Fornecedor Padrão"
      source="fornecedor_id"
      reference="fornecedores"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      perPage={30}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput> */}
      <ReferenceInput
        source="familia_produto_id"
        reference="familias_produtos"
        label="Família"
        allowEmpty={false}
      >
        <SelectInput optionText="nome" allowEmpty={false} />
      </ReferenceInput>
      <ReferenceInput
        label="Unidade"
        source="unidade_id"
        reference="unidades"
        allowEmpty={false}
        filter={{ status: STATUS_ATIVO }}
      >
        <SelectInput optionText="nome" allowEmpty={false} />
      </ReferenceInput>
      <BooleanInput label="Contém Lotes" source="has_lote" />
      <TextInput label="Descrição XML" source="descricao_pesquisa_xml" />
      <BooleanInput label="Invencível" source="is_invencivel" />
      <SelectInput
        source="estado_fisico"
        choices={choices}
        label="Estado Físico"
        allowEmpty={false}
      />
      <BooleanInput label="Pertencente ao Mix" source="pertencente_ao_mix" />
      <BooleanInput label="JOrnal de Ofertas" source="no_jornal" />
      {/* <FornecedorPastaInput source="fornecedor_com_pasta" label="Pasta" /> */}
      <ReferenceInput
        fullWidth
        label="Pasta de Fornecedor"
        source="pasta_id"
        reference="pastas-fornecedores"
        sort={{ field: 'nome', order: 'ASC' }}
        filterToQuery={(searchText) => ({ nome: `${searchText}` })}
        perPage={10}
        filter={{
          fornecedor_id: filterValues ? filterValues.fornecedor_id : null,
        }}
      >
        <AutocompleteInput optionText="nome" />
      </ReferenceInput>
    </FilterAdmin>
  );
};

export default Filter;
