import React from 'react';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import moment from 'moment';

import {
  TIPO_UNIDADE_KG,
  TIPO_UNIDADE_UN,
  TIPO_UNIDADE_CX1,
  TIPO_UNIDADE_CX2,
  TIPO_UNIDADE_CX3,
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  formatPeso,
} from '../../../../utils';

const styles = StyleSheet.create({
  page: {
    padding: '5mm',
  },
  titleDocument: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    fontWeight: 'bold',
    fontSize: '13',
    fontFamily: 'Roboto-Bold',
  },
  subTitleDocument: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'row',
    margin: '5 0',
    fontSize: '13',
    fontWeight: 'bold',
    fontFamily: 'Roboto-Bold',
    marginBottom: '10',
  },
  sessao: {},
  subTitle: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10 0 5',
    fontWeight: 'bold',
    fontSize: '12',
  },
  body: {
    width: '100%',
    fontSize: '10',
  },
  item: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    padding: '2',
  },
  item2: {
    margin: 0,
    flex: 2,
    display: 'flex',
    alignItems: 'flex-end',
    padding: '2',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    borderBottom: '1 solid black',
  },
  content2: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    //borderBottom: '1 solid black',
  },
  contentTotais: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#ffff00',
    fontWeight: 'bold',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#dae1f2',
    borderTop: '1 solid black',
    borderBottom: '1 solid black',
    fontFamily: 'Roboto-Bold',
    fontSize: '10',
  },
  negativo: {
    backgroundColor: '#ffc7cf',
  },
  positivo: {
    backgroundColor: '#cbf5d0',
  },
  totais: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: 'yellow',
    fontSize: '10',
    /* fontSize: '22px',
    fontWeight: 'bold', */
    padding: '2',
  },
  totais2: {
    margin: 0,
    //flex: 3,
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: 'yellow',
    fontSize: '10',
    /* fontSize: '22px',
    fontWeight: 'bold', */
    padding: '2',
  },
  bodyCabecalhos: {
    width: '100%',
    fontSize: '10',
  },
  itemCabecalho: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    padding: '2',
  },
  itemCabecalhoNegrito: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    padding: '2',
    fontFamily: 'Roboto-Bold',
  },
  headerCabecalho: {
    display: 'flex',
    flexDirection: 'row',
    //backgroundColor: '#dae1f2',
    borderTop: '1 solid black',
    borderBottom: '1 solid black',
    fontSize: '14',
    fontFamily: 'Roboto-Bold',
  },
  container_cabecalho: {
    display: 'flex',
    flexDirection: 'column',
    width: '49%',
    //marginBottom: '10',
  },
  linha_cabecalho: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '20',
  },
  pesagensLinha: {
    display: 'flex',
    flexDirection: 'row',
  },
  pesagensColuna: {
    borderBottom: '1 solid black',
    flex: 1,
    borderLeft: '1 solid black',
  },
});

const corLinhasTabelaItens = '#917cec';

function getLabelUnidade(tipo, peso_medio) {
  switch (tipo) {
    case POR_UNIDADE:
      return 'UN';
    case POR_PESO_FIXO:
      return `PP (${formatPeso(peso_medio)})`;
    case POR_PESO_VARIAVEL_SEM_UNIDADE:
      return 'KG';
    default:
      return '';
  }
}

function getLabelPesar(tipo) {
  switch (tipo) {
    case POR_UNIDADE:
      return 'Não precisa pesar';
    case POR_PESO_FIXO:
      return 'Não Precisa Pesar';
    case POR_PESO_VARIAVEL_SEM_UNIDADE:
      return 'Obrigatório Pesar';
    default:
      return '';
  }
}

const flexNumero = 0.3;
const flexProduto = 3;
const flexTipo = 0.3;
const flexObs = 1;
const flexConferencia = 1;

function Pesagens() {
  return (
    <View>
      <View style={styles.pesagensLinha}>
        <View style={styles.pesagensColuna}>
          <Text />
        </View>
        <View style={styles.pesagensColuna}>
          <Text />
        </View>
        <View style={styles.pesagensColuna}>
          <Text />
        </View>
        <View style={styles.pesagensColuna}>
          <Text />
        </View>
        <View style={styles.pesagensColuna}>
          <Text />
        </View>
        <View style={styles.pesagensColuna}>
          <Text />
        </View>
        <View style={styles.pesagensColuna}>
          <Text />
        </View>
        <View style={styles.pesagensColuna}>
          <Text />
        </View>
        <View style={styles.pesagensColuna}>
          <Text />
        </View>
        <View style={styles.pesagensColuna}>
          <Text>_</Text>
        </View>
      </View>
      <View style={styles.pesagensLinha}>
        <View style={styles.pesagensColuna}>
          <Text />
        </View>
        <View style={styles.pesagensColuna}>
          <Text />
        </View>
        <View style={styles.pesagensColuna}>
          <Text />
        </View>
        <View style={styles.pesagensColuna}>
          <Text />
        </View>
        <View style={styles.pesagensColuna}>
          <Text />
        </View>
        <View style={styles.pesagensColuna}>
          <Text />
        </View>
        <View style={styles.pesagensColuna}>
          <Text />
        </View>
        <View style={styles.pesagensColuna}>
          <Text />
        </View>
        <View style={styles.pesagensColuna}>
          <Text />
        </View>
        <View style={styles.pesagensColuna}>
          <Text>_</Text>
        </View>
      </View>
    </View>
  );
}

const ListaProdutos = ({ dados }) => {
  const { fornecedor, itens, id, dataPedido } = dados;

  return (
    <>
      <Document author="Caruaru Frios" title={`Pedido de Compra nº ${id}`}>
        <Page size="A4" style={styles.page} wrap orientation="portrait">
          <View style={styles.body}>
            <View style={styles.titleDocument}>
              <Text>CARUARU FRIOS</Text>
            </View>
            <View style={styles.subTitleDocument}>
              <Text>{`NOTA CEGA PEDIDO DE COMPRA Nº ${id} - ${dataPedido}`}</Text>
            </View>

            {itens.length > 0 && (
              <View style={styles.sessao}>
                <View style={styles.container}>
                  <View style={styles.header}>
                    <View
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: corLinhasTabelaItens,
                        opacity: 0.5,
                      }}
                    />
                    <View style={[styles.item, { flex: flexNumero }]}>
                      <Text>Nº</Text>
                    </View>
                    <View style={[styles.item, { flex: flexProduto }]}>
                      <Text>Produto</Text>
                    </View>
                    <View style={[styles.item, { flex: flexTipo }]}>
                      <Text>Tipo</Text>
                    </View>
                    <View style={[styles.item, { flex: flexObs }]}>
                      <Text>Observação</Text>
                    </View>
                    <View style={[styles.item2, { flex: flexConferencia }]}>
                      <Text>Conferência Cega</Text>
                    </View>
                  </View>
                  {itens.map(
                    (
                      {
                        id,
                        descricao,
                        unidade_preco_editado,
                        unidade_adotada_na_compra,
                        peso_medio,
                        status,
                        tipoUnidade,
                      },
                      index2,
                    ) => (
                      <>
                        <View style={styles.content}>
                          <View
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              backgroundColor: corLinhasTabelaItens,
                              opacity: index2 % 2 === 0 ? 0 : 0.3,
                            }}
                          />
                          <View style={[styles.item, { flex: flexNumero }]}>
                            <Text>{index2 + 1}</Text>
                          </View>
                          <View style={[styles.item, { flex: flexProduto }]}>
                            <Text>{descricao}</Text>
                          </View>
                          <View style={[styles.item, { flex: flexTipo }]}>
                            <Text>
                              {getLabelUnidade(tipoUnidade, peso_medio)}
                            </Text>
                          </View>
                          <View style={[styles.item, { flex: flexObs }]}>
                            <Text>{getLabelPesar(tipoUnidade)}</Text>
                          </View>

                          <View
                            style={[styles.item2, { flex: flexConferencia }]}
                          >
                            <Text />
                          </View>
                        </View>
                        <Pesagens />
                      </>
                    ),
                  )}
                </View>
              </View>
            )}
          </View>
        </Page>
      </Document>
    </>
  );
};
export default ListaProdutos;
