import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

import { api } from '../../../services';
import {
  SECONDARY_COLOR,
  TIPO_PEDIDO_MINIMO_COMPRA_KG,
  TIPO_PEDIDO_MINIMO_COMPRA_CAIXAS,
  TIPO_PEDIDO_MINIMO_COMPRA_VALOR,
  formatPeso,
  formatMoeda,
  formatCNPJ,
  formatPorcentagem,
  formatDateDDMMYYYYHHmm,
  formatDateDDMMYYYY,
} from '../../../utils';

function getLabelPedidoMinimo(fornecedor) {
  switch (fornecedor.tipo_pedido_minimo_de_compra) {
    case TIPO_PEDIDO_MINIMO_COMPRA_KG:
      return formatPeso(fornecedor.pedido_minimo_de_compra);
    case TIPO_PEDIDO_MINIMO_COMPRA_CAIXAS:
      return `${Math.round(fornecedor.pedido_minimo_de_compra)} Caixas`;
    case TIPO_PEDIDO_MINIMO_COMPRA_VALOR:
      return formatMoeda(fornecedor.pedido_minimo_de_compra);
    default:
      return '';
  }
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 12,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function Container({ children, show, fallback }) {
  return <>{show ? children : <div>{fallback}</div>}</>;
}

const DialogoTrocoInicial = forwardRef(
  (
    {
      fornecedor,
      totalPedido,
      pesoTotal,
      data,
      verbaJornal,
      verbaBackligth,
      bonificacao,
      comprador,
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const [dados, setDados] = useState(null);

    const handleClose = () => {
      setOpen(false);
    };

    async function getDadosCabecalho() {
      if (fornecedor) {
        const response = await api.get(
          `/dados-cabecalho-pedido-compra/${fornecedor.id}`,
        );

        const data2 = response.data;
        const { config, fornecedor: forn } = data2.data;

        /* setDados({
        fornecedor: forn,
        config,
      }); */
        setDados(
          getDados(
            forn,
            config,
            pesoTotal,
            data,
            totalPedido,
            verbaJornal,
            verbaBackligth,
            bonificacao,
            comprador,
          ),
        );
      } else {
        setDados(null);
      }
    }

    useImperativeHandle(ref, () => ({
      handleOpen() {
        setOpen(true);
        getDadosCabecalho();
      },
    }));

    function gerLabelPrazosFornecedor(fornecedor) {
      let prazoLabel = 'Nenhum prazo cadastrado!';
      if (fornecedor) {
        if (fornecedor.prazos) {
          if (fornecedor.prazos.length > 0) {
            prazoLabel = fornecedor.prazos
              .sort((a, b) => a.dias - b.dias)
              .map((item) => item.dias)
              .join(', ');
          }
        }
      }
      return prazoLabel;
    }

    function getDiasSemComprar(dataUltimoPedido) {
      if (!dataUltimoPedido) return '';
      const dataUltimoPedido2 = new Date(
        moment(dataUltimoPedido).endOf('day').format(),
      );

      const dataAtual = new Date(moment(new Date()).endOf('day').format());

      const delta = dataAtual.getTime() - dataUltimoPedido2.getTime();

      return parseInt(delta / (24 * 60 * 60 * 1000), 10);
    }

    function getDados(
      fornecedor,
      config,
      pesoTotal,
      data,
      totalPedido,
      verbaJornal,
      verbaBackligth,
      bonificacao,
      comprador,
    ) {
      const descarrego =
        (pesoTotal / 1000) * fornecedor.frete_valor_por_tonelada;
      const descontoBoletoPorcentagem = fornecedor.desconto_em_boleto;
      const descontoBoleto = (descontoBoletoPorcentagem / 100) * totalPedido;

      const verbaTotal =
        verbaJornal +
        verbaBackligth +
        bonificacao +
        descarrego +
        descontoBoleto;

      const dataUltimoPedido = formatDateDDMMYYYYHHmm(
        fornecedor.dataUltimoPedido,
      );

      const diasSemComprar = getDiasSemComprar(fornecedor.dataUltimoPedido);

      console.log({ dataUltimoPedido });
      return {
        fornecedor: {
          razao_social: fornecedor.razao_social,
          nome_fantasia: fornecedor.nome_fantasia,
          prazo_de_entrega: `${fornecedor.tempo_entrega} dias`,
          prazo_fornecedor: gerLabelPrazosFornecedor(fornecedor),
          prazo_medio_pagamento: `${fornecedor.prazo_medio_pagamento} dias`,
          restricao_pedido_minimo: getLabelPedidoMinimo(fornecedor),
          estado_onde_fatura: fornecedor.estado,
          credito_icms_aliquota_interna: formatPorcentagem(
            fornecedor.icms_ai_compra,
          ),
          limite_credito_total: formatMoeda(fornecedor.limite),
          limite_credito_utilizado: '',
          limite_credito_disponivel: '',
          penalizacao_juros_atrazo: formatPorcentagem(fornecedor.juros),
          penalizacao_multa_atrazo: formatPorcentagem(fornecedor.multa),
          perde_desconto_atrazo: fornecedor.is_perde_desconto_no_atrazo
            ? 'SIM'
            : 'NÃO',
          tarifa_boleto: formatMoeda(fornecedor.tarifa_boleto),
          desconto_menor_prazo: formatPorcentagem(
            fornecedor.desconto_menor_Preco,
          ),
          funcionarios: fornecedor.funcionarios,
        },
        comprador: {
          comprador,
          razao_social: config.razao_social,
          nome_fantasia: config.nome_fantasia,
          cnpj: config.cnpj ? formatCNPJ(config.cnpj) : '-',
          horario_recebimento: '',
          dias_recebimento: '',
          descarrego_tonelada: fornecedor.frete_valor_por_tonelada
            ? `${fornecedor.frete_valor_por_tonelada} / t`
            : '-',
        },
        pedido: {
          peso_total: formatPeso(pesoTotal),
          data_hora: formatDateDDMMYYYYHHmm(data),
          data_hora_ultimo_pedido: dataUltimoPedido,
          numero_dias_sem_compra: diasSemComprar,
          data_previsao_chegada: formatDateDDMMYYYY(
            new Date(
              moment(data).add('days', fornecedor.tempo_entrega).format(),
            ),
          ),
          total_pedido: formatMoeda(totalPedido),
          estimativa_total_encargos_1_dia_atrazo: formatMoeda(
            ((fornecedor.juros + fornecedor.multa) / 100) * totalPedido,
          ),
          estimativa_total_encargos_1_dia_atrazo_mais_perda_desconto_boleto: formatMoeda(
            ((fornecedor.juros +
              fornecedor.multa +
              fornecedor.desconto_menor_Preco) /
              100) *
              totalPedido,
          ),
          desconto_menor_prazo: formatMoeda(
            (fornecedor.desconto_menor_Preco / 100) * totalPedido,
          ),
        },
        verbas: {
          bonificacao: formatMoeda(bonificacao),
          jornal_ofertas: formatMoeda(verbaJornal),
          descarrego: formatMoeda(descarrego),
          desconto_boleto_porcentagem: formatPorcentagem(
            descontoBoletoPorcentagem,
          ),
          desconto_boleto: formatMoeda(descontoBoleto),
          desconto_backlight: formatMoeda(verbaBackligth),
          total: formatMoeda(verbaTotal),
        },
      };
    }

    function formatDados() {
      const dadosFormatados = {
        dadosFonecedor: [],
        dadosComprador: [],
        dadosPedido: [],
        dadosVerbas: [],
      };

      if (dados) {
        dadosFormatados.dadosFonecedor.push({
          label: 'Razão Social:',
          value: dados.fornecedor.razao_social,
        });

        dadosFormatados.dadosFonecedor.push({
          label: 'Nome Fantasia:',
          value: dados.fornecedor.nome_fantasia,
        });

        for (let i = 0; i < dados.fornecedor.funcionarios.length; i++) {
          dadosFormatados.dadosFonecedor.push({
            label: dados.fornecedor.funcionarios[i].funcao,
            value: dados.fornecedor.funcionarios[i].nome,
          });
        }

        dadosFormatados.dadosFonecedor.push({
          label: 'Prazo de Entrega (dias):',
          value: dados.fornecedor.prazo_de_entrega,
        });

        dadosFormatados.dadosFonecedor.push({
          label: 'Prazo de Pagamento do Fornecedor (dias):',
          value: dados.fornecedor.prazo_fornecedor,
        });

        dadosFormatados.dadosFonecedor.push({
          label: 'Prazo médio de pagamento (dias):',
          value: dados.fornecedor.prazo_medio_pagamento,
        });

        dadosFormatados.dadosFonecedor.push({
          label: 'Restrição de Pedido Mínimo (kg)/R$/cxs:',
          value: dados.fornecedor.restricao_pedido_minimo,
        });

        dadosFormatados.dadosFonecedor.push({
          label: 'Estado onde Fatura:',
          value: dados.fornecedor.estado_onde_fatura,
        });

        dadosFormatados.dadosFonecedor.push({
          label: 'Crédito de ICMS Alíquota Interna / Normal:',
          value: dados.fornecedor.credito_icms_aliquota_interna,
        });

        dadosFormatados.dadosFonecedor.push({
          label: 'Limite de Crédito Total:',
          value: dados.fornecedor.limite_credito_total,
        });

        dadosFormatados.dadosFonecedor.push({
          label: 'Limite de Crédito Utilizado:',
          value: dados.fornecedor.limite_credito_utilizado,
        });

        dadosFormatados.dadosFonecedor.push({
          label: 'Limite de Crédito Disponível:',
          value: dados.fornecedor.limite_credito_disponivel,
        });

        dadosFormatados.dadosFonecedor.push({
          label: 'Penalização de Juros de atraso (% ao mês):',
          value: dados.fornecedor.penalizacao_juros_atrazo,
        });

        dadosFormatados.dadosFonecedor.push({
          label: 'Penalização de Multa de atraso (% único):',
          value: dados.fornecedor.penalizacao_multa_atrazo,
        });

        dadosFormatados.dadosFonecedor.push({
          label: 'Perde o Desconto caso haja atraso (Sim/Não)?',
          value: dados.fornecedor.perde_desconto_atrazo,
        });

        dadosFormatados.dadosFonecedor.push({
          label: 'Tarifa de cada Boleto:',
          value: dados.fornecedor.tarifa_boleto,
        });

        dadosFormatados.dadosFonecedor.push({
          label: 'Desconto em caso de menor prazo (%):',
          value: dados.fornecedor.desconto_menor_prazo,
        });

        /////////////////////////////////////////

        dadosFormatados.dadosComprador.push({
          label: 'Comprador:',
          value: dados.comprador.comprador,
        });

        dadosFormatados.dadosComprador.push({
          label: 'Razão Social:',
          value: dados.comprador.razao_social,
        });

        dadosFormatados.dadosComprador.push({
          label: 'Nome Fantasia:',
          value: dados.comprador.nome_fantasia,
        });

        dadosFormatados.dadosComprador.push({
          label: 'CNPJ:',
          value: dados.comprador.cnpj,
        });

        dadosFormatados.dadosComprador.push({
          label: 'Horário de Recebimento:',
          value: '',
        });

        dadosFormatados.dadosComprador.push({
          label: 'Dias de Recebimento:',
          value: '',
        });

        dadosFormatados.dadosComprador.push({
          label: 'Não Recebemos em Feriados.',
          value: '',
        });

        dadosFormatados.dadosComprador.push({
          label: 'Não Recebemos Cargas acima de 10 toneladas após 17:00.',
          value: '',
        });

        dadosFormatados.dadosComprador.push({
          label: 'Não recebemos veículos de altura superior a 3,90 m',
          value: '',
        });

        dadosFormatados.dadosComprador.push({
          label: 'Descarrego/Tonelada:',
          value: dados.comprador.descarrego_tonelada,
        });

        ////////////

        dadosFormatados.dadosPedido.push({
          label: 'Peso Total do pedido (toneladas):',
          value: dados.pedido.peso_total,
        });

        dadosFormatados.dadosPedido.push({
          label: 'Data e Hora do pedido:',
          value: dados.pedido.data_hora,
        });

        dadosFormatados.dadosPedido.push({
          label: 'Data e Hora do último pedido:',
          value: dados.pedido.data_hora_ultimo_pedido,
        });

        dadosFormatados.dadosPedido.push({
          label: 'Número de dias sem compra',
          value: dados.pedido.numero_dias_sem_compra,
        });

        dadosFormatados.dadosPedido.push({
          label: 'Data de previsão de chegada:',
          value: dados.pedido.data_previsao_chegada,
        });

        dadosFormatados.dadosPedido.push({
          label: 'R$ Total do Pedido',
          value: dados.pedido.total_pedido,
        });

        dadosFormatados.dadosPedido.push({
          label: 'Estimativa de Total de Encargos (1 dia de atraso):',
          value: dados.pedido.estimativa_total_encargos_1_dia_atrazo,
        });

        dadosFormatados.dadosPedido.push({
          label:
            'Estimativa de Total de Encargos (1 dia de atraso) + Perda de Desconto no Boleto:',
          value:
            dados.pedido
              .estimativa_total_encargos_1_dia_atrazo_mais_perda_desconto_boleto,
        });

        dadosFormatados.dadosPedido.push({
          label: 'Desconto em caso de menor prazo (R$):',
          value: dados.pedido.desconto_menor_prazo,
        });

        /////////////////////////

        dadosFormatados.dadosVerbas.push({
          label: 'Verba de Bonificação:',
          value: dados.verbas.bonificacao,
        });

        dadosFormatados.dadosVerbas.push({
          label: 'Verba de Jornal de Ofertas:',
          value: dados.verbas.jornal_ofertas,
        });

        dadosFormatados.dadosVerbas.push({
          label: 'Verba de Descarrego:',
          value: dados.verbas.descarrego,
        });

        dadosFormatados.dadosVerbas.push({
          label: 'Verba de Desconto em Boleto (%):',
          value: dados.verbas.desconto_boleto_porcentagem,
        });

        dadosFormatados.dadosVerbas.push({
          label: 'Verba de Desconto em Boleto:',
          value: dados.verbas.desconto_boleto,
        });

        dadosFormatados.dadosVerbas.push({
          label: 'Verba de Backlight:',
          value: dados.verbas.desconto_backlight,
        });

        dadosFormatados.dadosVerbas.push({
          label: 'Verba Total',
          value: dados.verbas.total,
        });
      }

      return dadosFormatados;
    }

    const {
      dadosComprador,
      dadosFonecedor,
      dadosPedido,
      dadosVerbas,
    } = formatDados();

    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          disableEscapeKeyDown
          scroll="paper"
          //fullScreen
          fullWidth
          maxWidth="md"
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleClose}
            style={{
              backgroundColor: SECONDARY_COLOR,
              color: 'white',
            }}
          >
            Cabeçalho
          </DialogTitle>
          <DialogContent dividers style={{ padding: 0, height: '100%' }}>
            <div style={{ height: '100%' }}>
              <Container show={!!dados}>
                <Box
                  display={{ xs: 'block', sm: 'flex' }}
                  flexDirection="column"
                  marginBottom="0px"
                  height="100%"
                >
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left" colSpan={2}>
                            Dados do Fornecedor
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dadosFonecedor.map((item) => (
                          <StyledTableRow key="1">
                            <StyledTableCell align="left">
                              {item.label}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {item.value}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left" colSpan={2}>
                            Dados do Comprador
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dadosComprador.map((item) => (
                          <StyledTableRow key="1">
                            <StyledTableCell align="left">
                              {item.label}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {item.value}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left" colSpan={2}>
                            Dados do Pedido
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dadosPedido.map((item) => (
                          <StyledTableRow key="1">
                            <StyledTableCell align="left">
                              {item.label}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {item.value}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left" colSpan={2}>
                            Verbas Negociadas
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dadosVerbas.map((item) => (
                          <StyledTableRow key="1">
                            <StyledTableCell align="left">
                              {item.label}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {item.value}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Container>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  },
);

export default DialogoTrocoInicial;
