import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
  useEffect,
} from 'react';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { DialogoConfirmacao } from '../../../..';
import { getListAllAPI } from '../../../../../services';
import {
  POR_UNIDADE,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  getTotalCompra,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_1,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_2,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_3,
  TIPO_COMPRA_MERCADORIAS_REVENDA,
  TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA,
  TIPO_COMPRA_INSUMOS_UTILIZACAO,
  TIPO_COMPRA_SERVICOS_PRESTADO_A_EMPRESA,
  formatMoeda,
} from '../../../../../utils';
import DialogoParcelaCompra from '../FinalizarFrenteCaixaCompra/DialogoParcelaCompra';
import ContainerDialogoPrecificacaoNova from '../InicioFrenteCaixaCompra/ContainerDialogoPrecificacaoNova';
import DialogoPrecificacaoNova from '../InicioFrenteCaixaCompra/DialogoPrecificacaoNova';
import DialogoTotais from './DialogoTotais';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function getQuantidades(produto, quantidade, preco_compra) {
  switch (produto.unidade.tipo) {
    case POR_PESO_FIXO:
      return {
        unidades: quantidade,
        peso: quantidade * produto.peso_medio,
        preco_compra,
      };
    case POR_UNIDADE:
      return {
        unidades: quantidade,
        peso: 0,
        preco_compra,
      };
    case POR_PESO_VARIAVEL_SEM_UNIDADE:
      return {
        unidades: 0,
        peso: quantidade,
        preco_compra,
      };
    default:
      return {
        unidades: 0,
        peso: 0,
        preco_compra,
      };
  }
}

function getRepassesItens(item, qtde) {
  const repasses = {
    repasseDoIpi: 0,
    repasseDoFreteUnitario: 0,
    repasseDeSt: 0,
    repasseDeSeguros: 0,
    repasseDeOutrasDespesas: 0,
  };

  if (item) {
    if (item.vIPI) {
      repasses.repasseDoIpi = item.vIPI.value / qtde;
    }

    if (item.vFrete) {
      repasses.repasseDoFreteUnitario = item.vFrete.value / qtde;
    }

    if (item.vICMSST) {
      repasses.repasseDeSt = item.vICMSST.value / qtde;
    }

    if (item.vSeg) {
      repasses.repasseDeSeguros = item.vSeg.value / qtde;
    }

    if (item.vOutro) {
      repasses.repasseDeOutrasDespesas = item.vOutro.value / qtde;
    }
  }

  return repasses;
}

function getTotaisItemNota(item) {
  const totais = {
    pis: 0, // ok
    cofins: 0, // ok
    icms: 0, // ok
    total: 0, // ok
    bc_icms: 0, // ok
    repasse_ipi: 0, // ok
    repasse_st: 0, // ok
    repasse_frete: 0, // ok
    repasse_seguro: 0, // ok
    repasse_outras_despesas: 0, // ok
    desconto_nfe: 0, // ok
    isento: false, // ok
    nItem: '', // ok
    indTot: 1, // ok
  };

  if (item) {
    if (item.nItem) {
      totais.nItem = item.nItem.value;
    }

    if (item.vPIS) {
      totais.pis = item.vPIS.value;
    }

    if (item.vCOFINS) {
      totais.cofins = item.vCOFINS.value;
    }

    if (item.vIPI) {
      totais.repasse_ipi = item.vIPI.value;
    }

    if (item.vICMSST) {
      totais.repasse_st = item.vICMSST.value;
    }

    if (item.vFrete) {
      totais.repasse_frete = item.vFrete.value;
    }

    if (item.vSeg) {
      totais.repasse_seguro = item.vSeg.value;
    }

    if (item.vOutro) {
      totais.repasse_outras_despesas = item.vOutro.value;
    }

    if (item.vDesc) {
      totais.desconto_nfe = item.vDesc.value;
    }

    if (item.vBC) {
      totais.bc_icms = item.vBC.value;
    } else {
      totais.isento = true;
    }

    if (item.vICMS) {
      totais.icms = item.vICMS.value;
    }

    if (item.vProd) {
      totais.total = item.vProd.value;
    }

    if (item.indTot) {
      totais.indTot = item.indTot.value;
    }
  }

  return totais;
}

function getDescontosItens(item, qtde) {
  const descontos = {
    descontoNfe: 0,
  };

  if (item) {
    if (item.vDesc) {
      descontos.descontoNfe = item.vDesc.value / qtde;
    }
  }

  return descontos;
}

function getFatorCaixaria(produto) {
  if (!produto) return 1;
  if (produto.unidade.tipo === POR_UNIDADE && produto.is_fator_conversao_up)
    return 1;
  if (produto.unidade.tipo === POR_PESO_FIXO) return 1;
  switch (produto.fator_conversao_tipo_caixaria) {
    case FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM:
      return 1;
    case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_1: {
      if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
        if (produto.peso_caixa_nivel_1) {
          return produto.peso_caixa_nivel_1;
        }
        return 1;
      }
      if (produto.unidades_caixa_nivel_1) {
        return produto.unidades_caixa_nivel_1;
      }
      return 1;
    }

    case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_2: {
      if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
        if (!produto.peso_caixa_nivel_1) {
          return 1;
        }
        if (produto.unidades_caixa_nivel_2) {
          return produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2;
        }
        return 1;
      }
      if (!produto.unidades_caixa_nivel_1) {
        return 1;
      }
      if (produto.unidades_caixa_nivel_2) {
        return produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2;
      }
      return 1;
    }

    case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_3: {
      if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
        if (!produto.peso_caixa_nivel_1) {
          return 1;
        }
        if (!produto.unidades_caixa_nivel_2) {
          return 1;
        }
        if (produto.unidades_caixa_nivel_3) {
          return (
            produto.peso_caixa_nivel_1 *
            produto.unidades_caixa_nivel_2 *
            produto.unidades_caixa_nivel_3
          );
        }
        return 1;
      }
      if (!produto.unidades_caixa_nivel_1) {
        return 1;
      }
      if (!produto.unidades_caixa_nivel_2) {
        return 1;
      }
      if (produto.unidades_caixa_nivel_3) {
        return (
          produto.unidades_caixa_nivel_1 *
          produto.unidades_caixa_nivel_2 *
          produto.unidades_caixa_nivel_3
        );
      }
      return 1;
    }

    default:
      return 1;
  }
}

function getParcelasFormatadoInit(dados) {
  const { pag, fat, dup } = dados;

  const parcelasAjustadas = [];

  function getDesconto(fat) {
    if (fat) {
      if (fat.vDesc) {
        return fat.vDesc.value;
      }
      return 0;
    }

    return 0;
  }

  const desconto = getDesconto(fat);

  function distribuirDescontoParcelas(desconto, nParcelas) {
    if (nParcelas === 1) return [desconto];
    const descs = [];
    const multiplicadoPor100 = Math.round(desconto * 100);
    const parcelaNomral = parseInt(multiplicadoPor100 / nParcelas, 10);
    const resto = multiplicadoPor100 % nParcelas;
    for (let i = 0; i < nParcelas; i++) {
      if (i !== nParcelas - 2) {
        descs.push(parcelaNomral / 100);
      } else {
        descs.push(resto / 100);
      }
    }

    return descs;
  }

  if (dup) {
    const descontos = distribuirDescontoParcelas(desconto, dup.length);
    for (let i = 0; i < dup.length; i++) {
      parcelasAjustadas.push({
        valor: dup[i].vDup.value + descontos[i],
        numeroParcela: dup[i].nDup.value,
        dataPagamento: moment(dup[i].dVenc.value).format('YYYY-MM-DD'),
        desconto: descontos[i],
      });
    }
  }

  if (parcelasAjustadas.length === 0 && pag) {
    const descontos = distribuirDescontoParcelas(desconto, pag.length);
    for (let i = 0; i < pag.length; i++) {
      parcelasAjustadas.push({
        valor: pag[i].vPag.value + descontos[i],
        numeroParcela: i + 1,
        dataPagamento: '',
        desconto: descontos[i],
      });
    }
  }

  return parcelasAjustadas;
}

//function getParcelasFormatado(item, index) {}

function getParcelasFormatadoPosDialogo(dados, index) {
  const {
    valor,
    conta,
    data_pagamento,
    status,
    cartao,
    isCartao,
    fatura,
    codigo_boleto,
    problema,
    descricao_problema,
    agendamento_user_id,
    desconto,
    tarifa_boleto,
  } = dados;
  return {
    data_pagamento,
    conta,
    valor,
    uidd: `${isCartao ? cartao.nome : conta.nome}${index}`,
    idIndicadorChange: -1,
    status: isCartao ? false : conta.id === 1 ? false : status,
    cartao,
    isCartao,
    fatura,
    codigo_boleto,
    problema,
    descricao_problema,
    agendamento_user_id,
    desconto,
    tarifa_boleto,
  };
}

function getLoteFormatado(item, index) {
  const produto = item.correspondencia;

  const { preco_compra } = getQuantidades(
    item.correspondencia,
    item.qTrib.value,
    item.vUnTrib.value,
  );

  const {
    repasseDoIpi,
    repasseDoFreteUnitario,
    repasseDeSt,
    repasseDeSeguros,
    repasseDeOutrasDespesas,
  } = getRepassesItens(item, item.qTrib.value);

  const { descontoNfe } = getDescontosItens(item, item.qTrib.value);

  let fator_caixaria = 1;

  fator_caixaria = getFatorCaixaria(produto);

  let fator_conversao_importacao_xml_compra = 1;

  if (produto.unidade.tipo === POR_UNIDADE) {
    if (produto.is_fator_conversao_up) {
      if (produto.fator_conversao_importacao_xml_compra) {
        fator_conversao_importacao_xml_compra =
          produto.fator_conversao_importacao_xml_compra;
      }
    }
  }

  let fator_peso_medio = 1;

  if (produto.unidade.tipo === POR_PESO_FIXO) {
    fator_peso_medio = produto.peso_medio;
  }

  const fatorFinal = fator_conversao_importacao_xml_compra / fator_caixaria;

  return {
    produtoId: produto.id,
    validade: item.dVal ? item.dVal.value : null,
    unitario: preco_compra * fatorFinal,
    quantidade:
      item.qTrib.value /
      fatorFinal /
      (fator_caixaria !== 1 ? 1 : fator_peso_medio),
    repasseDoIpi: repasseDoIpi * fatorFinal,
    repasseDoFreteUnitario: repasseDoFreteUnitario * fatorFinal,
    repasseDeSt: repasseDeSt * fatorFinal,
    repasseDeSeguros: repasseDeSeguros * fatorFinal,
    repasseDeOutrasDespesas: repasseDeOutrasDespesas * fatorFinal,
    descontoNfe: descontoNfe * fatorFinal,
    index,
    dadosComplementaresNota: {
      qCom: item.qTrib.value,
      vUnCom: item.vUnTrib.value,
      uCom: item.uTrib.value,
      fator_caixaria,
      fator_conversao_importacao_xml_compra,
      fator_peso_medio,
      vIPI: item.vIPI ? item.vIPI.value : 0,
      vFrete: item.vFrete ? item.vFrete.value : 0,
      vICMSST: item.vICMSST ? item.vICMSST.value : 0,
      vSeg: item.vSeg ? item.vSeg.value : 0,
      vOutro: item.vOutro ? item.vOutro.value : 0,
      vDesc: item.vDesc ? item.vDesc.value : 0,
      //
      quantidadeCompradaFormatado: `${item.qCom.value} ${item.uCom.value} = ${item.qTrib.value} ${item.uTrib.value}`,
      valorUnitarioComprado: `${item.vUnCom.value} / ${item.uCom.value} = ${item.vUnTrib.value} / ${item.uTrib.value}`,
      //
      vProd: item.vProd ? item.vProd.value : 0,
    },
  };
}

const DialogoPassadorLotes = forwardRef(
  ({ handleClose, handleSalvar, tipoCompraNatureza }, ref) => {
    const [open, setOpen] = useState(false);

    const dadosLocal = useRef(null);

    const position = useRef(0);

    const positionParcela = useRef(0);

    const tipoPassador = useRef(0);

    const [cont, setCont] = useState(0);

    const refDialogoPrecificacaoNova = useRef(null);

    const refDialogoParcelaCompra = useRef(null);

    const refDialogoTotais = useRef(null);

    useImperativeHandle(ref, () => ({
      handleOpen(
        hasRepasseNaoUnitarioIPI,
        hasRepasseNaoUnitarioFrete,
        hasRepasseNaoUnitarioST,
        hasRepasseNaoUnitarioSeguros,
        hasRepasseNaoUnitarioOutrasDespesas,
        fornecedor,
        config,
        lotes,
        parcelas,
        descontoTotalDescarrego,
        descontoTotalBonificacao,
      ) {
        dadosLocal.current = {
          hasRepasseNaoUnitarioIPI,
          hasRepasseNaoUnitarioFrete,
          hasRepasseNaoUnitarioST,
          hasRepasseNaoUnitarioSeguros,
          hasRepasseNaoUnitarioOutrasDespesas,
          lotes,
          fornecedor,
          config,
          parcelas: getParcelasFormatadoInit(parcelas),
          descontoTotalDescarrego,
          descontoTotalBonificacao,
        };
        position.current = 0;
        tipoPassador.current = 0;
        positionParcela.current = 0;
        setOpen(true);
      },
    }));

    const isLotes =
      tipoCompraNatureza === TIPO_COMPRA_MERCADORIAS_REVENDA ||
      tipoCompraNatureza === TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA;

    useEffect(() => {
      if (open && dadosLocal.current) {
        if (isLotes) {
          handlePrecificar(dadosLocal.current.lotes[0], 0);
        } else if (refDialogoTotais.current) {
          refDialogoTotais.current.handleOpen();
        }

        position.current = 0;
      }
    }, [open]);

    function handleCloseDialog() {
      dadosLocal.current = null;
      position.current = 0;
      setOpen(false);
      handleClose();
    }

    function handleClosePrecificacao(dados) {
      if (dadosLocal.current) {
        if (dados) {
          const { index, produto: produto2, ...dados2 } = dados;

          if (index >= 0) {
            let unidades = 0;
            let peso = 0;

            if (produto2.unidade.tipo === POR_PESO_FIXO) {
              unidades = dados.qtde;
              peso = unidades * produto2.peso_medio;
            } else if (produto2.unidade.tipo === POR_UNIDADE) {
              unidades = dados.qtde;
              peso = 0;
            } else {
              unidades = 0;
              peso = dados.qtde;
            }

            const dadosFormatados = {
              produto: produto2,
              peso: produto2.unidade.tipo === POR_UNIDADE ? 0 : peso,
              total: getTotalCompra(
                peso,
                unidades,
                dados2.preco_compra_impostos,
                produto2,
              ),
              unidades:
                produto2.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
                  ? 0
                  : unidades,
              unitario: dados2.preco_compra,
              uidd: `${produto2.nome}${dadosLocal.current.lotes.length}`,
              idIndicadorChange: -1,
              validade: dados2.validade,
              unitarioImposto: dados2.preco_compra_impostos,
              cg: dados2.cg,
              dadosPrecificacao: dados2.dadosPrecificacao,
              nImpressoes: dados2.nImpressoes,
              nImpressoesCartazes: dados2.nImpressoesCartazes,
              index,
              validades: dados2.validades,
              totaisFiscais: getTotaisItemNota(
                dadosLocal.current.lotes[index],
                produto2,
              ),
              referencia_xml: dadosLocal.current.lotes[index].nItem.value,
              unitarioPresumido: dados2.preco_compra_presumido,
              unitarioImpostoPresumido: dados2.preco_compra_imposto_presumido,
              totalPresumido: getTotalCompra(
                peso,
                unidades,
                dados2.preco_compra_imposto_presumido,
                produto2,
              ),
            };

            const lotesCopy = dadosLocal.current.lotes.slice();
            lotesCopy[index].dadosFormatados = dadosFormatados;

            dadosLocal.current = {
              ...dadosLocal.current,
              lotes: lotesCopy,
            };

            if (index === lotesCopy.length - 1) {
              tipoPassador.current = 1;
              // ir para os itens
              /*  handleSalvar(
                dadosLocal.current.lotes.map((item) => item.dadosFormatados),
              );
              dadosLocal.current = null;
              position.current = 0; */
              if (dadosLocal.current.parcelas.length > 0) {
                positionParcela.current = 0;
                /* openDialogoParcelaCompra(
                  dadosLocal.current.parcelas[positionParcela.current],
                  positionParcela.current,
                ); */
                if (refDialogoTotais.current) {
                  refDialogoTotais.current.handleOpen();
                }
                setCont(cont + 1);
              } /*  else {
                handleSalvar(
                  dadosLocal.current.lotes.map((item) => item.dadosFormatados),
                  dadosLocal.current.parcelas.map(
                    (item) => item.dadosFormatados,
                  ),
                );
                dadosLocal.current = null;
                position.current = 0;
                positionParcela.current = 0;
                setOpen(false);
              } */ else if (
                refDialogoTotais.current
              ) {
                refDialogoTotais.current.handleOpen();
              }
              //setOpen(false);
            } else {
              position.current += 1;
              handlePrecificar(
                dadosLocal.current.lotes[position.current],
                position.current,
              );
              setCont(cont + 1);
            }
          }
        } else if (position.current === 0) {
          handleCloseDialog();
        } else {
          position.current -= 1;
          /* handlePrecificar(
            dadosLocal.current.lotes[position.current],
            position.current,
          ); */
          handleRePrecificar(position.current);
          setCont(cont + 1);
        }
      }
    }

    function getDadosLoteBonificacao(item, index, preco_compra_presumido) {
      const produto = item.correspondencia;

      const { preco_compra } = getQuantidades(
        item.correspondencia,
        item.qTrib.value,
        item.vUnTrib.value,
      );

      const {
        repasseDoIpi,
        repasseDoFreteUnitario,
        repasseDeSt,
        repasseDeSeguros,
        repasseDeOutrasDespesas,
      } = getRepassesItens(item, item.qTrib.value);

      const { descontoNfe } = getDescontosItens(item, item.qTrib.value);

      let fator_caixaria = 1;

      fator_caixaria = getFatorCaixaria(produto);

      let fator_conversao_importacao_xml_compra = 1;

      if (produto.unidade.tipo === POR_UNIDADE) {
        if (produto.is_fator_conversao_up) {
          if (produto.fator_conversao_importacao_xml_compra) {
            fator_conversao_importacao_xml_compra =
              produto.fator_conversao_importacao_xml_compra;
          }
        }
      }

      let fator_peso_medio = 1;

      if (produto.unidade.tipo === POR_PESO_FIXO) {
        fator_peso_medio = produto.peso_medio;
      }

      const fatorFinal = fator_conversao_importacao_xml_compra / fator_caixaria;

      const preco_compra_final = preco_compra * fatorFinal;

      const custoFinalProduto =
        (preco_compra +
          repasseDoFreteUnitario +
          repasseDeSeguros +
          repasseDeOutrasDespesas -
          descontoNfe +
          repasseDoIpi +
          repasseDeSt) *
        fatorFinal;

      const custoFinalProdutoPresumido =
        preco_compra_presumido +
        (repasseDoFreteUnitario +
          repasseDeSeguros +
          repasseDeOutrasDespesas -
          descontoNfe +
          repasseDoIpi +
          repasseDeSt) *
          fatorFinal;

      let unidades = 0;
      let peso = 0;

      const qtde =
        item.qTrib.value /
        fatorFinal /
        (fator_caixaria !== 1 ? 1 : fator_peso_medio);

      if (produto.unidade.tipo === POR_PESO_FIXO) {
        unidades = qtde;
        peso = unidades * produto.peso_medio;
      } else if (produto.unidade.tipo === POR_UNIDADE) {
        unidades = qtde;
        peso = 0;
      } else {
        unidades = 0;
        peso = qtde;
      }

      return {
        produto,
        peso: produto.unidade.tipo === POR_UNIDADE ? 0 : peso,
        total: getTotalCompra(peso, unidades, custoFinalProduto, produto),
        unidades:
          produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE ? 0 : unidades,
        unitario: preco_compra_final,
        uidd: `${produto.nome}${index + 1}`,
        idIndicadorChange: -1,
        validade: null,
        unitarioImposto: custoFinalProduto,
        cg: 0,
        dadosPrecificacao: null,
        nImpressoes: 0,
        nImpressoesCartazes: 0,
        index,
        validades: [],
        totaisFiscais: getTotaisItemNota(
          dadosLocal.current.lotes[index],
          produto,
        ),
        unitarioPresumido: preco_compra_presumido
          ? preco_compra_presumido
          : preco_compra_final,
        unitarioImpostoPresumido: preco_compra_presumido
          ? custoFinalProdutoPresumido
          : custoFinalProduto,
        totalPresumido: getTotalCompra(
          peso,
          unidades,
          preco_compra_presumido
            ? custoFinalProdutoPresumido
            : custoFinalProduto,
          produto,
        ),
        referencia_xml: item.nItem.value,
      };
    }

    function getDadosLoteSimplificado(item, index) {
      const produto = {
        nome: item.xProd.value,
        peso_medio: 1,
        id: -1,
        unidade: {
          tipo: POR_PESO_VARIAVEL_SEM_UNIDADE,
        },
      };

      const {
        repasseDoIpi,
        repasseDoFreteUnitario,
        repasseDeSt,
        repasseDeSeguros,
        repasseDeOutrasDespesas,
      } = getRepassesItens(item, item.qTrib.value);

      const { descontoNfe } = getDescontosItens(item, item.qTrib.value);

      const qtde = item.qTrib.value;

      const peso = qtde;
      const unidades = 0;
      const preco_compra_final = item.vUnTrib.value;
      const custoFinalProduto =
        preco_compra_final +
        repasseDoFreteUnitario +
        repasseDeSeguros +
        repasseDeOutrasDespesas -
        descontoNfe +
        repasseDoIpi +
        repasseDeSt;

      return {
        produto,
        peso,
        total: getTotalCompra(peso, unidades, custoFinalProduto, produto),
        unidades,
        unitario: preco_compra_final,
        uidd: `${produto.nome}${index + 1}`,
        idIndicadorChange: -1,
        validade: null,
        unitarioImposto: custoFinalProduto,
        cg: 0,
        dadosPrecificacao: null,
        nImpressoes: 0,
        nImpressoesCartazes: 0,
        index,
        validades: [],
        totaisFiscais: null,
        referencia_xml: item.nItem.value,
        totalPresumido: getTotalCompra(
          peso,
          unidades,
          custoFinalProduto,
          produto,
        ),
        unitarioPresumido: preco_compra_final,
        unitarioImpostoPresumido: custoFinalProduto,
        /* informacoesNota: {
          valor_negociado_industria: preco_compra_final,
          repasseDoIpi,
          repasseDoFreteUnitario,
          repasseDeSt,
          repasseDeSeguros,
          repasseDeOutrasDespesas,
          descontoNfe,
        }, */
      };
    }

    function getLotesFormatados() {
      switch (tipoCompraNatureza) {
        case TIPO_COMPRA_MERCADORIAS_REVENDA:
          return dadosLocal.current.lotes.map((item) => item.dadosFormatados);
        case TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA:
          /* return dadosLocal.current.lotes.map((item, index) =>
            getDadosLoteBonificacao(item, index),
          ); */
          return dadosLocal.current.lotes.map((item) => item.dadosFormatados);
        case TIPO_COMPRA_INSUMOS_UTILIZACAO:
          return dadosLocal.current.lotes.map((item, index) =>
            getDadosLoteSimplificado(item, index),
          );
        case TIPO_COMPRA_SERVICOS_PRESTADO_A_EMPRESA:
          return dadosLocal.current.lotes.map((item, index) =>
            getDadosLoteSimplificado(item, index),
          );
        default:
          return [];
      }
    }

    function handleCloseDialogoParcelaCompra(dados, index) {
      if (dadosLocal.current) {
        if (dados) {
          if (index >= 0) {
            const dadosFormatados = getParcelasFormatadoPosDialogo(
              dados,
              index,
            );

            const parcelasCopy = dadosLocal.current.parcelas.slice();
            parcelasCopy[index].dadosFormatados = dadosFormatados;

            dadosLocal.current = {
              ...dadosLocal.current,
              parcelas: parcelasCopy,
            };

            if (index === parcelasCopy.length - 1) {
              handleSalvar(
                //dadosLocal.current.lotes.map((item) => item.dadosFormatados),
                getLotesFormatados(),
                dadosLocal.current.parcelas.map((item) => item.dadosFormatados),
              );
              dadosLocal.current = null;
              position.current = 0;
              positionParcela.current = 0;
              setOpen(false);
            } else {
              positionParcela.current += 1;
              openDialogoParcelaCompra(
                dadosLocal.current.parcelas[positionParcela.current],
                positionParcela.current,
              );
              setCont(cont + 1);
            }
          }
        } else if (positionParcela.current === 0) {
          /* tipoPassador.current = 0;
          position.current = dadosLocal.current.lotes.length - 1;
          handlePrecificar(
            dadosLocal.current.lotes[position.current],
            position.current,
          );
          setCont(cont + 1); */
          if (refDialogoTotais.current) {
            refDialogoTotais.current.handleOpen();
          }
        } else {
          positionParcela.current -= 1;
          openDialogoParcelaCompra(
            dadosLocal.current.parcelas[positionParcela.current],
            positionParcela.current,
          );
          setCont(cont + 1);
        }
      }
    }

    function format10(value) {
      return value < 10 ? `0${value}` : `${value}`;
    }

    function formatDate(date) {
      if (date) {
        const dateReal = new Date(date);
        const ano = dateReal.getFullYear();
        const mes = format10(dateReal.getMonth() + 1);
        const dia = format10(dateReal.getDate());
        return `${ano}-${mes}-${dia}`;
      }
      return '';
    }

    function handlePrecificar(dados, index) {
      if (refDialogoPrecificacaoNova.current && dadosLocal.current) {
        const dadosFormatados = getLoteFormatado(dados, index);
        refDialogoPrecificacaoNova.current.handleEdit(
          {
            produtoId: dadosFormatados.produtoId,
            config: dadosLocal.current.config,
            fornecedor: dadosLocal.current.fornecedor,
            validade: formatDate(dadosFormatados.validade),
            nImpressoes: 0,
            valorNegociadoComAIndustria: dadosFormatados.unitario,
            quantidadeComprada: dadosFormatados.quantidade,
            repasseDoIpi: dadosFormatados.repasseDoIpi,
            repasseDoFreteUnitario: dadosFormatados.repasseDoFreteUnitario,
            repasseDeSt: dadosFormatados.repasseDeSt,
            repasseDeSeguros: dadosFormatados.repasseDeSeguros,
            repasseDeOutrasDespesas: dadosFormatados.repasseDeOutrasDespesas,
            index: dadosFormatados.index,
            descontoNfe: dadosFormatados.descontoNfe,
            descontoBoleto: 0,
            descontoBonificacao: 0,
            descontoBacklight: 0,
            descontoJornalOfertas: 0,
            descontoDescargoMercadorias: 0,
            dadosComplementaresNota: dadosFormatados.dadosComplementaresNota,
            validades: [],
          },
          dados.correspondencia,
          dados.dadosFormatados,
        );
      }
    }

    function getDadosPrecificacao(index) {
      const item = dadosLocal.current.lotes[index];
      const dados = item.dadosFormatados;

      if (!dados) {
        return null;
      }

      const dadosPrecificacao = dados.dadosPrecificacao;

      if (!dadosPrecificacao) {
        return null;
      }

      const { produto, nImpressoes, validades } = dados;

      const {
        repasse_do_ipi,
        repasse_do_frete,
        repasse_de_st,
        repasse_do_seguro,
        repasse_de_outras_despesas,
        desconto_em_nfe,
        desconto_em_boleto,
        desconto_em_bonificacao,
        desconto_em_backlight_e_ponto_extra,
        desconto_em_jornal_de_ofertas,
        desconto_em_descargo_de_mercadorias,
        taxa_lucro_bruto,
        valor_negociado_com_a_industria_presumido,
        valor_negociado_com_a_industria,
      } = dadosPrecificacao;

      let fator_caixaria = 1;

      fator_caixaria = getFatorCaixaria(produto);

      let fator_conversao_importacao_xml_compra = 1;

      if (produto.unidade.tipo === POR_UNIDADE) {
        if (produto.is_fator_conversao_up) {
          if (produto.fator_conversao_importacao_xml_compra) {
            fator_conversao_importacao_xml_compra =
              produto.fator_conversao_importacao_xml_compra;
          }
        }
      }

      let fator_peso_medio = 1;

      if (produto.unidade.tipo === POR_PESO_FIXO) {
        fator_peso_medio = produto.peso_medio;
      }

      const fatorFinal = fator_conversao_importacao_xml_compra / fator_caixaria;

      return {
        produto,
        repasseDoIpi: repasse_do_ipi,
        repasseDoFreteUnitario: repasse_do_frete,
        repasseDeSt: repasse_de_st,
        repasseDeSeguros: repasse_do_seguro,
        repasseDeOutrasDespesas: repasse_de_outras_despesas,
        descontoNfe: desconto_em_nfe,
        descontoBoleto: desconto_em_boleto,
        descontoBonificacao: desconto_em_bonificacao,
        descontoBacklight: desconto_em_backlight_e_ponto_extra,
        descontoJornalOfertas: desconto_em_jornal_de_ofertas,
        descontoDescargoMercadorias: desconto_em_descargo_de_mercadorias,
        validades,
        valorNegociadoComAIndustria: valor_negociado_com_a_industria,
        valorNegociadoComAIndustriaPresumido: valor_negociado_com_a_industria_presumido,
        nImpressoes,
        margemLucroBruto: taxa_lucro_bruto,
        correspondencia: item.correspondencia,
        dadosFormatados: dados,
        quantidade:
          item.qTrib.value /
          fatorFinal /
          (fator_caixaria !== 1 ? 1 : fator_peso_medio),
        dadosComplementaresNota: {
          qCom: item.qTrib.value,
          vUnCom: item.vUnTrib.value,
          uCom: item.uTrib.value,
          fator_caixaria,
          fator_conversao_importacao_xml_compra,
          fator_peso_medio,
          vIPI: item.vIPI ? item.vIPI.value : 0,
          vFrete: item.vFrete ? item.vFrete.value : 0,
          vICMSST: item.vICMSST ? item.vICMSST.value : 0,
          vSeg: item.vSeg ? item.vSeg.value : 0,
          vOutro: item.vOutro ? item.vOutro.value : 0,
          vDesc: item.vDesc ? item.vDesc.value : 0,
          //
          quantidadeCompradaFormatado: `${item.qCom.value} ${item.uCom.value} = ${item.qTrib.value} ${item.uTrib.value}`,
          valorUnitarioComprado: `${item.vUnCom.value} / ${item.uCom.value} = ${item.vUnTrib.value} / ${item.uTrib.value}`,
          //
          vProd: item.vProd ? item.vProd.value : 0,
        },
      };
    }

    function handleRePrecificar(index) {
      if (refDialogoPrecificacaoNova.current && dadosLocal.current) {
        const dados = getDadosPrecificacao(index);

        if (dados) {
          refDialogoPrecificacaoNova.current.handleEditarDiferente(
            {
              index,
              produto: dados.produto,
              repasseDoIpi: dados.repasseDoIpi,
              repasseDoFreteUnitario: dados.repasseDoFreteUnitario,
              repasseDeSt: dados.repasseDeSt,
              repasseDeSeguros: dados.repasseDeSeguros,
              repasseDeOutrasDespesas: dados.repasseDeOutrasDespesas,
              descontoNfe: dados.descontoNfe,
              descontoBoleto: dados.descontoBoleto,
              descontoBonificacao: dados.descontoBonificacao,
              descontoBacklight: dados.descontoBacklight,
              descontoJornalOfertas: dados.descontoJornalOfertas,
              descontoDescargoMercadorias: dados.descontoDescargoMercadorias,
              dadosComplementaresNota: dados.dadosComplementaresNota,
              validades: dados.validades,
              config: dadosLocal.current.config,
              fornecedor: dadosLocal.current.fornecedor,
              valorNegociadoComAIndustria: dados.valorNegociadoComAIndustria,
              quantidadeComprada: dados.quantidade,
              valorNegociadoComAIndustriaPresumido:
                dados.valorNegociadoComAIndustriaPresumido,
              nImpressoes: dados.nImpressoes,
              margemLucroBruto: dados.margemLucroBruto,
            },
            dados.correspondencia,
            dados.dadosFormatados,
          );
        } else {
          handlePrecificar(dadosLocal.current.lotes[index], index);
        }
      }
    }

    async function getProduto(produtoId) {
      try {
        const data = await getListAllAPI(
          'produtos',
          ['id', 'asc'],
          { id: produtoId },
          ['unidade'],
        );

        if (data.data.length > 0) {
          return data.data[0];
        }
        throw 'Este produto não existe!';
      } catch (erros) {
        return null;
      }
    }

    async function handleUpdateProduto(index) {
      const produto = await getProduto(
        dadosLocal.current.lotes[index].correspondencia.id,
      );
      dadosLocal.current.lotes[index].correspondencia = produto;
      handlePrecificar(dadosLocal.current.lotes[index], index);
      setCont(cont + 1);
    }

    function getData(data) {
      return moment(data).format('YYYY-MM-DD');
    }

    function openDialogoParcelaCompra(dados, index) {
      if (refDialogoParcelaCompra.current && dadosLocal.current) {
        if (dados.dadosFormatados) {
          refDialogoParcelaCompra.current.handleOpenEditar(
            getData(dados.dadosFormatados.data_pagamento),
            dados.dadosFormatados.valor,
            dados.dadosFormatados.desconto,
            index,
            dados.dadosFormatados.tarifa_boleto,
            //
            dados.dadosFormatados.conta, //
            dados.dadosFormatados.status, //
            dados.dadosFormatados.cartao, //
            dados.dadosFormatados.isCartao, //
            dados.dadosFormatados.fatura, //
            dados.dadosFormatados.codigo_boleto, //
            dados.dadosFormatados.problema, //
            dados.dadosFormatados.descricao_problema, //
          );
        } else {
          refDialogoParcelaCompra.current.handleOpenImportacao(
            dados.dataPagamento,
            dados.valor,
            dados.desconto,
            index,
          );
        }
      }
    }

    function getRestanteDescontoDescarrego(index) {
      let totalUsado = 0;
      if (dadosLocal.current) {
        for (let i = 0; i < dadosLocal.current.lotes.length; i++) {
          let total = 0;
          if (dadosLocal.current.lotes[i].dadosFormatados) {
            if (dadosLocal.current.lotes[i].dadosFormatados.index !== index) {
              if (
                dadosLocal.current.lotes[i].dadosFormatados.dadosPrecificacao
              ) {
                total = getTotalCompra(
                  dadosLocal.current.lotes[i].dadosFormatados.peso,
                  dadosLocal.current.lotes[i].dadosFormatados.unidades,
                  dadosLocal.current.lotes[i].dadosFormatados.dadosPrecificacao
                    .desconto_em_descargo_de_mercadorias,
                  dadosLocal.current.lotes[i].dadosFormatados.produto,
                );
              }
            }
          }
          totalUsado += total;
        }

        return dadosLocal.current.descontoTotalDescarrego - totalUsado;
      }

      return 0;
    }

    function getRestanteDescontoBonificacao(index) {
      let totalUsado = 0;
      if (dadosLocal.current) {
        for (let i = 0; i < dadosLocal.current.lotes.length; i++) {
          let total = 0;
          if (dadosLocal.current.lotes[i].dadosFormatados) {
            if (dadosLocal.current.lotes[i].dadosFormatados.index !== index) {
              if (
                dadosLocal.current.lotes[i].dadosFormatados.dadosPrecificacao
              ) {
                total = getTotalCompra(
                  dadosLocal.current.lotes[i].dadosFormatados.peso,
                  dadosLocal.current.lotes[i].dadosFormatados.unidades,
                  dadosLocal.current.lotes[i].dadosFormatados.dadosPrecificacao
                    .desconto_em_bonificacao,
                  dadosLocal.current.lotes[i].dadosFormatados.produto,
                );
              }
            }
          }
          totalUsado += total;
        }

        return dadosLocal.current.descontoTotalBonificacao - totalUsado;
      }

      return 0;
    }

    function verificaDiferencaNula(v1, v2, config = {}) {
      const configDefault = { diferencaAlvo: 0, tolerancia: 0.01 };

      const configFinal = { ...configDefault, ...config };

      const { diferencaAlvo, tolerancia } = configFinal;

      const diferencaCalculada = Math.abs(v1 - v2);
      if (
        diferencaCalculada >= diferencaAlvo - tolerancia &&
        diferencaCalculada <= diferencaAlvo + tolerancia
      )
        return true;
      return false;
    }

    function getErrosAdicionais(
      index,
      descontoDescarrego,
      descontoBonificacao,
    ) {
      const erros = ['', ''];
      const restanteDescarrego = getRestanteDescontoDescarrego(index);
      const descontoDescarrego2 = Number.isNaN(descontoDescarrego)
        ? 0
        : descontoDescarrego;
      if (descontoDescarrego2 - restanteDescarrego > 0.01) {
        /*  erros[0] = `O desconto de descarrego aplicado (${formatMoeda(
                  desconto,
                )}) é maior que o desconto de descarrego disponível (${formatMoeda(
                  restante,
                )})`; */
        erros[0] = (
          <div>{`* O Desconto de Descarrego Aplicado (${formatMoeda(
            descontoDescarrego2,
          )}) é maior que o Desconto de Descarrego Disponível (${formatMoeda(
            restanteDescarrego,
          )})`}</div>
        );
      }

      if (dadosLocal.current) {
        if (position.current === dadosLocal.current.lotes.length - 1) {
          if (!verificaDiferencaNula(restanteDescarrego, descontoDescarrego2)) {
            erros[1] = (
              <div>
                Até o último item, todo o desconto de descarrego deve ser
                utilizado
              </div>
            );
          }
        }
      }

      const restanteBonificacao = getRestanteDescontoBonificacao(index);
      const descontoBonificacao2 = Number.isNaN(descontoBonificacao)
        ? 0
        : descontoBonificacao;

      if (descontoBonificacao2 - restanteBonificacao > 0.01) {
        /*  erros[0] = `O desconto de descarrego aplicado (${formatMoeda(
                    desconto,
                  )}) é maior que o desconto de descarrego disponível (${formatMoeda(
                    restante,
                  )})`; */
        erros[2] = (
          <div>{`* O Desconto de Bonificação Aplicado (${formatMoeda(
            descontoBonificacao2,
          )}) é maior que o Desconto de Bonificação Disponível (${formatMoeda(
            restanteBonificacao,
          )})`}</div>
        );
      }

      if (dadosLocal.current) {
        if (position.current === dadosLocal.current.lotes.length - 1) {
          if (
            !verificaDiferencaNula(restanteBonificacao, descontoBonificacao2)
          ) {
            erros[3] = (
              <div>
                Até o último item, todo o desconto de bonificação deve ser
                utilizado
              </div>
            );
          }
        }
      }

      return erros;
    }

    function getInformacoesAdicionais(
      index,
      descontoDescarrego,
      descontoBonificacao,
    ) {
      const infos = [''];
      const restanteDescarrego = getRestanteDescontoDescarrego(index);

      const descontoDescarrego2 = Number.isNaN(descontoDescarrego)
        ? 0
        : descontoDescarrego;

      const diferencaDescarrego = restanteDescarrego - descontoDescarrego2;
      if (diferencaDescarrego <= 0) {
        /*  infos[0] = (
                  <div style={{ fontSize: '20px', fontWeight: 'bold', color: 'green' }}>
                    Nenhum Desconto de Descarrego Disponível!
                  </div>
                ); */
      } else {
        infos[0] = (
          <div
            style={{ fontSize: '20px', fontWeight: 'bold', color: 'green' }}
          >{`O Desconto de Descarrego disponível é de ${formatMoeda(
            diferencaDescarrego,
          )}`}</div>
        );
      }

      const restanteBonificacao = getRestanteDescontoBonificacao(index);

      const descontoBonificacao2 = Number.isNaN(descontoBonificacao)
        ? 0
        : descontoBonificacao;

      const diferencaBonificacao = restanteBonificacao - descontoBonificacao2;
      if (diferencaBonificacao <= 0) {
        /*  infos[0] = (
                  <div style={{ fontSize: '20px', fontWeight: 'bold', color: 'green' }}>
                    Nenhum Desconto de Descarrego Disponível!
                  </div>
                ); */
      } else {
        infos[1] = (
          <div
            style={{ fontSize: '20px', fontWeight: 'bold', color: 'green' }}
          >{`O Desconto de Bonificação disponível é de ${formatMoeda(
            diferencaBonificacao,
          )}`}</div>
        );
      }

      return infos;
    }

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          fullScreen
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogContent dividers>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '40px',
                fontWeight: 'bold',
              }}
            >
              {`Carregando Item ${position.current + 1}/${
                dadosLocal.current ? dadosLocal.current.lotes.length : 0
              }... `}
            </div>
          </DialogContent>
        </Dialog>
        <ContainerDialogoPrecificacaoNova
          getErrosAdicionais={getErrosAdicionais}
          getInformacoesAdicionais={getInformacoesAdicionais}
          isEmitMessage={false}
          isCloseX={false}
          ref={refDialogoPrecificacaoNova}
          handleClose={handleClosePrecificacao}
          estoque="-"
          hasRepasseNaoUnitarioIPI={
            dadosLocal.current
              ? dadosLocal.current.hasRepasseNaoUnitarioIPI
              : false
          }
          hasRepasseNaoUnitarioFrete={
            dadosLocal.current
              ? dadosLocal.current.hasRepasseNaoUnitarioFrete
              : false
          }
          hasRepasseNaoUnitarioST={
            dadosLocal.current
              ? dadosLocal.current.hasRepasseNaoUnitarioST
              : false
          }
          hasRepasseNaoUnitarioSeguros={
            dadosLocal.current
              ? dadosLocal.current.hasRepasseNaoUnitarioSeguros
              : false
          }
          hasRepasseNaoUnitarioOutrasDespesas={
            dadosLocal.current
              ? dadosLocal.current.hasRepasseNaoUnitarioOutrasDespesas
              : false
          }
          label1="Voltar"
          label2="Prosseguir"
          prefixoTitle={`Item ${position.current + 1}/${
            dadosLocal.current ? dadosLocal.current.lotes.length : 0
          } - `}
          handleUpdateProduto={handleUpdateProduto}
          tipo={tipoCompraNatureza}
          passadorLotes
          handleClose2={(valorNegociadoComAIndustriaPresumido, dados) => {
            if (valorNegociadoComAIndustriaPresumido) {
              if (dados) {
                const lotesCopy = dadosLocal.current.lotes.slice();
                const produto2 =
                  dadosLocal.current.lotes[dados.index].correspondencia;
                lotesCopy[dados.index].dadosFormatados = {
                  ...dados,
                  totaisFiscais: getTotaisItemNota(
                    dadosLocal.current.lotes[dados.index],
                    produto2,
                  ),
                  referencia_xml:
                    dadosLocal.current.lotes[dados.index].nItem.value,
                };

                dadosLocal.current = {
                  ...dadosLocal.current,
                  lotes: lotesCopy,
                };
              } else {
                const lotesCopy = dadosLocal.current.lotes.slice();
                lotesCopy[
                  position.current
                ].dadosFormatados = getDadosLoteBonificacao(
                  dadosLocal.current.lotes[position.current],
                  position.current,
                  valorNegociadoComAIndustriaPresumido,
                );

                dadosLocal.current = {
                  ...dadosLocal.current,
                  lotes: lotesCopy,
                };
              }
            } else if (dados) {
              const lotesCopy = dadosLocal.current.lotes.slice();
              const produto2 =
                dadosLocal.current.lotes[dados.index].correspondencia;
              lotesCopy[dados.index].dadosFormatados = {
                ...dados,
                totaisFiscais: getTotaisItemNota(
                  dadosLocal.current.lotes[dados.index],
                  produto2,
                ),
                referencia_xml:
                  dadosLocal.current.lotes[dados.index].nItem.value,
              };

              dadosLocal.current = {
                ...dadosLocal.current,
                lotes: lotesCopy,
              };
            } else {
              const lotesCopy = dadosLocal.current.lotes.slice();
              lotesCopy[
                position.current
              ].dadosFormatados = getDadosLoteBonificacao(
                dadosLocal.current.lotes[position.current],
                position.current,
                0,
              );

              dadosLocal.current = {
                ...dadosLocal.current,
                lotes: lotesCopy,
              };
            }

            if (position.current === dadosLocal.current.lotes.length - 1) {
              tipoPassador.current = 1;

              if (dadosLocal.current.parcelas.length > 0) {
                positionParcela.current = 0;

                if (refDialogoTotais.current) {
                  refDialogoTotais.current.handleOpen();
                }
                setCont(cont + 1);
              } else if (refDialogoTotais.current) {
                refDialogoTotais.current.handleOpen();
              }
            } else {
              position.current += 1;
              handlePrecificar(
                dadosLocal.current.lotes[position.current],
                position.current,
              );
              setCont(cont + 1);
            }
          }}
        />
        <DialogoParcelaCompra
          ref={refDialogoParcelaCompra}
          handleNewItem={handleCloseDialogoParcelaCompra}
          resto={0}
          label1="Voltar"
          label2="Prosseguir"
          isCloseX={false}
          handleClose={(index) => handleCloseDialogoParcelaCompra(null, index)}
        />
        <DialogoTotais
          ref={refDialogoTotais}
          handleClose={() => {
            tipoPassador.current = 0;
            position.current = dadosLocal.current.lotes.length - 1;
            if (isLotes) {
              /* handlePrecificar(
                dadosLocal.current.lotes[position.current],
                position.current,
              ); */
              handleRePrecificar(position.current);
            } else {
              handleCloseDialog();
            }
            setCont(cont + 1);
          }}
          handleSalvar={() => {
            if (dadosLocal.current.parcelas.length > 0) {
              positionParcela.current = 0;
              if (
                tipoCompraNatureza !==
                TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA
              ) {
                openDialogoParcelaCompra(
                  dadosLocal.current.parcelas[positionParcela.current],
                  positionParcela.current,
                );
              } else {
                handleSalvar(
                  //dadosLocal.current.lotes.map((item) => item.dadosFormatados),
                  getLotesFormatados(),
                  [],
                );
              }
            } else {
              handleSalvar(
                //dadosLocal.current.lotes.map((item) => item.dadosFormatados),
                getLotesFormatados(),
                dadosLocal.current.parcelas.map((item) => item.dadosFormatados),
              );
              dadosLocal.current = null;
              position.current = 0;
              positionParcela.current = 0;
              setOpen(false);
            }
          }}
        />
      </div>
    );
  },
);

export default DialogoPassadorLotes;
