import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
  TextField,
  ReferenceField,
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
  ListContextProvider,
} from 'react-admin';

import { Card, Divider, Tabs, Tab } from '@material-ui/core';

import { DialogoConfirmacao } from '../../Components';
import { api, getOneAPI } from '../../services';
import ActionsField from './ActionsField';
import Actions from './ActionsList';
import Dialogo from './ContainerDialogoProduto';
import Filter from './Filter';

const tabs = [
  { id: 'nao_conferidos', name: 'Não Conferidos' },
  { id: 'conferidos', name: 'Conferidos' },
];

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const refDialogoConfirmacao = useRef(null);
  const [
    produtosCaixariaVerificadas,
    setProdutosCaixariaVerificadas,
  ] = useState([]);
  const [
    produtosCaixariaNaoVerificadas,
    setProdutosCaixariaNaoVerificadas,
  ] = useState([]);

  const refDialogoCompra = useRef(null);

  function handleEdit({ id }) {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleEditConferenciaCaixaria(id);
    }
  }

  function handleMarcarComoVerificada({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Marcar caixaria como verificada',
        'Deseja realmente marcar esse produto como caixaria verificada?',
        { id, codigo: 1 },
      );
    }
  }

  function handleDesmarcarComoVerificada({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Desmarcar caixaria como verificada',
        'Deseja realmente marcar esse produto como caixaria desmarcada?',
        { id, codigo: 2 },
      );
    }
  }

  async function marcarComoVerificado(id) {
    await api.put(`/produtos-caixaria-verificacao/status/marcar/${id}`);
    handleClose();
  }

  async function desmarcarComoVerificado(id) {
    await api.put(`/produtos-caixaria-verificacao/status/desmarcar/${id}`);
    handleClose();
  }

  async function handleConfirma({ id, codigo }) {
    switch (codigo) {
      case 1:
        marcarComoVerificado(id);
        break;
      case 2:
        desmarcarComoVerificado(id);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (ids && ids !== filterValues.is_caixaria_verificada) {
      switch (filterValues.is_caixaria_verificada) {
        case false:
          setProdutosCaixariaNaoVerificadas(ids);
          break;
        case true:
          setProdutosCaixariaVerificadas(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.is_caixaria_verificada]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          {
            ...filterValues,
            is_caixaria_verificada: value === 'conferidos',
          },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const handleClose = () => {
    refresh();
  };

  async function posEdicao(id) {
    const response = await getOneAPI('produtos', id, []);
    if (response.data.is_caixaria_verificada) {
      handleDesmarcarComoVerificada({ id });
    } else {
      handleMarcarComoVerificada({ id });
    }
  }

  return (
    <>
      <Title title="Verificação Caixaria" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Divider />
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.is_caixaria_verificada ? tabs[1].id : tabs[0].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {!filterValues.is_caixaria_verificada && (
            <ListContextProvider
              value={{ ...listContext, ids: produtosCaixariaNaoVerificadas }}
            >
              <Datagrid {...props}>
                <TextField source="id" label="Id" />
                <TextField source="nome" label="Descrição" />
                <TextField source="codigo" label="Código" sortable={false} />
                <ReferenceField
                  label="Unidade"
                  source="unidade_id"
                  reference="unidades"
                  sortable={false}
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <ReferenceField
                  label="Categoria"
                  source="categoria_id"
                  reference="categorias"
                  sortable={false}
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleEdit={handleEdit}
                  handleMarcarComoVerificada={handleMarcarComoVerificada}
                  handleDesmarcarComoVerificada={handleDesmarcarComoVerificada}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.is_caixaria_verificada && (
            <ListContextProvider
              value={{ ...listContext, ids: produtosCaixariaVerificadas }}
            >
              <Datagrid {...props}>
                <TextField source="id" label="Id" />
                <TextField source="nome" label="Descrição" />
                <TextField source="codigo" label="Código" sortable={false} />
                <ReferenceField
                  label="Unidade"
                  source="unidade_id"
                  reference="unidades"
                  sortable={false}
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <ReferenceField
                  label="Categoria"
                  source="categoria_id"
                  reference="categorias"
                  sortable={false}
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <ActionsField
                  source="valor"
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                  handleEdit={handleEdit}
                  handleMarcarComoVerificada={handleMarcarComoVerificada}
                  handleDesmarcarComoVerificada={handleDesmarcarComoVerificada}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <Dialogo ref={refDialogoCompra} handleClose={posEdicao} />
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Verificação Caixaria"
      filterDefaultValues={{
        is_caixaria_verificada: false,
      }}
      component="div"
    />
  </>
);

export default List;
