import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

// import { useSnackbar } from 'notistack';
import {
  formatCPF,
  formatCNPJ,
  PIX_ALEATORIA_OPTION,
  PIX_CNPJ_OPTION,
  PIX_CPF_OPTION,
  PIX_EMAIL_OPTION,
  PIX_TELEFONE_OPTION,
  formatTelefone,
} from '../../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    // backgroundColor: (props) => props.cor,
    // color: 'white',
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    // backgroundColor: 'rgba(0, 0, 0, 0.12)',
    // color: 'red',
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function getValorFormatado(valor, type) {
  switch (type) {
    case PIX_CNPJ_OPTION:
      return formatCNPJ(valor);
    case PIX_CPF_OPTION:
      return formatCPF(valor);
    case PIX_TELEFONE_OPTION:
      return formatTelefone(valor);
    default:
      return valor;
  }
}

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose, handleDelete }, ref) => {
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(-1);
    const [dados, setDados] = useState({
      razao_social: '-',
      nome_fantasia: '-',
      cnpj: '-',
      nomePasta: '-',
    });

    const classes = useStyles();

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    async function handleSalvar() {
      handleDelete(id);
      handleCloseDialog();
    }

    useImperativeHandle(ref, () => ({
      handleDelete(id, razao_social, nome_fantasia, cnpj, nomePasta, pasta_id) {
        setId(id);
        setDados({
          razao_social,
          nome_fantasia,
          cnpj,
          nomePasta,
        });
        setOpen(true);
      },
    }));

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="sm"
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{
              backgroundColor: '#f44336',
              color: 'white',
            }}
          >
            Deletar Prazo Parcela
          </DialogTitle>
          <DialogContent
            style={{
              padding: '20px 36px 10px',
              margin: '0',
            }}
          >
            <div
              style={{
                fontSize: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <div>
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                  Razão Social:
                </span>
                <span>{dados.razao_social}</span>
              </div>
              <div>
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                  Nome Fantasia:
                </span>
                <span>{dados.nome_fantasia}</span>
              </div>
              <div>
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                  CNPJ:
                </span>
                <span>{dados.cnpj}</span>
              </div>
              <div>
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                  Pasta:
                </span>
                <span>{dados.nomePasta}</span>
              </div>
            </div>
          </DialogContent>
          <DialogActions
            style={{
              padding: '32px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={handleCloseDialog}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="outlined"
              color="primary"
            >
              Cancelar
            </Button>
            <Box>
              <div className={classes.wrapper}>
                <Button
                  onClick={handleSalvar}
                  classes={{
                    root: classes.btnAtions,
                    disabled: classes.btnAtionsDisabled,
                  }}
                  variant="contained"
                  color="primary"
                >
                  Deletar
                </Button>
              </div>
            </Box>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
