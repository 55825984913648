import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { getListAllAPI, api } from '../../../services';
import { formatDateDDMMYYYYHHmm, formatDateDDMMYYYY } from '../../../utils';
import Relatorio from './Relatorio';
import Document from './Relatorio/Document';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'black',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, onBaixar, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        endIcon={<GetAppIcon>send</GetAppIcon>}
        size="small"
        onClick={() => onBaixar()}
      >
        Baixar
      </Button>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogoTrocoInicial = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [dados, setDados] = useState(null);

  function getItensFormatados(dados) {
    const itensFormatados = [];
    for (let i = 0; i < dados.itens.length; i++) {
      const item = { ...dados.itens[i], ...dados.itens[i].produto };

      const unidade_preco_editado = item.tipo_unidade_preco;

      const unidade_adotada_na_compra = item.tipo_unidade_qtde;

      itensFormatados.push({
        id: item.id,
        descricao: item.produto.nome,
        unidade_preco_editado,
        unidade_adotada_na_compra,
        peso_medio: item.produto.peso_medio,
        status: item.produto.status,
        tipoUnidade: item.produto.unidade.tipo,
      });
    }
    return itensFormatados;
  }

  ////////////////////////////////CABEÇALHO

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'pedidos-compras',
        ['id', 'asc'],
        { id: [id] },
        [
          'fornecedor.cidade.estado',
          'itens.produto.unidade',
          'pasta',
          'comprador',
        ],
      );
      if (data.data.length > 0) {
        const itens = getItensFormatados(data.data[0]);

        const dataPedido = new Date(data.data[0].data);

        setDados({
          fornecedor: data.data[0].fornecedor,
          itens,
          id,
          dataPedido: formatDateDDMMYYYYHHmm(dataPedido),
        });
      } else {
        throw 'Este Pedido de Compra não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleClose();
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setOpen(true);
      getDataResource(id);
    },
  }));

  /////////////////////////////////////////

  const handleClose = () => {
    setOpen(false);
    setDados(null);
  };

  function getNomeArquivo() {
    return `Nota Cega Pedido Nº ${dados.id} ${
      dados.fornecedor.nome_fantasia
    } ${formatDateDDMMYYYY(dados.dataPedidoOriginal, '.')}.pdf`;
  }

  const handleDownload = async () => {
    const blob = await pdf(<Document dados={dados} />).toBlob();
    saveAs(blob, getNomeArquivo());
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        scroll="paper"
        fullScreen
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleClose}
          onBaixar={handleDownload}
        >
          {`Nota_Cega_Pedido_Nº_${dados ? dados.id : 0}`}
        </DialogTitle>
        <DialogContent dividers style={{ padding: 0, height: '100%' }}>
          <div style={{ height: '100%' }}>
            <Box
              display={{ xs: 'block', sm: 'flex' }}
              flexDirection="column"
              marginBottom="0px"
              height="100%"
            >
              <Relatorio open={open} dados={dados} />
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
});

export default DialogoTrocoInicial;
