import React from 'react';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import moment from 'moment';

import {
  TIPO_UNIDADE_KG,
  TIPO_UNIDADE_UN,
  TIPO_UNIDADE_CX1,
  TIPO_UNIDADE_CX2,
  TIPO_UNIDADE_CX3,
} from '../../../../utils';

const styles = StyleSheet.create({
  page: {
    padding: '5mm',
  },
  titleDocument: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    fontWeight: 'bold',
    fontSize: '13',
    fontFamily: 'Roboto-Bold',
  },
  subTitleDocument: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'row',
    margin: '5 0',
    fontSize: '13',
    fontWeight: 'bold',
    fontFamily: 'Roboto-Bold',
    marginBottom: '10',
  },
  sessao: {},
  subTitle: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10 0 5',
    fontWeight: 'bold',
    fontSize: '12',
  },
  body: {
    width: '100%',
    fontSize: '10',
  },
  item: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    padding: '2',
  },
  item2: {
    margin: 0,
    flex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    padding: '2',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    borderBottom: '1 solid black',
  },
  content2: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    //borderBottom: '1 solid black',
  },
  contentTotais: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#ffff00',
    fontWeight: 'bold',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#dae1f2',
    borderTop: '1 solid black',
    borderBottom: '1 solid black',
    fontFamily: 'Roboto-Bold',
    fontSize: '10',
  },
  negativo: {
    backgroundColor: '#ffc7cf',
  },
  positivo: {
    backgroundColor: '#cbf5d0',
  },
  totais: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: 'yellow',
    fontSize: '10',
    /* fontSize: '22px',
    fontWeight: 'bold', */
    padding: '2',
  },
  totais2: {
    margin: 0,
    //flex: 3,
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: 'yellow',
    fontSize: '10',
    /* fontSize: '22px',
    fontWeight: 'bold', */
    padding: '2',
  },
  bodyCabecalhos: {
    width: '100%',
    fontSize: '10',
  },
  itemCabecalho: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    padding: '2',
  },
  itemCabecalhoNegrito: {
    margin: 0,
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    padding: '2',
    fontFamily: 'Roboto-Bold',
  },
  headerCabecalho: {
    display: 'flex',
    flexDirection: 'row',
    //backgroundColor: '#dae1f2',
    borderTop: '1 solid black',
    borderBottom: '1 solid black',
    fontSize: '14',
    fontFamily: 'Roboto-Bold',
  },
  container_cabecalho: {
    display: 'flex',
    flexDirection: 'column',
    width: '49%',
    //marginBottom: '10',
  },
  linha_cabecalho: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '20',
  },
});

function Involucro({ children, field, colunas }) {
  return <>{colunas[field].show ? children : null}</>;
}

function headerTotais(colunas, totais) {
  return (
    <>
      {colunas.map((item, index) => {
        const valor = totais[item.name];
        return (
          <>
            {valor !== undefined && item.show ? (
              <View
                style={[
                  styles.totais,
                  {
                    flex: item.flex,
                    ...(item.styleTotal ? item.styleTotal : {}),
                  },
                ]}
              >
                <Text>{valor}</Text>
              </View>
            ) : item.show ? (
              <View
                style={[
                  styles.totais,
                  {
                    flex: item.flex,
                    ...(item.styleTotal ? item.styleTotal : {}),
                  },
                ]}
              >
                <Text>{item.labelTotal ? item.labelTotal : ''}</Text>
              </View>
            ) : null}
          </>
        );
      })}
    </>
  );
}

function Cabecalho({ dados, titulo, corHeader }) {
  return (
    <View style={styles.container_cabecalho}>
      <View style={[styles.headerCabecalho, { textAlign: 'center' }]}>
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: corHeader,
            opacity: 0.6, // Simula transparência
          }}
        />
        <View style={styles.item}>
          <Text>{titulo}</Text>
        </View>
      </View>

      {dados.map((item, index) => (
        <View style={[styles.content]}>
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: corHeader,
              opacity: index % 2 === 0 ? 0 : 0.4, // Simula transparência
            }}
          />
          <View style={styles.itemCabecalhoNegrito}>
            <Text>{item.label}</Text>
          </View>
          <View style={styles.itemCabecalho}>
            <Text>{item.value}</Text>
          </View>
        </View>
      ))}
    </View>
  );
}

function Cabecalhos({ dados }) {
  return (
    <>
      <Cabecalho dados={dados.dadosFonecedor} titulo="Dados do Fornecedor" />
      <Cabecalho dados={dados.dadosComprador} titulo="Dados do Comprador" />
      <Cabecalho dados={dados.dadosPedido} titulo="Dados do Pedido" />
      <Cabecalho dados={dados.dadosVerbas} titulo="Verbas Negociadas" />
    </>
  );
}

const corLinhasTabelaItens = '#917cec';

const ListaProdutos = ({ dados }) => {
  const {
    fornecedor,
    itens,
    totais,
    pasta,
    shows,
    colunas,
    cabecalho,
    id,
    dataPedido,
  } = dados;

  console.log({ dados });
  return (
    <>
      <Document author="Caruaru Frios" title={`Pedido de Compra nº ${id}`}>
        <Page size="A4" style={styles.page} wrap orientation="landscape">
          <View style={styles.body}>
            <View style={styles.titleDocument}>
              <Text>CARUARU FRIOS</Text>
            </View>
            <View style={styles.subTitleDocument}>
              <Text>{`PEDIDO DE COMPRA Nº ${id} - ${dataPedido}`}</Text>
            </View>

            {itens.length > 0 && (
              <View style={styles.sessao}>
                {/* <View style={styles.subTitle}>
                <Text>Vendas</Text>
              </View> */}
                <View style={styles.container}>
                  <View style={styles.header}>
                    {headerTotais(
                      Object.values(colunas).map((item) => item),
                      totais,
                    )}
                  </View>
                </View>
                <View style={styles.container}>
                  <View style={styles.header}>
                    <View
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: corLinhasTabelaItens,
                        opacity: 0.5,
                      }}
                    />
                    <View style={[styles.item, { flex: colunas.id.flex }]}>
                      <Text>Nº</Text>
                    </View>
                    <View
                      style={[styles.item, { flex: colunas.descricao.flex }]}
                    >
                      <Text>Descrição</Text>
                    </View>
                    <Involucro field="rs_kg" colunas={colunas}>
                      <View style={[styles.item, { flex: colunas.rs_kg.flex }]}>
                        <Text>R$/kg</Text>
                      </View>
                    </Involucro>
                    <Involucro field="rs_un" colunas={colunas}>
                      <View style={[styles.item, { flex: colunas.rs_un.flex }]}>
                        <Text>R$/un</Text>
                      </View>
                    </Involucro>
                    <Involucro field="rs_cx_1" colunas={colunas}>
                      <View
                        style={[styles.item, { flex: colunas.rs_cx_1.flex }]}
                      >
                        <Text>R$/cx1</Text>
                      </View>
                    </Involucro>
                    <Involucro field="rs_cx_2" colunas={colunas}>
                      <View
                        style={[styles.item, { flex: colunas.rs_cx_2.flex }]}
                      >
                        <Text>R$/cx2</Text>
                      </View>
                    </Involucro>
                    <Involucro field="rs_cx_3" colunas={colunas}>
                      <View
                        style={[styles.item, { flex: colunas.rs_cx_3.flex }]}
                      >
                        <Text>R$/cx3</Text>
                      </View>
                    </Involucro>
                    <View
                      style={[
                        styles.item,
                        {
                          flex: colunas.pedido.flex,
                          fontSize: '12',
                          color: 'red',
                          fontFamily: 'Roboto-Bold',
                        },
                      ]}
                    >
                      <Text>Pedido</Text>
                    </View>
                    <View
                      style={[
                        styles.item,
                        { flex: colunas.unidade_adotada_na_compra.flex },
                      ]}
                    >
                      <Text>un</Text>
                    </View>
                    <View
                      style={[
                        styles.item,
                        {
                          flex: colunas.subtotal.flex,
                          fontSize: '12',
                          //color: 'red',
                          fontFamily: 'Roboto-Bold',
                        },
                      ]}
                    >
                      <Text>SubTotal</Text>
                    </View>
                    <Involucro field="bonificacao_em_cxs" colunas={colunas}>
                      <View
                        style={[
                          styles.item,
                          { flex: colunas.bonificacao_em_cxs.flex },
                        ]}
                      >
                        <Text>Bon cx</Text>
                      </View>
                    </Involucro>
                    <Involucro field="bonificacao_subtotal" colunas={colunas}>
                      <View
                        style={[
                          styles.item,
                          { flex: colunas.bonificacao_subtotal.flex },
                        ]}
                      >
                        <Text>Bon Sub</Text>
                      </View>
                    </Involucro>
                    <Involucro field="bonificacao_subtotal" colunas={colunas}>
                      <View
                        style={[
                          styles.item,
                          { flex: colunas.rs_un_mais_bonificacao.flex },
                        ]}
                      >
                        <Text>R$ + Bon</Text>
                      </View>
                    </Involucro>
                    <Involucro field="peso_subtotal_liquido" colunas={colunas}>
                      <View
                        style={[
                          styles.item,
                          { flex: colunas.peso_subtotal_liquido.flex },
                        ]}
                      >
                        <Text>Peso Sub(kg)</Text>
                      </View>
                    </Involucro>
                    <Involucro field="volume_subtotal" colunas={colunas}>
                      <View
                        style={[
                          styles.item,
                          { flex: colunas.volume_subtotal.flex },
                        ]}
                      >
                        <Text>Vol Sub (m³)</Text>
                      </View>
                    </Involucro>
                  </View>
                  {itens.map(
                    (
                      {
                        id,
                        descricao,
                        rs_kg,
                        rs_un,
                        rs_cx_1,
                        rs_cx_2,
                        rs_cx_3,
                        pedido,
                        unidade_preco_editado,
                        unidade_adotada_na_compra,
                        subtotal,
                        bonificacao_em_cxs,
                        bonificacao_subtotal,
                        rs_un_mais_bonificacao,
                        peso_subtotal,
                        volume_subtotal,
                        estoque_atual,
                        estoque_ideal,
                        vtu_30,
                        vmd_na_maior_unidade_possivel,
                        peso_padrao,
                        volume_unitario,
                        peso_bruto,
                        regra_de_arredondamento,
                        caixaria_nivel_1,
                        caixaria_nivel_2,
                        caixaria_nivel_3,
                        //
                        show_rs_kg,
                        show_rs_un,
                        show_rs_cx_1,
                        show_rs_cx_2,
                        show_rs_cx_3,
                        show_pedido,
                        show_bonificacao,
                        index,
                        status,
                        peso_subtotal_liquido,
                        pertencente_ao_mix,
                      },
                      index2,
                    ) => (
                      <>
                        {/* <View style={styles.content2}>
                          <View
                            style={[
                              styles.item,
                              {
                                fontSize: '10',
                                marginLeft: '5',
                                fontFamily: 'Roboto-Bold',
                              },
                            ]}
                          >
                            <Text>{descricao}</Text>
                          </View>
                        </View> */}
                        <View style={styles.content}>
                          <View
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              backgroundColor: corLinhasTabelaItens,
                              opacity: index2 % 2 === 0 ? 0 : 0.3,
                            }}
                          />
                          <View
                            style={[styles.item, { flex: colunas.id.flex }]}
                          >
                            <Text>{index2 + 1}</Text>
                          </View>
                          <View
                            style={[
                              styles.item,
                              { flex: colunas.descricao.flex },
                            ]}
                          >
                            <Text>{descricao}</Text>
                          </View>
                          <Involucro field="rs_kg" colunas={colunas}>
                            <View
                              style={[
                                styles.item,
                                { flex: colunas.rs_kg.flex },
                              ]}
                            >
                              <View
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '100%',
                                  height: '100%',
                                  backgroundColor: '#88df59',
                                  opacity:
                                    unidade_preco_editado === TIPO_UNIDADE_KG
                                      ? 0.4
                                      : 0, // Simula transparência
                                }}
                              />
                              <Text>{rs_kg}</Text>
                            </View>
                          </Involucro>
                          <Involucro field="rs_un" colunas={colunas}>
                            <View
                              style={[
                                styles.item,
                                { flex: colunas.rs_un.flex },
                              ]}
                            >
                              <View
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '100%',
                                  height: '100%',
                                  backgroundColor: '#88df59',
                                  opacity:
                                    unidade_preco_editado === TIPO_UNIDADE_UN
                                      ? 0.4
                                      : 0, // Simula transparência
                                }}
                              />
                              <Text>{rs_un}</Text>
                            </View>
                          </Involucro>
                          <Involucro field="rs_cx_1" colunas={colunas}>
                            <View
                              style={[
                                styles.item,
                                { flex: colunas.rs_cx_1.flex },
                              ]}
                            >
                              <View
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '100%',
                                  height: '100%',
                                  backgroundColor: '#88df59',
                                  opacity:
                                    unidade_preco_editado === TIPO_UNIDADE_CX1
                                      ? 0.4
                                      : 0, // Simula transparência
                                }}
                              />
                              <Text>{rs_cx_1}</Text>
                            </View>
                          </Involucro>
                          <Involucro field="rs_cx_2" colunas={colunas}>
                            <View
                              style={[
                                styles.item,
                                { flex: colunas.rs_cx_2.flex },
                              ]}
                            >
                              <View
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '100%',
                                  height: '100%',
                                  backgroundColor: '#88df59',
                                  opacity:
                                    unidade_preco_editado === TIPO_UNIDADE_CX2
                                      ? 0.4
                                      : 0, // Simula transparência
                                }}
                              />
                              <Text>{rs_cx_2}</Text>
                            </View>
                          </Involucro>
                          <Involucro field="rs_cx_3" colunas={colunas}>
                            <View
                              style={[
                                styles.item,
                                { flex: colunas.rs_cx_3.flex },
                              ]}
                            >
                              <View
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '100%',
                                  height: '100%',
                                  backgroundColor: '#88df59',
                                  opacity:
                                    unidade_preco_editado === TIPO_UNIDADE_CX3
                                      ? 0.4
                                      : 0, // Simula transparência
                                }}
                              />
                              <Text>{rs_cx_3}</Text>
                            </View>
                          </Involucro>
                          <View
                            style={[styles.item, { flex: colunas.pedido.flex }]}
                          >
                            <Text>{pedido}</Text>
                          </View>
                          <View
                            style={[
                              styles.item,
                              {
                                flex: colunas.unidade_adotada_na_compra.flex,
                              },
                            ]}
                          >
                            <Text>{unidade_adotada_na_compra}</Text>
                          </View>
                          <View
                            style={[
                              styles.item,
                              { flex: colunas.subtotal.flex },
                            ]}
                          >
                            <Text> {subtotal}</Text>
                          </View>
                          <Involucro
                            field="bonificacao_em_cxs"
                            colunas={colunas}
                          >
                            <View
                              style={[
                                styles.item,
                                { flex: colunas.bonificacao_em_cxs.flex },
                              ]}
                            >
                              <Text> {bonificacao_em_cxs}</Text>
                            </View>
                          </Involucro>
                          <Involucro
                            field="bonificacao_subtotal"
                            colunas={colunas}
                          >
                            <View
                              style={[
                                styles.item,
                                { flex: colunas.bonificacao_subtotal.flex },
                              ]}
                            >
                              <Text> {bonificacao_subtotal}</Text>
                            </View>
                          </Involucro>
                          <Involucro
                            field="bonificacao_subtotal"
                            colunas={colunas}
                          >
                            <View
                              style={[
                                styles.item,
                                {
                                  flex: colunas.rs_un_mais_bonificacao.flex,
                                },
                              ]}
                            >
                              <Text> {rs_un_mais_bonificacao}</Text>
                            </View>
                          </Involucro>
                          <Involucro
                            field="peso_subtotal_liquido"
                            colunas={colunas}
                          >
                            <View
                              style={[
                                styles.item,
                                { flex: colunas.peso_subtotal_liquido.flex },
                              ]}
                            >
                              <Text> {peso_subtotal_liquido}</Text>
                            </View>
                          </Involucro>
                          <Involucro field="volume_subtotal" colunas={colunas}>
                            <View
                              style={[
                                styles.item,
                                { flex: colunas.volume_subtotal.flex },
                              ]}
                            >
                              <Text> {volume_subtotal}</Text>
                            </View>
                          </Involucro>
                        </View>
                      </>
                    ),
                  )}
                </View>
              </View>
            )}
          </View>
        </Page>
        <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.bodyCabecalhos}>
            <View style={styles.linha_cabecalho}>
              <Cabecalho
                dados={cabecalho.dadosFonecedor}
                titulo="Dados do Fornecedor"
                corHeader="#51ff00"
              />
              <Cabecalho
                dados={cabecalho.dadosComprador}
                titulo="Dados do Comprador"
                corHeader="#0066ff"
              />
            </View>
            <View style={styles.linha_cabecalho}>
              <Cabecalho
                dados={cabecalho.dadosPedido}
                titulo="Dados do Pedido"
                corHeader="#ff3c00"
              />
              <Cabecalho
                dados={cabecalho.dadosVerbas}
                titulo="Verbas Negociadas"
                corHeader="#ffff00"
              />
            </View>
          </View>
        </Page>
        {/* <Page size="A4" style={styles.page} orientation="portrait">
          <View style={styles.bodyCabecalhos}>
            <Cabecalho
              dados={cabecalho.dadosFonecedor}
              titulo="Dados do Fornecedor"
            />
          </View>
        </Page>
        <Page size="A4" style={styles.page} orientation="portrait">
          <View style={styles.bodyCabecalhos}>
            <Cabecalho
              dados={cabecalho.dadosComprador}
              titulo="Dados do Comprador"
            />
          </View>
        </Page>
        <Page size="A4" style={styles.page} orientation="portrait">
          <View style={styles.bodyCabecalhos}>
            <Cabecalho dados={cabecalho.dadosPedido} titulo="Dados do Pedido" />
          </View>
        </Page>
        <Page size="A4" style={styles.page} orientation="portrait">
          <View style={styles.bodyCabecalhos}>
            <Cabecalho
              dados={cabecalho.dadosVerbas}
              titulo="Verbas Negociadas"
            />
          </View>
        </Page> */}
      </Document>
    </>
  );
};
export default ListaProdutos;
