import React from 'react';
import {
  TextField,
  ListBase,
  ListToolbar,
  Pagination,
  Title,
  Datagrid,
  ReferenceField,
} from 'react-admin';

import { Card, makeStyles } from '@material-ui/core';

import Actions from './ActionsList';
import CaixaField from './Fields/CaixaField';
import ClienteField from './Fields/ClienteField';
import DataField from './Fields/DataField';
import FrentistaField from './Fields/FrentistaField';
import NumeroVendaField from './Fields/NumeroVendaField';
import PesoField from './Fields/PesoField';
import PrecoField from './Fields/PrecoField';
import ProdutoField from './Fields/ProdutoField';
import SessaoIdField from './Fields/SessaoIdField';
import SubtotalField from './Fields/SubtotalField';
import UnidadesField from './Fields/UnidadesField';
import Filter from './Filter';

const useStyles = makeStyles((theme) => ({
  dataHeader: {
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    padding: '10px',
    color: 'white',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const MyList = ({ ...props }) => {
  const classes = useStyles();

  return (
    <>
      <Title title="Itens Venda Não Finalizadas" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <ProdutoField
            source="grau_importancia"
            label="Produto"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <CaixaField
            source="grau_importancia"
            label="Checkout"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <ClienteField
            source="grau_importancia"
            label="Cliente"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <DataField
            source="grau_importancia"
            label="Data"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <FrentistaField
            source="grau_importancia"
            label="Frentista"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <NumeroVendaField
            source="grau_importancia"
            label="Nº venda"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <SessaoIdField
            source="grau_importancia"
            label="Id Sessão"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <UnidadesField
            source="grau_importancia"
            label="Unidades"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <PesoField
            source="grau_importancia"
            label="Peso"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <PrecoField
            source="grau_importancia"
            label="Preço"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <SubtotalField
            source="grau_importancia"
            label="Total"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <ReferenceField
            label="Reponsável"
            source="responsavel_id"
            reference="users"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            linkType={false}
          >
            <TextField source="username" />
          </ReferenceField>
        </Datagrid>
        <TransacoesPagination />
      </Card>
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      title="Itens Venda Não Finalizadas"
      filters={<Filter />}
      filterDefaultValues={{
        periodo: {
          start: new Date(),
          end: new Date(),
        },
      }}
    />
  </>
);

export default List2;
