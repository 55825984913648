import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow as TableRowMUI,
  useMediaQuery,
  TableSortLabel,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import RemoveIcon from '@material-ui/icons/Remove';
import { useSnackbar } from 'notistack';
import { object } from 'prop-types';

import {
  AutoCompleteRemoto,
  SwitchInput,
  DialogoFornecedor,
  DialogoProduto2 as DialogoProduto,
  DialogoConfirmacao,
  PrecoInput,
} from '../../../Components';
import useDebounce from '../../../hooks/useDebounce';
import {
  getListAllAPI,
  api,
  createAPI,
  updateAPI,
  getUserId,
} from '../../../services';
import {
  TIPO_PEDIDO_MINIMO_COMPRA_CAIXAS,
  SECONDARY_COLOR,
  STATUS_ATIVO,
  formatCNPJ,
  formatCPF,
  PRIMARY_COLOR,
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_PESO_FIXO,
  //
  TIPO_UNIDADE_KG,
  TIPO_UNIDADE_UN,
  TIPO_UNIDADE_CX1,
  TIPO_UNIDADE_CX2,
  TIPO_UNIDADE_CX3,
  formatMoeda,
  formatPeso,
  round10,
  formatPreco,
  TIPO_PEDIDO_MINIMO_COMPRA_KG,
  TIPO_PEDIDO_MINIMO_COMPRA_VALOR,
  STATUS_INATIVO,
} from '../../../utils';
import DialogoCreateCorrecao from '../../Produtos/DialogoCreateCorrecao';
import DialogoCabecalho from '../DialogoCabecalho';
import DialogoProdutosFornecedorForaMix from './DialogoProdutosFornecedorForaMix';
import DialogoProdutosSemPasta from './DialogoProdutosSemPasta';

function formatNegativo(value) {
  return `- (${value})`;
}

function getTextEstoque(item, unidades2, peso) {
  const qtde =
    item.unidade.tipo === POR_UNIDADE || item.unidade.tipo === POR_PESO_FIXO
      ? unidades2
      : peso;

  const isNegativa = qtde < 0;

  const pesoUtilizadasPositivas = isNegativa ? qtde * -1 : qtde;

  const objQtde = [];

  let caixaMae = 0;

  let resto1 = pesoUtilizadasPositivas;

  if (item.caixaria_nivel_3) {
    caixaMae = parseInt(
      pesoUtilizadasPositivas /
        (item.caixaria_nivel_3 * item.caixaria_nivel_2 * item.caixaria_nivel_1),
      10,
    );

    objQtde.push({
      qtde: caixaMae,
      label: `${caixaMae}cx3`,
    });

    resto1 =
      pesoUtilizadasPositivas %
      (item.caixaria_nivel_3 * item.caixaria_nivel_2 * item.caixaria_nivel_1);
  }

  let caixetas = 0;

  let resto2 = pesoUtilizadasPositivas;

  if (item.caixaria_nivel_2) {
    caixetas = parseInt(
      resto1 / (item.caixaria_nivel_2 * item.caixaria_nivel_1),
      10,
    );

    objQtde.push({
      qtde: caixetas,
      label: `${caixetas}cx2`,
    });

    resto2 = resto1 % (item.caixaria_nivel_2 * item.caixaria_nivel_1);
  }

  let caixas = 0;

  let unidades = pesoUtilizadasPositivas;

  if (item.caixaria_nivel_1) {
    caixas = parseInt(resto2 / item.caixaria_nivel_1, 10);

    objQtde.push({
      qtde: caixas,
      label: `${caixas}cx1`,
    });

    unidades = resto2 % item.caixaria_nivel_1;

    if (unidades) {
      if (item.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
        objQtde.push({
          qtde: unidades,
          label: `${formatPreco(unidades, 3, '')}`,
        });
      } else {
        objQtde.push({
          qtde: unidades,
          label: `${unidades}un`,
        });
      }
    }
  }

  let labelRetorno = objQtde
    .filter((item) => item.qtde > 0)
    .map((item) => item.label)
    .join(' + ')
    .trim();

  labelRetorno = labelRetorno
    ? isNegativa
      ? formatNegativo(labelRetorno)
      : labelRetorno
    : '-';

  return labelRetorno;
}

function getEstoqueLabel(produto, unidade_adotada_na_compra, unidades, peso) {
  switch (unidade_adotada_na_compra) {
    case TIPO_UNIDADE_KG: {
      return peso ? `${formatPreco(peso, 0, '')}kg` : '-';
    }
    case TIPO_UNIDADE_UN: {
      return unidades ? `${unidades}un` : '-';
    }
    case TIPO_UNIDADE_CX1: {
      return getTextEstoque(
        {
          ...produto,
          caixaria_nivel_1: produto.caixaria_nivel_1,
          caixaria_nivel_2: 0,
          caixaria_nivel_3: 0,
        },
        unidades,
        peso,
      );
    }
    case TIPO_UNIDADE_CX2: {
      return getTextEstoque(
        {
          ...produto,
          caixaria_nivel_1: produto.caixaria_nivel_1,
          caixaria_nivel_2: produto.caixaria_nivel_2,
          caixaria_nivel_3: 0,
        },
        unidades,
        peso,
      );
    }
    case TIPO_UNIDADE_CX3: {
      return getTextEstoque(
        {
          ...produto,
          caixaria_nivel_1: produto.caixaria_nivel_1,
          caixaria_nivel_2: produto.caixaria_nivel_2,
          caixaria_nivel_3: produto.caixaria_nivel_3,
        },
        unidades,
        peso,
      );
    }
    default: {
      return '-';
    }
  }
}

//////////////////////////////////////

export const NumberInput = forwardRef(
  ({ value, onChange, decimalPlaces, prefix = '', name, ...rest }, ref) => {
    const handleChange = (event) => {
      const newValue = event.target.value;
      onChange({ target: { name, value: sanitize(newValue) } });
    };

    function getZeros(count) {
      let zeros = '';
      for (let i = 0; i < count; i++) {
        zeros += '0';
      }
      return zeros;
    }

    function sanitize(value) {
      let replaceQuantity = formatNumberMask(value);
      replaceQuantity = replaceQuantity.replaceAll(/[^0-9.,]/g, '');
      replaceQuantity = replaceQuantity.replaceAll(/[.]/g, '');
      replaceQuantity = replaceQuantity.replaceAll(/,/g, '.');
      return Number(replaceQuantity);
    }

    function formatNumberMask(value) {
      let replaceQuantity = value;
      if (value === '') {
        if (decimalPlaces <= 0) {
          return '0';
        }
        return `0,${getZeros(decimalPlaces)}`;
      }
      replaceQuantity = String(Number(value.replaceAll(/[^0-9]/g, '')));
      if (decimalPlaces > 0) {
        if (Number.isNaN(Number(replaceQuantity))) {
          return `0,${getZeros(decimalPlaces)}`;
        }
        if (replaceQuantity.length <= decimalPlaces) {
          return `0,${getZeros(
            decimalPlaces - replaceQuantity.length,
          )}${replaceQuantity}`;
        }
        if (
          replaceQuantity.length > decimalPlaces &&
          replaceQuantity.length < 3 + decimalPlaces
        ) {
          replaceQuantity = `${replaceQuantity.slice(
            0,
            -1 * decimalPlaces,
          )},${replaceQuantity.slice(-1 * decimalPlaces)}`;
        } else {
          const before = replaceQuantity.slice(0, -1 * decimalPlaces);
          const after = replaceQuantity.slice(-1 * decimalPlaces);
          let formated = '';
          for (let i = 0; i < before.length; i += 1) {
            let dot = '';
            if (i % 3 === 0 && i !== 0) dot = '.';
            formated = before[before.length - 1 - i] + dot + formated;
          }
          replaceQuantity = `${formated},${after}`;
        }
      } else if (Number.isNaN(Number(replaceQuantity))) {
        replaceQuantity = '0';
      } else {
        let formated = '';
        for (let i = 0; i < replaceQuantity.length; i += 1) {
          let dot = '';
          if (i % 3 === 0 && i !== 0) dot = '.';
          formated =
            replaceQuantity[replaceQuantity.length - 1 - i] + dot + formated;
        }
        replaceQuantity = formated;
      }
      return replaceQuantity;
    }

    const valueFormated = `${prefix}${formatNumberMask(
      decimalPlaces <= 0
        ? String(value)
        : value.toLocaleString('de-DE', {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
          }),
    )}`;

    return (
      <input
        {...rest}
        value={valueFormated}
        onChange={handleChange}
        ref={ref}
      />
    );
  },
);

//////////////////////////////////////

const colorHeader = SECONDARY_COLOR;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    //    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const paddingTableCell = '3px';

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: 'black',
    whiteSpace: 'nowrap',
    border: '1px solid black',
    padding: paddingTableCell,
    fontWeight: 'bold',
    //width: '10px !important',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    border: '1px solid black',
    padding: paddingTableCell,
    //width: '10px !important',
    /* '&:nth-of-type(odd)': {
      backgroundColor: 'white',
    },
    '&:nth-of-type(even)': {
      backgroundColor: 'grey',
    }, */
  },
}))(TableCell);

const TableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'white',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#dbd5d5',
    },
  },
}))(TableRowMUI);

function StyledTableRowInput({
  children,
  campo,
  id,
  handleEdit,
  showInput,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(children);

  useEffect(() => {
    setValue(children);
  }, [children]);

  return (
    <StyledTableCell
      align="left"
      onClick={() => {
        if (showInput) {
          setOpen(true);
        }
      }}
      {...props}
    >
      {open ? (
        <NumberInput
          value={value}
          decimalPlaces={2}
          type="text"
          onBlur={() => {
            setOpen(false);
            //handleEdit(campo, id, value);
            setValue(children);
          }}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          autoFocus
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              setOpen(false);
              if (value !== children) {
                handleEdit(campo, id, value);
              }
            }
          }}
        />
      ) : (
        children
      )}
    </StyledTableCell>
  );
}

function StyledTableRowInputInteger({
  children,
  campo,
  id,
  handleEdit,
  showInput,
  validate = () => '',
  ...props
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(children);

  useEffect(() => {
    setValue(children);
  }, [children]);

  const erro = validate(value);

  return (
    <StyledTableCell
      align="left"
      onClick={() => {
        if (showInput) {
          setOpen(true);
        }
      }}
      {...props}
    >
      {open ? (
        <div>
          <NumberInput
            value={value}
            decimalPlaces={0}
            type="text"
            onBlur={() => {
              setOpen(false);
              //handleEdit(campo, id, value);
              setValue(children);
            }}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            autoFocus
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                setOpen(false);
                if (value !== children) {
                  if (!erro) {
                    handleEdit(campo, id, value);
                  }
                }
              }
            }}
          />
          {erro ? <div style={{ fontSize: '10px' }}>{erro}</div> : null}
        </div>
      ) : (
        children
      )}
    </StyledTableCell>
  );
}

function getHeaderTabela(columns, orderBy, order, handleSort) {
  return (
    <>
      {columns.map((item, index) => {
        console.log(item.style);
        /*    console.log({
          backgroundColor: '#7371d590',
          cursor: 'pointer',
          fontWeight: 'bold',
          ...(item.style ? item.style : {}),
        }); */
        return (
          <>
            {item.show ? (
              item.hasSort ? (
                <Tooltip title={item.dica}>
                  <StyledTableCell
                    align={item.align}
                    style={{
                      backgroundColor: '#7371d590',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      ...(item.style ? item.style : {}),
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === item.name}
                      direction={orderBy === item.name ? order : 'asc'}
                      onClick={() => handleSort(item.name)}
                      style={{
                        ...(item.style ? item.style : {}),
                      }}
                    >
                      {item.label}
                    </TableSortLabel>
                  </StyledTableCell>
                </Tooltip>
              ) : (
                <Tooltip title={item.dica}>
                  <StyledTableCell
                    align={item.align}
                    style={{
                      backgroundColor: '#7371d590',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      ...(item.style ? item.style : {}),
                    }}
                  >
                    {item.label}
                  </StyledTableCell>
                </Tooltip>
              )
            ) : null}
          </>
        );
      })}
    </>
  );
}

function LabelHeader({ texto }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {texto.map((item) => (
        <span>{item}</span>
      ))}
    </div>
  );
}

function getColunaTemplate() {
  const colunasTemplate = {
    id: {
      label: 'Nº',
      name: 'id',
      show: true,
      dica: 'Ordem',
      align: 'center',
    },
    descricao: {
      label: 'Descrição do Produto',
      name: 'descricao',
      show: true,
      dica: 'Descrição do Produto',
      align: 'left',
      hasSort: true,
      labelTotal: 'TOTAIS',
      styleTotal: {
        fontSize: '32px',
      },
    },
    rs_kg: {
      label: 'R$/kg',
      name: 'rs_kg',
      show: true,
      dica: 'Preço do Kg',
      align: 'center',
    },
    rs_un: {
      label: 'R$/un',
      name: 'rs_un',
      show: true,
      dica: 'Preço da Unidade',
      align: 'center',
    },
    rs_cx_1: {
      label: 'R$/cx1',
      name: 'rs_cx_1',
      show: true,
      dica: 'Preço da Caixa Nível 1',
      align: 'center',
    },
    rs_cx_2: {
      label: 'R$/cx2',
      name: 'rs_cx_2',
      show: true,
      dica: 'Preço da Caixa Nível 2',
      align: 'center',
    },
    rs_cx_3: {
      label: 'R$/cx3',
      name: 'rs_cx_3',
      show: true,
      dica: 'Preço da Caixa Nível 3',
      align: 'center',
    },
    pedido: {
      label: 'Pedido',
      name: 'pedido',
      show: true,
      dica: 'Pedido feito ao Fornecedor',
      align: 'center',
      style: {
        color: 'red',
        fontSize: '20px',
      },
      hasSort: true,
    },
    unidade_adotada_na_compra: {
      label: 'un',
      name: 'unidade_adotada_na_compra',
      show: true,
      dica: 'Unidade adotada na compra',
      align: 'center',
      hasSort: true,
    },
    subtotal: {
      label: 'Subtotal',
      name: 'subtotal',
      show: true,
      dica: 'Subtotal',
      align: 'center',
      hasSort: true,
      style: {
        fontSize: '20px',
      },
    },
    bonificacao_em_cxs: {
      label: 'Bon cx',
      name: 'bonificacao_em_cxs',
      show: true,
      dica: 'Bonificação em Caixas',
      align: 'center',
    },
    bonificacao_subtotal: {
      label: 'Bon Subtotal',
      name: 'bonificacao_subtotal',
      show: true,
      dica: 'Bonificação Subtotal',
      align: 'center',
    },
    rs_un_mais_bonificacao: {
      label: 'R$ + Bon',
      name: 'rs_un_mais_bonificacao',
      show: true,
      dica: 'Preço Final incluindo a precificação',
      align: 'center',
    },
    peso_subtotal: {
      label: 'Peso Sub (kg)',
      name: 'peso_subtotal',
      show: false,
      dica: 'Peso Subtotal',
      align: 'center',
    },
    peso_subtotal_liquido: {
      label: 'Peso Sub (kg)',
      name: 'peso_subtotal_liquido',
      show: true,
      dica: 'Peso Subtotal',
      align: 'center',
      hasSort: true,
    },
    volume_subtotal: {
      label: 'Vol Sub (m³)',
      name: 'volume_subtotal',
      show: true,
      dica: 'Volume Subtotal',
      align: 'center',
    },
    estoque_atual: {
      label: 'Est. Atual',
      name: 'estoque_atual',
      show: true,
      dica: 'Estoque Atual',
      align: 'center',
    },
    estoque_ideal: {
      label: 'Est. Ideal',
      name: 'estoque_ideal',
      show: true,
      dica: 'Estoque Ideal calculado pelo sistema',
      align: 'center',
    },
    vtu_30: {
      label: 'VTU30',
      name: 'vtu_30',
      show: true,
      dica: 'Venda Total dos últimos 30 dias',
      align: 'center',
    },
    status: {
      label: 'Status',
      name: 'status',
      show: true,
      dica: 'Status do Item, se está inativo ou ativo',
      align: 'center',
      hasSort: true,
    },
    vmd_na_maior_unidade_possivel: {
      label: 'VMD',
      name: 'vmd_na_maior_unidade_possivel',
      show: true,
      dica: 'Venda Média Diária',
      align: 'center',
    },
    peso_padrao: {
      label: 'PP (kg)',
      name: 'peso_padrao',
      show: true,
      dica: 'Peso Padrão (Kg)',
      align: 'center',
    },
    volume_unitario: {
      label: 'VU (m³)',
      name: 'volume_unitario',
      show: true,
      dica: 'Volume Unitário de uma caixa (m³)',
      align: 'center',
    },
    peso_bruto: {
      label: 'PB (kg)',
      name: 'peso_bruto',
      show: false,
      dica: 'Peso Bruto da caixa (kg)',
      align: 'center',
    },
    regra_de_arredondamento: {
      label: 'RA',
      name: 'regra_de_arredondamento',
      show: true,
      dica: 'Regra de Arredondamento',
      align: 'center',
    },
    caixaria_nivel_1: {
      label: 'cx1',
      name: 'caixaria_nivel_1',
      show: true,
      dica: 'Caixaria nível 1',
      align: 'center',
    },
    caixaria_nivel_2: {
      label: 'cx2',
      name: 'caixaria_nivel_2',
      show: true,
      dica: 'Caixaria nível 2',
      align: 'center',
    },
    caixaria_nivel_3: {
      label: 'cx3',
      name: 'caixaria_nivel_3',
      show: true,
      dica: 'Caixaria nível 3',
      align: 'center',
    },
    acoes: {
      label: 'Ações',
      name: 'acoes',
      show: true,
      dica: 'Ações no Produto',
      align: 'center',
    },
  };

  return colunasTemplate;
}

const TELA_1 = 0;
const TELA_2 = 1;

function Involucro({ children, field, colunas }) {
  return <>{colunas[field].show ? children : null}</>;
}

function headerTotais(colunas, totais) {
  return (
    <>
      {colunas.map((item, index) => {
        const valor = totais[item.name];
        return (
          <>
            {valor !== undefined && item.show ? (
              <StyledTableCell
                align={item.align}
                style={{
                  backgroundColor: 'yellow',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  ...(item.styleTotal ? item.styleTotal : {}),
                }}
                rowSpan={2}
              >
                {valor}
              </StyledTableCell>
            ) : item.show ? (
              <StyledTableCell
                align={item.align}
                style={
                  item.labelTotal
                    ? {
                        backgroundColor: 'yellow',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        ...(item.styleTotal ? item.styleTotal : {}),
                      }
                    : {
                        backgroundColor: 'yellow',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        ...(item.styleTotal ? item.styleTotal : {}),
                      }
                }
                rowSpan={2}
              >
                {item.labelTotal ? item.labelTotal : ''}
              </StyledTableCell>
            ) : null}
          </>
        );
      })}
    </>
  );
}

function InputSearch({ handleChange }) {
  const [nome, setNome] = useState('');
  const [nomeDebounce] = useDebounce(nome, 500);

  useEffect(() => {
    handleChange(nomeDebounce);
  }, [nomeDebounce]);

  return (
    <TextField
      id="outlined-basic"
      label="Pesquisar"
      variant="outlined"
      //fullWidth
      value={nome}
      onChange={(e) => {
        setNome(e.target.value);
      }}
    />
  );
}

function getLabelPedidoMinimo(fornecedor) {
  switch (fornecedor.tipo_pedido_minimo_de_compra) {
    case TIPO_PEDIDO_MINIMO_COMPRA_KG:
      return formatPeso(fornecedor.pedido_minimo_de_compra);
    case TIPO_PEDIDO_MINIMO_COMPRA_CAIXAS:
      return `${Math.round(fornecedor.pedido_minimo_de_compra)} Caixas`;
    case TIPO_PEDIDO_MINIMO_COMPRA_VALOR:
      return formatMoeda(fornecedor.pedido_minimo_de_compra);
    default:
      return '';
  }
}

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose, cor = 'green' }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [id, setId] = useState(-1);
    const [carregando, setCarregando] = useState(false);
    const [fornecedor, setFornecedor] = useState(null);
    const [itens, setItens] = useState([]);
    const [tela, setTela] = useState(TELA_1);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [search, setSearch] = useState('');
    const [suprimirPedidoZero, setSuprimirPedidoZero] = useState(false);
    const refDialogoFornecedor = useRef(null);
    const refDialogoProdutosFornecedorForaMix = useRef(null);
    const refDialogoCreateCorrecao = useRef(null);
    const refDialogoProduto = useRef(null);
    const refDialogoConfirmacao = useRef(null);
    const [representante, setRepresentante] = useState(null);
    const [pasta, setPasta] = useState(null);
    const [comprador, setComprador] = useState('');

    const refDialogoProdutosSemPasta = useRef(null);

    const refDialogoCabecalho = useRef(null);

    const [data, setData] = useState(new Date());
    const [verbaJornal, setVerbaJornal] = useState(0);
    const [verbaBacklight, setVerbaBacklight] = useState(0);

    async function getPrecosUltimoPedido(fornecedor) {
      if (fornecedor) {
        const response = await api.get(
          `/get-precos-ultimo-pedido-compra/${fornecedor.id}`,
        );

        const { status, message, data } = response.data;

        if (status) {
          const dados = JSON.parse(data);

          const mapa = {};

          for (let i = 0; i < dados.length; i++) {
            mapa[dados[i].produto_id] = dados[i];
          }

          return mapa;
        }
      }
      return {};
    }

    function adicionaCampoFornecedor(fornecedor) {
      if (fornecedor.usar_prazo_medio_pagamento_como_prazo_medio_da_compra) {
        fornecedor.prazo_compra = fornecedor.prazo_medio_pagamento;
      } else {
        fornecedor.prazo_compra = fornecedor.prazo_medio_compra;
      }
    }

    async function getFornecedor(id) {
      const data = await getListAllAPI('fornecedores', ['id', 'asc'], { id }, [
        'cidade.estado',
      ]);
      if (data.data.length <= 0) {
        throw 'Fornecedor não encontrado!';
      }
      adicionaCampoFornecedor(data.data[0]);
      setFornecedor(data.data[0]);

      recalcularPedidos(false, data.data[0]);

      refDialogoConfirmacao.current.handleOpen(
        'Recalcular Pedidos',
        `Tem certeza que deseja recalcular todos os pedidos?`,
        {
          tipo: 'recalcular',
          id,
        },
      );
    }

    function recalcularPedidoItem(id, usarStatusAntigo = true, newStatus) {
      const index = itens.findIndex((item) => item.id === id);
      if (index !== -1) {
        const item = { ...itens[index] };

        const estoque_atual = item.estoque_atual;

        const estoque_ideal = item.estoque_ideal;

        let pedido = 0;
        pedido = estoque_atual < 0 ? 0 : estoque_ideal - estoque_atual;
        pedido = pedido > 0 ? pedido : 0;

        const regra_de_arredondamento = item.regra_de_arredondamento;

        pedido = arredondar(pedido, regra_de_arredondamento);

        const status = usarStatusAntigo ? item.status : newStatus;

        if (status !== STATUS_ATIVO) pedido = 0;

        ////////////////////////////

        const precoParaCalculos = getPrecoParaCalculos({
          rs_kg: item.rs_kg,
          rs_un: item.rs_un,
          rs_cx_1: item.rs_cx_1,
          rs_cx_2: item.rs_cx_2,
          rs_cx_3: item.rs_cx_3,
          unidade_adotada_na_compra: item.unidade_adotada_na_compra,
        });

        let bonificacao_em_cxs = 0;

        if (item.ativar_bonificacao) {
          bonificacao_em_cxs = pedido / item.regra_de_arredondamento;
        } else {
          bonificacao_em_cxs = item.bonificacao_em_cxs;
        }

        const subtotal = pedido * precoParaCalculos;

        const bonificacao_subtotal = bonificacao_em_cxs * precoParaCalculos;

        let rs_un_mais_bonificacao =
          (pedido * precoParaCalculos) / (pedido + bonificacao_em_cxs);

        if (pedido <= 0) rs_un_mais_bonificacao = 0;

        const peso_subtotal = pedido * item.peso_bruto;

        const volume_subtotal = pedido * item.volume_unitario;

        const peso_subtotal_liquido = getPeso(
          item,
          pedido,
          item.unidade_adotada_na_compra,
        );

        ////////////////////////////

        const itensCopy = [...itens];
        itensCopy[index] = {
          ...item,
          pedido,
          subtotal,
          bonificacao_subtotal,
          rs_un_mais_bonificacao,
          peso_subtotal,
          volume_subtotal,
          bonificacao_em_cxs,
          peso_subtotal_liquido,
          status,
        };

        setItens(itensCopy);
      }
    }

    function recalcularPedidos(status, fornecedor) {
      const newItens = [];
      const itens2 = itens.filter((item) => item.pertencente_ao_mix);
      for (let i = 0; i < itens2.length; i++) {
        const item = { ...itens2[i] };

        const vmd_na_maior_unidade_possivel =
          item.vmd_na_maior_unidade_possivel;

        const prazo_medio_pagamento = fornecedor.prazo_compra;

        const regra_de_arredondamento = item.regra_de_arredondamento;

        const unidade_adotada_na_compra = item.unidade_adotada_na_compra;

        const estoque_ideal =
          vmd_na_maior_unidade_possivel * prazo_medio_pagamento;

        const estoque_ideal_label = estoque_ideal
          ? `${formatPreco(estoque_ideal, 0, '')}${unidade_adotada_na_compra}`
          : '-';

        const estoque_atual = item.estoque_atual;

        let pedido = 0;
        let peso_subtotal = 0;
        let peso_subtotal_liquido = 0;
        let volume_subtotal = 0;
        let subtotal = 0;
        let rs_un_mais_bonificacao = 0;
        let bonificacao_subtotal = 0;

        if (status) {
          pedido = estoque_atual < 0 ? 0 : estoque_ideal - estoque_atual;
          pedido = pedido > 0 ? pedido : 0;

          pedido = arredondar(pedido, regra_de_arredondamento);

          if (item.status !== STATUS_ATIVO) pedido = 0;

          let bonificacao_em_cxs = 0;

          if (item.ativar_bonificacao) {
            bonificacao_em_cxs = pedido / regra_de_arredondamento;
          }

          const precoParaCalculos = getPrecoParaCalculos({
            rs_kg: item.rs_kg,
            rs_un: item.rs_un,
            rs_cx_1: item.rs_cx_1,
            rs_cx_2: item.rs_cx_2,
            rs_cx_3: item.rs_cx_3,
            unidade_adotada_na_compra,
          });

          rs_un_mais_bonificacao =
            (pedido * precoParaCalculos) / (pedido + bonificacao_em_cxs);

          if (pedido <= 0) rs_un_mais_bonificacao = 0;

          const volume_unitario = item.volume_unitario;

          const peso_bruto = item.peso_bruto;

          peso_subtotal = pedido * peso_bruto;
          peso_subtotal_liquido = getPeso(
            item,
            pedido,
            unidade_adotada_na_compra,
          );

          volume_subtotal = pedido * volume_unitario;

          subtotal = pedido * precoParaCalculos;

          bonificacao_subtotal = bonificacao_em_cxs * precoParaCalculos;
        } else {
          pedido = item.pedido;
          peso_subtotal = item.peso_subtotal;
          peso_subtotal_liquido = item.peso_subtotal_liquido;
          volume_subtotal = item.volume_subtotal;
          subtotal = item.subtotal;
          rs_un_mais_bonificacao = item.rs_un_mais_bonificacao;
          bonificacao_subtotal = item.bonificacao_subtotal;
        }

        newItens.push({
          id: item.id, // OK
          descricao: item.descricao, // OK
          rs_kg: item.rs_kg, // OK
          rs_un: item.rs_un, // OK
          rs_cx_1: item.rs_cx_1, // OK
          rs_cx_2: item.rs_cx_2, // OK
          rs_cx_3: item.rs_cx_3, // OK
          pedido,
          pedido_sugestao_original: pedido,
          unidade_preco_editado: item.unidade_preco_editado, // OK
          unidade_adotada_na_compra: item.unidade_adotada_na_compra, // OK
          subtotal,
          bonificacao_em_cxs: item.bonificacao_em_cxs, // OK
          bonificacao_subtotal, // OK
          rs_un_mais_bonificacao, // OK
          peso_subtotal,
          volume_subtotal,
          estoque_atual: item.estoque_atual, // OK
          estoque_ideal, // OK
          vtu_30: item.estoque_atual, // OK
          vmd_na_maior_unidade_possivel: item.vmd_na_maior_unidade_possivel, // OK
          peso_padrao: item.peso_padrao, // OK
          volume_unitario: item.volume_unitario, // OK
          peso_bruto: item.peso_bruto, // OK
          regra_de_arredondamento: item.regra_de_arredondamento, // OK
          caixaria_nivel_1: item.caixaria_nivel_1, // OK
          caixaria_nivel_2: item.caixaria_nivel_2, // OK
          caixaria_nivel_3: item.caixaria_nivel_3, // OK
          tipoUnidade: item.tipoUnidade, // OK
          ativar_bonificacao: item.ativar_bonificacao, // OK
          estoque_atual_label: item.estoque_atual_label, // OK
          vtu_30_label: item.vtu_30_label, // OK
          vmd_na_maior_unidade_possivel_label:
            item.vmd_na_maior_unidade_possivel_label, // OK
          estoque_ideal_label, // OK
          index: item.index, // OK
          status: item.status, // OK
          peso_subtotal_liquido,
          peso_padrao_na_compra: item.peso_padrao_na_compra, // OK
          peso_medio: item.peso_medio, // OK
          unidade: item.unidade, // OK
          pertencente_ao_mix: item.pertencente_ao_mix,
        });
      }

      setItens(newItens);
    }

    function zerarPedidoInteiro() {
      const itensCopy = [...itens];

      for (let i = 0; i < itensCopy.length; i++) {
        const item = itensCopy[i];

        /////////////////////////////////

        const pedido = 0;

        ////////////////////////////

        const precoParaCalculos = getPrecoParaCalculos({
          rs_kg: item.rs_kg,
          rs_un: item.rs_un,
          rs_cx_1: item.rs_cx_1,
          rs_cx_2: item.rs_cx_2,
          rs_cx_3: item.rs_cx_3,
          unidade_adotada_na_compra: item.unidade_adotada_na_compra,
        });

        let bonificacao_em_cxs = 0;

        if (item.ativar_bonificacao) {
          bonificacao_em_cxs = pedido / item.regra_de_arredondamento;
        } else {
          bonificacao_em_cxs = item.bonificacao_em_cxs;
        }

        const subtotal = pedido * precoParaCalculos;

        const bonificacao_subtotal = bonificacao_em_cxs * precoParaCalculos;

        let rs_un_mais_bonificacao =
          (pedido * precoParaCalculos) / (pedido + bonificacao_em_cxs);

        if (pedido <= 0) rs_un_mais_bonificacao = 0;

        const peso_subtotal = pedido * item.peso_bruto;

        const volume_subtotal = pedido * item.volume_unitario;

        const peso_subtotal_liquido = getPeso(
          item,
          pedido,
          item.unidade_adotada_na_compra,
        );

        /////////////////////////////////
        itensCopy[i] = {
          ...itensCopy[i],
          pedido,
          subtotal,
          bonificacao_subtotal,
          rs_un_mais_bonificacao,
          peso_subtotal,
          volume_subtotal,
          bonificacao_em_cxs,
          peso_subtotal_liquido,
        };
      }

      setItens(itensCopy);
    }

    function zerarPrecosAnteriores() {
      const itensCopy = [...itens];

      for (let i = 0; i < itensCopy.length; i++) {
        itensCopy[i] = {
          ...itensCopy[i],
          rs_kg: 0,
          rs_un: 0,
          rs_cx_1: 0,
          rs_cx_2: 0,
          rs_cx_3: 0,
          subtotal: 0,
        };
      }

      setItens(itensCopy);
    }

    function handleDialogoConfirmacao(dados, status) {
      switch (dados.tipo) {
        case 'recalcular':
          recalcularPedidos(status, fornecedor);
          break;
        case 'ativar':
          if (status) handleAtivarItem(dados.id);
          break;
        case 'desativar':
          if (status) handleDesativarItem(dados.id);
          break;
        case 'recalcular-pedido-item':
          if (status) recalcularPedidoItem(dados.id);
          break;
        case 'remover-do-mix':
          if (status) handleRemoverDoMix([dados.id]);
          break;
        case 'adicionar-ao-mix':
          if (status) handleAdicionaAoMix([dados.id]);
          break;
        case 'recalcular-2':
          if (status) recalcularPedidos(status, fornecedor);
          break;
        case 'zerar-precos-anteriores':
          if (status) zerarPrecosAnteriores();
          break;
        case 'zerar-pedido-inteiro':
          if (status) zerarPedidoInteiro();
          break;
        default:
          break;
      }
    }

    const classes = useStyles();

    const colunasTemplate = getColunaTemplate();

    const colunas = { ...colunasTemplate };

    async function getItens(fornecedor, pasta) {
      const response = await api.get(
        `/get-produtos-fornecedor-para-pedido-compra/${fornecedor.id}/${
          pasta ? pasta.id : 0
        }`,
      );

      return response.data;
    }

    ////////////////////////////////////////////

    function getUnidadeAdotadaNaCompra(item) {
      if (item.caixaria_nivel_3) return TIPO_UNIDADE_CX3;
      if (item.caixaria_nivel_2) return TIPO_UNIDADE_CX2;
      if (item.caixaria_nivel_1) return TIPO_UNIDADE_CX1;
      if (
        item.unidade.tipo === POR_UNIDADE ||
        item.unidade.tipo === POR_PESO_FIXO
      )
        return TIPO_UNIDADE_UN;
      return TIPO_UNIDADE_KG;
    }

    function getUnidadePrecoEditado(item) {
      switch (item.unidade.tipo) {
        case POR_UNIDADE: {
          return TIPO_UNIDADE_UN;
        }
        case POR_PESO_VARIAVEL_SEM_UNIDADE: {
          return TIPO_UNIDADE_KG;
        }
        case POR_PESO_FIXO: {
          return TIPO_UNIDADE_KG;
        }
        default: {
          return TIPO_UNIDADE_UN;
        }
      }
    }

    function getEstoque(item, unidade_adotada_na_compra) {
      const qtde =
        item.unidade.tipo === POR_UNIDADE
          ? item.unidadesDisponivel
          : item.pesoDisponivel;
      switch (unidade_adotada_na_compra) {
        case TIPO_UNIDADE_KG: {
          return qtde;
        }
        case TIPO_UNIDADE_UN: {
          const fator =
            item.unidade.tipo === POR_PESO_FIXO ? item.peso_medio : 1;
          return qtde / fator;
        }
        case TIPO_UNIDADE_CX1: {
          const fator =
            item.unidade.tipo === POR_PESO_FIXO ? item.peso_medio : 1;
          return qtde / (fator * item.caixaria_nivel_1);
        }
        case TIPO_UNIDADE_CX2: {
          const fator =
            item.unidade.tipo === POR_PESO_FIXO ? item.peso_medio : 1;
          return qtde / (fator * item.caixaria_nivel_1 * item.caixaria_nivel_2);
        }
        case TIPO_UNIDADE_CX3: {
          const fator =
            item.unidade.tipo === POR_PESO_FIXO ? item.peso_medio : 1;
          return (
            qtde /
            (fator *
              item.caixaria_nivel_1 *
              item.caixaria_nivel_2 *
              item.caixaria_nivel_3)
          );
        }
        default: {
          return qtde;
        }
      }
    }

    function getVtu30(item, unidade_adotada_na_compra) {
      const qtde =
        item.unidade.tipo === POR_UNIDADE
          ? item.unidades_vendidas_ultimos_30_dias
          : item.peso_vendido_ultimos_30_dias;

      switch (unidade_adotada_na_compra) {
        case TIPO_UNIDADE_KG: {
          return qtde;
        }
        case TIPO_UNIDADE_UN: {
          const fator =
            item.unidade.tipo === POR_PESO_FIXO ? item.peso_medio : 1;
          return qtde / fator;
        }
        case TIPO_UNIDADE_CX1: {
          const fator =
            item.unidade.tipo === POR_PESO_FIXO ? item.peso_medio : 1;
          return qtde / (fator * item.caixaria_nivel_1);
        }
        case TIPO_UNIDADE_CX2: {
          const fator =
            item.unidade.tipo === POR_PESO_FIXO ? item.peso_medio : 1;
          return qtde / (fator * item.caixaria_nivel_1 * item.caixaria_nivel_2);
        }
        case TIPO_UNIDADE_CX3: {
          const fator =
            item.unidade.tipo === POR_PESO_FIXO ? item.peso_medio : 1;
          return (
            qtde /
            (fator *
              item.caixaria_nivel_1 *
              item.caixaria_nivel_2 *
              item.caixaria_nivel_3)
          );
        }
        default: {
          return Math.round(qtde);
        }
      }
    }

    function arredondar(numero, multiplo) {
      const resto = numero % multiplo;

      if (resto === 0) return numero;

      const divisao = parseInt(numero / multiplo, 10);

      if (resto < multiplo / 2) return divisao * multiplo;

      return (divisao + 1) * multiplo;
    }

    ////////////////////////////////////////////

    function getFatorCaixariaUnidadeAdotadaNaCompra(
      item,
      unidade_adotada_na_compra,
    ) {
      switch (unidade_adotada_na_compra) {
        case TIPO_UNIDADE_KG: {
          return 1;
        }
        case TIPO_UNIDADE_UN: {
          return 1;
        }
        case TIPO_UNIDADE_CX1: {
          return item.caixaria_nivel_1;
        }
        case TIPO_UNIDADE_CX2: {
          return item.caixaria_nivel_1 * item.caixaria_nivel_2;
        }
        case TIPO_UNIDADE_CX3: {
          return (
            item.caixaria_nivel_1 *
            item.caixaria_nivel_2 *
            item.caixaria_nivel_3
          );
        }
        default: {
          return 0;
        }
      }
    }

    function getPeso(item, pedido, unidade_adotada_na_compra) {
      const fator = getFatorCaixariaUnidadeAdotadaNaCompra(
        item,
        unidade_adotada_na_compra,
      );
      switch (item.unidade.tipo) {
        case POR_UNIDADE:
          return pedido * fator * item.peso_padrao_na_compra;
        case POR_PESO_FIXO:
          return pedido * fator * item.peso_medio;
        case POR_PESO_VARIAVEL_SEM_UNIDADE:
          return pedido * fator;
        default:
          return 0;
      }
    }

    ////////////////////////////////////////////

    function formatItens(itens, isPedidoZero = false, fornecedor) {
      const newItens = [];
      for (let i = 0; i < itens.length; i++) {
        const item = { ...itens[i] };

        item.caixaria_nivel_1 =
          item.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
            ? item.peso_caixa_nivel_1
            : item.unidades_caixa_nivel_1;
        item.caixaria_nivel_2 = item.unidades_caixa_nivel_2;
        item.caixaria_nivel_3 = item.unidades_caixa_nivel_3;

        const unidade_adotada_na_compra = getUnidadeAdotadaNaCompra(item);

        const unidade_preco_editado = getUnidadePrecoEditado(item);

        const estoque_atual = getEstoque(item, unidade_adotada_na_compra);

        const vtu_30 = getVtu30(item, unidade_adotada_na_compra);

        const vmd_na_maior_unidade_possivel = vtu_30 / 30;

        const prazo_medio_pagamento = fornecedor.prazo_compra;

        const regra_de_arredondamento = item.arredondamento_no_pedido_de_compra;

        /*  const estoque_ideal = arredondar(
          vmd_na_maior_unidade_possivel * prazo_medio_pagamento,
          regra_de_arredondamento,
        ); */

        const estoque_ideal =
          vmd_na_maior_unidade_possivel * prazo_medio_pagamento;

        const estoque_ideal_label = estoque_ideal
          ? `${formatPreco(estoque_ideal, 0, '')}${unidade_adotada_na_compra}`
          : '-';

        let pedido = estoque_atual < 0 ? 0 : estoque_ideal - estoque_atual;
        pedido = pedido > 0 ? pedido : 0;
        pedido = isPedidoZero ? 0 : pedido;

        pedido = arredondar(pedido, regra_de_arredondamento);

        if (item.status !== STATUS_ATIVO) pedido = 0;

        let bonificacao_em_cxs = 0;

        if (item.ativar_bonificacao) {
          bonificacao_em_cxs = pedido / regra_de_arredondamento;
        }

        const precoParaCalculos = 0;

        const bonificacao_subtotal = bonificacao_em_cxs * precoParaCalculos;

        let rs_un_mais_bonificacao =
          (pedido * precoParaCalculos) / (pedido + bonificacao_em_cxs);

        if (pedido <= 0) rs_un_mais_bonificacao = 0;

        const volume_unitario = item.volume_unitario_vu;

        const peso_bruto = item.peso_unitario_pu;

        const peso_subtotal = pedido * peso_bruto;
        const peso_subtotal_liquido = getPeso(
          item,
          pedido,
          unidade_adotada_na_compra,
        );

        const volume_subtotal = pedido * volume_unitario;

        const subtotal = pedido * precoParaCalculos;

        const estoque_atual_label = getEstoqueLabel(
          item,
          unidade_adotada_na_compra,
          item.unidadesDisponivel,
          item.pesoDisponivel,
        );

        const vtu_30_label = getEstoqueLabel(
          item,
          unidade_adotada_na_compra,
          item.unidades_vendidas_ultimos_30_dias,
          item.peso_vendido_ultimos_30_dias,
        );

        const vmd_na_maior_unidade_possivel_label = vtu_30
          ? `${formatPreco(vtu_30 / 30, 2, '')}${unidade_adotada_na_compra}`
          : '-';

        newItens.push({
          id: item.id, // OK
          descricao: item.nome, // OK
          rs_kg: 0, // OK
          rs_un: 0, // OK
          rs_cx_1: 0, // OK
          rs_cx_2: 0, // OK
          rs_cx_3: 0, // OK
          pedido, // OK
          pedido_sugestao_original: pedido,
          unidade_preco_editado, // OK
          unidade_adotada_na_compra, // OK
          subtotal, // OK
          bonificacao_em_cxs, // OK
          bonificacao_subtotal, // OK
          rs_un_mais_bonificacao, // OK
          peso_subtotal, // OK
          volume_subtotal, // OK
          estoque_atual, // OK
          estoque_ideal, // OK
          vtu_30, // OK
          vmd_na_maior_unidade_possivel, // OK
          peso_padrao: item.peso_medio, // OK
          volume_unitario: item.volume_unitario_vu, // OK
          peso_bruto: item.peso_unitario_pu, // OK
          regra_de_arredondamento, // OK
          caixaria_nivel_1: item.caixaria_nivel_1, // OK
          caixaria_nivel_2: item.caixaria_nivel_2, // OK
          caixaria_nivel_3: item.caixaria_nivel_3, // OK
          tipoUnidade: item.unidade.tipo,
          ativar_bonificacao: item.ativar_bonificacao,
          estoque_atual_label,
          vtu_30_label,
          vmd_na_maior_unidade_possivel_label,
          estoque_ideal_label,
          index: i,
          status: item.status,
          peso_subtotal_liquido,
          peso_padrao_na_compra: item.peso_padrao_na_compra,
          peso_medio: item.peso_medio,
          unidade: item.unidade,
          pertencente_ao_mix: item.pertencente_ao_mix,
        });
      }
      return newItens;
    }

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    function getPrecos(dados) {
      const valor = dados.unitario;
      const item = dados;
      switch (dados.tipo_unidade_preco) {
        case TIPO_UNIDADE_KG: {
          const rs_kg = valor;
          let fator = 1;
          if (item.produto.unidade.tipo === POR_PESO_FIXO)
            fator = item.produto.peso_medio;
          let rs_un = valor * fator;
          if (item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE)
            rs_un = 0;
          const rs_cx_1 = rs_kg * fator * item.caixaria_nivel_1;

          const rs_cx_2 =
            rs_kg * fator * item.caixaria_nivel_1 * item.caixaria_nivel_2;

          const rs_cx_3 =
            rs_kg *
            fator *
            item.caixaria_nivel_1 *
            item.caixaria_nivel_2 *
            item.caixaria_nivel_3;

          return {
            rs_kg,
            rs_un,
            rs_cx_1,
            rs_cx_2,
            rs_cx_3,
          };
        }
        case TIPO_UNIDADE_UN: {
          let fator = 1;
          if (item.produto.unidade.tipo === POR_PESO_FIXO)
            fator = item.produto.peso_medio;
          let rs_kg = valor / fator;

          const rs_un = valor;
          const rs_cx_1 = rs_kg * fator * item.caixaria_nivel_1;

          const rs_cx_2 =
            rs_kg * fator * item.caixaria_nivel_1 * item.caixaria_nivel_2;

          const rs_cx_3 =
            rs_kg *
            fator *
            item.caixaria_nivel_1 *
            item.caixaria_nivel_2 *
            item.caixaria_nivel_3;

          if (item.produto.unidade.tipo === POR_UNIDADE) rs_kg = 0;

          return {
            rs_kg,
            rs_un,
            rs_cx_1,
            rs_cx_2,
            rs_cx_3,
          };
        }
        case TIPO_UNIDADE_CX1: {
          let fator = 1;
          if (item.produto.unidade.tipo === POR_PESO_FIXO)
            fator = item.produto.peso_medio;
          let rs_kg = valor / (fator * item.caixaria_nivel_1);

          let rs_un = valor / item.caixaria_nivel_1;

          if (item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE)
            rs_un = 0;
          const rs_cx_1 = valor;

          const rs_cx_2 =
            rs_kg * fator * item.caixaria_nivel_1 * item.caixaria_nivel_2;

          const rs_cx_3 =
            rs_kg *
            fator *
            item.caixaria_nivel_1 *
            item.caixaria_nivel_2 *
            item.caixaria_nivel_3;

          if (item.produto.unidade.tipo === POR_UNIDADE) rs_kg = 0;

          return {
            rs_kg,
            rs_un,
            rs_cx_1,
            rs_cx_2,
            rs_cx_3,
          };
        }
        case TIPO_UNIDADE_CX2: {
          let fator = 1;
          if (item.produto.unidade.tipo === POR_PESO_FIXO)
            fator = item.produto.peso_medio;
          let rs_kg =
            valor / (fator * item.caixaria_nivel_1 * item.caixaria_nivel_2);

          let rs_un = valor / (item.caixaria_nivel_1 * item.caixaria_nivel_2);

          if (item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE)
            rs_un = 0;

          const rs_cx_1 = valor / item.caixaria_nivel_2;

          const rs_cx_2 = valor;

          const rs_cx_3 =
            rs_kg *
            fator *
            item.caixaria_nivel_1 *
            item.caixaria_nivel_2 *
            item.caixaria_nivel_3;

          if (item.produto.unidade.tipo === POR_UNIDADE) rs_kg = 0;

          return {
            rs_kg,
            rs_un,
            rs_cx_1,
            rs_cx_2,
            rs_cx_3,
          };
        }
        case TIPO_UNIDADE_CX3: {
          let fator = 1;
          if (item.produto.unidade.tipo === POR_PESO_FIXO)
            fator = item.produto.peso_medio;
          let rs_kg =
            valor /
            (fator *
              item.caixaria_nivel_1 *
              item.caixaria_nivel_2 *
              item.caixaria_nivel_3);

          let rs_un =
            valor /
            (item.caixaria_nivel_1 *
              item.caixaria_nivel_2 *
              item.caixaria_nivel_3);

          if (item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE)
            rs_un = 0;

          const rs_cx_1 =
            valor / (item.caixaria_nivel_2 * item.caixaria_nivel_3);

          const rs_cx_2 = valor / item.caixaria_nivel_3;

          const rs_cx_3 = valor;

          if (item.produto.unidade.tipo === POR_UNIDADE) rs_kg = 0;

          return {
            rs_kg,
            rs_un,
            rs_cx_1,
            rs_cx_2,
            rs_cx_3,
          };
        }
        default:
          return { rs_kg: 0, rs_un: 0, rs_cx_1: 0, rs_cx_2: 0, rs_cx_3: 0 };
      }
    }

    function getItensFormatadosEditarPedido(dados) {
      const itensFormatados = [];
      for (let i = 0; i < dados.itens.length; i++) {
        const item = { ...dados.itens[i], ...dados.itens[i].produto };
        adicionaCampoFornecedor(dados.fornecedor);

        const prazo_medio_pagamento = dados.fornecedor.prazo_compra;
        /////////////
        item.caixaria_nivel_1 =
          item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
            ? item.produto.peso_caixa_nivel_1
            : item.produto.unidades_caixa_nivel_1;
        item.caixaria_nivel_2 = item.produto.unidades_caixa_nivel_2;
        item.caixaria_nivel_3 = item.produto.unidades_caixa_nivel_3;

        //item.tipo_unidade_preco = 0

        const { rs_kg, rs_un, rs_cx_1, rs_cx_2, rs_cx_3 } = getPrecos(item);

        const precoParaCalculos = getPrecoParaCalculos({
          rs_kg,
          rs_un,
          rs_cx_1,
          rs_cx_2,
          rs_cx_3,
          unidade_adotada_na_compra: item.tipo_unidade_qtde,
        });

        const unidade_preco_editado = item.tipo_unidade_preco;

        const unidade_adotada_na_compra = item.tipo_unidade_qtde;

        const regra_de_arredondamento =
          item.produto.arredondamento_no_pedido_de_compra;

        const pedido = item.qtde;

        const estoque_atual = getEstoque(item, unidade_adotada_na_compra);

        const vtu_30 = getVtu30(item, unidade_adotada_na_compra);

        const vmd_na_maior_unidade_possivel = vtu_30 / 30;

        const estoque_ideal =
          vmd_na_maior_unidade_possivel * prazo_medio_pagamento;

        let bonificacao_em_cxs = 0;

        if (item.produto.ativar_bonificacao) {
          bonificacao_em_cxs = pedido / regra_de_arredondamento;
        }

        const bonificacao_subtotal = bonificacao_em_cxs * precoParaCalculos;

        const volume_unitario = item.produto.volume_unitario_vu;

        const peso_bruto = item.produto.peso_unitario_pu;

        const peso_subtotal = pedido * peso_bruto;

        const volume_subtotal = pedido * volume_unitario;

        const peso_subtotal_liquido = getPeso(
          item,
          pedido,
          unidade_adotada_na_compra,
        );

        const subtotal = pedido * precoParaCalculos;

        const rs_un_mais_bonificacao =
          (pedido * precoParaCalculos) / (pedido + bonificacao_em_cxs);

        /////////////
        const estoque_ideal_label = estoque_ideal
          ? `${formatPreco(estoque_ideal, 0, '')}${unidade_adotada_na_compra}`
          : '-';

        const estoque_atual_label = getEstoqueLabel(
          item,
          unidade_adotada_na_compra,
          item.produto.unidadesDisponivel,
          item.produto.pesoDisponivel,
        );

        const vtu_30_label = getEstoqueLabel(
          item,
          unidade_adotada_na_compra,
          item.produto.unidades_vendidas_ultimos_30_dias,
          item.produto.peso_vendido_ultimos_30_dias,
        );

        const vmd_na_maior_unidade_possivel_label = vtu_30
          ? `${formatPreco(vtu_30 / 30, 2, '')}${unidade_adotada_na_compra}`
          : '-';
        /////////////

        itensFormatados.push({
          id: item.produto.id, // OK
          descricao: item.produto.nome, // OK
          rs_kg, // OK
          rs_un, // OK
          rs_cx_1, // OK
          rs_cx_2, // OK
          rs_cx_3, // OK
          pedido, // OK
          pedido_sugestao_original: pedido, // OK
          unidade_preco_editado, // OK
          unidade_adotada_na_compra, // OK
          subtotal, // OK
          bonificacao_em_cxs, // OK
          bonificacao_subtotal, // OK
          rs_un_mais_bonificacao, // OK
          peso_subtotal, // OK
          volume_subtotal, // OK
          estoque_atual, // OK
          estoque_ideal, // OK
          vtu_30, // OK
          vmd_na_maior_unidade_possivel, // OK
          peso_padrao: item.produto.peso_medio,
          volume_unitario: item.produto.volume_unitario_vu,
          peso_bruto: item.produto.peso_unitario_pu,
          regra_de_arredondamento,
          caixaria_nivel_1: item.caixaria_nivel_1,
          caixaria_nivel_2: item.caixaria_nivel_2,
          caixaria_nivel_3: item.caixaria_nivel_3,
          tipoUnidade: item.produto.unidade.tipo,
          ativar_bonificacao: item.produto.ativar_bonificacao,
          estoque_atual_label,
          vtu_30_label,
          vmd_na_maior_unidade_possivel_label,
          estoque_ideal_label,
          index: i,
          status: item.produto.status,
          peso_subtotal_liquido,
          peso_padrao_na_compra: item.peso_padrao_na_compra,
          peso_medio: item.peso_medio,
          unidade: item.unidade,
          pertencente_ao_mix: item.pertencente_ao_mix,
        });
      }

      return itensFormatados;
    }

    function getMapa(dados) {
      const dadosFormatados = {};
      for (let i = 0; i < dados.length; i++) {
        dadosFormatados[dados[i].id] = dados[i];
      }
      return dadosFormatados;
    }

    async function getDataResource(id) {
      try {
        const data = await getListAllAPI(
          'pedidos-compras',
          ['id', 'asc'],
          { id: [id] },
          [
            'fornecedor.cidade.estado',
            'itens.produto.unidade',
            'pasta',
            'comprador',
          ],
        );
        if (data.data.length > 0) {
          setTela(TELA_2);
          adicionaCampoFornecedor(data.data[0].fornecedor);
          const itens = await getItens(
            data.data[0].fornecedor,
            data.data[0].pasta,
          );
          const itensFormatos = formatItens(
            itens,
            true,
            data.data[0].fornecedor,
          );
          const mapa = getMapa(itensFormatos);
          const itensPedido = getItensFormatadosEditarPedido(data.data[0]);
          for (let i = 0; i < itensPedido.length; i++) {
            mapa[itensPedido[i].id] = itensPedido[i];
          }

          const itensFinais = Object.values(mapa);

          for (let i = 0; i < itensFinais.length; i++) {
            itensFinais[i].index = i;
          }

          const mapaPrecos = await getPrecosUltimoPedido(
            data.data[0].fornecedor,
          );

          for (let i = 0; i < itensFinais.length; i++) {
            if (mapaPrecos[itensFinais[i].id]) {
              if (itensFinais[i].rs_kg === 0 && itensFinais[i].rs_un === 0) {
                const { rs_kg, rs_un, rs_cx_1, rs_cx_2, rs_cx_3 } = getPrecos({
                  unitario: Number(mapaPrecos[itensFinais[i].id].unitario),
                  tipo_unidade_preco:
                    mapaPrecos[itensFinais[i].id].tipo_unidade,
                  caixaria_nivel_1: itensFinais[i].caixaria_nivel_1,
                  caixaria_nivel_2: itensFinais[i].caixaria_nivel_2,
                  caixaria_nivel_3: itensFinais[i].caixaria_nivel_3,
                  produto: {
                    peso_medio: itensFinais[i].peso_medio,
                    unidade: {
                      tipo: itensFinais[i].tipoUnidade,
                    },
                  },
                });

                itensFinais[i] = {
                  ...itensFinais[i],
                  rs_kg,
                  rs_un,
                  rs_cx_1,
                  rs_cx_2,
                  rs_cx_3,
                  unidade_preco_editado:
                    mapaPrecos[itensFinais[i].id].tipo_unidade,
                };
              }
            }
          }

          setItens(itensFinais);

          setFornecedor(data.data[0].fornecedor);

          setPasta(data.data[0].pasta);

          setOrder('desc');
          setOrderBy('pedido');
          setSuprimirPedidoZero(true);
          setData(new Date(data.data[0].data));

          setVerbaJornal(data.data[0].verba_jornal);
          setVerbaBacklight(data.data[0].verba_backlight);

          setComprador(
            data.data[0].comprador ? data.data[0].comprador.username : null,
          );
        } else {
          throw 'Este Pedido de Compra não existe!';
        }
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog(-1);
      }
    }

    function getItensFormatados() {
      const itensFormatados = [];

      const itensNew = itens.filter((item) => Math.abs(item.subtotal) >= 0.1);

      for (let i = 0; i < itensNew.length; i++) {
        const item = itensNew[i];
        const precoParaCalculos = getPrecoParaCalculos({
          rs_kg: item.rs_kg,
          rs_un: item.rs_un,
          rs_cx_1: item.rs_cx_1,
          rs_cx_2: item.rs_cx_2,
          rs_cx_3: item.rs_cx_3,
          unidade_adotada_na_compra: item.unidade_preco_editado,
        });
        itensFormatados.push({
          produto_id: item.id,
          qtde: item.pedido,
          unitario: precoParaCalculos,
          tipo_unidade_qtde: item.unidade_adotada_na_compra,
          tipo_unidade_preco: item.unidade_preco_editado,
          total: item.subtotal,
        });
      }

      return itensFormatados;
    }

    function getTextoPreco(itens) {
      const precos = [];

      for (let i = 0; i < itens.length; i++) {
        const item = itens[i];
        const precoParaCalculos = getPrecoParaCalculos({
          rs_kg: item.rs_kg,
          rs_un: item.rs_un,
          rs_cx_1: item.rs_cx_1,
          rs_cx_2: item.rs_cx_2,
          rs_cx_3: item.rs_cx_3,
          unidade_adotada_na_compra: item.unidade_preco_editado,
        });
        if (Math.abs(precoParaCalculos) >= 0.1) {
          precos.push({
            produto_id: item.id,
            unitario: Number.parseFloat(round10(precoParaCalculos, -2)).toFixed(
              2,
            ),
            tipo_unidade: item.unidade_preco_editado,
          });
        }
      }

      return JSON.stringify(precos);
    }

    async function handleSalvar() {
      try {
        setCarregando(true);
        if (id <= 0) {
          setCarregando(false);
          await createAPI('pedidos-compras', {
            fornecedor_id: fornecedor.id,
            total: totais.subtotalOriginal,
            itens: getItensFormatados(),
            total_bonificacao: totais.bonificacao_subtotalOriginal,
            volume_total: totais.volume_subtotalOriginal,
            total_qtde: totais.pedido,
            total_bonificado: totais.bonificacao_em_cxsOriginal,
            peso_total: totais.peso_subtotalOriginal,
            peso_total_liquido: totais.peso_subtotal_liquidoOriginal,
            texto_precos: getTextoPreco(itens),
            pasta_fornecedor_id: pasta ? pasta.id : null,
            verba_jornal: verbaJornal,
            verba_backlight: verbaBacklight,
            user_id: getUserId(),
          });
        } else {
          setCarregando(false);
          await updateAPI('pedidos-compras', id, {
            fornecedor_id: fornecedor.id,
            total: totais.subtotalOriginal,
            itens: getItensFormatados(),
            total_bonificacao: totais.bonificacao_subtotalOriginal,
            volume_total: totais.volume_subtotalOriginal,
            total_qtde: totais.pedido,
            total_bonificado: totais.bonificacao_em_cxsOriginal,
            peso_total: totais.peso_subtotalOriginal,
            peso_total_liquido: totais.peso_subtotal_liquidoOriginal,
            texto_precos: getTextoPreco(itens),
            verba_jornal: verbaJornal,
            verba_backlight: verbaBacklight,
            user_id: getUserId(),
          });
        }
        handleCloseDialog();
      } catch (erros) {
        enqueueSnackbar('Existem registros relacionados a este software!', {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    async function getDadosUsuario(id) {
      const response = await api.get(`/get-username/${id}`);
      const { data } = response.data;
      setComprador(data ? data : '');
    }

    useImperativeHandle(ref, () => ({
      handleCreate() {
        setOpen(true);
        setItens([]);
        setFornecedor(null);
        setTela(TELA_1);
        setSearch('');
        setSuprimirPedidoZero(false);
        setId(-1);
        setPasta(null);
        setData(new Date());
        setVerbaJornal(0);
        setVerbaBacklight(0);
        getDadosUsuario(getUserId());
      },
      handleUpdate(id) {
        setId(id);
        getDataResource(id);
        setOpen(true);
      },
    }));

    const dadosDialogo = {};

    if (tela === TELA_1) {
      dadosDialogo.fullWidth = true;
      dadosDialogo.maxWidth = 'md';
    } else {
      dadosDialogo.fullScreen = true;
    }

    async function bucaItens() {
      const itens = await getItens(fornecedor, pasta);
      const mapaPrecos = await getPrecosUltimoPedido(fornecedor);
      const itensFormatados = formatItens(itens, false, fornecedor);
      for (let i = 0; i < itensFormatados.length; i++) {
        if (mapaPrecos[itensFormatados[i].id]) {
          const { rs_kg, rs_un, rs_cx_1, rs_cx_2, rs_cx_3 } = getPrecos({
            unitario: Number(mapaPrecos[itensFormatados[i].id].unitario),
            tipo_unidade_preco: mapaPrecos[itensFormatados[i].id].tipo_unidade,
            caixaria_nivel_1: itensFormatados[i].caixaria_nivel_1,
            caixaria_nivel_2: itensFormatados[i].caixaria_nivel_2,
            caixaria_nivel_3: itensFormatados[i].caixaria_nivel_3,
            produto: {
              peso_medio: itensFormatados[i].peso_medio,
              unidade: {
                tipo: itensFormatados[i].tipoUnidade,
              },
            },
          });

          const precoParaCalculos = getPrecoParaCalculos({
            rs_kg,
            rs_un,
            rs_cx_1,
            rs_cx_2,
            rs_cx_3,
            unidade_adotada_na_compra:
              itensFormatados[i].unidade_adotada_na_compra,
          });

          const subtotal = itensFormatados[i].pedido * precoParaCalculos;

          const bonificacao_subtotal =
            itensFormatados[i].bonificacao_em_cxs * precoParaCalculos;

          let rs_un_mais_bonificacao =
            (itensFormatados[i].pedido * precoParaCalculos) /
            (itensFormatados[i].pedido + itensFormatados[i].bonificacao_em_cxs);

          if (itensFormatados[i].pedido <= 0) rs_un_mais_bonificacao = 0;

          itensFormatados[i] = {
            ...itensFormatados[i],
            rs_kg,
            rs_un,
            rs_cx_1,
            rs_cx_2,
            rs_cx_3,
            unidade_preco_editado:
              mapaPrecos[itensFormatados[i].id].tipo_unidade,
            subtotal,
            bonificacao_subtotal,
            rs_un_mais_bonificacao,
          };
        }
      }
      setItens(itensFormatados);
    }

    useEffect(() => {
      if (tela === TELA_2) {
        if (id === -1) {
          bucaItens();
        }
      }
    }, [tela]);

    function getPrecoParaCalculos({
      rs_kg,
      rs_un,
      rs_cx_1,
      rs_cx_2,
      rs_cx_3,
      unidade_adotada_na_compra,
    }) {
      switch (unidade_adotada_na_compra) {
        case TIPO_UNIDADE_KG:
          return rs_kg;
        case TIPO_UNIDADE_UN:
          return rs_un;
        case TIPO_UNIDADE_CX1:
          return rs_cx_1;
        case TIPO_UNIDADE_CX2:
          return rs_cx_2;
        case TIPO_UNIDADE_CX3:
          return rs_cx_3;
        default:
          return rs_kg;
      }
    }

    function formatarValores({
      rs_kg,
      rs_un,
      rs_cx_1,
      rs_cx_2,
      rs_cx_3,
      tipoUnidade,
    }) {
      switch (tipoUnidade) {
        case POR_UNIDADE:
          return {
            rs_kg: 0,
            rs_un: Number.isNaN(rs_un) ? 0 : rs_un,
            rs_cx_1: Number.isNaN(rs_cx_1) ? 0 : rs_cx_1,
            rs_cx_2: Number.isNaN(rs_cx_2) ? 0 : rs_cx_2,
            rs_cx_3: Number.isNaN(rs_cx_3) ? 0 : rs_cx_3,
          };
        case POR_PESO_FIXO:
          return {
            rs_kg: Number.isNaN(rs_kg) ? 0 : rs_kg,
            rs_un: Number.isNaN(rs_un) ? 0 : rs_un,
            rs_cx_1: Number.isNaN(rs_cx_1) ? 0 : rs_cx_1,
            rs_cx_2: Number.isNaN(rs_cx_2) ? 0 : rs_cx_2,
            rs_cx_3: Number.isNaN(rs_cx_3) ? 0 : rs_cx_3,
          };
        case POR_PESO_VARIAVEL_SEM_UNIDADE:
          return {
            rs_kg: Number.isNaN(rs_kg) ? 0 : rs_kg,
            rs_un: 0,
            rs_cx_1: Number.isNaN(rs_cx_1) ? 0 : rs_cx_1,
            rs_cx_2: Number.isNaN(rs_cx_2) ? 0 : rs_cx_2,
            rs_cx_3: Number.isNaN(rs_cx_3) ? 0 : rs_cx_3,
          };
        default:
          return {
            rs_kg: 0,
            rs_un: 0,
            rs_cx_1: 0,
            rs_cx_2: 0,
            rs_cx_3: 0,
          };
      }
    }

    function updateEstado(campo, id, valor) {
      const itensCopy = [...itens];
      itensCopy[id][campo] = valor;
      //setItens(itensCopy);

      const item = itens[id];

      switch (campo) {
        case 'rs_kg': {
          const rs_kg = valor;
          let fator = 1;
          if (item.tipoUnidade === POR_PESO_FIXO) fator = item.peso_padrao;
          const rs_un = valor * fator;
          const rs_cx_1 = rs_kg * fator * item.caixaria_nivel_1;

          const rs_cx_2 =
            rs_kg * fator * item.caixaria_nivel_1 * item.caixaria_nivel_2;

          const rs_cx_3 =
            rs_kg *
            fator *
            item.caixaria_nivel_1 *
            item.caixaria_nivel_2 *
            item.caixaria_nivel_3;

          /////////////////////////

          const precoParaCalculos = getPrecoParaCalculos({
            rs_kg,
            rs_un,
            rs_cx_1,
            rs_cx_2,
            rs_cx_3,
            unidade_adotada_na_compra: item.unidade_adotada_na_compra,
          });

          const subtotal = item.pedido * precoParaCalculos;

          const bonificacao_subtotal =
            item.bonificacao_em_cxs * precoParaCalculos;

          let rs_un_mais_bonificacao =
            (item.pedido * precoParaCalculos) /
            (item.pedido + item.bonificacao_em_cxs);

          if (item.pedido <= 0) rs_un_mais_bonificacao = 0;

          const dadosNew = formatarValores({
            rs_kg,
            rs_un,
            rs_cx_1,
            rs_cx_2,
            rs_cx_3,
            tipoUnidade: item.tipoUnidade,
          });

          const dados = {
            ...item,
            rs_kg: dadosNew.rs_kg,
            rs_un: dadosNew.rs_un,
            rs_cx_1: dadosNew.rs_cx_1,
            rs_cx_2: dadosNew.rs_cx_2,
            rs_cx_3: dadosNew.rs_cx_3,
            subtotal,
            bonificacao_subtotal,
            rs_un_mais_bonificacao,
            unidade_preco_editado: TIPO_UNIDADE_KG,
          };

          itensCopy[id] = dados;

          setItens(itensCopy);
          break;
        }
        case 'rs_un': {
          let fator = 1;
          if (item.tipoUnidade === POR_PESO_FIXO) fator = item.peso_padrao;
          const rs_kg = valor / fator;

          const rs_un = valor;
          const rs_cx_1 = rs_kg * fator * item.caixaria_nivel_1;

          const rs_cx_2 =
            rs_kg * fator * item.caixaria_nivel_1 * item.caixaria_nivel_2;

          const rs_cx_3 =
            rs_kg *
            fator *
            item.caixaria_nivel_1 *
            item.caixaria_nivel_2 *
            item.caixaria_nivel_3;

          ///////////////////////////

          const precoParaCalculos = getPrecoParaCalculos({
            rs_kg,
            rs_un,
            rs_cx_1,
            rs_cx_2,
            rs_cx_3,
            unidade_adotada_na_compra: item.unidade_adotada_na_compra,
          });

          const subtotal = item.pedido * precoParaCalculos;

          const bonificacao_subtotal =
            item.bonificacao_em_cxs * precoParaCalculos;

          let rs_un_mais_bonificacao =
            (item.pedido * precoParaCalculos) /
            (item.pedido + item.bonificacao_em_cxs);

          if (item.pedido <= 0) rs_un_mais_bonificacao = 0;

          const dadosNew = formatarValores({
            rs_kg,
            rs_un,
            rs_cx_1,
            rs_cx_2,
            rs_cx_3,
            tipoUnidade: item.tipoUnidade,
          });

          const dados = {
            ...item,
            rs_kg: dadosNew.rs_kg,
            rs_un: dadosNew.rs_un,
            rs_cx_1: dadosNew.rs_cx_1,
            rs_cx_2: dadosNew.rs_cx_2,
            rs_cx_3: dadosNew.rs_cx_3,
            subtotal,
            bonificacao_subtotal,
            rs_un_mais_bonificacao,
            unidade_preco_editado: TIPO_UNIDADE_UN,
          };

          itensCopy[id] = dados;

          setItens(itensCopy);
          break;
        }
        case 'rs_cx_1': {
          let fator = 1;
          if (item.tipoUnidade === POR_PESO_FIXO) fator = item.peso_padrao;
          const rs_kg = valor / (fator * item.caixaria_nivel_1);

          const rs_un = valor / item.caixaria_nivel_1;
          const rs_cx_1 = valor;

          const rs_cx_2 =
            rs_kg * fator * item.caixaria_nivel_1 * item.caixaria_nivel_2;

          const rs_cx_3 =
            rs_kg *
            fator *
            item.caixaria_nivel_1 *
            item.caixaria_nivel_2 *
            item.caixaria_nivel_3;

          ///////////////////////////

          const precoParaCalculos = getPrecoParaCalculos({
            rs_kg,
            rs_un,
            rs_cx_1,
            rs_cx_2,
            rs_cx_3,
            unidade_adotada_na_compra: item.unidade_adotada_na_compra,
          });

          const subtotal = item.pedido * precoParaCalculos;

          const bonificacao_subtotal =
            item.bonificacao_em_cxs * precoParaCalculos;

          let rs_un_mais_bonificacao =
            (item.pedido * precoParaCalculos) /
            (item.pedido + item.bonificacao_em_cxs);

          if (item.pedido <= 0) rs_un_mais_bonificacao = 0;

          const dadosNew = formatarValores({
            rs_kg,
            rs_un,
            rs_cx_1,
            rs_cx_2,
            rs_cx_3,
            tipoUnidade: item.tipoUnidade,
          });

          const dados = {
            ...item,
            rs_kg: dadosNew.rs_kg,
            rs_un: dadosNew.rs_un,
            rs_cx_1: dadosNew.rs_cx_1,
            rs_cx_2: dadosNew.rs_cx_2,
            rs_cx_3: dadosNew.rs_cx_3,
            subtotal,
            bonificacao_subtotal,
            rs_un_mais_bonificacao,
            unidade_preco_editado: TIPO_UNIDADE_CX1,
          };

          itensCopy[id] = dados;

          setItens(itensCopy);
          break;
        }
        case 'rs_cx_2': {
          let fator = 1;
          if (item.tipoUnidade === POR_PESO_FIXO) fator = item.peso_padrao;
          const rs_kg =
            valor / (fator * item.caixaria_nivel_1 * item.caixaria_nivel_2);

          const rs_un = valor / (item.caixaria_nivel_1 * item.caixaria_nivel_2);
          const rs_cx_1 = valor / item.caixaria_nivel_2;

          const rs_cx_2 = valor;

          const rs_cx_3 =
            rs_kg *
            fator *
            item.caixaria_nivel_1 *
            item.caixaria_nivel_2 *
            item.caixaria_nivel_3;

          ///////////////////////////

          const precoParaCalculos = getPrecoParaCalculos({
            rs_kg,
            rs_un,
            rs_cx_1,
            rs_cx_2,
            rs_cx_3,
            unidade_adotada_na_compra: item.unidade_adotada_na_compra,
          });

          const subtotal = item.pedido * precoParaCalculos;

          const bonificacao_subtotal =
            item.bonificacao_em_cxs * precoParaCalculos;

          let rs_un_mais_bonificacao =
            (item.pedido * precoParaCalculos) /
            (item.pedido + item.bonificacao_em_cxs);

          if (item.pedido <= 0) rs_un_mais_bonificacao = 0;

          const dadosNew = formatarValores({
            rs_kg,
            rs_un,
            rs_cx_1,
            rs_cx_2,
            rs_cx_3,
            tipoUnidade: item.tipoUnidade,
          });

          const dados = {
            ...item,
            rs_kg: dadosNew.rs_kg,
            rs_un: dadosNew.rs_un,
            rs_cx_1: dadosNew.rs_cx_1,
            rs_cx_2: dadosNew.rs_cx_2,
            rs_cx_3: dadosNew.rs_cx_3,
            subtotal,
            bonificacao_subtotal,
            rs_un_mais_bonificacao,
            unidade_preco_editado: TIPO_UNIDADE_CX2,
          };

          itensCopy[id] = dados;

          setItens(itensCopy);
          break;
        }
        case 'rs_cx_3': {
          let fator = 1;
          if (item.tipoUnidade === POR_PESO_FIXO) fator = item.peso_padrao;
          const rs_kg =
            valor /
            (fator *
              item.caixaria_nivel_1 *
              item.caixaria_nivel_2 *
              item.caixaria_nivel_3);

          const rs_un =
            valor /
            (item.caixaria_nivel_1 *
              item.caixaria_nivel_2 *
              item.caixaria_nivel_3);
          const rs_cx_1 =
            valor / (item.caixaria_nivel_2 * item.caixaria_nivel_3);

          const rs_cx_2 = valor / item.caixaria_nivel_3;

          const rs_cx_3 = valor;

          ///////////////////////////

          const precoParaCalculos = getPrecoParaCalculos({
            rs_kg,
            rs_un,
            rs_cx_1,
            rs_cx_2,
            rs_cx_3,
            unidade_adotada_na_compra: item.unidade_adotada_na_compra,
          });

          const subtotal = item.pedido * precoParaCalculos;

          const bonificacao_subtotal =
            item.bonificacao_em_cxs * precoParaCalculos;

          let rs_un_mais_bonificacao =
            (item.pedido * precoParaCalculos) /
            (item.pedido + item.bonificacao_em_cxs);

          if (item.pedido <= 0) rs_un_mais_bonificacao = 0;

          const dadosNew = formatarValores({
            rs_kg,
            rs_un,
            rs_cx_1,
            rs_cx_2,
            rs_cx_3,
            tipoUnidade: item.tipoUnidade,
          });

          const dados = {
            ...item,
            rs_kg: dadosNew.rs_kg,
            rs_un: dadosNew.rs_un,
            rs_cx_1: dadosNew.rs_cx_1,
            rs_cx_2: dadosNew.rs_cx_2,
            rs_cx_3: dadosNew.rs_cx_3,
            subtotal,
            bonificacao_subtotal,
            rs_un_mais_bonificacao,
            unidade_preco_editado: TIPO_UNIDADE_CX3,
          };

          itensCopy[id] = dados;

          setItens(itensCopy);
          break;
        }
        case 'pedido': {
          const pedido = valor;

          const precoParaCalculos = getPrecoParaCalculos({
            rs_kg: item.rs_kg,
            rs_un: item.rs_un,
            rs_cx_1: item.rs_cx_1,
            rs_cx_2: item.rs_cx_2,
            rs_cx_3: item.rs_cx_3,
            unidade_adotada_na_compra: item.unidade_adotada_na_compra,
          });

          let bonificacao_em_cxs = 0;

          if (item.ativar_bonificacao) {
            bonificacao_em_cxs = pedido / item.regra_de_arredondamento;
          } else {
            bonificacao_em_cxs = item.bonificacao_em_cxs;
          }

          const subtotal = pedido * precoParaCalculos;

          const bonificacao_subtotal = bonificacao_em_cxs * precoParaCalculos;

          let rs_un_mais_bonificacao =
            (pedido * precoParaCalculos) / (pedido + bonificacao_em_cxs);

          if (pedido <= 0) rs_un_mais_bonificacao = 0;

          const peso_subtotal = pedido * item.peso_bruto;

          const volume_subtotal = pedido * item.volume_unitario;

          const peso_subtotal_liquido = getPeso(
            item,
            pedido,
            item.unidade_adotada_na_compra,
          );

          const dados = {
            ...item,
            subtotal,
            bonificacao_subtotal,
            rs_un_mais_bonificacao,
            peso_subtotal,
            volume_subtotal,
            bonificacao_em_cxs,
            peso_subtotal_liquido,
          };

          itensCopy[id] = dados;

          setItens(itensCopy);
          break;
        }
        case 'bonificacao_em_cxs': {
          const precoParaCalculos = getPrecoParaCalculos({
            rs_kg: item.rs_kg,
            rs_un: item.rs_un,
            rs_cx_1: item.rs_cx_1,
            rs_cx_2: item.rs_cx_2,
            rs_cx_3: item.rs_cx_3,
            unidade_adotada_na_compra: item.unidade_adotada_na_compra,
          });

          let bonificacao_em_cxs = 0;

          if (item.ativar_bonificacao) {
            bonificacao_em_cxs = item.pedido / item.regra_de_arredondamento;
          } else {
            bonificacao_em_cxs = item.bonificacao_em_cxs;
          }

          const bonificacao_subtotal = bonificacao_em_cxs * precoParaCalculos;

          let rs_un_mais_bonificacao =
            (item.pedido * precoParaCalculos) /
            (item.pedido + bonificacao_em_cxs);

          if (item.pedido <= 0) rs_un_mais_bonificacao = 0;

          const dados = {
            ...item,
            bonificacao_em_cxs,
            bonificacao_subtotal,
            rs_un_mais_bonificacao,
          };

          itensCopy[id] = dados;

          setItens(itensCopy);
          break;
        }
        default:
          break;
      }
    }

    function formatarParaApresentacao(itens) {
      const itensFormatados = [];
      for (let i = 0; i < itens.length; i++) {
        const item = itens[i];
        const is_estoque_negativo =
          item.status !== STATUS_ATIVO
            ? true
            : item.estoque_atual < 0
            ? true
            : false;

        itensFormatados.push({
          id: item.id, // OK
          descricao: item.descricao, // OK
          rs_kg:
            item.tipoUnidade === POR_UNIDADE ? '-' : formatMoeda(item.rs_kg),
          rs_un:
            item.tipoUnidade === POR_PESO_VARIAVEL_SEM_UNIDADE
              ? '-'
              : formatMoeda(item.rs_un),
          rs_cx_1: item.caixaria_nivel_1 <= 0 ? '-' : formatMoeda(item.rs_cx_1),
          rs_cx_2: item.caixaria_nivel_2 <= 0 ? '-' : formatMoeda(item.rs_cx_2),
          rs_cx_3: item.caixaria_nivel_3 <= 0 ? '-' : formatMoeda(item.rs_cx_3),
          pedido: item.pedido, // OK
          unidade_adotada_na_compra: item.unidade_adotada_na_compra, // OK
          subtotal: item.subtotal,
          bonificacao_em_cxs: item.bonificacao_em_cxs
            ? item.bonificacao_em_cxs
            : '-',
          bonificacao_subtotal: item.bonificacao_subtotal
            ? formatMoeda(item.bonificacao_subtotal)
            : '-',
          rs_un_mais_bonificacao: item.bonificacao_subtotal
            ? formatMoeda(item.rs_un_mais_bonificacao)
            : '-',
          peso_subtotal: item.peso_subtotal
            ? formatPeso(item.peso_subtotal)
            : '-',
          volume_subtotal: item.volume_subtotal
            ? `${formatPreco(item.volume_subtotal, 2, '')}m³`
            : '-',
          //estoque_atual: item.estoque_atual,
          estoque_atual: item.estoque_atual_label,
          //estoque_ideal: item.estoque_ideal,
          estoque_ideal: item.estoque_ideal_label,
          //vtu_30: item.vtu_30,
          vtu_30: item.vtu_30_label,
          //vmd_na_maior_unidade_possivel: item.vmd_na_maior_unidade_possivel,
          vmd_na_maior_unidade_possivel:
            item.vmd_na_maior_unidade_possivel_label,
          peso_padrao: formatPreco(item.peso_padrao, 3, ''),
          volume_unitario: formatPreco(item.volume_unitario, 2, ''),
          peso_bruto: formatPreco(item.peso_bruto, 3, ''),
          regra_de_arredondamento: item.regra_de_arredondamento,
          caixaria_nivel_1: item.caixaria_nivel_1,
          caixaria_nivel_2: item.caixaria_nivel_2,
          caixaria_nivel_3: item.caixaria_nivel_3,
          tipoUnidade: item.tipoUnidade,
          //
          show_rs_kg: is_estoque_negativo
            ? false
            : item.tipoUnidade === POR_UNIDADE
            ? false
            : true,
          show_rs_un: is_estoque_negativo
            ? false
            : item.tipoUnidade === POR_PESO_VARIAVEL_SEM_UNIDADE
            ? false
            : true,
          show_rs_cx_1: is_estoque_negativo
            ? false
            : item.caixaria_nivel_1 <= 0
            ? false
            : true,
          show_rs_cx_2: is_estoque_negativo
            ? false
            : item.caixaria_nivel_2 <= 0
            ? false
            : true,
          show_rs_cx_3: is_estoque_negativo
            ? false
            : item.caixaria_nivel_3 <= 0
            ? false
            : true,
          show_pedido: is_estoque_negativo ? false : true,
          is_estoque_negativo,
          show_bonificacao: item.ativar_bonificacao ? false : false,
          styles: {
            //backgroundColor: is_estoque_negativo ? 'red' : 'white',
            opacity: is_estoque_negativo ? 0.5 : 1,
            //color: 'gray',
            //filter: 'grayscale(100%)',
            //color: is_estoque_negativo ? 'green' : 'black',b
            backgroundColor: !item.pertencente_ao_mix ? '#e7191960' : 'white',
          },
          //
          show_rs_kg_original: item.tipoUnidade === POR_UNIDADE ? false : true,
          show_rs_un_original:
            item.tipoUnidade === POR_PESO_VARIAVEL_SEM_UNIDADE ? false : true,
          show_rs_cx_1_original: item.caixaria_nivel_1 <= 0 ? false : true,
          show_rs_cx_2_original: item.caixaria_nivel_2 <= 0 ? false : true,
          show_rs_cx_3_original: item.caixaria_nivel_3 <= 0 ? false : true,
          index: item.index,
          unidade_preco_editado: item.unidade_preco_editado,
          status: item.status === 'inativo' ? 'Inativo' : 'Ativo',
          peso_subtotal_liquido: item.peso_subtotal_liquido
            ? formatPeso(item.peso_subtotal_liquido)
            : '-',
          pertencente_ao_mix: item.pertencente_ao_mix,
        });
      }
      return itensFormatados;
    }

    function getShows(itensFormatados) {
      const shows = {
        rs_kg: false,
        rs_un: false,
        rs_cx_1: false,
        rs_cx_2: false,
        rs_cx_3: false,
        bonificacao_em_cxs: false,
        bonificacao_subtotal: false,
        rs_un_mais_bonificacao: false,
        peso_subtotal: false,
        volume_subtotal: false,
      };

      for (let i = 0; i < itensFormatados.length; i++) {
        const item = itensFormatados[i];
        if (item.show_rs_kg_original) shows.rs_kg = true;
        if (item.show_rs_un_original) shows.rs_un = true;
        if (item.show_rs_cx_1_original) shows.rs_cx_1 = true;
        if (item.show_rs_cx_2_original) shows.rs_cx_2 = true;
        if (item.show_rs_cx_3_original) shows.rs_cx_3 = true;
        if (item.bonificacao_em_cxs !== '-') {
          shows.bonificacao_em_cxs = true;
          shows.bonificacao_subtotal = true;
          shows.rs_un_mais_bonificacao = true;
        }

        if (item.peso_subtotal !== '-') shows.peso_subtotal = true;
        if (item.volume_subtotal !== '-') shows.volume_subtotal = true;
      }

      return shows;
    }

    const itensFormatados = formatarParaApresentacao(itens);

    const showsColunas = getShows(itensFormatados);

    const valuesChows = Object.keys(showsColunas);

    for (let i = 0; i < valuesChows.length; i++) {
      const key = valuesChows[i];

      colunas[key].show = showsColunas[key]
        ? colunas[key].show
        : showsColunas[key];
    }

    const handleSort = (property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    let sortedData = [...itensFormatados].sort((a, b) => {
      if (order === 'asc') {
        return a[orderBy] > b[orderBy] ? 1 : -1;
      }
      return a[orderBy] < b[orderBy] ? 1 : -1;
    });

    function getTotais() {
      const totais = {
        pedido: 0,
        subtotal: 0,
        bonificacao_em_cxs: 0,
        bonificacao_subtotal: 0,
        peso_subtotal: 0,
        volume_subtotal: 0,
        rs_un_mais_bonificacao: 0,
        peso_subtotal_liquido: 0,
        //
        subtotalOriginal: 0,
        bonificacao_em_cxsOriginal: 0,
        bonificacao_subtotalOriginal: 0,
        peso_subtotalOriginal: 0,
        volume_subtotalOriginal: 0,
        rs_un_mais_bonificacaoOriginal: 0,
        peso_subtotal_liquidoOriginal: 0,
      };

      const itens2 = itens.filter((item) => item.subtotal > 0);

      for (let i = 0; i < itens2.length; i++) {
        totais.pedido += itens2[i].pedido;
        totais.subtotal += itens2[i].subtotal;
        totais.bonificacao_em_cxs += itens2[i].bonificacao_em_cxs;
        totais.bonificacao_subtotal += itens2[i].bonificacao_subtotal;
        totais.peso_subtotal += itens2[i].peso_subtotal;
        totais.volume_subtotal += itens2[i].volume_subtotal;
        totais.peso_subtotal_liquido += itens2[i].peso_subtotal_liquido;
      }

      ///////////////////

      totais.subtotalOriginal = totais.subtotal;
      totais.bonificacao_subtotalOriginal = totais.bonificacao_subtotal;
      totais.peso_subtotalOriginal = totais.peso_subtotal;
      totais.volume_subtotalOriginal = totais.volume_subtotal;
      totais.bonificacao_em_cxsOriginal = totais.bonificacao_em_cxs;
      totais.peso_subtotal_liquidoOriginal = totais.peso_subtotal_liquido;

      ///////////////////

      totais.rs_un_mais_bonificacao =
        totais.subtotal - totais.bonificacao_subtotal;

      totais.subtotal = formatMoeda(totais.subtotal);
      totais.bonificacao_subtotal = formatMoeda(totais.bonificacao_subtotal);
      totais.peso_subtotal = formatPeso(totais.peso_subtotal);
      totais.volume_subtotal = `${formatPreco(
        totais.volume_subtotal,
        2,
        '',
      )}m³`;
      totais.rs_un_mais_bonificacao = formatMoeda(
        totais.rs_un_mais_bonificacao,
      );
      totais.peso_subtotal_liquido = formatPeso(totais.peso_subtotal_liquido);

      return totais;
    }

    const totais = getTotais();

    if (search.trim()) {
      sortedData = sortedData.filter((item) =>
        item.descricao
          .trim()
          .toLowerCase()
          .includes(search.toLowerCase().trim()),
      );
    }

    if (suprimirPedidoZero) {
      sortedData = sortedData.filter((item) => item.pedido > 0);
    }

    function editarFornecedor() {
      if (refDialogoFornecedor.current) {
        const idFornecedor = fornecedor ? fornecedor.id : 0;
        if (idFornecedor > 0) {
          refDialogoFornecedor.current.handleEdit(idFornecedor, 0);
        }
      }
    }

    function openDialogoProdutosFornecedorForaMix() {
      if (refDialogoProdutosFornecedorForaMix.current) {
        refDialogoProdutosFornecedorForaMix.current.handleOpen();
      }
    }

    function handleCreateCorrecao(id) {
      if (refDialogoCreateCorrecao.current) {
        refDialogoCreateCorrecao.current.handleOpen(id);
      }
    }

    function handleEditProduto(id) {
      if (refDialogoProduto.current) {
        refDialogoProduto.current.handleEdit(id);
      }
    }

    function updateProduto(produto) {
      const index = itens.findIndex((item) => item.id === produto.id);
      if (index !== -1) {
        const item1 = itens[index];
        const item = { ...item1, ...produto, produto };
        item.caixaria_nivel_1 =
          produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
            ? produto.peso_caixa_nivel_1
            : produto.unidades_caixa_nivel_1;
        item.caixaria_nivel_2 = produto.unidades_caixa_nivel_2;
        item.caixaria_nivel_3 = produto.unidades_caixa_nivel_3;

        item.unitario = getPrecoParaCalculos({
          rs_kg: item.rs_kg,
          rs_un: item.rs_un,
          rs_cx_1: item.rs_cx_1,
          rs_cx_2: item.rs_cx_2,
          rs_cx_3: item.rs_cx_3,
          unidade_adotada_na_compra: item.unidade_preco_editado,
        });

        item.tipo_unidade_preco = item.unidade_preco_editado;

        let { rs_kg, rs_un, rs_cx_1, rs_cx_2, rs_cx_3 } = getPrecos(item);

        let pedido = item.pedido;
        let unidade_preco_editado = item.unidade_preco_editado;
        //let unidade_adotada_na_compra = item.unidade_adotada_na_compra;
        let unidade_adotada_na_compra = getUnidadeAdotadaNaCompra(item);

        if (
          (unidade_adotada_na_compra === TIPO_UNIDADE_CX1 &&
            !item.caixaria_nivel_1) ||
          (unidade_preco_editado === TIPO_UNIDADE_CX1 && !item.caixaria_nivel_1)
        ) {
          pedido = 0;
          unidade_preco_editado = getUnidadePrecoEditado(item);
          unidade_adotada_na_compra = getUnidadeAdotadaNaCompra(item);
          rs_kg = 0;
          rs_un = 0;
          rs_cx_1 = 0;
          rs_cx_2 = 0;
          rs_cx_3 = 0;
        }

        if (
          (unidade_adotada_na_compra === TIPO_UNIDADE_CX2 &&
            !item.caixaria_nivel_2) ||
          (unidade_preco_editado === TIPO_UNIDADE_CX2 && !item.caixaria_nivel_2)
        ) {
          pedido = 0;
          unidade_preco_editado = getUnidadePrecoEditado(item);
          unidade_adotada_na_compra = getUnidadeAdotadaNaCompra(item);
          rs_kg = 0;
          rs_un = 0;
          rs_cx_1 = 0;
          rs_cx_2 = 0;
          rs_cx_3 = 0;
        }

        if (
          (unidade_adotada_na_compra === TIPO_UNIDADE_CX3 &&
            !item.caixaria_nivel_3) ||
          (unidade_preco_editado === TIPO_UNIDADE_CX3 && !item.caixaria_nivel_3)
        ) {
          pedido = 0;
          unidade_preco_editado = getUnidadePrecoEditado(item);
          unidade_adotada_na_compra = getUnidadeAdotadaNaCompra(item);
          rs_kg = 0;
          rs_un = 0;
          rs_cx_1 = 0;
          rs_cx_2 = 0;
          rs_cx_3 = 0;
        }

        const vtu_30 = getVtu30(item, unidade_adotada_na_compra);

        const vmd_na_maior_unidade_possivel = vtu_30 / 30;

        const prazo_medio_pagamento = fornecedor.prazo_compra;

        const estoque_ideal =
          vmd_na_maior_unidade_possivel * prazo_medio_pagamento;

        const estoque_atual = getEstoque(item, unidade_adotada_na_compra);

        const precoParaCalculos = getPrecoParaCalculos({
          rs_kg,
          rs_un,
          rs_cx_1,
          rs_cx_2,
          rs_cx_3,
          unidade_adotada_na_compra,
        });

        const subtotal = pedido * precoParaCalculos;

        let bonificacao_em_cxs = 0;

        const regra_de_arredondamento =
          produto.arredondamento_no_pedido_de_compra;

        if (produto.ativar_bonificacao) {
          bonificacao_em_cxs = pedido / regra_de_arredondamento;
        }

        const bonificacao_subtotal = bonificacao_em_cxs * precoParaCalculos;

        const rs_un_mais_bonificacao =
          (pedido * precoParaCalculos) / (pedido + bonificacao_em_cxs);

        const volume_unitario = item.produto.volume_unitario_vu;

        const peso_bruto = item.produto.peso_unitario_pu;

        const peso_subtotal = pedido * peso_bruto;

        const volume_subtotal = pedido * volume_unitario;

        const peso_subtotal_liquido = getPeso(
          item,
          pedido,
          unidade_adotada_na_compra,
        );

        /////////////
        const estoque_ideal_label = estoque_ideal
          ? `${formatPreco(estoque_ideal, 0, '')}${unidade_adotada_na_compra}`
          : '-';

        const estoque_atual_label = getEstoqueLabel(
          item,
          unidade_adotada_na_compra,
          produto.unidadesDisponivel,
          produto.pesoDisponivel,
        );

        const vtu_30_label = getEstoqueLabel(
          item,
          unidade_adotada_na_compra,
          item.produto.unidades_vendidas_ultimos_30_dias,
          item.produto.peso_vendido_ultimos_30_dias,
        );

        const vmd_na_maior_unidade_possivel_label = vtu_30
          ? `${formatPreco(vtu_30 / 30, 2, '')}${unidade_adotada_na_compra}`
          : '-';
        /////////////

        const newItem = {
          id: item.id, // OK
          descricao: produto.nome, // OK
          rs_kg, // OK
          rs_un, // OK
          rs_cx_1, // OK
          rs_cx_2, // OK
          rs_cx_3, // OK
          pedido, // OK
          pedido_sugestao_original: pedido, // OK
          unidade_preco_editado, // OK
          unidade_adotada_na_compra, // OK
          subtotal,
          bonificacao_em_cxs,
          bonificacao_subtotal,
          rs_un_mais_bonificacao,
          peso_subtotal,
          volume_subtotal,
          estoque_atual,
          estoque_ideal, // OK
          vtu_30, // OK
          vmd_na_maior_unidade_possivel, // OK
          peso_padrao: produto.peso_medio, // OK
          volume_unitario, // OK
          peso_bruto, // OK
          regra_de_arredondamento, // OK
          caixaria_nivel_1: item.caixaria_nivel_1, // OK
          caixaria_nivel_2: item.caixaria_nivel_2, // OK
          caixaria_nivel_3: item.caixaria_nivel_3, // OK
          tipoUnidade: produto.unidade.tipo, // OK
          ativar_bonificacao: produto.ativar_bonificacao, // OK
          estoque_atual_label,
          vtu_30_label,
          vmd_na_maior_unidade_possivel_label,
          estoque_ideal_label,
          index: item.index, // OK
          status: produto.status, // OK
          peso_subtotal_liquido,
          peso_padrao_na_compra: produto.peso_padrao_na_compra, // OK
          peso_medio: produto.peso_medio, // OK
          unidade: produto.unidade, // OK
          pertencente_ao_mix: produto.pertencente_ao_mix,
        };

        const itensCopy = [...itens];
        itensCopy[index] = newItem;
        setItens(itensCopy);
      }
    }

    async function getProduto(id) {
      const response = await api.get(`/get-produto-para-pedido-compra/${id}`);
      const { status, message, data } = response.data;
      if (status) {
        updateProduto(data);
      }
    }

    async function handleAdicionaAoMix(ids) {
      const response = await api.post(
        `/get-produtos-adicionados-ao-mix-pelo-pedido-de-compra`,
        {
          ids,
        },
      );

      const { status, message, data: itens2 } = response.data;
      if (status) {
        const mapa = {};
        const formatados = formatItens(itens2, true, fornecedor);
        for (let i = 0; i < formatados.length; i++) {
          mapa[formatados[i].id] = formatados[i];
        }
        for (let i = 0; i < itens.length; i++) {
          if (mapa[itens[i].id]) {
            mapa[itens[i].id] = { ...itens[i], pertencente_ao_mix: true };
          } else {
            mapa[itens[i].id] = { ...itens[i] };
          }
        }
        const itensNew = Object.values(mapa);

        for (let i = 0; i < itensNew.length; i++) {
          itensNew[i].index = i;
        }
        setItens(itensNew);
      }
    }

    async function handleRemoverDoMix(ids) {
      const response = await api.post(
        `/get-produtos-removidos-do-mix-pelo-pedido-de-compra`,
        {
          ids,
        },
      );

      const mapa = {};
      for (let i = 0; i < ids.length; i++) {
        mapa[ids[i]] = ids[i];
      }

      const { status, message, data: itens2 } = response.data;
      if (status) {
        const itensNew = itens.filter((item) => {
          if (!mapa[item.id]) return true;
          if (mapa[item.id] && item.pedido) return true;
          return false;
        });
        for (let i = 0; i < itensNew.length; i++) {
          itensNew[i].index = i;
          if (mapa[itensNew[i].id]) {
            itensNew[i].pertencente_ao_mix = false;
          }
        }
        setItens(itensNew);
      }
    }

    function validateSubtotal() {
      let error = '';
      if (tela === TELA_2) {
        if (totais.subtotalOriginal < 0.1) {
          error = '* O valor do Subtotal deve ser um valor válido!';
        }
      }
      return error;
    }

    function validatePedidoMinimo() {
      let error = '';
      if (tela === TELA_2) {
        if (fornecedor) {
          switch (fornecedor.tipo_pedido_minimo_de_compra) {
            case TIPO_PEDIDO_MINIMO_COMPRA_KG:
              if (
                totais.peso_subtotal_liquidoOriginal <
                fornecedor.pedido_minimo_de_compra
              ) {
                error = `* O Peso Total do Pedido de Compra é ${formatPeso(
                  totais.peso_subtotal_liquidoOriginal,
                )}, que é menor que o Pedido mínimo ${formatPeso(
                  fornecedor.pedido_minimo_de_compra,
                )}`;
              }
              break;
            case TIPO_PEDIDO_MINIMO_COMPRA_CAIXAS:
              if (totais.pedido < fornecedor.pedido_minimo_de_compra) {
                error = `* O Número de Caixas é ${Math.round(
                  totais.pedido,
                )}, que é menor que o Pedido mínimo ${formatPeso(
                  fornecedor.pedido_minimo_de_compra,
                )}`;
              }
              break;
            case TIPO_PEDIDO_MINIMO_COMPRA_VALOR:
              if (
                totais.subtotalOriginal < fornecedor.pedido_minimo_de_compra
              ) {
                error = `* O Total do Pedido de Compra é ${formatMoeda(
                  totais.subtotalOriginal,
                )}, que é menor que o Pedido mínimo ${formatMoeda(
                  fornecedor.pedido_minimo_de_compra,
                )}`;
              }
              break;
            default:
              return '';
          }
        } else {
          error = '* Fornecedor não definido';
        }
      }
      return error;
    }

    function validateVerbaJornal() {
      let error = '';
      if (verbaJornal < 0 || Number.isNaN(verbaJornal)) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function validateVerbaBacklight() {
      let error = '';
      if (verbaBacklight < 0 || Number.isNaN(verbaBacklight)) {
        error = 'Este campo é obrigatório';
      }
      return error;
    }

    function getErros() {
      const erros = ['', ''];

      erros[0] = validateSubtotal();
      erros[1] = validatePedidoMinimo();
      erros[2] = validateVerbaJornal();
      erros[3] = validateVerbaBacklight();

      return erros;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    function ativarItem(id, nome) {
      refDialogoConfirmacao.current.handleOpen(
        `REATIVAR Item - ${nome}`,
        `Tem certeza que deseja REATIVAR o Item - ${nome}?`,
        {
          tipo: 'ativar',
          id,
        },
      );
    }

    function desativarItem(id, nome) {
      refDialogoConfirmacao.current.handleOpen(
        `INATIVAR Item - ${nome}`,
        `Tem certeza que deseja INATIVAR o Item - ${nome}?`,
        {
          tipo: 'desativar',
          id,
        },
      );
    }

    async function handleDesativarItem(id) {
      await api.put(`/produtos/status/inativar/${id}`);
      recalcularPedidoItem(id, false, STATUS_INATIVO);
    }

    async function handleAtivarItem(id) {
      await api.put(`/produtos/status/ativar/${id}`);
      recalcularPedidoItem(id, false, STATUS_ATIVO);
    }

    function handleRecalcularPedidoItem(id, nome) {
      refDialogoConfirmacao.current.handleOpen(
        `Recalcular Pedido do Item - ${nome}`,
        `Tem certeza que deseja recalcular o pedido do item ${nome}?`,
        {
          tipo: 'recalcular-pedido-item',
          id,
        },
      );
    }

    function removerDoMix(id, nome) {
      refDialogoConfirmacao.current.handleOpen(
        `REMOVER DO MIX o Item - ${nome}`,
        `Tem certeza que deseja REMOVER DO MIX o Item - ${nome}?`,
        {
          tipo: 'remover-do-mix',
          id,
        },
      );
    }

    function adicionarAoMix(id, nome) {
      refDialogoConfirmacao.current.handleOpen(
        `ADICIONAR AO MIX o Item - ${nome}`,
        `Tem certeza que deseja ADICIONAR AO MIX o Item - ${nome}?`,
        {
          tipo: 'adicionar-ao-mix',
          id,
        },
      );
    }

    function getErrosUi() {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            margin: '20px 0',
          }}
        >
          {erros.map((item) => {
            if (item) {
              return (
                <div
                  style={{
                    color: 'red',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  {item}
                </div>
              );
            }
            return null;
          })}
        </div>
      );
    }

    async function mudarPastaProdutos(ids) {
      const response = await api.post(
        '/definir-pasta-para-produtos-no-pedido',
        {
          ids,
          fornecedor_id: fornecedor ? fornecedor.id : null,
          pasta_id: pasta ? pasta.id : null,
        },
      );

      const { status, message, data } = response.data;
    }

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          TransitionComponent={Transition}
          keepMounted
          {...dadosDialogo}
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{
              backgroundColor: SECONDARY_COLOR,
              color: 'white',
            }}
          >
            {`Pedido de Compra - ${fornecedor ? fornecedor.razao_social : ''} `}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0px 36px 20px',
              margin: '0',
            }}
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div
                style={{
                  padding: '10px 0px',
                  flex: 1,
                }}
              >
                <Box
                  display="flex"
                  css={{
                    backgroundColor: 'white',
                    opacity: '0.75',
                    height: '100%',
                    padding: '0px 0px',
                    flexDirection: 'column',
                  }}
                >
                  {tela === TELA_2 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '20px 0px',
                        padding: '10px',
                        border: '2px solid black',
                        borderRadius: '10px',
                        gap: '15px',
                      }}
                    >
                      <Button
                        onClick={openDialogoProdutosFornecedorForaMix}
                        variant="contained"
                        color="primary"
                      >
                        Mostrar Itens fora do Mix
                      </Button>
                      <Button
                        onClick={() => {
                          refDialogoConfirmacao.current.handleOpen(
                            'Zerar o Preços Anteriores',
                            `Tem certeza que deseja Zerar o Preços Anteriores?`,
                            {
                              tipo: 'zerar-precos-anteriores',
                              id,
                            },
                          );
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Zerar Preços Anteriores
                      </Button>
                      <Button
                        onClick={() => {
                          refDialogoConfirmacao.current.handleOpen(
                            'Zerar o Pedido Inteiro',
                            `Tem certeza que deseja Zerar o Pedido Inteiro?`,
                            {
                              tipo: 'zerar-pedido-inteiro',
                              id,
                            },
                          );
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Zerar o Pedido Inteiro
                      </Button>
                      <Button
                        onClick={() => {
                          refDialogoConfirmacao.current.handleOpen(
                            'Recalcular Pedidos',
                            `Tem certeza que deseja recalcular todos os pedidos?`,
                            {
                              tipo: 'recalcular-2',
                              id,
                            },
                          );
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Recalcular Pedido Inteiro
                      </Button>
                      <Button
                        onClick={() => {}}
                        variant="contained"
                        color="primary"
                      >
                        Novo Produto
                      </Button>
                      <Button
                        onClick={() => {
                          if (refDialogoCabecalho.current) {
                            refDialogoCabecalho.current.handleOpen();
                          }
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Mostrar Cabeçalho
                      </Button>
                    </div>
                  ) : null}
                  {tela === TELA_2 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                      }}
                    >
                      <InputSearch handleChange={(nome) => setSearch(nome)} />
                      <SwitchInput
                        handleEnter={() => {}}
                        handleKey={() => {}}
                        label="Suprimir linhas com pedido zerado"
                        checked={suprimirPedidoZero}
                        onChange={(e) =>
                          setSuprimirPedidoZero(e.target.checked)
                        }
                      />
                    </div>
                  ) : null}
                  {getErrosUi()}

                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    <PrecoInput
                      name="verba_jornal"
                      handleEnter={() => {}}
                      label="Verba de Jornal"
                      handleKey={(keyCode, keyName) => {}}
                      value={
                        Number.isNaN(verbaJornal) ? '' : String(verbaJornal)
                      }
                      onChange={(value) =>
                        setVerbaJornal(parseFloat(value.target.value))
                      }
                      style={{
                        marginTop: '10px',
                        backgroundColor: 'white',
                      }}
                      error={erros[2] !== ''}
                      helperText={erros[2]}
                      fullWidth
                    />
                    <PrecoInput
                      name="verba_backlight"
                      handleEnter={() => {}}
                      label="Verba de Backlight"
                      handleKey={(keyCode, keyName) => {}}
                      value={
                        Number.isNaN(verbaBacklight)
                          ? ''
                          : String(verbaBacklight)
                      }
                      onChange={(value) =>
                        setVerbaBacklight(parseFloat(value.target.value))
                      }
                      style={{
                        marginTop: '10px',
                        backgroundColor: 'white',
                      }}
                      error={erros[3] !== ''}
                      helperText={erros[3]}
                      fullWidth
                    />
                  </div>

                  <Box
                    display="flex"
                    alignItems="center"
                    flex={1}
                    flexDirection="column"
                  >
                    {tela === TELA_1 ? (
                      <AutoCompleteRemoto
                        id="representante"
                        name="representante"
                        label="Representante"
                        value={representante}
                        onChange={(value) => {
                          setFornecedor(null);
                          setRepresentante(value);
                        }}
                        handleKey={(keyCode, keyName) => {}}
                        handleEnter={() => {}}
                        resource="representantes-fornecedores"
                        nested={[]}
                        toString={false}
                        fullwidth
                        autoFocus
                        filters={{ status: STATUS_ATIVO }}
                      />
                    ) : null}

                    <fieldset
                      style={{
                        width: '100%',
                        display: 'flex',
                        margin: '20px',
                        padding: '10px',
                        borderRadius: '5px',
                        flexDirection: 'column',
                      }}
                    >
                      <legend style={{ padding: '0 10px' }}>Fornecedor</legend>
                      {tela === TELA_1 ? (
                        <div style={{ display: 'flex', width: '100%' }}>
                          <AutoCompleteRemoto
                            id="cliente"
                            name="cliente"
                            label="Responsável"
                            value={fornecedor}
                            onChange={(value) => {
                              if (value) adicionaCampoFornecedor(value);
                              setFornecedor(value);
                            }}
                            handleKey={(keyCode, keyName) => {}}
                            handleEnter={() => {}}
                            resource="fornecedores"
                            nested={['cidade.estado']}
                            toString={false}
                            fullwidth
                            autoFocus
                            filters={
                              representante
                                ? {
                                    status: STATUS_ATIVO,
                                    ids_excluso: [1, 2],
                                    representante_fornecedor_id:
                                      representante.id,
                                  }
                                : { status: STATUS_ATIVO, ids_excluso: [1, 2] }
                            }
                          />
                          <AutoCompleteRemoto
                            id="cliente"
                            name="cliente"
                            label="Nome Fantasia"
                            value={fornecedor}
                            onChange={(value) => {
                              if (value) adicionaCampoFornecedor(value);
                              setFornecedor(value);
                            }}
                            handleKey={(keyCode, keyName) => {}}
                            handleEnter={() => {}}
                            resource="fornecedores"
                            campo="nome_fantasia"
                            nested={['cidade.estado']}
                            toString={false}
                            style={{ marginLeft: '10px' }}
                            fullwidth
                            filters={
                              representante
                                ? {
                                    status: STATUS_ATIVO,
                                    ids_excluso: [1, 2],
                                    representante_fornecedor_id:
                                      representante.id,
                                  }
                                : { status: STATUS_ATIVO, ids_excluso: [1, 2] }
                            }
                          />
                        </div>
                      ) : null}
                      {fornecedor && (
                        <Box display="flex" style={{ gap: '10px' }}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            flex={1}
                            style={{
                              border: `1px solid ${PRIMARY_COLOR}`,
                              marginLeft: '0px',
                              padding: '5px',
                              borderRadius: '5px',
                              marginBottom: '10px',
                            }}
                          >
                            <Box
                              marginLeft="10px"
                              display="flex"
                              flexDirection="column"
                              flex={1}
                            >
                              <Box
                                marginLeft="0px"
                                fontSize="15px"
                                fontWeight="bold"
                                display="flex"
                                flex={1}
                              >
                                <p
                                  className={classes.textOverflow}
                                  style={{
                                    marginBottom: '0px',
                                    color: colorHeader,
                                    marginRight: '10px',
                                  }}
                                >
                                  Responsável:
                                </p>
                                {fornecedor.nome}
                              </Box>
                              <Box
                                marginLeft="0px"
                                fontSize="15px"
                                fontWeight="bold"
                                display="flex"
                                flex={1}
                              >
                                <p
                                  className={classes.textOverflow}
                                  style={{
                                    marginBottom: '0px',
                                    color: colorHeader,
                                    marginRight: '10px',
                                  }}
                                >
                                  Razão Social:
                                </p>
                                {fornecedor.razao_social}
                              </Box>
                              <Box
                                marginLeft="0px"
                                fontSize="15px"
                                fontWeight="bold"
                                display="flex"
                                flex={1}
                              >
                                <p
                                  className={classes.textOverflow}
                                  style={{
                                    marginBottom: '0px',
                                    color: colorHeader,
                                    marginRight: '10px',
                                  }}
                                >
                                  CPF:
                                </p>
                                {formatCPF(fornecedor.cpf)}
                              </Box>
                              <Box
                                marginLeft="0px"
                                fontSize="15px"
                                fontWeight="bold"
                                display="flex"
                                flex={1}
                              >
                                <p
                                  className={classes.textOverflow}
                                  style={{
                                    marginBottom: '0px',
                                    color: colorHeader,
                                    marginRight: '10px',
                                  }}
                                >
                                  CNPJ:
                                </p>
                                {formatCNPJ(fornecedor.cnpj)}
                              </Box>
                              <Box
                                marginLeft="0px"
                                fontSize="15px"
                                fontWeight="bold"
                                display="flex"
                                flex={1}
                              >
                                <p
                                  className={classes.textOverflow}
                                  style={{
                                    marginBottom: '0px',
                                    color: colorHeader,
                                    marginRight: '10px',
                                  }}
                                >
                                  Prazo Médio de Compra:
                                </p>
                                {`${fornecedor.prazo_compra} dias`}
                              </Box>
                              <Box
                                marginLeft="0px"
                                fontSize="15px"
                                fontWeight="bold"
                                display="flex"
                                flex={1}
                              >
                                <p
                                  className={classes.textOverflow}
                                  style={{
                                    marginBottom: '0px',
                                    color: colorHeader,
                                    marginRight: '10px',
                                  }}
                                >
                                  Pasta:
                                </p>
                                {pasta ? pasta.nome : '-'}
                              </Box>
                            </Box>
                            <Box
                              marginLeft="10px"
                              display="flex"
                              flexDirection="column"
                              flex={1}
                            >
                              <Box
                                marginLeft="0px"
                                fontSize="15px"
                                fontWeight="bold"
                                display="flex"
                                flex={1}
                                justifyContent="flex-start"
                              >
                                <p
                                  className={classes.textOverflow}
                                  style={{
                                    marginBottom: '0px',
                                    color: colorHeader,
                                    marginRight: '10px',
                                  }}
                                >
                                  Nome Fantasia:
                                </p>
                                {fornecedor.nome_fantasia}
                              </Box>
                              <Box
                                marginLeft="0px"
                                fontSize="15px"
                                fontWeight="bold"
                                display="flex"
                                flex={1}
                                justifyContent="flex-start"
                              >
                                <p
                                  className={classes.textOverflow}
                                  style={{
                                    marginBottom: '0px',
                                    color: colorHeader,
                                    marginRight: '10px',
                                  }}
                                >
                                  Bairro:
                                </p>
                                {fornecedor.bairro}
                              </Box>
                              <Box
                                marginLeft="0px"
                                fontSize="15px"
                                fontWeight="bold"
                                display="flex"
                                flex={1}
                                justifyContent="flex-start"
                              >
                                <p
                                  className={classes.textOverflow}
                                  style={{
                                    marginBottom: '0px',
                                    color: colorHeader,
                                    marginRight: '10px',
                                  }}
                                >
                                  Cidade:
                                </p>
                                {fornecedor.cidade
                                  ? `${fornecedor.cidade.nome} - ${fornecedor.cidade.estado.sigla}`
                                  : ''}
                              </Box>
                              <Box
                                marginLeft="0px"
                                fontSize="15px"
                                fontWeight="bold"
                                display="flex"
                                flex={1}
                                justifyContent="flex-start"
                              >
                                <p
                                  className={classes.textOverflow}
                                  style={{
                                    marginBottom: '0px',
                                    color: colorHeader,
                                    marginRight: '10px',
                                  }}
                                >
                                  Logradouro:
                                </p>
                                {fornecedor.logradouro}
                              </Box>
                              <Box
                                marginLeft="0px"
                                fontSize="15px"
                                fontWeight="bold"
                                display="flex"
                                flex={1}
                              >
                                <p
                                  className={classes.textOverflow}
                                  style={{
                                    marginBottom: '0px',
                                    color: colorHeader,
                                    marginRight: '10px',
                                  }}
                                >
                                  Pedido Mínimo:
                                </p>
                                {getLabelPedidoMinimo(fornecedor)}
                              </Box>
                              <Box
                                marginLeft="0px"
                                fontSize="15px"
                                fontWeight="bold"
                                display="flex"
                                flex={1}
                              >
                                <p
                                  className={classes.textOverflow}
                                  style={{
                                    marginBottom: '0px',
                                    color: colorHeader,
                                    marginRight: '10px',
                                  }}
                                >
                                  Tempo de Entrega:
                                </p>
                                {`${fornecedor.tempo_entrega} dias`}
                              </Box>
                            </Box>
                          </Box>
                          <Tooltip title="Editar Fornecedor">
                            <IconButton
                              edge="start"
                              className={classes.menuButton}
                              color="inherit"
                              aria-label="open drawer"
                              size="small"
                              onClick={() => editarFornecedor()}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                    </fieldset>

                    {tela === TELA_1 ? (
                      <fieldset
                        style={{
                          width: '100%',
                          display: 'flex',
                          //margin: '20px',
                          padding: '10px',
                          borderRadius: '5px',
                          flexDirection: 'column',
                        }}
                      >
                        <legend style={{ padding: '0 10px' }}>
                          Pastas do Fornecedor
                        </legend>
                        {fornecedor ? (
                          <div
                            style={{
                              display: 'flex',
                              gap: '10px',
                            }}
                          >
                            <AutoCompleteRemoto
                              id="pasta"
                              name="pasta"
                              label="Pasta"
                              value={pasta}
                              onChange={(value) => {
                                setPasta(value);
                              }}
                              handleKey={(keyCode, keyName) => {}}
                              handleEnter={() => {}}
                              resource="pastas-fornecedores"
                              nested={[]}
                              toString={false}
                              style={{}}
                              fullwidth={false}
                              autoFocus
                              filters={{
                                fornecedor_id: fornecedor
                                  ? fornecedor.id
                                  : null,
                              }}
                              getOptionSelected={(option, value) =>
                                option.nome === value.nome
                              }
                            />
                            {pasta ? (
                              <Tooltip title="Adicionar itens a esta pasta">
                                <IconButton
                                  edge="start"
                                  className={classes.menuButton}
                                  color="inherit"
                                  aria-label="open drawer"
                                  size="small"
                                  onClick={() => {
                                    if (refDialogoProdutosSemPasta.current) {
                                      refDialogoProdutosSemPasta.current.handleOpen();
                                    }
                                  }}
                                >
                                  <AddIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </div>
                        ) : (
                          'SELECIONE O FRONECEDOR PRIMEIRO'
                        )}
                      </fieldset>
                    ) : null}
                  </Box>
                </Box>

                {tela === TELA_2 ? (
                  <div>
                    <Table aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          {headerTotais(
                            Object.values(colunas).map((item) => item),
                            totais,
                          )}
                        </TableRow>
                        <TableRow />
                        <TableRow>
                          {getHeaderTabela(
                            Object.values(colunas).map((item) => item),
                            orderBy,
                            order,
                            handleSort,
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortedData.map(
                          (
                            {
                              id,
                              descricao,
                              rs_kg,
                              rs_un,
                              rs_cx_1,
                              rs_cx_2,
                              rs_cx_3,
                              pedido,
                              unidade_preco_editado,
                              unidade_adotada_na_compra,
                              subtotal,
                              bonificacao_em_cxs,
                              bonificacao_subtotal,
                              rs_un_mais_bonificacao,
                              peso_subtotal,
                              volume_subtotal,
                              estoque_atual,
                              estoque_ideal,
                              vtu_30,
                              vmd_na_maior_unidade_possivel,
                              peso_padrao,
                              volume_unitario,
                              peso_bruto,
                              regra_de_arredondamento,
                              caixaria_nivel_1,
                              caixaria_nivel_2,
                              caixaria_nivel_3,
                              //
                              show_rs_kg,
                              show_rs_un,
                              show_rs_cx_1,
                              show_rs_cx_2,
                              show_rs_cx_3,
                              show_pedido,
                              styles,
                              show_bonificacao,
                              index,
                              status,
                              peso_subtotal_liquido,
                              pertencente_ao_mix,
                            },
                            index2,
                          ) => (
                            //index,
                            <TableRow style={styles} key={id}>
                              <StyledTableCell align="center">
                                {index2 + 1}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {descricao}
                              </StyledTableCell>
                              <Involucro field="rs_kg" colunas={colunas}>
                                <StyledTableRowInput
                                  align="center"
                                  handleEdit={(campo, id, valor) => {
                                    updateEstado(campo, id, valor);
                                  }}
                                  campo="rs_kg"
                                  id={index}
                                  showInput={show_rs_kg}
                                  style={
                                    unidade_preco_editado === TIPO_UNIDADE_KG
                                      ? { backgroundColor: '#88df5973' }
                                      : {}
                                  }
                                >
                                  {rs_kg}
                                </StyledTableRowInput>
                              </Involucro>
                              <Involucro field="rs_un" colunas={colunas}>
                                <StyledTableRowInput
                                  align="center"
                                  handleEdit={(campo, id, valor) => {
                                    updateEstado(campo, id, valor);
                                  }}
                                  campo="rs_un"
                                  id={index}
                                  showInput={show_rs_un}
                                  style={
                                    unidade_preco_editado === TIPO_UNIDADE_UN
                                      ? { backgroundColor: '#88df5973' }
                                      : {}
                                  }
                                >
                                  {rs_un}
                                </StyledTableRowInput>
                              </Involucro>
                              <Involucro field="rs_cx_1" colunas={colunas}>
                                <StyledTableRowInput
                                  align="center"
                                  handleEdit={(campo, id, valor) => {
                                    updateEstado(campo, id, valor);
                                  }}
                                  campo="rs_cx_1"
                                  id={index}
                                  showInput={show_rs_cx_1}
                                  style={
                                    unidade_preco_editado === TIPO_UNIDADE_CX1
                                      ? { backgroundColor: '#88df5973' }
                                      : {}
                                  }
                                >
                                  {rs_cx_1}
                                </StyledTableRowInput>
                              </Involucro>
                              <Involucro field="rs_cx_2" colunas={colunas}>
                                <StyledTableRowInput
                                  align="center"
                                  handleEdit={(campo, id, valor) => {
                                    updateEstado(campo, id, valor);
                                  }}
                                  campo="rs_cx_2"
                                  id={index}
                                  showInput={show_rs_cx_2}
                                  style={
                                    unidade_preco_editado === TIPO_UNIDADE_CX2
                                      ? { backgroundColor: '#88df5973' }
                                      : {}
                                  }
                                >
                                  {rs_cx_2}
                                </StyledTableRowInput>
                              </Involucro>
                              <Involucro field="rs_cx_3" colunas={colunas}>
                                <StyledTableRowInput
                                  align="center"
                                  handleEdit={(campo, id, valor) => {
                                    updateEstado(campo, id, valor);
                                  }}
                                  campo="rs_cx_3"
                                  id={index}
                                  showInput={show_rs_cx_3}
                                  style={
                                    unidade_preco_editado === TIPO_UNIDADE_CX3
                                      ? { backgroundColor: '#88df5973' }
                                      : {}
                                  }
                                >
                                  {rs_cx_3}
                                </StyledTableRowInput>
                              </Involucro>
                              <StyledTableRowInputInteger
                                align="center"
                                handleEdit={(campo, id, valor) => {
                                  updateEstado(campo, id, valor);
                                }}
                                campo="pedido"
                                id={index}
                                showInput={show_pedido}
                                style={{
                                  color: 'red',
                                  fontWeight: 'bold',
                                  fontSize: '20px',
                                }}
                                validate={(value) => {
                                  const resto = value % regra_de_arredondamento;

                                  if (resto === 0) {
                                    return '';
                                  }

                                  const divisao = parseInt(
                                    value / regra_de_arredondamento,
                                    10,
                                  );

                                  const limiteInferior =
                                    divisao * regra_de_arredondamento;
                                  const limiteSuperior =
                                    (divisao + 1) * regra_de_arredondamento;

                                  return `escolha um múltiplo de ${regra_de_arredondamento},  sugestões (${limiteInferior} e ${limiteSuperior})`;
                                }}
                              >
                                {pedido}
                              </StyledTableRowInputInteger>
                              <StyledTableCell align="center">
                                {unidade_adotada_na_compra}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatMoeda(subtotal)}
                              </StyledTableCell>
                              <Involucro
                                field="bonificacao_em_cxs"
                                colunas={colunas}
                              >
                                <StyledTableRowInputInteger
                                  align="center"
                                  handleEdit={(campo, id, valor) => {
                                    updateEstado(campo, id, valor);
                                  }}
                                  campo="bonificacao_em_cxs"
                                  id={index}
                                  showInput={show_bonificacao}
                                >
                                  {bonificacao_em_cxs}
                                </StyledTableRowInputInteger>
                              </Involucro>
                              <Involucro
                                field="bonificacao_subtotal"
                                colunas={colunas}
                              >
                                <StyledTableCell align="center">
                                  {bonificacao_subtotal}
                                </StyledTableCell>
                              </Involucro>
                              <Involucro
                                field="bonificacao_subtotal"
                                colunas={colunas}
                              >
                                <StyledTableCell align="center">
                                  {rs_un_mais_bonificacao}
                                </StyledTableCell>
                              </Involucro>
                              <Involucro
                                field="peso_subtotal_liquido"
                                colunas={colunas}
                              >
                                <StyledTableCell align="center">
                                  {peso_subtotal_liquido}
                                </StyledTableCell>
                              </Involucro>
                              <Involucro
                                field="peso_subtotal"
                                colunas={colunas}
                              >
                                <StyledTableCell align="center">
                                  {peso_subtotal}
                                </StyledTableCell>
                              </Involucro>
                              <Involucro
                                field="volume_subtotal"
                                colunas={colunas}
                              >
                                <StyledTableCell align="center">
                                  {volume_subtotal}
                                </StyledTableCell>
                              </Involucro>
                              <StyledTableCell align="center">
                                {estoque_atual}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {estoque_ideal}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {vtu_30}
                              </StyledTableCell>
                              <Involucro field="status" colunas={colunas}>
                                {status === 'Ativo' ? (
                                  <StyledTableCell
                                    align="center"
                                    style={{
                                      color: 'green',
                                      fontWeight: 'bold',
                                      fontSize: '20px',
                                    }}
                                  >
                                    {status}
                                  </StyledTableCell>
                                ) : (
                                  <StyledTableCell
                                    align="center"
                                    style={{
                                      color: 'red',
                                      fontWeight: 'bold',
                                      fontSize: '20px',
                                    }}
                                  >
                                    {status}
                                  </StyledTableCell>
                                )}
                              </Involucro>
                              <Involucro
                                field="vmd_na_maior_unidade_possivel"
                                colunas={colunas}
                              >
                                <StyledTableCell align="center">
                                  {vmd_na_maior_unidade_possivel}
                                </StyledTableCell>
                              </Involucro>
                              <Involucro field="peso_padrao" colunas={colunas}>
                                <StyledTableCell align="center">
                                  {peso_padrao}
                                </StyledTableCell>
                              </Involucro>
                              <Involucro
                                field="volume_unitario"
                                colunas={colunas}
                              >
                                <StyledTableCell align="center">
                                  {volume_unitario}
                                </StyledTableCell>
                              </Involucro>
                              <Involucro field="peso_bruto" colunas={colunas}>
                                <StyledTableCell align="center">
                                  {peso_bruto}
                                </StyledTableCell>
                              </Involucro>
                              <Involucro
                                field="regra_de_arredondamento"
                                colunas={colunas}
                              >
                                <StyledTableCell align="center">
                                  {regra_de_arredondamento}
                                </StyledTableCell>
                              </Involucro>
                              <Involucro
                                field="caixaria_nivel_1"
                                colunas={colunas}
                              >
                                <StyledTableCell align="center">
                                  {caixaria_nivel_1}
                                </StyledTableCell>
                              </Involucro>
                              <Involucro
                                field="caixaria_nivel_2"
                                colunas={colunas}
                              >
                                <StyledTableCell align="center">
                                  {caixaria_nivel_2}
                                </StyledTableCell>
                              </Involucro>
                              <Involucro
                                field="caixaria_nivel_3"
                                colunas={colunas}
                              >
                                <StyledTableCell align="center">
                                  {caixaria_nivel_3}
                                </StyledTableCell>
                              </Involucro>
                              <StyledTableCell align="center">
                                <Tooltip
                                  title="Editar Produto"
                                  aria-label="adicionar"
                                >
                                  <IconButton
                                    color="primary"
                                    onClick={() => {
                                      handleEditProduto(id);
                                    }}
                                    disabled={false}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title="Corrigir Estoque"
                                  aria-label="adicionar"
                                >
                                  <IconButton
                                    color="primary"
                                    onClick={() => {
                                      handleCreateCorrecao(id);
                                    }}
                                    disabled={false}
                                  >
                                    <AssessmentIcon />
                                  </IconButton>
                                </Tooltip>
                                {pertencente_ao_mix ? (
                                  <Tooltip
                                    title="Remover do Mix"
                                    aria-label="adicionar"
                                  >
                                    <IconButton
                                      color="primary"
                                      onClick={() => {
                                        removerDoMix(id, descricao);
                                      }}
                                      disabled={false}
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title="Adicionar ao Mix"
                                    aria-label="adicionar"
                                  >
                                    <IconButton
                                      color="primary"
                                      onClick={() => {
                                        adicionarAoMix(id, descricao);
                                      }}
                                      disabled={false}
                                    >
                                      <AddIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {status !== 'Ativo' ? (
                                  <Tooltip
                                    title="Ativar Item"
                                    aria-label="adicionar"
                                  >
                                    <IconButton
                                      color="primary"
                                      onClick={() => {
                                        ativarItem(id, descricao);
                                      }}
                                      disabled={false}
                                    >
                                      <CheckIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title="Inativar Item"
                                    aria-label="adicionar"
                                  >
                                    <IconButton
                                      color="primary"
                                      onClick={() => {
                                        desativarItem(id, descricao);
                                      }}
                                      disabled={false}
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip
                                  title="Recalcular Pedido"
                                  aria-label="adicionar"
                                >
                                  <IconButton
                                    color="primary"
                                    onClick={() => {
                                      handleRecalcularPedidoItem(id, descricao);
                                    }}
                                    disabled={false}
                                  >
                                    <RefreshIcon />
                                  </IconButton>
                                </Tooltip>
                              </StyledTableCell>
                            </TableRow>
                          ),
                        )}
                      </TableBody>
                    </Table>
                  </div>
                ) : null}
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{
              padding: '32px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={handleCloseDialog}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              Cancelar
            </Button>
            <Box>
              <div className={classes.wrapper}>
                <Button
                  onClick={() => {
                    if (tela === TELA_1) {
                      setTela(TELA_2);
                    } else {
                      handleSalvar();
                    }
                  }}
                  classes={{
                    root: classes.btnAtions,
                    disabled: classes.btnAtionsDisabled,
                  }}
                  variant="contained"
                  color="primary"
                  disabled={
                    carregando ||
                    (tela === TELA_1
                      ? fornecedor
                        ? false
                        : true
                      : erroExistente)
                  }
                >
                  {tela === TELA_1 ? 'Avançar' : 'Salvar'}
                </Button>
                {carregando && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Box>
          </DialogActions>
        </Dialog>
        <DialogoFornecedor
          ref={refDialogoFornecedor}
          handleClose={(idUpdate) => {
            if (idUpdate > 0) {
              getFornecedor(idUpdate);
            }
          }}
        />
        <DialogoProdutosFornecedorForaMix
          ref={refDialogoProdutosFornecedorForaMix}
          fornecedor={fornecedor}
          handleSalvar={(ids) => {
            handleAdicionaAoMix(ids);
          }}
          pasta={pasta}
        />
        <DialogoCreateCorrecao
          ref={refDialogoCreateCorrecao}
          handleClose={(status, id) => {
            if (status) {
              getProduto(id);
            }
          }}
        />
        <DialogoProduto
          ref={refDialogoProduto}
          handleClose={(idUpdate) => {
            if (idUpdate) {
              getProduto(idUpdate);
            }
          }}
          isImprimir={false}
        />
        <DialogoConfirmacao
          ref={refDialogoConfirmacao}
          handleConfirma={(dados) => {
            handleDialogoConfirmacao(dados, true);
          }}
          handleRejeita={(dados) => {
            handleDialogoConfirmacao(dados, false);
          }}
        />
        <DialogoCabecalho
          ref={refDialogoCabecalho}
          fornecedor={fornecedor}
          totalPedido={totais.subtotalOriginal}
          pesoTotal={totais.peso_subtotal_liquidoOriginal}
          data={data}
          verbaJornal={verbaJornal}
          verbaBackligth={verbaBacklight}
          bonificacao={totais.bonificacao_subtotalOriginal}
          comprador={comprador}
        />
        <DialogoProdutosSemPasta
          ref={refDialogoProdutosSemPasta}
          fornecedor={fornecedor}
          handleSalvar={(ids) => {
            mudarPastaProdutos(ids);
          }}
          pasta={pasta}
        />
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
