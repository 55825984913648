import React from 'react';

import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import './index.css';

import Document from './Document';

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex" style={{}}>
      <CircularProgress variant="determinate" {...props} color="secondary" />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const RelatorioFc = ({ open, dados }) => (
  <>
    {dados !== null ? (
      <PDFViewer
        style={{ margin: 0, padding: 0, border: 'none' }}
        width="100%"
        height="100%"
      >
        <Document dados={dados} />
      </PDFViewer>
    ) : (
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: '0.75',
        }}
      >
        <CircularProgressWithLabel value={50} />
      </div>
    )}
  </>
);

export default RelatorioFc;

/* <button
            onClick={handleDownload}
            style={{ marginTop: 10 }}
            type="button"
          >
            Baixar PDF
          </button> */
