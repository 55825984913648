import React, { useRef } from 'react';
import {
  DateField,
  ReferenceField,
  TextField,
  ListBase,
  ListToolbar,
  Pagination,
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  Datagrid,
  useListContext,
  Title,
  NumberField,
} from 'react-admin';

import { Card, makeStyles, Box } from '@material-ui/core';

import { PRIMARY_COLOR } from '../../utils';
import ValorField from '../CreditoDevolucaoVenda/fields/ValorField';
import ActionsField from './ActionsField';
import Actions from './ActionsList';
import Dialogo from './ContainerDialogoVenda';
import DialogoDelete from './DialogoDelete';
import DialogoShow from './DialogoShow';
import Filter from './Filter';
import VendaField from './VendaField';

const useStyles = makeStyles((theme) => ({
  dataHeader: {
    flex: 1,
    backgroundColor: PRIMARY_COLOR,
    padding: '10px',
    color: 'white',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const MyList = ({ ...props }) => {
  const refDialogoCreate = useRef(null);
  const refDialogoDelete = useRef(null);
  const listContext = useListContext();
  const { filterValues } = listContext;
  const refDialogoShow = useRef(null);
  const classes = useStyles();

  function handleDelete(record) {
    if (refDialogoDelete.current) refDialogoDelete.current.handleOpen(record);
  }

  function handleCriar() {
    if (refDialogoCreate.current) {
      //const venda_id = filterValues.venda_id;
      refDialogoCreate.current.handleOpen();
    }
  }

  function handleShow({ id }) {
    if (refDialogoShow.current) refDialogoShow.current.handleOpen(id);
  }

  return (
    <>
      <Title title="Devoluções de Vendas" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <TextField
            source="id"
            label="ID"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <DateField
            source="data"
            label="Data da Devolução"
            showTime
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <ValorField
            source="valor"
            label="Valor"
            sortable={false}
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <ReferenceField
            label="Cliente"
            source="cliente_id"
            reference="clientes"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            linkType={false}
          >
            <TextField source="nome" />
          </ReferenceField>
          <ReferenceField
            source="responsavel_id"
            reference="users"
            label="Responsável"
            allowEmpty={false}
            sortable={false}
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            linkType={false}
          >
            <TextField source="username" />
          </ReferenceField>
          <VendaField
            source="venda"
            label="Venda Referente"
            sortable={false}
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <ActionsField
            source="lalala"
            handleDelete={handleDelete}
            handleShow={handleShow}
            textAlign="right"
            label="Ações"
            sortable={false}
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <Dialogo ref={refDialogoCreate} />
      <DialogoDelete ref={refDialogoDelete} />
      <DialogoShow ref={refDialogoShow} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Devoluções de Vendas"
      filterDefaultValues={{
        periodo: {
          start: new Date(),
          end: new Date(),
        },
      }}
    />
  </>
);

export default List2;
