import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  DateField,
  ListContextProvider,
  NumberField,
  ReferenceField,
  TextField,
  useGetList,
  useListContext,
  ListBase,
  ListToolbar,
  Pagination,
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  Datagrid,
  Title,
} from 'react-admin';
import { useHistory } from 'react-router-dom';

import { Divider, Tabs, Tab, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { stringify } from 'query-string';

import DialogoAtivarResources from '../../Components/DialogoAtivarResource';
import DialogoDeleteResource from '../../Components/DialogoDeleteResource';
import DialogoDevolucaoVenda from '../DevolucoesVenda/DialogoCreateEdit';
import ActionsField from './ActionsField';
import Actions from './ActionsList';
import Dialogo from './ContainerDialogoVenda';
import DialogoChangeStatus from './DialogoChangeStatus';
import DialogoMudarTipoVenda from './DialogoMudarTipoVenda';
import DialogoResumoMercadoriasRestantes from './DialogoResumoMercadoriasVenda';
import DialogoShowVenda from './DialogoShow';
import Filter from './Filter';
import NumeroField from './NumeroField';
import StatusField from './StatusField';

const tabs = [
  { id: 'confirmado', name: 'confirmado' },
  { id: 'cancelado', name: 'cancelado' },
];

const useGetTotals = (filterValues) => {
  const { total: totalConfirmado } = useGetList(
    'commands',
    { perPage: 1, page: 1 },
    { field: 'id', order: 'ASC' },
    { ...filterValues, cancelado: false },
  );
  const { total: totalCancelado } = useGetList(
    'commands',
    { perPage: 1, page: 1 },
    { field: 'id', order: 'ASC' },
    { ...filterValues, cancelado: true },
  );

  return {
    confirmado: totalConfirmado,
    cancelado: totalCancelado,
  };
};

const PostPanel = (props) => (
  <Show {...props} title="." actions={false}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <TextField source="sessao_id" label="Sessão" />
      <ReferenceManyField
        addLabel={false}
        reference="parcelasvenda"
        target="venda_id"
      >
        <Datagrid isBlue>
          <ReferenceField
            label="Meio Pagamento"
            source="tipo_pagamento_id"
            reference="tipospagamento"
            linkType={false}
          >
            <TextField source="nome" />
          </ReferenceField>
          <NumberField
            source="valor"
            label="Valor"
            locales="pt-BR"
            sortable={false}
            options={{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            }}
          />
          <DateField
            source="data_pagamento"
            sortable={false}
            label="Data de Pagamento"
          />
          <DateField
            source="data_pagamento_real"
            sortable={false}
            label="Data Real de Pagamento"
          />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

const MyList = ({ ...props }) => {
  const refDialogoCompra = useRef(null);
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [confirmados, setConfirmados] = useState([]);
  const [cancelados, setCancelados] = useState([]);
  const totals = useGetTotals(filterValues);
  const refDialogoDeleteResource = useRef(null);
  const refDialogoAtivaResource = useRef(null);
  const refDialogoResumoMercadoriasRestantes = useRef(null);
  const refDialogoMudarTipoVenda = useRef(null);
  const history = useHistory();
  const refDialogoShowVenda = useRef(null);
  const refDialogoDevolucaoVenda = useRef(null);

  function ativarHandle(record) {
    if (refDialogoAtivaResource.current)
      refDialogoAtivaResource.current.handleOpen(record);
  }

  useEffect(() => {
    if (ids && ids !== filterValues.cancelado) {
      switch (filterValues.cancelado) {
        case false:
          setConfirmados(ids);
          break;
        case true:
          setCancelados(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.cancelado]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          { ...filterValues, cancelado: value === 'cancelado' },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  function handleCriar() {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleCreateVenda();
    }
  }

  function handleEdit({ id }) {
    if (refDialogoCompra.current) {
      refDialogoCompra.current.handleEdit(id);
    }
  }

  function handleShow({ id }) {
    //history.push(`/vendas/${record.id}/show`);
    if (refDialogoShowVenda.current) {
      refDialogoShowVenda.current.handleOpen(id);
    }
  }

  function handleVendasParciais({ id }) {
    const queryOj = {};

    queryOj.displayedFilters = JSON.stringify({});
    queryOj.filter = JSON.stringify({
      venda_id: id,
    });

    history.push(
      `/vendas_parciais?${stringify(
        queryOj,
      )}&order=DESC&page=1&perPage=50&sort=data`,
    );
  }

  function handleResumoMercadoriasRestantes({ id, ...rest }) {
    if (refDialogoResumoMercadoriasRestantes.current) {
      refDialogoResumoMercadoriasRestantes.current.handleOpen(id, rest);
    }
  }

  function handleMudarTipoVenda(record) {
    if (refDialogoMudarTipoVenda.current)
      refDialogoMudarTipoVenda.current.handleOpen(record.id);
  }

  function handleDevolucoes(record) {
    if (refDialogoDevolucaoVenda.current)
      refDialogoDevolucaoVenda.current.handleCreate(record.id);
  }

  return (
    <>
      <Title title="Vendas" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.cancelado ? tabs[1].id : tabs[0].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab
              key={choice.id}
              label={
                totals[choice.name]
                  ? `${choice.name} (${totals[choice.name]})`
                  : choice.name
              }
              value={choice.id}
            />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.cancelado === false && (
            <ListContextProvider value={{ ...listContext, ids: confirmados }}>
              <Datagrid {...props}>
                <StatusField
                  source="status"
                  label="Tipo"
                  sortable={false}
                  handleVendasParciais={handleVendasParciais}
                />
                <NumeroField source="id" label="Nº venda" />
                <DateField source="data" label="Data da Venda" showTime />
                <ReferenceField
                  label="Cliente"
                  source="cliente_id"
                  reference="clientes"
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <NumberField
                  source="valor"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor Total"
                />
                <NumberField
                  source="lucro"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Lucro"
                />
                {/*<NumberField
                  source="valorEmDinheiro"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor em dinheiro"
                  sortable={false}
                />*/}
                <NumberField
                  source="porcentagem_parcial"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }}
                  label="Entregue (%)"
                  sortable={false}
                />
                <ActionsField
                  source="lalala"
                  handleEdit={handleEdit}
                  handleEfetivar={ativarHandle}
                  handleShow={handleShow}
                  handleResumoMercadoriasRestantes={
                    handleResumoMercadoriasRestantes
                  }
                  handleMudarTipoVenda={handleMudarTipoVenda}
                  handleDevolucoes={handleDevolucoes}
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.cancelado === true && (
            <ListContextProvider value={{ ...listContext, ids: cancelados }}>
              <Datagrid {...props}>
                <StatusField
                  source="status"
                  label="Tipo"
                  sortable={false}
                  handleVendasParciais={handleVendasParciais}
                />
                <NumeroField source="id" label="Nº venda" />
                <DateField source="data" label="Data da Venda" showTime />
                <ReferenceField
                  label="Cliente"
                  source="cliente_id"
                  reference="clientes"
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <NumberField
                  source="valor"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor Total"
                />
                <NumberField
                  source="lucro"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Lucro"
                />
                {/*<NumberField
                  source="valorEmDinheiro"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor em dinheiro"
                  sortable={false}
                />*/}
                <NumberField
                  source="porcentagem_parcial"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }}
                  label="Entregue (%)"
                  sortable={false}
                />
                <ActionsField
                  source="lalala"
                  //handleEdit={handleEdit}
                  //handleEfetivar={ativarHandle}
                  handleShow={handleShow}
                  /*handleResumoMercadoriasRestantes={
                    handleResumoMercadoriasRestantes
                  }*/
                  //handleMudarTipoVenda={handleMudarTipoVenda}
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <Dialogo ref={refDialogoCompra} />
      <DialogoResumoMercadoriasRestantes
        ref={refDialogoResumoMercadoriasRestantes}
      />
      <DialogoDeleteResource ref={refDialogoDeleteResource} />
      <DialogoMudarTipoVenda ref={refDialogoMudarTipoVenda} />
      <DialogoShowVenda ref={refDialogoShowVenda} />
      <DialogoDevolucaoVenda ref={refDialogoDevolucaoVenda} />
      <DialogoChangeStatus ref={refDialogoAtivaResource} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Vendas"
      filterDefaultValues={{
        periodo: {
          start: new Date(),
          end: new Date(),
        },
        cancelado: false,
      }}
      filter={{ devolucao: false }}
    />
  </>
);

export default List2;
