import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import {
  SelectInput,
  TelefoneInput,
  TextInput,
  AutoCompleteRemoto,
} from '../..';
import {
  PIX_CPF_OPTION,
  PIX_TELEFONE_OPTION,
  TIPO_CONTATO_TELEFONE,
  STATUS_ATIVO,
  PRIMARY_COLOR,
  formatCPF,
  formatCNPJ,
  REGIME_TRIBUTARIO_MEI,
  REGIME_TRIBUTARIO_SIMPLES_NACIONAL,
  REGIME_TRIBUTARIO_LUCRO_PRESUMIDO,
  REGIME_TRIBUTARIO_LUCRO_REAL,
  REGIME_TRIBUTARIO_PRODUTOR_RURAL,
  REGIME_TRIBUTARIO_SEM_NFE,
} from '../../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const optionsRegimeTributario = [
  {
    value: REGIME_TRIBUTARIO_PRODUTOR_RURAL,
    label: 'Produtor Rural',
  },
  {
    value: REGIME_TRIBUTARIO_MEI,
    label: 'MEI (Micro Empreendedor Individual)',
  },
  {
    value: REGIME_TRIBUTARIO_SIMPLES_NACIONAL,
    label: 'SIMPLES Nacional',
  },
  {
    value: REGIME_TRIBUTARIO_LUCRO_PRESUMIDO,
    label: 'Lucro Presumido',
  },
  {
    value: REGIME_TRIBUTARIO_LUCRO_REAL,
    label: 'Lucro Real',
  },
  {
    value: REGIME_TRIBUTARIO_SEM_NFE,
    label: 'Sem NFE',
  },
];

function getRegimeTributario(value) {
  switch (value) {
    case REGIME_TRIBUTARIO_PRODUTOR_RURAL:
      return optionsRegimeTributario[0].label;
    case REGIME_TRIBUTARIO_MEI:
      return optionsRegimeTributario[1].label;
    case REGIME_TRIBUTARIO_SIMPLES_NACIONAL:
      return optionsRegimeTributario[2].label;
    case REGIME_TRIBUTARIO_LUCRO_PRESUMIDO:
      return optionsRegimeTributario[3].label;
    case REGIME_TRIBUTARIO_LUCRO_REAL:
      return optionsRegimeTributario[4].label;
    case REGIME_TRIBUTARIO_SEM_NFE:
      return optionsRegimeTributario[5].label;
    default:
      return null;
  }
}

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(
  ({ handleClose, handleSave, fornecedores }, ref) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [id, setId] = useState(DEFAULT_ID);
    const [fornecedor, setFornecedor] = useState(null);
    const [pasta, setPasta] = useState(null);

    const classes = useStyles({ cor: '#009FD4' });

    const handleCloseDialog = () => {
      setOpen(false);
      handleClose();
    };

    async function reiniciar() {
      setId(DEFAULT_ID);
      setFornecedor(null);
      setPasta(null);
      setOpen(true);
    }

    function getDados() {
      return {
        id,
        razao_social: fornecedor ? fornecedor.razao_social : '-',
        nome_fantasia: fornecedor ? fornecedor.nome_fantasia : '-',
        cnpj: fornecedor ? fornecedor.cnpj : '-',
        nomePasta: pasta ? pasta.nome : '-',
        pasta_id: pasta ? pasta.id : null,
        fornecedor_id: fornecedor ? fornecedor.id : null,
      };
    }

    async function handleSalvar() {
      try {
        const {
          id,
          razao_social,
          nome_fantasia,
          cnpj,
          nomePasta,
          pasta_id,
          fornecedor_id,
        } = getDados();
        if (id <= 0) {
          handleSave(
            id,
            razao_social,
            nome_fantasia,
            cnpj,
            nomePasta,
            pasta_id,
            fornecedor_id,
          );
          enqueueSnackbar('Fornecedor adicionado com sucesso!', {
            variant: 'success',
          });
        } else {
          handleSave(
            id,
            razao_social,
            nome_fantasia,
            cnpj,
            nomePasta,
            pasta_id,
            fornecedor_id,
          );
          enqueueSnackbar('Fornecedor editado com sucesso!', {
            variant: 'success',
          });
        }
        handleCloseDialog();
      } catch (erros) {
        enqueueSnackbar(`${erros}`, {
          variant: 'error',
        });
        handleCloseDialog();
      }
    }

    useImperativeHandle(ref, () => ({
      handleCreate() {
        reiniciar();
        setOpen(true);
      },
      handleEdit(id, fornecedor, pasta) {
        setId(id);
        setFornecedor(fornecedor);
        setPasta(pasta);
        setOpen(true);
      },
    }));

    function validateFornecedor() {
      let error = '';

      if (!validateFornecedor) {
        error = 'Este campo é obrigatório';
      }

      return error;
    }

    function getErros() {
      const errosOld = [''];
      errosOld[0] = validateFornecedor();
      return errosOld;
    }

    const erros = getErros();

    function hasError() {
      for (let i = 0; i < erros.length; i += 1) {
        if (erros[i] !== '') return true;
      }
      return false;
    }

    const erroExistente = hasError();

    function gerLabelPrazosFornecedor(fornecedor) {
      let prazoLabel = 'Nenhum prazo cadastrado!';
      if (fornecedor) {
        if (fornecedor.prazosFornecedores) {
          if (fornecedor.prazosFornecedores.length > 0) {
            prazoLabel = fornecedor.prazosFornecedores
              .sort((a, b) => a.dias - b.dias)
              .map((item) => item.dias)
              .join(', ');
          }
        }
      }
      return prazoLabel;
    }

    const prazoLabel = gerLabelPrazosFornecedor(fornecedor);

    const idsFornecedores = [
      1,
      2,
      ...fornecedores.map((item) => item.fornecedor_id),
    ];

    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.rootDialogo }}
          className={classes.rootDialogo}
          fullWidth
          maxWidth="lg"
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={handleCloseDialog}
            style={{ backgroundColor: '#009FD4', color: 'white' }}
          >
            {id < 0 ? 'Adicionar Fornecedor' : 'Editar Fornecedor'}
          </DialogTitle>
          <DialogContent
            style={{
              padding: '10px 36px 20px',
              margin: '0',
            }}
          >
            <div>
              <Box
                flex={1}
                //mr="0.5em"
                mt="20px"
                style={{
                  display: 'flex',
                }}
              >
                <Box
                  display="flex"
                  css={{
                    backgroundColor: 'white',
                    opacity: '0.75',
                    //height: '100%',
                    padding: '0px 10px',
                    flexDirection: 'column',
                    //width: '100%',
                    //backgroundClip: 'content-box, padding-box'
                  }}
                  flex={1}
                >
                  <fieldset
                    style={{
                      width: '100%',
                      display: 'flex',
                      //margin: '20px',
                      padding: '10px',
                      borderRadius: '5px',
                      flexDirection: 'column',
                    }}
                  >
                    <legend style={{ padding: '0 10px' }}>Fornecedor</legend>
                    <Box
                      display="flex"
                      alignItems="center"
                      flex={1}
                      style={{
                        gap: '10px',
                      }}
                    >
                      {/* <AutoCompleteRemoto
                      id="fornecedor"
                      name="fornecedor"
                      label="Responsável"
                      value={fornecedor}
                      onChange={(value) => {
                        setPasta(null);
                        setFornecedor(value);
                      }}
                      handleKey={(keyCode, keyName) => {}}
                      handleEnter={() => {}}
                      resource="fornecedores"
                      nested={['cidade.estado', 'prazosFornecedores', 'estado']}
                      toString={false}
                      style={{}}
                      fullwidth={false}
                      autoFocus
                      filters={{ status: STATUS_ATIVO, ids_excluso: idsFornecedores }}
                      getOptionSelected={(option, value) =>
                        option.nome === value.nome
                      }
                      getOptionLabel={(option) =>
                        `${option.nome}${
                          option.nome_fantasia
                            ? ` - (${option.nome_fantasia})`
                            : ''
                        }`
                      }
                    /> */}
                      <AutoCompleteRemoto
                        id="fornecedor"
                        name="fornecedor"
                        label="Nome Fantasia"
                        value={fornecedor}
                        onChange={(value) => {
                          setPasta(null);
                          setFornecedor(value);
                        }}
                        handleKey={(keyCode, keyName) => {}}
                        handleEnter={() => {}}
                        resource="fornecedores"
                        campo="nome_fantasia"
                        nested={[
                          'cidade.estado',
                          'prazosFornecedores',
                          'estado',
                        ]}
                        toString={false}
                        style={{}}
                        fullwidth={false}
                        filters={{
                          status: STATUS_ATIVO,
                          ids_excluso: idsFornecedores,
                        }}
                        getOptionSelected={(option, value) =>
                          option.nome_fantasia === value.nome_fantasia
                        }
                        getOptionLabel={(option) =>
                          `${
                            option.nome_fantasia
                              ? `(${option.nome_fantasia})`
                              : ''
                          }`
                        }
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      flex={1}
                      style={{
                        gap: '10px',
                      }}
                    >
                      <AutoCompleteRemoto
                        id="fornecedor"
                        name="fornecedor"
                        label="Razão Social"
                        value={fornecedor}
                        onChange={(value) => {
                          setPasta(null);
                          setFornecedor(value);
                        }}
                        handleKey={(keyCode, keyName) => {}}
                        handleEnter={() => {}}
                        resource="fornecedores"
                        campo="razao_social"
                        nested={[
                          'cidade.estado',
                          'prazosFornecedores',
                          'estado',
                        ]}
                        toString={false}
                        style={{}}
                        fullwidth={false}
                        filters={{
                          status: STATUS_ATIVO,
                          ids_excluso: idsFornecedores,
                        }}
                        getOptionSelected={(option, value) =>
                          option.razao_social === value.razao_social
                        }
                        getOptionLabel={(option) =>
                          `${
                            option.razao_social
                              ? `(${option.razao_social})`
                              : ''
                          }`
                        }
                      />
                      {/* <AutoCompleteRemoto
                      id="fornecedor"
                      name="fornecedor"
                      label="CNPJ"
                      value={fornecedor}
                      onChange={(value) => {
                        setPasta(null);
                        setFornecedor(value);
                      }}
                      handleKey={(keyCode, keyName) => {}}
                      handleEnter={() => {}}
                      resource="fornecedores"
                      campo="cnpj"
                      nested={['cidade.estado', 'prazosFornecedores', 'estado']}
                      campoPesquisa="cnpj"
                      toString={false}
                      style={{}}
                      fullwidth={false}
                      filters={{ status: STATUS_ATIVO, ids_excluso: idsFornecedores }}
                      getOptionSelected={(option, value) =>
                        option.cnpj === value.cnpj
                      }
                      getOptionLabel={(option) =>
                        `${option.cnpj ? `(${option.cnpj})` : ''}`
                      }
                    /> */}
                    </Box>

                    {fornecedor && (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        flex={1}
                        style={{
                          //border: `1px solid ${PRIMARY_COLOR}`,
                          marginLeft: '0px',
                          padding: '5px',
                          borderRadius: '5px',
                          marginBottom: '10px',
                        }}
                      >
                        <Box
                          marginLeft="10px"
                          display="flex"
                          flexDirection="column"
                          flex={1}
                        >
                          <Box
                            marginLeft="0px"
                            fontSize="15px"
                            fontWeight="bold"
                            display="flex"
                            flex={1}
                          >
                            <p
                              className={classes.textOverflow}
                              style={{
                                marginBottom: '0px',
                                color: PRIMARY_COLOR,
                                marginRight: '10px',
                              }}
                            >
                              Responsável:
                            </p>
                            {fornecedor.nome}
                          </Box>
                          <Box
                            marginLeft="0px"
                            fontSize="15px"
                            fontWeight="bold"
                            display="flex"
                            flex={1}
                          >
                            <p
                              className={classes.textOverflow}
                              style={{
                                marginBottom: '0px',
                                color: PRIMARY_COLOR,
                                marginRight: '10px',
                              }}
                            >
                              Razão Social:
                            </p>
                            {fornecedor.razao_social}
                          </Box>
                          <Box
                            marginLeft="0px"
                            fontSize="15px"
                            fontWeight="bold"
                            display="flex"
                            flex={1}
                          >
                            <p
                              className={classes.textOverflow}
                              style={{
                                marginBottom: '0px',
                                color: PRIMARY_COLOR,
                                marginRight: '10px',
                              }}
                            >
                              CPF:
                            </p>
                            {formatCPF(fornecedor.cpf)}
                          </Box>
                          <Box
                            marginLeft="0px"
                            fontSize="15px"
                            fontWeight="bold"
                            display="flex"
                            flex={1}
                          >
                            <p
                              className={classes.textOverflow}
                              style={{
                                marginBottom: '0px',
                                color: PRIMARY_COLOR,
                                marginRight: '10px',
                              }}
                            >
                              CNPJ:
                            </p>
                            {formatCNPJ(fornecedor.cnpj)}
                          </Box>
                          <Box
                            marginLeft="0px"
                            fontSize="15px"
                            fontWeight="bold"
                            display="flex"
                            flex={1}
                          >
                            <p
                              className={classes.textOverflow}
                              style={{
                                marginBottom: '0px',
                                color: PRIMARY_COLOR,
                                marginRight: '10px',
                              }}
                            >
                              Regime Tributário:
                            </p>
                            {getRegimeTributario(fornecedor.regime_tributario)}
                          </Box>
                        </Box>
                        <Box
                          marginLeft="10px"
                          display="flex"
                          flexDirection="column"
                          flex={1}
                        >
                          <Box
                            marginLeft="0px"
                            fontSize="15px"
                            fontWeight="bold"
                            display="flex"
                            flex={1}
                            justifyContent="flex-start"
                          >
                            <p
                              className={classes.textOverflow}
                              style={{
                                marginBottom: '0px',
                                color: PRIMARY_COLOR,
                                marginRight: '10px',
                              }}
                            >
                              Nome Fantasia:
                            </p>
                            {fornecedor.nome_fantasia}
                          </Box>

                          <Box
                            marginLeft="0px"
                            fontSize="15px"
                            fontWeight="bold"
                            display="flex"
                            flex={1}
                            justifyContent="flex-start"
                          >
                            <p
                              className={classes.textOverflow}
                              style={{
                                marginBottom: '0px',
                                color: PRIMARY_COLOR,
                                marginRight: '10px',
                              }}
                            >
                              Estado da Compra:
                            </p>
                            {fornecedor.estado
                              ? `${fornecedor.estado.nome} (${fornecedor.estado.sigla})`
                              : ''}
                          </Box>
                          <Box
                            marginLeft="0px"
                            fontSize="15px"
                            fontWeight="bold"
                            display="flex"
                            flex={1}
                            justifyContent="flex-start"
                          >
                            <p
                              className={classes.textOverflow}
                              style={{
                                marginBottom: '0px',
                                color: PRIMARY_COLOR,
                                marginRight: '10px',
                              }}
                            >
                              Prazos:
                            </p>
                            {prazoLabel}
                          </Box>
                          <Box
                            marginLeft="0px"
                            fontSize="15px"
                            fontWeight="bold"
                            display="flex"
                            flex={1}
                            justifyContent="flex-start"
                          >
                            <p
                              className={classes.textOverflow}
                              style={{
                                marginBottom: '0px',
                                color: PRIMARY_COLOR,
                                marginRight: '10px',
                              }}
                            >
                              ICMS de Compra:
                            </p>
                            {fornecedor.estado
                              ? `${fornecedor.estado.icms_ai_compra}%`
                              : ''}
                          </Box>
                          <Box
                            marginLeft="0px"
                            fontSize="15px"
                            fontWeight="bold"
                            display="flex"
                            flex={1}
                            justifyContent="flex-start"
                          />
                        </Box>
                      </Box>
                    )}
                  </fieldset>
                </Box>
                <Box flex={1}>
                  <fieldset
                    style={{
                      width: '100%',
                      display: 'flex',
                      //margin: '20px',
                      padding: '10px',
                      borderRadius: '5px',
                      flexDirection: 'column',
                    }}
                  >
                    <legend style={{ padding: '0 10px' }}>
                      Pastas do Fornecedor
                    </legend>
                    {fornecedor ? (
                      <AutoCompleteRemoto
                        id="pasta"
                        name="pasta"
                        label="Pasta"
                        value={pasta}
                        onChange={(value) => {
                          setPasta(value);
                        }}
                        handleKey={(keyCode, keyName) => {}}
                        handleEnter={() => {}}
                        resource="pastas-fornecedores"
                        nested={[]}
                        toString={false}
                        style={{}}
                        fullwidth={false}
                        autoFocus
                        filters={{
                          fornecedor_id: fornecedor ? fornecedor.id : null,
                        }}
                        getOptionSelected={(option, value) =>
                          option.nome === value.nome
                        }
                      />
                    ) : (
                      'SELECIONE O FRONECEDOR PRIMEIRO'
                    )}
                  </fieldset>
                </Box>
              </Box>
            </div>
          </DialogContent>
          <DialogActions
            style={{
              padding: '0px 36px 20px',
              margin: '0',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={handleCloseDialog}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              Fechar
            </Button>
            <div className={classes.wrapper}>
              <Button
                onClick={handleSalvar}
                classes={{
                  root: classes.btnAtions,
                  disabled: classes.btnAtionsDisabled,
                }}
                disabled={erroExistente}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

export default DialogoCategoriaTransacao;
