import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';

import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  getLabelTipo,
  getValorFormatado,
  getLabelTipoContato,
  getValorFormatadoContato,
} from '../../../Components/Dialogos/DialogoFornecedor/utils';
import { getListAllAPI, api, getUserId } from '../../../services';
import {
  formatTelefone,
  formatCNPJ,
  formatCPF,
  formatCEP,
  formatIE,
  SECONDARY_COLOR,
  formatMoeda,
} from '../../../utils';
import DicaPix from '../DialogoCreateEdit/DicaPix';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: SECONDARY_COLOR,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const DEFAULT_ID = -1;

function getTipo(type) {
  switch (type) {
    case 'conta_corrente':
      return 'Conta Corrente';
    case 'conta_poupanca':
      return 'Conta Poupança';
    case 'conta_pagamento':
      return 'Conta Pagamento';
    default:
      return '';
  }
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

async function getPermissaoAbaComprador() {
  try {
    const response = await api.get(`/obter-cargo-usuario/${getUserId()}`);
    const { status, cargo } = response.data;
    if (status) {
      return cargo.sistema_aba_comprador_no_fornecedor ? true : false;
    }
    return false;
  } catch (e) {
    return false;
  }
}

const SidebarInputs = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [id, setId] = useState(DEFAULT_ID);
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [telefone, setTelefone] = useState('');
  const [bairro, setBairro] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [rg, setRg] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [ie, setIe] = useState('');
  const [email, setEmail] = useState('');
  const [cep, setCep] = useState('');
  const [aniversario, setAniversario] = useState('');
  const [cidade, setCidade] = useState(null);
  const [tempoEntrega, setTempoEntrega] = useState(1);
  const [pixAleatoria, setPixAleatoria] = useState('');
  const [dadosPix, setDadosPix] = useState([]);

  const [isPixEmail, setIsPixEmail] = useState(false);
  const [isPixTelefone, setIsPixTelefone] = useState(false);
  const [isPixCpf, setIsPixCpf] = useState(false);
  const [isPixCnpj, setIsPixCnpj] = useState(false);
  const [isPixAleatoria, setIsPixAleatoria] = useState(false);

  const [contas, setContas] = useState([]);

  const [funcionarios, setFuncionarios] = useState([]);

  const [carregando, setCarregando] = useState(false);

  const [value, setValue] = React.useState(0);
  const [tipo, setTipo] = React.useState(1);

  const [freteValorPorTonelada, setFreteValorPorTonelada] = useState(0);

  const [contatos, setContatos] = useState([]);

  const [isComprador, setIsComprador] = useState(false);

  const [pastas, setPastas] = useState([]);

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  function getPastasIn(pastas) {
    const formatados = [];
    for (let i = 0; i < pastas.length; i++) {
      const pasta = pastas[i];
      formatados.push({
        id: i + 1,
        idIndicadorChange: pasta.id,
        nome: pasta.nome,
      });
    }

    return formatados;
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'fornecedores_todos_tipos',
        ['id', 'asc'],
        { id: [id] },
        [
          'cidade.estado',
          'dadosBancarios.banco',
          'funcionarios.funcao',
          'dadosPix',
          'contatos',
          'pastas',
        ],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setNome(data.data[0].nome);
        setCpf(data.data[0].cpf);
        setTelefone(data.data[0].telefone);
        setBairro(data.data[0].bairro);
        setLogradouro(data.data[0].logradouro);
        setNumero(data.data[0].numero);
        setRg(data.data[0].rg);
        setRazaoSocial(data.data[0].razao_social);
        setNomeFantasia(data.data[0].nome_fantasia);
        setCnpj(data.data[0].cnpj);
        setEmail(data.data[0].email);
        setCep(data.data[0].cep);
        setAniversario(
          moment(new Date(data.data[0].dataAniversario)).format('YYYY-MM-DD'),
        );
        setCidade(data.data[0].cidade);
        setIe(data.data[0].inscricao_estadual);
        setTempoEntrega(data.data[0].tempo_entrega);
        setIsPixEmail(!!data.data[0].is_pix_email);
        setIsPixTelefone(!!data.data[0].is_pix_telefone);
        setIsPixCpf(!!data.data[0].is_pix_cpf);
        setIsPixCnpj(!!data.data[0].is_pix_cnpj);
        setIsPixAleatoria(!!data.data[0].is_pix_aleatoria);
        setPixAleatoria(data.data[0].pix_aleatoria);
        setContas(data.data[0].dadosBancarios);
        setFuncionarios(data.data[0].funcionarios);
        setDadosPix(data.data[0].dadosPix);
        setValue(0);
        setTipo(data.data[0].tipo);
        setFreteValorPorTonelada(data.data[0].frete_valor_por_tonelada);
        setContatos(data.data[0].contatos ? data.data[0].contatos : []);
        setIsComprador(await getPermissaoAbaComprador());
        setPastas(data.data[0].pastas ? getPastasIn(data.data[0].pastas) : []);
        setCarregando(false);
      } else {
        throw 'Este fornecedor não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setOpen(true);
      setCarregando(true);
      setId(id);
      getDataResource(id);
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function getLabelTipoFornecedor() {
    switch (tipo) {
      case 1:
        return 'Fornecedor Mercadorias';
      case 2:
        return 'Fornecedor Insumos';
      case 3:
        return 'Fornecedor Serviços';
      case 4:
        return 'Outro Contato';
      default:
        return '';
    }
  }

  const label = getLabelTipoFornecedor();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle id="form-dialog-title">
        {`Detalhes ${label} ${nome}`}
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ height: '100%' }}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            flexDirection="column"
            marginBottom="30px"
            height="100%"
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Box p="1em">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label="Geral" {...a11yProps(0)} />
                  <Tab label="Contas" {...a11yProps(1)} />
                  <Tab label="Funcionários" {...a11yProps(2)} />
                  <Tab label="Pix" {...a11yProps(3)} />
                  <Tab label="Prazos" {...a11yProps(4)} />
                  {isComprador ? (
                    <Tab label="Contatos" {...a11yProps(5)} />
                  ) : null}
                  {isComprador ? (
                    <Tab label="Pastas" {...a11yProps(6)} />
                  ) : null}
                </Tabs>
                <Box display="flex">
                  <Box flex={2} mr="1em">
                    <TabPanel value={value} index={0}>
                      <Box mt="1em" />
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{ margin: '30px 0', color: SECONDARY_COLOR }}
                      >
                        Dados Pessoais
                      </Typography>
                      <Box mt="1em" />
                      <Box display="flex" alignItems="center">
                        <Box flex={1} mr="0.5em" mb="10px">
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Nome:
                          </span>
                          {`${nome || ''}`}
                        </Box>
                        <Box
                          flex={1}
                          ml="0.5em"
                          display="flex"
                          alignItems="center"
                          mb="10px"
                        >
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            CPF:{' '}
                          </span>
                          {`${formatCPF(cpf)}`}
                          {/* {cpf && (
                            <Box ml="5px">
                              <DicaPix
                                title="Pix CPF"
                                value={isPixCpf}
                                onChange={setIsPixCpf}
                              />
                            </Box>
                          )} */}
                        </Box>
                      </Box>
                      <Box display="flex">
                        <Box
                          flex={1}
                          mr="0.5em"
                          display="flex"
                          alignItems="center"
                          mb="10px"
                        >
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Telefone:{' '}
                          </span>
                          {`${telefone ? formatTelefone(telefone) : ''}`}
                          {/* {telefone && (
                            <Box ml="5px">
                              <DicaPix
                                title="Pix Telefone"
                                value={isPixTelefone}
                                onChange={setIsPixTelefone}
                              />
                            </Box>
                          )} */}
                        </Box>
                        <Box
                          flex={1}
                          ml="0.5em"
                          display="flex"
                          alignItems="center"
                          mb="10px"
                        >
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Email:{' '}
                          </span>
                          {`${email || ''}`}
                          {/* {email && (
                            <Box ml="5px">
                              <DicaPix
                                title="Pix Email"
                                value={isPixEmail}
                                onChange={setIsPixEmail}
                              />
                            </Box>
                          )} */}
                        </Box>
                      </Box>
                      <Box display="flex">
                        <Box flex={1} mr="0.5em" mb="10px">
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Aniversário:{' '}
                          </span>
                          {`${razaoSocial || ''}`}
                        </Box>
                        <Box flex={1} ml="0.5em" mb="10px">
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            RG:{' '}
                          </span>
                          {`${rg || ''}`}
                        </Box>
                      </Box>
                      <Box mt="1em" />
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{ margin: '30px 0', color: SECONDARY_COLOR }}
                      >
                        Dados Empresariais
                      </Typography>
                      <Box mt="1em" />
                      <Box display="flex">
                        <Box flex={1} mr="0.5em" mb="10px">
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Razão Social:{' '}
                          </span>
                          {`${razaoSocial || ''}`}
                        </Box>
                        <Box flex={1} ml="0.5em" mb="10px">
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Nome Fantasia:{' '}
                          </span>
                          {`${nomeFantasia || ''}`}
                        </Box>
                      </Box>
                      <Box display="flex">
                        <Box
                          flex={1}
                          mr="0.5em"
                          display="flex"
                          alignItems="center"
                          mb="10px"
                        >
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            cnpj:{' '}
                          </span>
                          {`${cnpj ? formatCNPJ(cnpj) : ''}`}
                          {/* {cnpj && (
                            <Box ml="5px">
                              <DicaPix
                                title="Pix CNPJ"
                                value={isPixCnpj}
                                onChange={setIsPixCnpj}
                              />
                            </Box>
                          )} */}
                        </Box>
                        <Box flex={1} ml="0.5em" mb="10px">
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            IE:{' '}
                          </span>
                          {`${ie ? formatIE(ie) : ''}`}
                        </Box>
                      </Box>
                      <Box mt="1em" />
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{ margin: '30px 0', color: SECONDARY_COLOR }}
                      >
                        Endereço
                      </Typography>
                      <Box mt="1em" />
                      <Box display="flex">
                        <Box flex={1} mr="0.5em" mb="10px">
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Cidade:{' '}
                          </span>
                          {`${cidade ? cidade.nome : ''}`}
                        </Box>
                        <Box flex={1} ml="0.5em" mb="10px">
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Bairro:{' '}
                          </span>
                          {`${bairro || ''}`}
                        </Box>
                      </Box>
                      <Box display="flex">
                        <Box flex={1} mr="0.5em" mb="10px">
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Logradouro:{' '}
                          </span>
                          {`${logradouro || ''}`}
                        </Box>
                        <Box flex={1} ml="0.5em" mb="10px">
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Nº:{' '}
                          </span>
                          {`${numero || ''}`}
                        </Box>
                      </Box>
                      <Box display="flex">
                        <Box flex={1} mr="0.5em" mb="10px">
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            CEP:{' '}
                          </span>
                          {`${cep ? formatCEP(cep) : ''}`}
                        </Box>
                        <Box flex={1} ml="0.5em" mb="10px" />
                      </Box>
                      <Box mt="1em" />
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{ margin: '30px 0', color: SECONDARY_COLOR }}
                      >
                        Dados Logísticos
                      </Typography>
                      <Box mt="1em" />
                      <Box display="flex">
                        <Box flex={1} mr="0.5em" mb="10px">
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Tempo de Entrega (dias):{' '}
                          </span>
                          {`${tempoEntrega}`}
                        </Box>
                        <Box flex={1} mr="0.5em" mb="10px">
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Frete Valor por Tonelada
                          </span>
                          {`${formatMoeda(freteValorPorTonelada)}`}
                        </Box>
                        {/* <Box
                          flex={1}
                          ml="0.5em"
                          display="flex"
                          alignItems="center"
                          mb="10px"
                        >
                          <span
                            style={{ fontWeight: 'bold', marginRight: '10px' }}
                          >
                            Pix Aleatória:{' '}
                          </span>
                          {`${pixAleatoria || ''}`}
                          {pixAleatoria && (
                            <Box ml="5px">
                              <DicaPix
                                title="Pix Chave Aleatória"
                                value
                                onChange={() => {}}
                              />
                            </Box>
                          )}
                        </Box> */}
                      </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Titular</StyledTableCell>
                              <StyledTableCell>Banco</StyledTableCell>
                              <StyledTableCell>Conta</StyledTableCell>
                              <StyledTableCell>Agência</StyledTableCell>
                              <StyledTableCell>Tipo</StyledTableCell>
                              <StyledTableCell>CPF</StyledTableCell>
                              <StyledTableCell>CNPJ</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {contas.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.titular}</StyledTableCell>
                                <StyledTableCell>
                                  {row.banco.nome}
                                </StyledTableCell>
                                <StyledTableCell>{row.conta}</StyledTableCell>
                                <StyledTableCell>{row.agencia}</StyledTableCell>
                                <StyledTableCell>
                                  {getTipo(row.tipo)}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {formatCPF(row.cpf)}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {formatCNPJ(row.cnpj)}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Nome</StyledTableCell>
                              <StyledTableCell>Função</StyledTableCell>
                              <StyledTableCell>Telefone</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {funcionarios.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>{row.nome}</StyledTableCell>
                                <StyledTableCell>
                                  {row.funcao.nome}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {formatTelefone(row.telefone)}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <Box mt="1em" />
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Tipo</StyledTableCell>
                              <StyledTableCell>Chave</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dadosPix.map((row) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                  {getLabelTipo(row.tipo)}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {getValorFormatado(row.valor, row.tipo)}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    {isComprador ? (
                      <TabPanel value={value} index={5}>
                        <Box mt="2em" />
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Tipo</StyledTableCell>
                                <StyledTableCell>Contato</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {contatos.map((row) => (
                                <StyledTableRow key={row.id}>
                                  <StyledTableCell>
                                    {getLabelTipoContato(row.tipo)}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {getValorFormatadoContato(
                                      row.valor,
                                      row.tipo,
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                    ) : null}
                    {isComprador ? (
                      <TabPanel value={value} index={6}>
                        <Box mt="2em" />
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Descrição</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {pastas.map((row) => (
                                <StyledTableRow key={row.id}>
                                  <StyledTableCell>{row.nome}</StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                    ) : null}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.wrapper}>
          <Button
            color="primary"
            onClick={() => {
              handleCloseDialog();
            }}
            disabled={carregando}
            classes={{
              root: classes.btnAtions,
              disabled: classes.btnAtionsDisabled,
            }}
          >
            Fechar
          </Button>
          {carregando && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
});

export default SidebarInputs;
