import React, { useRef } from 'react';
import {
  TextField,
  ReferenceField,
  Button,
  Link,
  RefreshButton,
  TabbedShowLayout,
  Tab,
  DateField,
  ReferenceManyField,
  NumberField,
  TabbedShowLayoutTabs,
  ShowButton,
  Pagination,
  ListButton,
  Datagrid,
  useRefresh,
} from 'react-admin';
import { useHistory } from 'react-router-dom';

import { useMediaQuery, Box, makeStyles } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import { withStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AssignmentIcon from '@material-ui/icons/Assignment';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  DialogoTransacao,
  DialogoChangeStatusTransacao,
  DialogoDeletarTransacao,
  AutoCompleteLocalCustomizado,
} from '../../Components';
// import Datagrid from '../../Components/Datagrid';
import Datagrid2 from '../../Components/Datagrid/index2';
import DialogoDeleteResource from '../../Components/DialogoDeleteResource';
import DialogoRelatorio from '../../Components/DialogoRelatorio';
import EditButton from '../../Components/EditButton';
import FabButtonNewResource from '../../Components/FabButtonNewResource';
import ShowTemplate from '../../Components/Show';
import {
  getUserId,
  getListAllAPI,
  api,
  imprimirNotaRetirada,
} from '../../services';
import {
  formatMoeda,
  MALOTE_TROCO_OPTION,
  MALOTE_SUBMALOTE_OPTION,
} from '../../utils';
import DialogoEditDevolucao from '../Devolucoes/ContainerDialogoDevolucao';
import DialogoEditVenda from '../Vendas/ContainerDialogoVenda';
import DialogoShowVenda from '../Vendas/DialogoShow';
import DialogoShowVendasNaoFinalizadas from '../VendasNaoFinalizadas/DialogoShow';
import DialogoContadorCedulas from './DialogoContadorCedulas';
import DialogoTrocoEntrada from './DialogoTrocoEntrada';
import ActionsField from './Fields/ActionsField';
import ActionsField2 from './Fields/ActionsField2';
import CategoriaField from './Fields/CategoriaField2';
import CodigoField from './Fields/CodigoDescarregoField';
import ContaField from './Fields/ContaField2';
import ContaField3 from './Fields/ContaField3';
import DescricaoField from './Fields/DescricaoField';
import ReferenceFieldCategoria from './Fields/ReferenceFieldCategoria';
import StatusField from './Fields/StatusField';
import ValorField from './Fields/ValorField';

const styles = {
  button: {
    marginTop: '1em',
  },
};

/* const Title = ({ record }) => (
  <span>
    {record
      ? ` Sessão ${moment(record.abertura).format('DD/MM/YYYY HH:mm')} - ${
          record.fechamento
            ? moment(record.fechamento).format('DD/MM/YYYY HH:mm')
            : 'em aberto'
        }`
      : ''}
  </span>
); */

const Title = ({ record }) => (
  <span>{record ? ` Sessão id = ${record.id}` : ''}</span>
);

const AddNewVendaButton = withStyles(styles)(({ classes, record }) => (
  <Button
    component={Link}
    className={classes.button}
    to={{
      pathname: '/vendas/create',
      search: `?sessao_id=${record.id}`,
    }}
    label="Novo Item"
  >
    <AddBoxIcon />
  </Button>
));

const AddNewDevolucaoButton = withStyles(styles)(({ classes, record }) => (
  <Button
    component={Link}
    className={classes.button}
    to={{
      pathname: '/devolucoes/create',
      search: `?sessao_id=${record.id}`,
    }}
    label="Novo Item"
  >
    <AddBoxIcon />
  </Button>
));

const AddNewEntradaButton = withStyles(styles)(({ classes, record }) => (
  <Button
    component={Link}
    className={classes.button}
    to={{
      pathname: '/entradascaixa/create',
      search: `?sessao_id=${record.id}`,
    }}
    label="Novo Item"
  >
    <AddBoxIcon />
  </Button>
));

const AddNewSaidaButton = withStyles(styles)(({ classes, record }) => (
  <Button
    component={Link}
    className={classes.button}
    to={{
      pathname: '/saidascaixa/create',
      search: `?sessao_id=${record.id}`,
    }}
    label="Novo Item"
  >
    <AddBoxIcon />
  </Button>
));

const AddNewTransferenciaButton = withStyles(styles)(({ classes, record }) => (
  <Button
    component={Link}
    className={classes.button}
    to={{
      pathname: '/retiradascaixa/create',
      search: `?sessao_id=${record.id}`,
    }}
    label="Novo Item"
  >
    <AddBoxIcon />
  </Button>
));

const Actions = ({ basePath, data }) => {
  const refDialogoRelatorio = useRef(null);
  const refDialogoTrocoEntrada = useRef(null);
  const refDialogoContadorCedulas = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  function openNota() {
    refDialogoRelatorio.current.handleOpen();
  }

  function criarRetirada() {
    refDialogoContadorCedulas.current.handleOpen();
  }

  function carregarDeposito() {
    refDialogoTrocoEntrada.current.handleOpen();
  }

  const isFechado = data ? !data.is_fechamento : false;

  function getData(fechamento) {
    const data1 = new Date(fechamento);
    const time1 = data1.getTime() - 10 * 1000;
    const data2 = new Date();
    data2.setTime(time1);
    return data2;
  }

  async function handleTrocoEntrada(codigo) {
    try {
      const data2 = await getListAllAPI(
        'malote_pacotes',
        ['id', 'asc'],
        { codigo },
        [],
      );
      if (data2.data.length > 0) {
        if (
          data2.data.tipo === MALOTE_TROCO_OPTION ||
          data2.data.tipo === MALOTE_SUBMALOTE_OPTION
        ) {
          throw 'Não existe um troco ou submalote com este código!';
        }

        const data_pagamento = data.fechamento
          ? getData(data.fechamento)
          : new Date();

        const troco = await api.post('/malote_sessoes/deposito', {
          user_id: getUserId(),
          troco_id: data2.data[0].id,
          sessao_id: data.id,
          conta_id: data.caixa_id,
          data_pagamento,
        });
        const codigo = await imprimirNotaRetirada(troco.data.idTroco);
        if (codigo < 0) {
          enqueueSnackbar('Depósito feito com sucesso!', {
            variant: 'success',
          });
        }
        if (codigo < 0) {
          enqueueSnackbar('Erro ao imprimir depósito!', {
            variant: 'error',
          });
        }
      } else {
        enqueueSnackbar('Este troco não existe ou já foi usado!', {
          variant: 'error',
        });
      }
    } catch (erros) {
      enqueueSnackbar('Este troco ou submalote não existe ou já foi usado!', {
        variant: 'error',
      });
    }
  }

  async function novaRetirada(valor, objCedulas) {
    try {
      const data_pagamento = data.fechamento
        ? getData(data.fechamento)
        : new Date();

      data_pagamento.setTime(data_pagamento.getTime() - 5 * 1000);

      const transferencia = await api.post('/malote_sessoes/retirada', {
        nome: '',
        valor,
        sessao_id: data.id,
        //data_pagamento: new Date(),
        conta_id: data.caixa_id,
        categoria_transacao_2_id: null,
        status: true,
        tipo: 'Transferência',
        conta_destino_id: 1,
        repetir: false,
        vezesRepetir: 1,
        tipoPeriodo: 'Mensal',
        notas: objCedulas,
        responsavel_id: getUserId(),
        data_pagamento,
      });
      const codigo = await imprimirNotaRetirada(transferencia.data.id);
      if (codigo < 0) {
        enqueueSnackbar('Erro ao imprimir a nota da retirada!', {
          variant: 'error',
        });
      }
    } catch (erros) {
      enqueueSnackbar('Erro ao registrar a retirada', {
        variant: 'error',
      });
    }
  }

  return (
    <CardActions>
      <RefreshButton />
      {/* <EditButton basePath={basePath} record={data} /> */}
      <Button onClick={openNota} label="Relatório">
        <AssignmentIcon />
      </Button>
      {isFechado && (
        <Button onClick={criarRetirada} label="Criar Retirada">
          <AssignmentIcon />
        </Button>
      )}
      {isFechado && (
        <Button onClick={carregarDeposito} label="Carregar Depósito">
          <AssignmentIcon />
        </Button>
      )}
      {data && (
        <DialogoRelatorio
          ref={refDialogoRelatorio}
          sessaoId={data.id}
          handleClose={() => {}}
        />
      )}
      {data && (
        <DialogoContadorCedulas
          ref={refDialogoContadorCedulas}
          handleClose={() => {}}
          handleSalvar={novaRetirada}
          getTitle={(total) => `Nova retirada, Total = ${formatMoeda(total)}`}
        />
      )}
      {data && (
        <DialogoTrocoEntrada
          ref={refDialogoTrocoEntrada}
          handleSalvar={handleTrocoEntrada}
        />
      )}
    </CardActions>
  );
};

const useStylesDatagrid = makeStyles((theme) => ({
  statusHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    paddingLeft: '30px',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusCell: {
    // marginLeft: '30px',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingLeft: '30px',
  },
  statusContent: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 5,
    marginLeft: '30px',
    maxWidth: '100px',
  },
  dataHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  actionsHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
  },
  actionsCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
  },
}));

const Show = (props) => {
  const refresh = useRefresh();
  const refDialogoDeleteResource = useRef(null);
  const refDialogoTransacao = useRef(null);
  const refDialogoDeletar = useRef(null);
  //
  const refDialogoEditVenda = useRef(null);
  const refDialogoShowVenda = useRef(null);
  //
  const refDialogoShowVendaExcluida = useRef(null);
  //
  const refDialogoEditDevolucao = useRef(null);
  //const refDialogoShowVenda = useRef(null);
  //

  const classes = useStylesDatagrid();

  /*function deleteHundle(record) {
    if (refDialogoDeleteResource.current)
      refDialogoDeleteResource.current.handleOpen(record);
  }
  const edit = (data) => {
    if (refDialogoTransacao.current) {
      refDialogoTransacao.current.handleEdit(data.record.id);
    }
  };
  const deletar = (data) => {
    if (refDialogoDeletar.current) {
      refDialogoDeletar.current.handleOpen(data.record.id);
    }
  };*/

  function editVenda(data) {
    if (refDialogoEditVenda.current) {
      refDialogoEditVenda.current.handleEdit(data.record.id);
    }
  }

  function showVenda(data) {
    if (refDialogoShowVenda.current) {
      refDialogoShowVenda.current.handleOpen(data.record.id);
    }
  }

  function showVendaExcluida(data) {
    if (refDialogoShowVendaExcluida.current) {
      refDialogoShowVendaExcluida.current.handleOpen(data.record.id);
    }
  }

  function editDevolucao(data) {
    if (refDialogoEditDevolucao.current) {
      refDialogoEditDevolucao.current.handleEdit(data.record.id);
    }
  }

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <ShowTemplate
        {...props}
        actions={<Actions />}
        title={<Title />}
        isBack
        // routeBack={`/caixas/${getCaixaAtualNavigate()}/show/1`}
        routeBack="/sessoes"
      >
        <TabbedShowLayout
          tabs={
            <TabbedShowLayoutTabs variant="fullWidth" centered {...props} />
          }
        >
          {/* <Tab label="Geral">
          <ReferenceField
            source="user_id"
            reference="users"
            label="users"
            allowEmpty={false}
            sortable={false}
            linkType={false}
          >
            <TextField source="username" />
          </ReferenceField>
          <NumberField
            source="troco_inicial"
            label="Troco Inicial"
            locales="pt-BR"
            sortable={false}
            options={{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            }}
          />
          <NumberField
            source="saldo"
            label="Saldo"
            locales="pt-BR"
            sortable={false}
            options={{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            }}
          />
          <DateField source="abertura" label="Abertura" showTime />
          <DateField source="fechamento" label="Fechamento" showTime />
        </Tab> */}
          <Tab label="Vendas">
            <ReferenceManyField
              addLabel={false}
              reference="vendas"
              target="sessao_id"
              pagination={<Pagination />}
              filter={{ devolucao: false }}
            >
              <Datagrid2 isButtonAtivo campoAtivo="cancelado" inverterAtivo>
                <TextField source="numero" label="Nº Venda" />
                <ReferenceField
                  label="Cliente"
                  source="cliente_id"
                  reference="clientes"
                  sortable={false}
                  link="show"
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <NumberField
                  source="valor"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor Total"
                />
                {/*<NumberField
                  source="valorEmDinheiro"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor em dinheiro"
                />*/}
                {/* <NumberField
              source="lucro"
              locales="pt-BR"
              options={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'BRL',
              }}
              label="Lucro"
            /> */}
                <DateField source="data" label="Data da Venda" showTime />
                {/*<EditButton query={`?sessao_id=${props.id}`} />
                <ShowButton />*/}
                <ActionsField2
                  source="lalala"
                  textAlign="right"
                  label="Ações"
                  //headerClassName={classes.actionsHeader}
                  //cellClassName={classes.actionsCell}
                  sortable={false}
                  handleDelete={() => {}}
                  handleEdit={editVenda}
                  handleShow={showVenda}
                  isDelete={false}
                  isEdit
                  isShow
                />
              </Datagrid2>
            </ReferenceManyField>
            {/* <FabButtonNewResource
            pathname="/vendas/create"
            search={(record) => `?sessao_id=${record.id}`}
          /> */}
          </Tab>
          <Tab label="Vendas Não Finalizadas">
            <ReferenceManyField
              addLabel={false}
              reference="vendas_excluidas"
              target="sessao_id"
              pagination={<Pagination />}
            >
              <Datagrid2>
                <ReferenceField
                  label="Cliente"
                  source="cliente_id"
                  reference="clientes"
                  sortable={false}
                  link="show"
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <NumberField
                  source="valor"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor Total"
                />
                <DateField source="data" label="Data da Venda" showTime />
                <ActionsField2
                  source="lalala"
                  textAlign="right"
                  label="Ações"
                  //headerClassName={classes.actionsHeader}
                  //cellClassName={classes.actionsCell}
                  sortable={false}
                  handleDelete={() => {}}
                  handleEdit={() => {}}
                  handleShow={showVendaExcluida}
                  isDelete={false}
                  isEdit={false}
                  isShow
                />
              </Datagrid2>
            </ReferenceManyField>
          </Tab>
          <Tab label="Devoluções">
            <ReferenceManyField
              addLabel={false}
              reference="devolucoes"
              target="sessao_id"
              pagination={<Pagination />}
              filter={{ devolucao: true }}
            >
              <Datagrid2 isButtonAtivo campoAtivo="cancelado" inverterAtivo>
                <TextField source="numero" label="Nº Venda" />
                <ReferenceField
                  label="Cliente"
                  source="cliente_id"
                  reference="clientes"
                  sortable={false}
                  link="show"
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <NumberField
                  source="valor"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor Total"
                />
                {/* <NumberField
              source="lucro"
              locales="pt-BR"
              options={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'BRL',
              }}
              label="Lucro"
            /> */}
                <DateField source="data" label="Data da Devolução" showTime />
                <EditButton query={`?sessao_id=${props.id}`} />
                <ShowButton />
                <ActionsField2
                  source="lalala"
                  textAlign="right"
                  label="Ações"
                  //headerClassName={classes.actionsHeader}
                  //cellClassName={classes.actionsCell}
                  sortable={false}
                  handleDelete={() => {}}
                  handleEdit={editDevolucao}
                  handleShow={() => {}}
                  isDelete={false}
                  isEdit
                  isShow
                />
              </Datagrid2>
            </ReferenceManyField>
            {/* <FabButtonNewResource
            pathname="/devolucoes/create"
            search={(record) => `?sessao_id=${record.id}`}
          /> */}
          </Tab>
          <Tab label="Retiradas">
            <ReferenceManyField
              addLabel={false}
              /* reference="entradascaixa"
            target="sessao_id" */
              reference="retiradas_sessoes"
              target="sessao_id"
              pagination={<Pagination />}
            >
              <Datagrid>
                <StatusField
                  source="status"
                  label="Status"
                  sortable={false}
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.statusCell}
                />
                <DateField
                  source="data_pagamento"
                  label="Data"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  showTime
                />
                {/* <DescricaoField
                source="descricao"
                label="Descrição"
                sortable={false}
                headerClassName={classes.descricaoHeader}
                cellClassName={classes.descricaoCell}
              /> */}
                <CategoriaField
                  label="Categoria"
                  source="categoria_transacao_2_id"
                  sortable={false}
                  headerClassName={classes.categoriaHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ContaField
                  label="De"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.categoriaHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ContaField3
                  label="Para"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.categoriaHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ValorField
                  source="valor"
                  textAlign="right"
                  label="Valor"
                  headerClassName={classes.valorHeader}
                  cellClassName={classes.valorCell}
                />
                <ActionsField
                  source="lalala"
                  textAlign="right"
                  label="Ações"
                  headerClassName={classes.actionsHeader}
                  cellClassName={classes.actionsCell}
                  tipo="Receita"
                  sortable={false}
                  handleDelete={() => {}}
                  handleEdit={() => {}}
                />
              </Datagrid>
            </ReferenceManyField>
            {/* <FabButtonNewResource
            pathname="/entradascaixa/create"
            search={(record) => `?sessao_id=${record.id}`}
          /> */}
          </Tab>
          <Tab label="Depósitos">
            <ReferenceManyField
              addLabel={false}
              /* reference="saidascaixa"
            target="sessao_id" */
              reference="depositos_sessoes"
              target="sessao_id"
              pagination={<Pagination />}
            >
              <Datagrid>
                <StatusField
                  source="status"
                  label="Status"
                  sortable={false}
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.statusCell}
                />
                <DateField
                  source="data_pagamento"
                  label="Data"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  showTime
                />
                {/* <DescricaoField
                source="descricao"
                label="Descrição"
                sortable={false}
                headerClassName={classes.descricaoHeader}
                cellClassName={classes.descricaoCell}
              /> */}
                <CategoriaField
                  label="Categoria"
                  source="categoria_transacao_2_id"
                  sortable={false}
                  headerClassName={classes.categoriaHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ContaField3
                  label="De"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.categoriaHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ContaField
                  label="Para"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.categoriaHeader}
                  cellClassName={classes.categoriaCell}
                />

                <ValorField
                  source="valor"
                  textAlign="right"
                  label="Valor"
                  headerClassName={classes.valorHeader}
                  cellClassName={classes.valorCell}
                />
                <ActionsField
                  source="lalala"
                  textAlign="right"
                  label="Ações"
                  headerClassName={classes.actionsHeader}
                  cellClassName={classes.actionsCell}
                  tipo="Receita"
                  sortable={false}
                  handleDelete={() => {}}
                  handleEdit={() => {}}
                />
              </Datagrid>
            </ReferenceManyField>
            {/* <FabButtonNewResource
            pathname="/saidascaixa/create"
            search={(record) => `?sessao_id=${record.id}`}
          /> */}
          </Tab>
          <Tab label="Pagamentos Descarrego">
            <ReferenceManyField
              addLabel={false}
              reference="descarrego_mercadorias"
              target="sessao_id"
              pagination={<Pagination />}
            >
              <Datagrid>
                <CodigoField
                  source="id"
                  label="Código"
                  sortable={false}
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.statusCell}
                />
                <DateField
                  source="data"
                  label="Data"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  showTime
                />
                <ValorField
                  source="valor"
                  textAlign="right"
                  label="Valor"
                  headerClassName={classes.valorHeader}
                  cellClassName={classes.valorCell}
                />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          <Tab label="Ajustes">
            <ReferenceManyField
              addLabel={false}
              /* reference="retiradascaixa"
            target="sessao_id" */
              reference="ajustes_sessoes"
              target="sessao_id"
              pagination={<Pagination />}
            >
              <Datagrid>
                <StatusField
                  source="status"
                  label="Status"
                  sortable={false}
                  headerClassName={classes.statusHeader}
                  cellClassName={classes.statusCell}
                />
                <DateField
                  source="data_pagamento"
                  label="Data"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.dataCell}
                  showTime
                />
                {/* <DescricaoField
                source="descricao"
                label="Descrição"
                sortable={false}
                headerClassName={classes.descricaoHeader}
                cellClassName={classes.descricaoCell}
              /> */}
                <CategoriaField
                  label="Categoria"
                  source="categoria_transacao_2_id"
                  sortable={false}
                  headerClassName={classes.categoriaHeader}
                  cellClassName={classes.categoriaCell}
                />
                <ContaField
                  label="Conta"
                  source="conta_id"
                  sortable={false}
                  headerClassName={classes.categoriaHeader}
                  cellClassName={classes.categoriaCell}
                />

                <ValorField
                  source="valor"
                  textAlign="right"
                  label="Valor"
                  headerClassName={classes.valorHeader}
                  cellClassName={classes.valorCell}
                />
                <ActionsField
                  source="lalala"
                  textAlign="right"
                  label="Ações"
                  headerClassName={classes.actionsHeader}
                  cellClassName={classes.actionsCell}
                  tipo="Receita"
                  sortable={false}
                  handleDelete={() => {}}
                  handleEdit={() => {}}
                />
              </Datagrid>
            </ReferenceManyField>
            {/* <FabButtonNewResource
            pathname="/retiradascaixa/create"
            search={(record) => `?sessao_id=${record.id}`}
          /> */}
          </Tab>
          {/* <Tab label="Pagamentos">
          <ReferenceManyField
            addLabel={false}
            reference="pagamentosemdinheiro"
            target="sessao_id"
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="venda.numero" label="Nº venda" />
              <TextField source="venda.cliente.nome" label="Cliente" />
              <TextField source="valor" label="Valor" />
              <ReferenceField
                label="Meio Pagamento"
                source="tipo_pagamento_id"
                reference="tipospagamento"
                sortable={false}
                linkType={false}
              >
                <TextField source="nome" />
              </ReferenceField>
              <DateField source="data_pagamento" label="Data" />
              <DateField source="data_pagamento_real" label="Data Real" />
              <ChangeStatusButton
                editAuthorization
                rotaChangeStatus="parcelasvenda"
                sessao_id={props.id}
              />
            </Datagrid>
          </ReferenceManyField>
          <AddNewPagamentoFiadoButton />
        </Tab> */}
          <DialogoDeleteResource ref={refDialogoDeleteResource} />
        </TabbedShowLayout>
      </ShowTemplate>
      <DialogoTransacao ref={refDialogoTransacao} handleClose={handleClose} />
      <DialogoDeletarTransacao
        ref={refDialogoDeletar}
        handleClose={handleClose}
      />

      <DialogoEditVenda ref={refDialogoEditVenda} handleClose={handleClose} />
      <DialogoShowVenda ref={refDialogoShowVenda} handleClose={handleClose} />
      <DialogoShowVendasNaoFinalizadas
        ref={refDialogoShowVendaExcluida}
        handleClose={handleClose}
      />
      <DialogoEditDevolucao
        ref={refDialogoEditDevolucao}
        handleClose={handleClose}
      />
    </>
  );
};

export default Show;
