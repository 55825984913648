import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
  TextField,
  ReferenceField,
  DateField,
  NumberField,
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  useListContext,
  ListContextProvider,
} from 'react-admin';

import { Tabs, Tab, Card } from '@material-ui/core';

import Datagrid from '../../Components/Datagrid';
import { imprimirNotaDescarregoMercadoria } from '../../services';
import Actions from '../Compras/ActionsList';
import ActionsField from './ActionsField';
import CodigoField from './CodigoField';
import Dialogo from './DialogoCreateEdit';
import DialogoDeletar from './DialogoDelete';
import DialogoShow from './DialogoShow';
import Filter from './Filter';
import PesoField from './PesoField';

const tabs = [
  { id: 'nao_pago', name: 'Não Pago' },
  { id: 'pago', name: 'Pago' },
];

const MyList = ({ ...props }) => {
  const refDialogo = useRef(null);
  const refDialogoDelete = useRef(null);
  const refDialogoShow = useRef(null);
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [confirmados, setConfirmados] = useState([]);
  const [cancelados, setCancelados] = useState([]);

  const refresh = useRefresh();

  function handleCriar() {
    if (refDialogo.current) {
      refDialogo.current.handleCreate();
    }
  }
  function handleEdit({ id }) {
    if (refDialogo.current) {
      refDialogo.current.handleEdit(id);
    }
  }
  function deleteHundle({ id }) {
    if (refDialogoDelete.current) refDialogoDelete.current.handleOpen(id);
  }

  async function handleImprimir(record) {
    await imprimirNotaDescarregoMercadoria(record.id);
  }

  function handleShow(record) {
    if (refDialogoShow.current) {
      refDialogoShow.current.handleOpen(record.id);
    }
  }

  function handleClose() {
    refresh();
  }

  useEffect(() => {
    if (ids && ids !== filterValues.pago) {
      switch (filterValues.pago) {
        case false:
          setConfirmados(ids);
          break;
        case true:
          setCancelados(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.pago]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          {
            ...filterValues,
            pago: value === 'pago',
          },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  return (
    <>
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleCriar={handleCriar} {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.pago ? tabs[1].id : tabs[0].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <div>
          {filterValues.pago === false && (
            <ListContextProvider value={{ ...listContext, ids: confirmados }}>
              <Datagrid {...props}>
                <CodigoField source="id" label="Código" />
                <ReferenceField
                  label="Fornecedor"
                  source="fornecedor_id"
                  reference="fornecedores"
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <NumberField
                  source="valor"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor Total"
                />
                <PesoField source="peso" label="Peso Total" />
                <DateField source="data" label="Data da Compra" />
                <ActionsField
                  source="lalala"
                  handleEdit={handleEdit}
                  handleShow={handleShow}
                  handleDelete={deleteHundle}
                  handleImprimir={handleImprimir}
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.pago === true && (
            <ListContextProvider value={{ ...listContext, ids: cancelados }}>
              <Datagrid {...props}>
                <CodigoField source="id" label="Código" />
                <ReferenceField
                  label="Fornecedor"
                  source="fornecedor_id"
                  reference="fornecedores"
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <NumberField
                  source="valor"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Valor Total"
                />
                <PesoField source="peso" label="Peso Total" />
                <DateField source="data" label="Data da Compra" />

                <ActionsField
                  source="lalala"
                  handleEdit={handleEdit}
                  handleShow={handleShow}
                  handleDelete={deleteHundle}
                  handleImprimir={handleImprimir}
                  textAlign="right"
                  label="Ações"
                  sortable={false}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <Dialogo ref={refDialogo} handleClose={handleClose} />
      <DialogoDeletar ref={refDialogoDelete} handleClose={handleClose} />

      <DialogoShow ref={refDialogoShow} handleClose={() => {}} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Descarrego de Mercadoria"
      filterDefaultValues={{
        pago: false,
      }}
    />
  </>
);

export default List2;
