import * as React from 'react';
import { useRecordContext } from 'react-admin';

import {
  formatMoeda,
  getPrecoUnitarioVerdadeiroCaixa2,
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_PESO_FIXO,
  TIPO_IMPRESSAO_UN,
} from '../../utils';

function getUnidade(produto) {
  if (produto.unidade.tipo === POR_UNIDADE) return 'Un';
  if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) return 'Kg';
  if (produto.unidade.tipo === POR_PESO_FIXO) {
    if (produto.tipo_impressao === TIPO_IMPRESSAO_UN) return 'Un';
    return 'Kg';
  }
  return '';
}

function getFator(produto) {
  if (produto.unidade.tipo === POR_UNIDADE) return 1;
  if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) return 1;
  if (produto.unidade.tipo === POR_PESO_FIXO) {
    if (produto.tipo_impressao === TIPO_IMPRESSAO_UN) return produto.peso_medio;
    return 1;
  }
  return '';
}

const ValorField = (props) => {
  const record = useRecordContext(props);
  const preco_venda = record
    ? record.record
      ? record.record.preco_venda
      : 0
    : 0;
  const preco_oferta = record
    ? record.record
      ? getPrecoUnitarioVerdadeiroCaixa2(record.record)
      : 0
    : 0;

  const fator = record ? (record.record ? getFator(record.record) : 1) : 1;
  const label_unidade = record
    ? record.record
      ? getUnidade(record.record)
      : ''
    : '';

  return (
    <>
      {preco_venda > preco_oferta ? (
        <div>
          <div
            style={{
              color: 'red',
              textDecoration: 'line-through',
              fontSize: '12px',
            }}
          >
            {`${formatMoeda(preco_venda * fator)} / ${label_unidade}`}
          </div>
          <div
            style={{
              backgroundColor: 'yellow',
              fontWeight: 'bold',
              fontSize: '15px',
            }}
          >
            {`${formatMoeda(preco_oferta * fator)} / ${label_unidade}`}
          </div>
        </div>
      ) : (
        <div>{`${formatMoeda(preco_venda * fator)} / ${label_unidade}`}</div>
      )}
    </>
  );
};

export default ValorField;
