import React, { useRef } from 'react';
import {
  TextField,
  ReferenceField,
  Button,
  Link,
  ListButton,
  RefreshButton,
  TabbedShowLayout,
  Tab,
  Show,
  DateField,
  ReferenceManyField,
  NumberField,
  TabbedShowLayoutTabs,
  Pagination,
  SimpleShowLayout,
  Show as ShowAdmin,
  useRefresh,
  Datagrid,
} from 'react-admin';

import { makeStyles, useMediaQuery, Box } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import { withStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AssignmentIcon from '@material-ui/icons/Assignment';

import logo from '../../assets/logo.png';
import {
  DialogoNota,
  DialogoTransacao,
  DialogoChangeStatusTransacao,
  DialogoDeletarTransacao,
  AutoCompleteLocalCustomizado,
} from '../../Components';
import Datagrid2 from '../../Components/Datagrid/index2';
import DialogoPagarParcela from '../../Components/DialogoPagarParcela';
import EditButton from '../../Components/EditButton';
import ShowTemplate from '../../Components/Show';
import { getListAllAPI } from '../../services';
import ActionsField from '../Compras/Fields/ActionsField';
import CategoriaField from '../Compras/Fields/CategoriaField';
import ContaField from '../Compras/Fields/ContaField';
import DescricaoField from '../Compras/Fields/DescricaoField';
import ReferenceFieldCategoria from '../Compras/Fields/ReferenceFieldCategoria';
import StatusField from '../Compras/Fields/StatusField';
import ValorField from '../Compras/Fields/ValorField';

const styles = {
  button: {
    marginTop: '1em',
  },
};

const Title = ({ record }) => (
  <span>Venda Nº: {record ? `"${record.numero}"` : ''}</span>
);

const AddNewItemVendaButton = withStyles(styles)(({ classes, record }) => (
  <Button
    component={Link}
    className={classes.button}
    to={{
      pathname: '/itensvenda/create',
      search: `?venda_id=${record.id}`,
    }}
    label="Novo Item"
  >
    <AddBoxIcon />
  </Button>
));

const AddNewParcelaButton = withStyles(styles)(({ classes, record }) => (
  <Button
    component={Link}
    className={classes.button}
    to={{
      pathname: '/parcelasvenda/create',
      search: `?venda_id=${record.id}`,
    }}
    label="Nova Parcela"
  >
    <AddBoxIcon />
  </Button>
));

const Actions = ({ basePath, data }) => {
  const refDialogoNota = useRef();
  function openNota() {
    if (refDialogoNota.current)
      refDialogoNota.current.handleOpen(data.id, 'venda');
  }
  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" justifyContent="flex-end">
        <CardActions>
          <ListButton basePath={basePath} />
          <RefreshButton />
          <Button onClick={openNota} label="Nota">
            <AssignmentIcon />
          </Button>
          <DialogoNota ref={refDialogoNota} logo={logo} />
        </CardActions>
      </Box>
    </Box>
  );
};

const useStylesDatagrid = makeStyles((theme) => ({
  statusHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    paddingLeft: '30px',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusCell: {
    // marginLeft: '30px',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingLeft: '30px',
  },
  statusContent: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 5,
    marginLeft: '30px',
    maxWidth: '100px',
  },
  dataHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaCell: {
    // marginLeft: '30px',
    // backgroundColor: 'blue',
    flex: 2,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  actionsHeader: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
  },
  actionsCell: {
    // marginLeft: '30px',
    // backgroundColor: 'red',
    flex: 1,
    // backgroundColor: '#f8f8f8',
    padding: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
  },
}));

const TransacaoPanel = ({
  handleEdit = () => {},
  handleDelete = () => {},
  handleEfetivar = () => {},
  ...props
}) => {
  const classes = useStylesDatagrid();

  return (
    <ShowAdmin
      {...props}
      /* disable the app title change when shown */
      title=" "
    >
      <SimpleShowLayout>
        <ReferenceManyField
          reference="transacoes"
          target="parcela_venda_id"
          addLabel={false}
        >
          <Datagrid>
            <StatusField
              source="status"
              label="Status"
              sortable={false}
              headerClassName={classes.statusHeader}
              cellClassName={classes.statusCell}
              // className={classes.statusContent}
            />
            <DateField
              source="data_pagamento"
              label="Data"
              headerClassName={classes.dataHeader}
              cellClassName={classes.dataCell}
            />
            <DescricaoField
              source="descricao"
              label="Descrição"
              sortable={false}
              headerClassName={classes.descricaoHeader}
              cellClassName={classes.descricaoCell}
            />
            <ReferenceFieldCategoria
              label="Categoria"
              source="categoria_transacao_2_id"
              reference="categorias_transacao"
              sortable={false}
              headerClassName={classes.categoriaHeader}
              cellClassName={classes.categoriaCell}
              link={false}
            />
            <ReferenceField
              label="Conta"
              source="conta_id"
              reference="contas"
              sortable={false}
              headerClassName={classes.contaHeader}
              cellClassName={classes.contaCell}
              link={false}
              linkType={false}
            >
              <ContaField source="nome" />
            </ReferenceField>

            <ValorField
              source="valor"
              /* locales="pt-BR"
          options={{
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
          }} */
              textAlign="right"
              label="Valor"
              headerClassName={classes.valorHeader}
              cellClassName={classes.valorCell}
            />
            <ActionsField
              source="lalala"
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleEfetivar={handleEfetivar}
              textAlign="right"
              label="Ações"
              headerClassName={classes.actionsHeader}
              cellClassName={classes.actionsCell}
              tipo="Receita"
              sortable={false}
            />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </ShowAdmin>
  );
};

const VendasShow = (props) => {
  const refresh = useRefresh();
  const refDialogo = useRef(null);
  const refDialogoStatus = useRef(null);
  const edit = (data) => {
    if (refDialogo.current) {
      refDialogo.current.handleEdit(data.record.id);
    }
  };

  const efetivar = (data) => {
    if (refDialogoStatus.current) {
      refDialogoStatus.current.handleOpen(data.record.id);
    }
  };

  const handleClose = () => {
    refresh();
  };
  return (
    <>
      <ShowTemplate
        {...props}
        actions={<Actions />}
        // title={<Title />}
        isBack
        routeBack="/vendas"
      >
        <TabbedShowLayout
          tabs={
            <TabbedShowLayoutTabs variant="fullWidth" centered {...props} />
          }
        >
          <Tab label="Geral">
            <TextField source="numero" label="Nº venda" />
            <ReferenceField
              label="Cliente"
              source="cliente_id"
              reference="clientes"
              linkType={false}
            >
              <TextField source="nome" />
            </ReferenceField>
            <NumberField
              source="valor"
              locales="pt-BR"
              options={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'BRL',
              }}
              label="Valor Total"
            />
            <DateField source="data" label="Data da Venda" showTime />
          </Tab>
          <Tab label="Itens">
            <ReferenceManyField
              addLabel={false}
              reference="itensvenda"
              target="venda_id"
              pagination={<Pagination />}
            >
              <Datagrid2>
                <ReferenceField
                  label="Produto"
                  source="produto_id"
                  reference="produtos"
                  sortable={false}
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <TextField source="unidades" label="Unidades" />
                <TextField source="peso" label="Peso (Kg)" />
                <NumberField
                  source="preco_venda"
                  locales="pt-BR"
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                  label="Preço Unitário Venda"
                />
                {/* <EditButton query={`?venda_id=${props.id}`} /> */}
              </Datagrid2>
            </ReferenceManyField>
            {/*  <AddNewItemVendaButton /> */}
          </Tab>
          <Tab label="Parcelas">
            <ReferenceManyField
              addLabel={false}
              reference="parcelasvenda"
              target="venda_id"
              pagination={<Pagination />}
            >
              <Datagrid2
                expand={
                  <TransacaoPanel handleEdit={edit} handleEfetivar={efetivar} />
                }
              >
                <ReferenceField
                  label="Meio Pagamento"
                  source="tipo_pagamento_id"
                  reference="tipospagamento"
                  linkType={false}
                >
                  <TextField source="nome" />
                </ReferenceField>
                <DateField
                  source="data_pagamento"
                  sortable={false}
                  label="Data de Pagamento"
                />
                <NumberField
                  source="valor"
                  label="Valor"
                  locales="pt-BR"
                  sortable={false}
                  options={{
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                  }}
                />
              </Datagrid2>
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </ShowTemplate>
      <DialogoTransacao ref={refDialogo} handleClose={handleClose} />
      <DialogoChangeStatusTransacao
        ref={refDialogoStatus}
        handleClose={handleClose}
      />
    </>
  );
};

export default VendasShow;
