import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  ListBase,
  ListToolbar,
  Pagination,
  useRefresh,
  Title,
  useListContext,
  Datagrid,
  ListContextProvider,
  ReferenceField,
  TextField,
} from 'react-admin';

import { Card, makeStyles, Tabs, Tab, Divider } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { DialogoConfirmacao } from '../../Components';
import { api, getUserId, imprimirChecklistColaborador } from '../../services';
import {
  SECONDARY_COLOR,
  PRIMARY_COLOR,
  STATUS_ANDAMENTO_ATIVIDADE_FINALIZADA,
} from '../../utils';
import Actions from './ActionsList';
import DialogoAvaliacao from './DialogoAvaliacao';
import ActionsField from './Fields/ActionsField';
import AndamentoField from './Fields/AndamentoField';
import AvaliacaoField from './Fields/AvaliacaoField';
import DataAvaliacaoField from './Fields/DataAvaliacaoField';
import DataEntregaField from './Fields/DataEntregaField';
import DataTerminoField from './Fields/DataTerminoField';
import DescricaoField from './Fields/DescricaoField';
import StatusField from './Fields/StatusField';
import TituloField from './Fields/TituloField';
import Filter from './Filter';

const StyledTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: 'green',
    color: 'black',
    //padding: '8px',
    //whiteSpace: 'nowrap',
    //writingMode: 'sideways-lr',
    //textOrientation: 'sideways',
    //width: 'min-content',
    //width: '1px',
    //whiteSpace: 'nowrap',
    //textAlign: 'center',
    //display: 'flex',
    //justifyContent: 'center',
    padding: '2px 5px',
    border: '2px solid black',
    //textAlign: 'center',
    //verticalAlign: 'middle',
  },
  body: {
    //fontSize: 14,
    //whiteSpace: 'nowrap',
    padding: '2px 5px',
    border: '2px solid black',
    //textAlign: 'center',
    //verticalAlign: 'middle',
    //textAlign: 'center',
  },
}))(TableCell);

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function formatTempoPrevisto(value) {
  return moment(new Date(value)).format('HH:mm:ss');
}

const useStyles2 = makeStyles((theme) => ({
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
    cursor: 'pointer',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
    cursor: 'pointer',
  },
  cartao: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#00796b',
    marginLeft: '10px',
  },
}));

function getDataFormatada(data) {
  if (data) {
    return moment(new Date(data)).format('DD/MM/YYYY - HH:mm');
  }
  return '-';
}

const PostPanel = ({ handleEfetivar = () => {}, ...props }) => {
  const classes = useStyles2();
  return (
    <div>
      <div>
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          Detalhes:{' '}
        </span>
        {`${
          props.record.atividade.detalhes ? props.record.atividade.detalhes : ''
        }`}
      </div>
      <div style={{ margin: '10px 0px' }}>
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          Tempo Previsto:{' '}
        </span>
        {`${formatTempoPrevisto(props.record.atividade.tempo_previsto)}`}
      </div>
      <Box mt="1em" />
      {props.record.itens.length > 0 ? (
        <div>
          <Table aria-label="collapsible table" component="div">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="left"
                  style={{
                    backgroundColor: PRIMARY_COLOR,
                    color: 'white',
                  }}
                >
                  Status
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{
                    backgroundColor: PRIMARY_COLOR,
                    color: 'white',
                  }}
                >
                  Horário
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{
                    backgroundColor: PRIMARY_COLOR,
                    color: 'white',
                  }}
                >
                  Subtarefa
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{
                    backgroundColor: PRIMARY_COLOR,
                    color: 'white',
                  }}
                >
                  Detalhes
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.record.itens.map((row) => {
                const status = row.is_concluido;
                return (
                  <TableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      {status ? (
                        <Tooltip title="" placement="bottom" aria-label="add2">
                          <Avatar
                            className={classes.confirmado}
                            onClick={() => handleEfetivar(row)}
                          >
                            <CheckIcon style={{ fontSize: 15 }} />
                          </Avatar>
                        </Tooltip>
                      ) : (
                        <Tooltip title="" placement="bottom" aria-label="add2">
                          <Avatar
                            className={classes.pendente}
                            onClick={() => handleEfetivar(row)}
                          >
                            <PriorityHighIcon style={{ fontSize: 15 }} />
                          </Avatar>
                        </Tooltip>
                      )}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {status ? getDataFormatada(row.updated_at) : '-'}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.subAtividade.nome}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.subAtividade.detalhes
                        ? row.subAtividade.detalhes
                        : ''}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  dataHeader: {
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    padding: '10px',
    color: 'white',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const MyList = ({ ...props }) => {
  const refresh = useRefresh();
  const refDialogoConfirmacao = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const refDialogoAvaliacao = useRef(null);
  const classes = useStyles();

  const handleClose = () => {
    refresh();
  };

  function handleChangeStatusAtividade({ id, andamento }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        andamento === STATUS_ANDAMENTO_ATIVIDADE_FINALIZADA
          ? 'Marcar como Não Concluído'
          : 'Marcar como Concluído',
        'Deseja realmente mudar o status dessa atividade?',
        { id, codigo: 1 },
      );
    }
  }

  function handleChangeStatusSubTarefa({ id, is_concluido }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        is_concluido ? 'Marcar como Não Concluído' : 'Marcar como Concluído',
        'Deseja realmente mudar o status dessa sub tarefa?',
        { id, codigo: 2 },
      );
    }
  }

  function handleDeletar({ id }) {
    /*if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Exclusão de Atividade de Processos',
        'Deseja realmente excluir esta Atividade de Processos?',
        { id, codigo: 3 },
      );
    }*/
  }

  async function changeStatusAtividade(id) {
    const response = await api.put(
      `/instancias_atividades_processos/status/${id}`,
    );
    const { status, message } = response.data;
    if (!status) {
      enqueueSnackbar(`${message}`, {
        variant: 'error',
      });
    }
    handleClose();
  }

  async function changeStatusSubTarefa(id) {
    const response = await api.put(
      `/instancias_subatividades_processos/status/${id}`,
    );
    const { status, message } = response.data;
    if (!status) {
      enqueueSnackbar(`${message}`, {
        variant: 'error',
      });
    }
    handleClose();
  }

  async function removerAvaliacao(id) {
    const response = await api.put(
      `/instancias_atividades_processos/avaliar/${id}`,
      {
        avaliacao: null,
      },
    );
    const { status, message } = response.data;
    if (!status) {
      enqueueSnackbar(`${message}`, {
        variant: 'error',
      });
    }
    handleClose();
  }

  async function deletar(id) {
    //await api.delete(`/instancias_atividades_processos/${id}`);
    //handleClose();
  }

  async function handleConfirma({ id, codigo }) {
    switch (codigo) {
      case 1:
        changeStatusAtividade(id);
        break;
      case 2:
        changeStatusSubTarefa(id);
        break;
      case 3:
        deletar(id);
        break;
      case 5:
        removerAvaliacao(id);
        break;
      default:
        break;
    }
  }

  function handleDesavaliar({ id }) {
    if (refDialogoConfirmacao.current) {
      refDialogoConfirmacao.current.handleOpen(
        'Remover Avaliação',
        'Deseja realmente remover a avaliação da atividade?',
        { id, codigo: 5 },
      );
    }
  }

  function handleAvaliar({ id }) {
    if (refDialogoAvaliacao.current) {
      refDialogoAvaliacao.current.handleOpen(id);
    }
  }

  async function handleImprimir() {
    const codigo = await imprimirChecklistColaborador(getUserId());
    if (codigo < 0) {
      enqueueSnackbar('Erro ao imprimir o checklist!', {
        variant: 'error',
      });
    }
  }

  return (
    <>
      <Title title="Atividades Colaborador" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} handleImprimir={handleImprimir} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid
          {...props}
          expand={<PostPanel handleEfetivar={handleChangeStatusSubTarefa} />}
        >
          <StatusField
            source="status"
            label="Status"
            sortable={false}
            handleEfetivar={handleChangeStatusAtividade}
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <TituloField
            source="atividades_processo_id"
            label="Título"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            //sortable={false}
          />
          <DescricaoField
            source="grau_urgencia"
            label="Urgência"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <DescricaoField
            source="grau_importancia"
            label="Importância"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
          />
          <ReferenceField
            label="Departamento"
            source="departamento_colaborador_id"
            reference="departamentos_colaborador"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            linkType={false}
          >
            <TextField source="nome" />
          </ReferenceField>
          <DataEntregaField
            source="data_entrega"
            label="Data Entrega"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            //sortable={false}
          />
          <DataTerminoField
            source="data_termino"
            label="Data Término"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            //sortable={false}
          />
          <AvaliacaoField
            source="avaliacao"
            label="Avaliação"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            //sortable={false}
          />
          <ReferenceField
            label="Avaliador"
            source="avaliador_id"
            reference="colaboradores"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            linkType={false}
          >
            <TextField source="nome" />
          </ReferenceField>
          <DataAvaliacaoField
            source="data_avaliacao"
            label="Data Avaliação"
            headerClassName={classes.dataHeader}
            cellClassName={classes.dataCell}
            //sortable={false}
          />
        </Datagrid>
        <TransacoesPagination />
        <DialogoConfirmacao
          ref={refDialogoConfirmacao}
          handleConfirma={handleConfirma}
        />
        <DialogoAvaliacao ref={refDialogoAvaliacao} handleClose={handleClose} />
      </Card>
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 20]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Atividades Colaborador"
      component="div"
      filter={{
        periodoDataEntrega: {
          start: new Date(),
          end: new Date(),
        },
        colaborador_id_2: getUserId(),
      }}
      //filter={{ devolucao: false }}
    />
  </>
);

export default List2;
