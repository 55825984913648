import React, { useState, forwardRef, useImperativeHandle } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

import { TextInput, SwitchInput } from '../../Components';
import { createAPI, updateAPI, getListAllAPI } from '../../services';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {},
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DEFAULT_ID = -1;

const DialogoCategoriaTransacao = forwardRef(({ handleClose }, ref) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [nome, setNome] = useState('');

  const [permissoes, setPermissoes] = useState({
    gerencial_dashboard: false,
    gerencial_vendas: false,
    gerencial_devolucoes: false,
    gerencial_pedidos: false,
    gerencial_compras: false,
    gerencial_clientes: false,
    gerencial_fornecedores: false,
    gerencial_adms: false,
    gerencial_setores: false,
    config: false,
    produtos_produtos: false,
    produtos_familias: false,
    produtos_inventario: false,
    produtos_ofertas: false,
    produtos_categorias: false,
    produtos_taras: false,
    produtos_baixar: false,
    produtos_locais_setores: false,
    produtos_impostos: false,
    produtos_tabela_lucro: false,
    conferencias_sugestao_produtos: false,
    financeiro_dashboard: false,
    financeiro_sessoes: false,
    financeiro_contas: false,
    financeiro_cartoes_credito: false,
    financeiro_transacoes: false,
    financeiro_pix: false,
    financeiro_futurologia: false,
    financeiro_nfes: false,
    financeiro_tela_tranferencias: false,
    financeiro_tela_reajustes: false,
    financeiro_transacoes_simuladas: false,
    financeiro_vendas_faturadas: false,
    televendas_clientes: false,
    relatorios_mensal: false,
    relatorios_anual: false,
    relatorios_produtos: false,
    relatorios_clientes: false,
    relatorios_indicadores_kpi: false,
    relatorios_cadastramento_clientes: false,
    relatorios_produtos_setores: false,
    relatorios_produtos_colaborador: false,
    relatorio_colaborador_fardamento: false,
    global_relatorios_mensal: false,
    global_relatorios_anual: false,
    global_relatorios_produtos: false,
    global_relatorios_clientes: false,
    global_relatorios_indicadores_kpi: false,
    global_relatorios_cadastro_clientes: false,
    global_relatorios_produtos_local: false,
    global_relatorios_produtos_colaborador: false,
    global_relatorio_colaborador_fardamento: false,
    global_inventario: false,
    mais_opcoes_meios_pagamentos: false,
    mais_opcoes_unidades: false,
    mais_opcoes_categorias_transacoes: false,
    mais_opcoes_categorias_clientes: false,
    mais_opcoes_cargos: false,
    mais_opcoes_banco_fornecedores: false,
    mais_opcoes_funcao_funcionario_fornecedor: false,
    contabilidade_colaboradores: false,
    contabilidade_funcoes_colaboradores: false,
    contabilidade_checagem_fardamento: false,
    contabilidade_fardamentos: false,
    contabilidade_tamanho_fardamento: false,
    contabilidade_qualidades: false,
    contabilidade_defeitos: false,
    contabilidade_feriados: false,
    development_features: false,
    development_versoes: false,
    development_softwares: false,
    conferencias_estoque: false,
    conferencias_impressoes: false,
    conferencias_freezers: false,
    equipamentos_freezers: false,
    televendas_aniversariantes: false,
    fornecimento_produtos_insumos: false,
    fornecimento_compras_insumos: false,
    sistema_pergunta: false,
    sistema_categoria_pergunta: false,
    contabilidade_desconto_funcionario: false,
    contabilidade_categoria_desconto_funcionario: false,
    malote_malotes: false,
    malote_submalotes: false,
    malote_sangrias: false,
    malote_trocos: false,
    malote_sobras: false,
    malote_sinistros: false,
    malote_conta_gerencial: false,
    sistema_categoria_frente_caixa: false,
    fornecimento_fornecedores_insumos: false,
    fornecimento_fornecedores_servicos: false,
    fornecimento_fornecedores_qualquer: false,
    sistema_cotacoes_templates: false,
    relatorios_relatorio_cartoes: false,
    relatorios_relatorio_fluxo_caixa: false,
    relatorios_relatorio_scores: false,
    sistema_data_comemorativa: false,
    sistema_categorias_problemas_despesas: false,
    sistema_contas_tabela: false,
    sistema_transacoes_conferencias: false,
    sistema_despesas_agendamentos: false,
    sistema_caixas_fc: false,
    sistema_conferencia_caixaria: false,
    coletor_solicitacao_impressao: false,
    coletor_conferencia_temperatura_freezer: false,
    sistema_grupo_colaborador: false,
    sistema_equipe_colaborador: false,
    sistema_codinomes_colaborador: false,
    pessoal_turnos: false,
    sistema_atividades_processos: false,
    sistema_departamento_colaborador: false,
    sistema_pontos_colaborador: false,
    sistema_edit_conta: false,
    sistema_compensacao_feriados: false,
    sistema_vendas_nao_finalizadas: false,
    sistema_itens_vendas_nao_finalizadas: false,
    sistema_lixeira: false,
    sistema_tela_transportadores: false,
    sistema_videos_tutoriais: false,
    sistema_estados_uf: false,
    sistema_conferencia_maquineta: false,
    sistema_conferencia_grandes_pesagens: false,
    sistema_historico_precificacao: false,
    sistema_transacoes_especiais: false,
    sistema_verbas_produtos: false,
    sistema_horas_extras: false,
    sistema_liberacao_de_precos: false,
    sistema_oferta_zerar_estoque: false,
    sistema_jornal_de_ofertas: false,
    sistema_folha_pagamentos: false,
    sistema_empresas: false,
    sistema_ratoeira: false,
    sistema_credito_devolucao: false,
    sistema_nfe_compra: false,
    sistema_nfe_compra_problematicas: false,
    sistema_pedidos_liberacoes: false,
    sistema_aprovacoes_pedidos_liberacoes: false,
    comprador_configuracoes_produtos: false,
    sistema_tabela_ibtp: false,
    sistema_logs_tasks: false,
    sistema_sugestao_pre_compra: false,
    sistema_pedido_pre_compra: false,
    sistema_aba_comprador_no_produto: false,
    sistema_aba_comprador_no_fornecedor: false,
    sistema_tela_representantes: false,
    sistema_tela_relatorio_compras_fiscais: false,
    //
    sistema_compras_insumos: false,
    sistema_compras_servicos: false,
    sistema_compras_bonificacoes: false,
    //
    sistema_descarrego: false,

    sistema_solicitacao_impressao_cartaz: false,

    sistema_pedido_compra: false,
    sistema_acoes_pedido_compra: false,
  });

  /////////////////

  const [id, setId] = useState(DEFAULT_ID);
  const [carregando, setCarregando] = useState(false);
  const classes = useStyles({ cor: '#009FD4' });

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  async function reiniciar() {
    setId(DEFAULT_ID);
    setCarregando(false);
    setNome('');
    setPermissoes({
      gerencial_dashboard: false,
      gerencial_vendas: false,
      gerencial_devolucoes: false,
      gerencial_pedidos: false,
      gerencial_compras: false,
      gerencial_clientes: false,
      gerencial_fornecedores: false,
      gerencial_adms: false,
      gerencial_setores: false,
      config: false,
      produtos_produtos: false,
      produtos_familias: false,
      produtos_inventario: false,
      produtos_ofertas: false,
      produtos_categorias: false,
      produtos_taras: false,
      produtos_baixar: false,
      produtos_locais_setores: false,
      produtos_impostos: false,
      produtos_tabela_lucro: false,
      conferencias_sugestao_produtos: false,
      financeiro_dashboard: false,
      financeiro_sessoes: false,
      financeiro_contas: false,
      financeiro_cartoes_credito: false,
      financeiro_transacoes: false,
      financeiro_pix: false,
      financeiro_futurologia: false,
      financeiro_nfes: false,
      financeiro_tela_tranferencias: false,
      financeiro_tela_reajustes: false,
      financeiro_transacoes_simuladas: false,
      financeiro_vendas_faturadas: false,
      televendas_clientes: false,
      relatorios_mensal: false,
      relatorios_anual: false,
      relatorios_produtos: false,
      relatorios_clientes: false,
      relatorios_indicadores_kpi: false,
      relatorios_cadastramento_clientes: false,
      relatorios_produtos_setores: false,
      relatorios_produtos_colaborador: false,
      relatorio_colaborador_fardamento: false,
      global_relatorios_mensal: false,
      global_relatorios_anual: false,
      global_relatorios_produtos: false,
      global_relatorios_clientes: false,
      global_relatorios_indicadores_kpi: false,
      global_relatorios_cadastro_clientes: false,
      global_relatorios_produtos_local: false,
      global_relatorios_produtos_colaborador: false,
      global_relatorio_colaborador_fardamento: false,
      global_inventario: false,
      mais_opcoes_meios_pagamentos: false,
      mais_opcoes_unidades: false,
      mais_opcoes_categorias_transacoes: false,
      mais_opcoes_categorias_clientes: false,
      mais_opcoes_cargos: false,
      mais_opcoes_banco_fornecedores: false,
      mais_opcoes_funcao_funcionario_fornecedor: false,
      contabilidade_colaboradores: false,
      contabilidade_funcoes_colaboradores: false,
      contabilidade_checagem_fardamento: false,
      contabilidade_fardamentos: false,
      contabilidade_tamanho_fardamento: false,
      contabilidade_qualidades: false,
      contabilidade_defeitos: false,
      contabilidade_feriados: false,
      development_features: false,
      development_versoes: false,
      development_softwares: false,
      conferencias_estoque: false,
      conferencias_impressoes: false,
      conferencias_freezers: false,
      equipamentos_freezers: false,
      televendas_aniversariantes: false,
      fornecimento_produtos_insumos: false,
      fornecimento_compras_insumos: false,
      sistema_pergunta: false,
      sistema_categoria_pergunta: false,
      contabilidade_desconto_funcionario: false,
      contabilidade_categoria_desconto_funcionario: false,
      malote_malotes: false,
      malote_submalotes: false,
      malote_sangrias: false,
      malote_trocos: false,
      malote_sobras: false,
      malote_sinistros: false,
      malote_conta_gerencial: false,
      sistema_categoria_frente_caixa: false,
      fornecimento_fornecedores_insumos: false,
      fornecimento_fornecedores_servicos: false,
      fornecimento_fornecedores_qualquer: false,
      sistema_cotacoes_templates: false,
      relatorios_relatorio_cartoes: false,
      relatorios_relatorio_fluxo_caixa: false,
      relatorios_relatorio_scores: false,
      sistema_data_comemorativa: false,
      sistema_categorias_problemas_despesas: false,
      sistema_contas_tabela: false,
      sistema_transacoes_conferencias: false,
      sistema_despesas_agendamentos: false,
      sistema_caixas_fc: false,
      sistema_conferencia_caixaria: false,
      coletor_solicitacao_impressao: false,
      coletor_conferencia_temperatura_freezer: false,
      sistema_grupo_colaborador: false,
      sistema_equipe_colaborador: false,
      sistema_codinomes_colaborador: false,
      pessoal_turnos: false,
      sistema_atividades_processos: false,
      sistema_departamento_colaborador: false,
      sistema_pontos_colaborador: false,
      sistema_edit_conta: false,
      sistema_compensacao_feriados: false,
      sistema_vendas_nao_finalizadas: false,
      sistema_itens_vendas_nao_finalizadas: false,
      sistema_lixeira: false,
      sistema_tela_transportadores: false,
      sistema_videos_tutoriais: false,
      sistema_estados_uf: false,
      sistema_conferencia_maquineta: false,
      sistema_conferencia_grandes_pesagens: false,
      sistema_historico_precificacao: false,
      sistema_transacoes_especiais: false,
      sistema_verbas_produtos: false,
      sistema_horas_extras: false,
      sistema_liberacao_de_precos: false,
      sistema_oferta_zerar_estoque: false,
      sistema_jornal_de_ofertas: false,
      sistema_folha_pagamentos: false,
      sistema_empresas: false,
      sistema_ratoeira: false,
      sistema_credito_devolucao: false,
      sistema_nfe_compra: false,
      sistema_nfe_compra_problematicas: false,
      sistema_pedidos_liberacoes: false,
      sistema_aprovacoes_pedidos_liberacoes: false,
      comprador_configuracoes_produtos: false,
      sistema_tabela_ibtp: false,
      sistema_logs_tasks: false,
      sistema_tela_validade: false,
      sistema_sugestao_pre_compra: false,
      sistema_pedido_pre_compra: false,
      sistema_aba_comprador_no_produto: false,
      sistema_aba_comprador_no_fornecedor: false,
      sistema_tela_representantes: false,
      sistema_tela_relatorio_compras_fiscais: false,
      sistema_compras_insumos: false,
      sistema_compras_servicos: false,
      sistema_compras_bonificacoes: false,
      //
      sistema_descarrego: false,
      sistema_solicitacao_impressao_cartaz: false,

      sistema_pedido_compra: false,
      sistema_acoes_pedido_compra: false,
    });
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'cargos',
        ['id', 'asc'],
        { id: [id] },
        [],
      );
      if (data.data.length > 0) {
        setId(data.data[0].id);
        setCarregando(false);
        setNome(data.data[0].nome);
        setPermissoes({
          gerencial_dashboard: !!data.data[0].gerencial_dashboard,
          gerencial_vendas: !!data.data[0].gerencial_vendas,
          gerencial_devolucoes: !!data.data[0].gerencial_devolucoes,
          gerencial_pedidos: !!data.data[0].gerencial_pedidos,
          gerencial_compras: !!data.data[0].gerencial_compras,
          gerencial_clientes: !!data.data[0].gerencial_clientes,
          gerencial_fornecedores: !!data.data[0].gerencial_fornecedores,
          gerencial_adms: !!data.data[0].gerencial_adms,
          gerencial_setores: !!data.data[0].gerencial_setores,
          config: !!data.data[0].config,
          produtos_produtos: !!data.data[0].produtos_produtos,
          produtos_familias: !!data.data[0].produtos_familias,
          produtos_inventario: !!data.data[0].produtos_inventario,
          produtos_ofertas: !!data.data[0].produtos_ofertas,
          produtos_categorias: !!data.data[0].produtos_categorias,
          produtos_taras: !!data.data[0].produtos_taras,
          produtos_baixar: !!data.data[0].produtos_baixar,
          produtos_locais_setores: !!data.data[0].produtos_locais_setores,
          produtos_impostos: !!data.data[0].produtos_impostos,
          produtos_tabela_lucro: !!data.data[0].produtos_tabela_lucro,
          conferencias_sugestao_produtos: !!data.data[0]
            .conferencias_sugestao_produtos,
          financeiro_dashboard: !!data.data[0].financeiro_dashboard,
          financeiro_sessoes: !!data.data[0].financeiro_sessoes,
          financeiro_contas: !!data.data[0].financeiro_contas,
          financeiro_cartoes_credito: !!data.data[0].financeiro_cartoes_credito,
          financeiro_transacoes: !!data.data[0].financeiro_transacoes,
          financeiro_pix: !!data.data[0].financeiro_pix,
          financeiro_futurologia: !!data.data[0].financeiro_futurologia,
          financeiro_nfes: !!data.data[0].financeiro_nfes,
          financeiro_tela_tranferencias: !!data.data[0]
            .financeiro_tela_tranferencias,
          financeiro_tela_reajustes: !!data.data[0].financeiro_tela_reajustes,
          financeiro_transacoes_simuladas: !!data.data[0]
            .financeiro_transacoes_simuladas,
          financeiro_vendas_faturadas: !!data.data[0]
            .financeiro_vendas_faturadas,
          televendas_clientes: !!data.data[0].televendas_clientes,
          relatorios_mensal: !!data.data[0].relatorios_mensal,
          relatorios_anual: !!data.data[0].relatorios_anual,
          relatorios_produtos: !!data.data[0].relatorios_produtos,
          relatorios_clientes: !!data.data[0].relatorios_clientes,
          relatorios_indicadores_kpi: !!data.data[0].relatorios_indicadores_kpi,
          relatorios_cadastramento_clientes: !!data.data[0]
            .relatorios_cadastramento_clientes,
          relatorios_produtos_setores: !!data.data[0]
            .relatorios_produtos_setores,
          relatorios_produtos_colaborador: !!data.data[0]
            .relatorios_produtos_colaborador,
          relatorio_colaborador_fardamento: !!data.data[0]
            .relatorio_colaborador_fardamento,
          global_relatorios_mensal: !!data.data[0].global_relatorios_mensal,
          global_relatorios_anual: !!data.data[0].global_relatorios_anual,
          global_relatorios_produtos: !!data.data[0].global_relatorios_produtos,
          global_relatorios_clientes: !!data.data[0].global_relatorios_clientes,
          global_relatorios_indicadores_kpi: !!data.data[0]
            .global_relatorios_indicadores_kpi,
          global_relatorios_cadastro_clientes: !!data.data[0]
            .global_relatorios_cadastro_clientes,
          global_relatorios_produtos_local: !!data.data[0]
            .global_relatorios_produtos_local,
          global_relatorios_produtos_colaborador: !!data.data[0]
            .global_relatorios_produtos_colaborador,
          global_relatorio_colaborador_fardamento: !!data.data[0]
            .global_relatorio_colaborador_fardamento,
          global_inventario: !!data.data[0].global_inventario,
          mais_opcoes_meios_pagamentos: !!data.data[0]
            .mais_opcoes_meios_pagamentos,
          mais_opcoes_unidades: !!data.data[0].mais_opcoes_unidades,
          mais_opcoes_categorias_transacoes: !!data.data[0]
            .mais_opcoes_categorias_transacoes,
          mais_opcoes_categorias_clientes: !!data.data[0]
            .mais_opcoes_categorias_clientes,
          mais_opcoes_cargos: !!data.data[0].mais_opcoes_cargos,
          mais_opcoes_banco_fornecedores: !!data.data[0]
            .mais_opcoes_banco_fornecedores,
          mais_opcoes_funcao_funcionario_fornecedor: !!data.data[0]
            .mais_opcoes_funcao_funcionario_fornecedor,
          contabilidade_colaboradores: !!data.data[0]
            .contabilidade_colaboradores,
          contabilidade_funcoes_colaboradores: !!data.data[0]
            .contabilidade_funcoes_colaboradores,
          contabilidade_checagem_fardamento: !!data.data[0]
            .contabilidade_checagem_fardamento,
          contabilidade_fardamentos: !!data.data[0].contabilidade_fardamentos,
          contabilidade_tamanho_fardamento: !!data.data[0]
            .contabilidade_tamanho_fardamento,
          contabilidade_qualidades: !!data.data[0].contabilidade_qualidades,
          contabilidade_defeitos: !!data.data[0].contabilidade_defeitos,
          contabilidade_feriados: !!data.data[0].contabilidade_feriados,
          development_features: !!data.data[0].development_features,
          development_versoes: !!data.data[0].development_versoes,
          development_softwares: !!data.data[0].development_softwares,
          conferencias_estoque: !!data.data[0].conferencias_estoque,
          conferencias_impressoes: !!data.data[0].conferencias_impressoes,
          conferencias_freezers: !!data.data[0].conferencias_freezers,
          equipamentos_freezers: !!data.data[0].equipamentos_freezers,
          televendas_aniversariantes: !!data.data[0].televendas_aniversariantes,
          fornecimento_produtos_insumos: !!data.data[0]
            .fornecimento_produtos_insumos,
          fornecimento_compras_insumos: !!data.data[0]
            .fornecimento_compras_insumos,
          sistema_pergunta: !!data.data[0].sistema_pergunta,
          sistema_categoria_pergunta: !!data.data[0].sistema_categoria_pergunta,
          contabilidade_desconto_funcionario: !!data.data[0]
            .contabilidade_desconto_funcionario,
          contabilidade_categoria_desconto_funcionario: !!data.data[0]
            .contabilidade_categoria_desconto_funcionario,
          malote_malotes: !!data.data[0].malote_malotes,
          malote_submalotes: !!data.data[0].malote_submalotes,
          malote_sangrias: !!data.data[0].malote_sangrias,
          malote_trocos: !!data.data[0].malote_trocos,
          malote_sobras: !!data.data[0].malote_sobras,
          malote_sinistros: !!data.data[0].malote_sinistros,
          malote_conta_gerencial: !!data.data[0].malote_conta_gerencial,
          sistema_categoria_frente_caixa: !!data.data[0]
            .sistema_categoria_frente_caixa,
          fornecimento_fornecedores_insumos: !!data.data[0]
            .fornecimento_fornecedores_insumos,
          fornecimento_fornecedores_servicos: !!data.data[0]
            .fornecimento_fornecedores_servicos,
          fornecimento_fornecedores_qualquer: !!data.data[0]
            .fornecimento_fornecedores_qualquer,
          sistema_cotacoes_templates: !!data.data[0].sistema_cotacoes_templates,
          relatorios_relatorio_cartoes: !!data.data[0]
            .relatorios_relatorio_cartoes,
          relatorios_relatorio_fluxo_caixa: !!data.data[0]
            .relatorios_relatorio_fluxo_caixa,
          relatorios_relatorio_scores: !!data.data[0]
            .relatorios_relatorio_scores,
          sistema_data_comemorativa: !!data.data[0].sistema_data_comemorativa,
          sistema_categorias_problemas_despesas: !!data.data[0]
            .sistema_categorias_problemas_despesas,
          sistema_contas_tabela: !!data.data[0].sistema_contas_tabela,
          sistema_transacoes_conferencias: !!data.data[0]
            .sistema_transacoes_conferencias,
          sistema_despesas_agendamentos: !!data.data[0]
            .sistema_despesas_agendamentos,
          sistema_caixas_fc: !!data.data[0].sistema_caixas_fc,
          sistema_conferencia_caixaria: !!data.data[0]
            .sistema_conferencia_caixaria,
          coletor_solicitacao_impressao: !!data.data[0]
            .coletor_solicitacao_impressao,
          coletor_conferencia_temperatura_freezer: !!data.data[0]
            .coletor_conferencia_temperatura_freezer,
          sistema_grupo_colaborador: !!data.data[0].sistema_grupo_colaborador,
          sistema_equipe_colaborador: !!data.data[0].sistema_equipe_colaborador,
          sistema_codinomes_colaborador: !!data.data[0]
            .sistema_codinomes_colaborador,
          pessoal_turnos: !!data.data[0].pessoal_turnos,
          sistema_atividades_processos: !!data.data[0]
            .sistema_atividades_processos,
          sistema_departamento_colaborador: !!data.data[0]
            .sistema_departamento_colaborador,
          sistema_pontos_colaborador: !!data.data[0].sistema_pontos_colaborador,
          sistema_edit_conta: !!data.data[0].sistema_edit_conta,
          sistema_compensacao_feriados: !!data.data[0]
            .sistema_compensacao_feriados,
          sistema_vendas_nao_finalizadas: !!data.data[0]
            .sistema_vendas_nao_finalizadas,
          sistema_itens_vendas_nao_finalizadas: !!data.data[0]
            .sistema_itens_vendas_nao_finalizadas,
          sistema_lixeira: !!data.data[0].sistema_lixeira,
          sistema_tela_transportadores: !!data.data[0]
            .sistema_tela_transportadores,
          sistema_videos_tutoriais: !!data.data[0].sistema_videos_tutoriais,
          sistema_estados_uf: !!data.data[0].sistema_estados_uf,
          sistema_conferencia_maquineta: !!data.data[0]
            .sistema_conferencia_maquineta,
          sistema_conferencia_grandes_pesagens: !!data.data[0]
            .sistema_conferencia_grandes_pesagens,
          sistema_historico_precificacao: !!data.data[0]
            .sistema_historico_precificacao,
          sistema_transacoes_especiais: !!data.data[0]
            .sistema_transacoes_especiais,
          sistema_verbas_produtos: !!data.data[0].sistema_verbas_produtos,
          sistema_horas_extras: !!data.data[0].sistema_horas_extras,
          sistema_liberacao_de_precos: !!data.data[0]
            .sistema_liberacao_de_precos,
          sistema_oferta_zerar_estoque: !!data.data[0]
            .sistema_oferta_zerar_estoque,
          sistema_jornal_de_ofertas: !!data.data[0].sistema_jornal_de_ofertas,
          sistema_folha_pagamentos: !!data.data[0].sistema_folha_pagamentos,
          sistema_empresas: !!data.data[0].sistema_empresas,
          sistema_ratoeira: !!data.data[0].sistema_ratoeira,
          sistema_credito_devolucao: !!data.data[0].sistema_credito_devolucao,
          sistema_nfe_compra: !!data.data[0].sistema_nfe_compra,
          sistema_nfe_compra_problematicas: !!data.data[0]
            .sistema_nfe_compra_problematicas,
          sistema_pedidos_liberacoes: !!data.data[0].sistema_pedidos_liberacoes,
          sistema_aprovacoes_pedidos_liberacoes: !!data.data[0]
            .sistema_aprovacoes_pedidos_liberacoes,
          comprador_configuracoes_produtos: !!data.data[0]
            .comprador_configuracoes_produtos,
          sistema_tabela_ibtp: !!data.data[0].sistema_tabela_ibtp,
          sistema_logs_tasks: !!data.data[0].sistema_logs_tasks,
          sistema_tela_validade: !!data.data[0].sistema_tela_validade,
          sistema_sugestao_pre_compra: !!data.data[0]
            .sistema_sugestao_pre_compra,
          sistema_pedido_pre_compra: !!data.data[0].sistema_pedido_pre_compra,
          sistema_aba_comprador_no_produto: !!data.data[0]
            .sistema_aba_comprador_no_produto,
          sistema_aba_comprador_no_fornecedor: !!data.data[0]
            .sistema_aba_comprador_no_fornecedor,
          sistema_tela_representantes: !!data.data[0]
            .sistema_tela_representantes,
          sistema_tela_relatorio_compras_fiscais: !!data.data[0]
            .sistema_tela_relatorio_compras_fiscais,
          //
          sistema_compras_insumos: !!data.data[0].sistema_compras_insumos,
          sistema_compras_servicos: !!data.data[0].sistema_compras_servicos,
          sistema_compras_bonificacoes: !!data.data[0]
            .sistema_compras_bonificacoes,
          //
          //
          sistema_descarrego: !!data.data[0].sistema_descarrego,
          sistema_solicitacao_impressao_cartaz: !!data.data[0]
            .sistema_solicitacao_impressao_cartaz,

          sistema_pedido_compra: !!data.data[0].sistema_pedido_compra,
          sistema_acoes_pedido_compra: !!data.data[0]
            .sistema_acoes_pedido_compra,
        });
        setCarregando(false);
      } else {
        throw 'Este cargo não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    try {
      if (id <= 0) {
        await createAPI('cargos', {
          nome,
          gerencial_dashboard: permissoes.gerencial_dashboard,
          gerencial_vendas: permissoes.gerencial_vendas,
          gerencial_devolucoes: permissoes.gerencial_devolucoes,
          gerencial_pedidos: permissoes.gerencial_pedidos,
          gerencial_compras: permissoes.gerencial_compras,
          gerencial_clientes: permissoes.gerencial_clientes,
          gerencial_fornecedores: permissoes.gerencial_fornecedores,
          gerencial_adms: permissoes.gerencial_adms,
          gerencial_setores: permissoes.gerencial_setores,
          config: permissoes.config,
          produtos_produtos: permissoes.produtos_produtos,
          produtos_familias: permissoes.produtos_familias,
          produtos_inventario: permissoes.produtos_inventario,
          produtos_ofertas: permissoes.produtos_ofertas,
          //produtos_precos_liberados: produtoPrecosLiberados,
          produtos_categorias: permissoes.produtos_categorias,
          produtos_taras: permissoes.produtos_taras,
          produtos_baixar: permissoes.produtos_baixar,
          produtos_locais_setores: permissoes.produtos_locais_setores,
          produtos_impostos: permissoes.produtos_impostos,
          produtos_tabela_lucro: permissoes.produtos_tabela_lucro,
          conferencias_sugestao_produtos:
            permissoes.conferencias_sugestao_produtos,
          financeiro_dashboard: permissoes.financeiro_dashboard,
          financeiro_sessoes: permissoes.financeiro_sessoes,
          financeiro_contas: permissoes.financeiro_contas,
          financeiro_cartoes_credito: permissoes.financeiro_cartoes_credito,
          financeiro_transacoes: permissoes.financeiro_transacoes,
          financeiro_pix: permissoes.financeiro_pix,
          financeiro_futurologia: permissoes.financeiro_futurologia,
          financeiro_nfes: permissoes.financeiro_nfes,
          financeiro_tela_tranferencias:
            permissoes.financeiro_tela_tranferencias,
          financeiro_tela_reajustes: permissoes.financeiro_tela_reajustes,
          financeiro_transacoes_simuladas:
            permissoes.financeiro_transacoes_simuladas,
          financeiro_vendas_faturadas: permissoes.financeiro_vendas_faturadas,
          televendas_clientes: permissoes.televendas_clientes,
          relatorios_mensal: permissoes.relatorios_mensal,
          relatorios_anual: permissoes.relatorios_anual,
          relatorios_produtos: permissoes.relatorios_produtos,
          relatorios_clientes: permissoes.relatorios_clientes,
          relatorios_indicadores_kpi: permissoes.relatorios_indicadores_kpi,
          relatorios_cadastramento_clientes:
            permissoes.relatorios_cadastramento_clientes,
          relatorios_produtos_setores: permissoes.relatorios_produtos_setores,
          relatorios_produtos_colaborador:
            permissoes.relatorios_produtos_colaborador,
          relatorio_colaborador_fardamento:
            permissoes.relatorio_colaborador_fardamento,
          global_relatorios_mensal: permissoes.global_relatorios_mensal,
          global_relatorios_anual: permissoes.global_relatorios_anual,
          global_relatorios_produtos: permissoes.global_relatorios_produtos,
          global_relatorios_clientes: permissoes.global_relatorios_clientes,
          global_relatorios_indicadores_kpi:
            permissoes.global_relatorios_indicadores_kpi,
          global_relatorios_cadastro_clientes:
            permissoes.global_relatorios_cadastro_clientes,
          global_relatorios_produtos_local:
            permissoes.global_relatorios_produtos_local,
          global_relatorios_produtos_colaborador:
            permissoes.global_relatorios_produtos_colaborador,
          global_relatorio_colaborador_fardamento:
            permissoes.global_relatorio_colaborador_fardamento,
          global_inventario: permissoes.global_inventario,
          mais_opcoes_meios_pagamentos: permissoes.mais_opcoes_meios_pagamentos,
          mais_opcoes_unidades: permissoes.mais_opcoes_unidades,
          mais_opcoes_categorias_transacoes:
            permissoes.mais_opcoes_categorias_transacoes,
          mais_opcoes_categorias_clientes:
            permissoes.mais_opcoes_categorias_clientes,
          mais_opcoes_cargos: permissoes.mais_opcoes_cargos,
          mais_opcoes_banco_fornecedores:
            permissoes.mais_opcoes_banco_fornecedores,
          mais_opcoes_funcao_funcionario_fornecedor:
            permissoes.mais_opcoes_funcao_funcionario_fornecedor,
          contabilidade_colaboradores: permissoes.contabilidade_colaboradores,
          contabilidade_funcoes_colaboradores:
            permissoes.contabilidade_funcoes_colaboradores,
          contabilidade_checagem_fardamento:
            permissoes.contabilidade_checagem_fardamento,
          contabilidade_fardamentos: permissoes.contabilidade_fardamentos,
          contabilidade_tamanho_fardamento:
            permissoes.contabilidade_tamanho_fardamento,
          contabilidade_qualidades: permissoes.contabilidade_qualidades,
          contabilidade_defeitos: permissoes.contabilidade_defeitos,
          contabilidade_feriados: permissoes.contabilidade_feriados,
          development_features: permissoes.development_features,
          development_versoes: permissoes.development_versoes,
          development_softwares: permissoes.development_softwares,
          conferencias_estoque: permissoes.conferencias_estoque,
          conferencias_impressoes: permissoes.conferencias_impressoes,
          conferencias_freezers: permissoes.conferencias_freezers,
          equipamentos_freezers: permissoes.equipamentos_freezers,
          televendas_aniversariantes: permissoes.televendas_aniversariantes,
          fornecimento_produtos_insumos:
            permissoes.fornecimento_produtos_insumos,
          fornecimento_compras_insumos: permissoes.fornecimento_compras_insumos,
          sistema_pergunta: permissoes.sistema_pergunta,
          sistema_categoria_pergunta: permissoes.sistema_categoria_pergunta,
          contabilidade_desconto_funcionario:
            permissoes.contabilidade_desconto_funcionario,
          contabilidade_categoria_desconto_funcionario:
            permissoes.contabilidade_categoria_desconto_funcionario,
          malote_malotes: permissoes.malote_malotes,
          malote_submalotes: permissoes.malote_submalotes,
          malote_sangrias: permissoes.malote_sangrias,
          malote_trocos: permissoes.malote_trocos,
          malote_sobras: permissoes.malote_sobras,
          malote_sinistros: permissoes.malote_sinistros,
          malote_conta_gerencial: permissoes.malote_conta_gerencial,
          sistema_categoria_frente_caixa:
            permissoes.sistema_categoria_frente_caixa,
          fornecimento_fornecedores_insumos:
            permissoes.fornecimento_fornecedores_insumos,
          fornecimento_fornecedores_servicos:
            permissoes.fornecimento_fornecedores_servicos,
          fornecimento_fornecedores_qualquer:
            permissoes.fornecimento_fornecedores_qualquer,
          sistema_cotacoes_templates: permissoes.sistema_cotacoes_templates,
          relatorios_relatorio_cartoes: permissoes.relatorios_relatorio_cartoes,
          relatorios_relatorio_fluxo_caixa:
            permissoes.relatorios_relatorio_fluxo_caixa,
          relatorios_relatorio_scores: permissoes.relatorios_relatorio_scores,
          sistema_data_comemorativa: permissoes.sistema_data_comemorativa,
          sistema_categorias_problemas_despesas:
            permissoes.sistema_categorias_problemas_despesas,
          sistema_contas_tabela: permissoes.sistema_contas_tabela,
          sistema_transacoes_conferencias:
            permissoes.sistema_transacoes_conferencias,
          sistema_despesas_agendamentos:
            permissoes.sistema_despesas_agendamentos,
          sistema_caixas_fc: permissoes.sistema_caixas_fc,
          sistema_conferencia_caixaria: permissoes.sistema_conferencia_caixaria,
          coletor_solicitacao_impressao:
            permissoes.coletor_solicitacao_impressao,
          coletor_conferencia_temperatura_freezer:
            permissoes.coletor_conferencia_temperatura_freezer,
          sistema_grupo_colaborador: permissoes.sistema_grupo_colaborador,
          sistema_equipe_colaborador: permissoes.sistema_equipe_colaborador,
          sistema_codinomes_colaborador:
            permissoes.sistema_codinomes_colaborador,
          pessoal_turnos: permissoes.pessoal_turnos,
          sistema_atividades_processos: permissoes.sistema_atividades_processos,
          sistema_departamento_colaborador:
            permissoes.sistema_departamento_colaborador,
          sistema_pontos_colaborador: permissoes.sistema_pontos_colaborador,
          sistema_edit_conta: permissoes.sistema_edit_conta,
          sistema_compensacao_feriados: permissoes.sistema_compensacao_feriados,
          sistema_vendas_nao_finalizadas:
            permissoes.sistema_vendas_nao_finalizadas,
          sistema_itens_vendas_nao_finalizadas:
            permissoes.sistema_itens_vendas_nao_finalizadas,
          sistema_lixeira: permissoes.sistema_lixeira,
          sistema_tela_transportadores: permissoes.sistema_tela_transportadores,
          sistema_videos_tutoriais: permissoes.sistema_videos_tutoriais,
          sistema_estados_uf: permissoes.sistema_estados_uf,
          sistema_conferencia_maquineta:
            permissoes.sistema_conferencia_maquineta,
          sistema_conferencia_grandes_pesagens:
            permissoes.sistema_conferencia_grandes_pesagens,
          sistema_historico_precificacao:
            permissoes.sistema_historico_precificacao,
          sistema_transacoes_especiais: permissoes.sistema_transacoes_especiais,
          sistema_verbas_produtos: permissoes.sistema_verbas_produtos,
          sistema_horas_extras: permissoes.sistema_horas_extras,
          sistema_liberacao_de_precos: permissoes.sistema_liberacao_de_precos,
          sistema_oferta_zerar_estoque: permissoes.sistema_oferta_zerar_estoque,
          sistema_jornal_de_ofertas: permissoes.sistema_jornal_de_ofertas,
          sistema_folha_pagamentos: permissoes.sistema_folha_pagamentos,
          sistema_empresas: permissoes.sistema_empresas,
          sistema_ratoeira: permissoes.sistema_ratoeira,
          sistema_credito_devolucao: permissoes.sistema_credito_devolucao,
          sistema_nfe_compra: permissoes.sistema_nfe_compra,
          sistema_nfe_compra_problematicas:
            permissoes.sistema_nfe_compra_problematicas,
          sistema_pedidos_liberacoes: permissoes.sistema_pedidos_liberacoes,
          sistema_aprovacoes_pedidos_liberacoes:
            permissoes.sistema_aprovacoes_pedidos_liberacoes,
          comprador_configuracoes_produtos:
            permissoes.comprador_configuracoes_produtos,
          sistema_tabela_ibtp: permissoes.sistema_tabela_ibtp,
          sistema_logs_tasks: permissoes.sistema_logs_tasks,
          sistema_tela_validade: permissoes.sistema_tela_validade,
          sistema_sugestao_pre_compra: permissoes.sistema_sugestao_pre_compra,
          sistema_pedido_pre_compra: permissoes.sistema_sugestao_pre_compra,
          sistema_aba_comprador_no_produto:
            permissoes.sistema_aba_comprador_no_produto,
          sistema_aba_comprador_no_fornecedor:
            permissoes.sistema_aba_comprador_no_fornecedor,
          sistema_tela_representantes: permissoes.sistema_tela_representantes,
          sistema_tela_relatorio_compras_fiscais:
            permissoes.sistema_tela_relatorio_compras_fiscais,
          ////
          sistema_compras_insumos: permissoes.sistema_compras_insumos,
          sistema_compras_servicos: permissoes.sistema_compras_servicos,
          sistema_compras_bonificacoes: permissoes.sistema_compras_bonificacoes,
          //
          sistema_descarrego: permissoes.sistema_descarrego,

          sistema_solicitacao_impressao_cartaz:
            permissoes.sistema_solicitacao_impressao_cartaz,

          sistema_pedido_compra: permissoes.sistema_pedido_compra,
          sistema_acoes_pedido_compra: permissoes.sistema_acoes_pedido_compra,
        });
        setCarregando(false);
        enqueueSnackbar('Cargo criado com sucesso!', {
          variant: 'success',
        });
      } else {
        await updateAPI('cargos', id, {
          nome,
          gerencial_dashboard: permissoes.gerencial_dashboard,
          gerencial_vendas: permissoes.gerencial_vendas,
          gerencial_devolucoes: permissoes.gerencial_devolucoes,
          gerencial_pedidos: permissoes.gerencial_pedidos,
          gerencial_compras: permissoes.gerencial_compras,
          gerencial_clientes: permissoes.gerencial_clientes,
          gerencial_fornecedores: permissoes.gerencial_fornecedores,
          gerencial_adms: permissoes.gerencial_adms,
          gerencial_setores: permissoes.gerencial_setores,
          config: permissoes.config,
          produtos_produtos: permissoes.produtos_produtos,
          produtos_familias: permissoes.produtos_familias,
          produtos_inventario: permissoes.produtos_inventario,
          produtos_ofertas: permissoes.produtos_ofertas,
          //produtos_precos_liberados: produtoPrecosLiberados,
          produtos_categorias: permissoes.produtos_categorias,
          produtos_taras: permissoes.produtos_taras,
          produtos_baixar: permissoes.produtos_baixar,
          produtos_locais_setores: permissoes.produtos_locais_setores,
          produtos_impostos: permissoes.produtos_impostos,
          produtos_tabela_lucro: permissoes.produtos_tabela_lucro,
          conferencias_sugestao_produtos:
            permissoes.conferencias_sugestao_produtos,
          financeiro_dashboard: permissoes.financeiro_dashboard,
          financeiro_sessoes: permissoes.financeiro_sessoes,
          financeiro_contas: permissoes.financeiro_contas,
          financeiro_cartoes_credito: permissoes.financeiro_cartoes_credito,
          financeiro_transacoes: permissoes.financeiro_transacoes,
          financeiro_pix: permissoes.financeiro_pix,
          financeiro_futurologia: permissoes.financeiro_futurologia,
          financeiro_nfes: permissoes.financeiro_nfes,
          financeiro_tela_tranferencias:
            permissoes.financeiro_tela_tranferencias,
          financeiro_tela_reajustes: permissoes.financeiro_tela_reajustes,
          financeiro_transacoes_simuladas:
            permissoes.financeiro_transacoes_simuladas,
          financeiro_vendas_faturadas: permissoes.financeiro_vendas_faturadas,
          televendas_clientes: permissoes.televendas_clientes,
          relatorios_mensal: permissoes.relatorios_mensal,
          relatorios_anual: permissoes.relatorios_anual,
          relatorios_produtos: permissoes.relatorios_produtos,
          relatorios_clientes: permissoes.relatorios_clientes,
          relatorios_indicadores_kpi: permissoes.relatorios_indicadores_kpi,
          relatorios_cadastramento_clientes:
            permissoes.relatorios_cadastramento_clientes,
          relatorios_produtos_setores: permissoes.relatorios_produtos_setores,
          relatorios_produtos_colaborador:
            permissoes.relatorios_produtos_colaborador,
          relatorio_colaborador_fardamento:
            permissoes.relatorio_colaborador_fardamento,
          global_relatorios_mensal: permissoes.global_relatorios_mensal,
          global_relatorios_anual: permissoes.global_relatorios_anual,
          global_relatorios_produtos: permissoes.global_relatorios_produtos,
          global_relatorios_clientes: permissoes.global_relatorios_clientes,
          global_relatorios_indicadores_kpi:
            permissoes.global_relatorios_indicadores_kpi,
          global_relatorios_cadastro_clientes:
            permissoes.global_relatorios_cadastro_clientes,
          global_relatorios_produtos_local:
            permissoes.global_relatorios_produtos_local,
          global_relatorios_produtos_colaborador:
            permissoes.global_relatorios_produtos_colaborador,
          global_relatorio_colaborador_fardamento:
            permissoes.global_relatorio_colaborador_fardamento,
          global_inventario: permissoes.global_inventario,
          mais_opcoes_meios_pagamentos: permissoes.mais_opcoes_meios_pagamentos,
          mais_opcoes_unidades: permissoes.mais_opcoes_unidades,
          mais_opcoes_categorias_transacoes:
            permissoes.mais_opcoes_categorias_transacoes,
          mais_opcoes_categorias_clientes:
            permissoes.mais_opcoes_categorias_clientes,
          mais_opcoes_cargos: permissoes.mais_opcoes_cargos,
          mais_opcoes_banco_fornecedores:
            permissoes.mais_opcoes_banco_fornecedores,
          mais_opcoes_funcao_funcionario_fornecedor:
            permissoes.mais_opcoes_funcao_funcionario_fornecedor,
          contabilidade_colaboradores: permissoes.contabilidade_colaboradores,
          contabilidade_funcoes_colaboradores:
            permissoes.contabilidade_funcoes_colaboradores,
          contabilidade_checagem_fardamento:
            permissoes.contabilidade_checagem_fardamento,
          contabilidade_fardamentos: permissoes.contabilidade_fardamentos,
          contabilidade_tamanho_fardamento:
            permissoes.contabilidade_tamanho_fardamento,
          contabilidade_qualidades: permissoes.contabilidade_qualidades,
          contabilidade_defeitos: permissoes.contabilidade_defeitos,
          contabilidade_feriados: permissoes.contabilidade_feriados,
          development_features: permissoes.development_features,
          development_versoes: permissoes.development_versoes,
          development_softwares: permissoes.development_softwares,
          conferencias_estoque: permissoes.conferencias_estoque,
          conferencias_impressoes: permissoes.conferencias_impressoes,
          conferencias_freezers: permissoes.conferencias_freezers,
          equipamentos_freezers: permissoes.equipamentos_freezers,
          televendas_aniversariantes: permissoes.televendas_aniversariantes,
          fornecimento_produtos_insumos:
            permissoes.fornecimento_produtos_insumos,
          fornecimento_compras_insumos: permissoes.fornecimento_compras_insumos,
          sistema_pergunta: permissoes.sistema_pergunta,
          sistema_categoria_pergunta: permissoes.sistema_categoria_pergunta,
          contabilidade_desconto_funcionario:
            permissoes.contabilidade_desconto_funcionario,
          contabilidade_categoria_desconto_funcionario:
            permissoes.contabilidade_categoria_desconto_funcionario,
          malote_malotes: permissoes.malote_malotes,
          malote_submalotes: permissoes.malote_submalotes,
          malote_sangrias: permissoes.malote_sangrias,
          malote_trocos: permissoes.malote_trocos,
          malote_sobras: permissoes.malote_sobras,
          malote_sinistros: permissoes.malote_sinistros,
          malote_conta_gerencial: permissoes.malote_conta_gerencial,
          sistema_categoria_frente_caixa:
            permissoes.sistema_categoria_frente_caixa,
          fornecimento_fornecedores_insumos:
            permissoes.fornecimento_fornecedores_insumos,
          fornecimento_fornecedores_servicos:
            permissoes.fornecimento_fornecedores_servicos,
          fornecimento_fornecedores_qualquer:
            permissoes.fornecimento_fornecedores_qualquer,
          sistema_cotacoes_templates: permissoes.sistema_cotacoes_templates,
          relatorios_relatorio_cartoes: permissoes.relatorios_relatorio_cartoes,
          relatorios_relatorio_fluxo_caixa:
            permissoes.relatorios_relatorio_fluxo_caixa,
          relatorios_relatorio_scores: permissoes.relatorios_relatorio_scores,
          sistema_data_comemorativa: permissoes.sistema_data_comemorativa,
          sistema_categorias_problemas_despesas:
            permissoes.sistema_categorias_problemas_despesas,
          sistema_contas_tabela: permissoes.sistema_contas_tabela,
          sistema_transacoes_conferencias:
            permissoes.sistema_transacoes_conferencias,
          sistema_despesas_agendamentos:
            permissoes.sistema_despesas_agendamentos,
          sistema_caixas_fc: permissoes.sistema_caixas_fc,
          sistema_conferencia_caixaria: permissoes.sistema_conferencia_caixaria,
          coletor_solicitacao_impressao:
            permissoes.coletor_solicitacao_impressao,
          coletor_conferencia_temperatura_freezer:
            permissoes.coletor_conferencia_temperatura_freezer,
          sistema_grupo_colaborador: permissoes.sistema_grupo_colaborador,
          sistema_equipe_colaborador: permissoes.sistema_equipe_colaborador,
          sistema_codinomes_colaborador:
            permissoes.sistema_codinomes_colaborador,
          pessoal_turnos: permissoes.pessoal_turnos,
          sistema_atividades_processos: permissoes.sistema_atividades_processos,
          sistema_departamento_colaborador:
            permissoes.sistema_departamento_colaborador,
          sistema_pontos_colaborador: permissoes.sistema_pontos_colaborador,
          sistema_edit_conta: permissoes.sistema_edit_conta,
          sistema_compensacao_feriados: permissoes.sistema_compensacao_feriados,
          sistema_vendas_nao_finalizadas:
            permissoes.sistema_vendas_nao_finalizadas,
          sistema_itens_vendas_nao_finalizadas:
            permissoes.sistema_itens_vendas_nao_finalizadas,
          sistema_lixeira: permissoes.sistema_lixeira,
          sistema_tela_transportadores: permissoes.sistema_tela_transportadores,
          sistema_videos_tutoriais: permissoes.sistema_videos_tutoriais,
          sistema_estados_uf: permissoes.sistema_estados_uf,
          sistema_conferencia_maquineta:
            permissoes.sistema_conferencia_maquineta,
          sistema_conferencia_grandes_pesagens:
            permissoes.sistema_conferencia_grandes_pesagens,
          sistema_historico_precificacao:
            permissoes.sistema_historico_precificacao,
          sistema_transacoes_especiais: permissoes.sistema_transacoes_especiais,
          sistema_verbas_produtos: permissoes.sistema_verbas_produtos,
          sistema_horas_extras: permissoes.sistema_horas_extras,
          sistema_liberacao_de_precos: permissoes.sistema_liberacao_de_precos,
          sistema_oferta_zerar_estoque: permissoes.sistema_oferta_zerar_estoque,
          sistema_jornal_de_ofertas: permissoes.sistema_jornal_de_ofertas,
          sistema_folha_pagamentos: permissoes.sistema_folha_pagamentos,
          sistema_empresas: permissoes.sistema_empresas,
          sistema_ratoeira: permissoes.sistema_ratoeira,
          sistema_credito_devolucao: permissoes.sistema_credito_devolucao,
          sistema_nfe_compra: permissoes.sistema_nfe_compra,
          sistema_nfe_compra_problematicas:
            permissoes.sistema_nfe_compra_problematicas,
          sistema_pedidos_liberacoes: permissoes.sistema_pedidos_liberacoes,
          sistema_aprovacoes_pedidos_liberacoes:
            permissoes.sistema_aprovacoes_pedidos_liberacoes,
          comprador_configuracoes_produtos:
            permissoes.comprador_configuracoes_produtos,
          sistema_tabela_ibtp: permissoes.sistema_tabela_ibtp,
          sistema_logs_tasks: permissoes.sistema_logs_tasks,
          sistema_tela_validade: permissoes.sistema_tela_validade,
          sistema_sugestao_pre_compra: permissoes.sistema_sugestao_pre_compra,
          sistema_pedido_pre_compra: permissoes.sistema_sugestao_pre_compra,
          sistema_aba_comprador_no_produto:
            permissoes.sistema_aba_comprador_no_produto,
          sistema_aba_comprador_no_fornecedor:
            permissoes.sistema_aba_comprador_no_fornecedor,
          sistema_tela_representantes: permissoes.sistema_tela_representantes,
          sistema_tela_relatorio_compras_fiscais:
            permissoes.sistema_tela_relatorio_compras_fiscais,
          ////
          sistema_compras_insumos: permissoes.sistema_compras_insumos,
          sistema_compras_servicos: permissoes.sistema_compras_servicos,
          sistema_compras_bonificacoes: permissoes.sistema_compras_bonificacoes,
          //
          sistema_descarrego: permissoes.sistema_descarrego,

          sistema_solicitacao_impressao_cartaz:
            permissoes.sistema_solicitacao_impressao_cartaz,

          sistema_pedido_compra: permissoes.sistema_pedido_compra,
          sistema_acoes_pedido_compra: permissoes.sistema_acoes_pedido_compra,
        });
        setCarregando(false);
        enqueueSnackbar('Cargo atualizado com sucesso!', {
          variant: 'success',
        });
      }
      handleCloseDialog();
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog();
    }
  }

  useImperativeHandle(ref, () => ({
    handleCreate() {
      reiniciar();
      setOpen(true);
    },
    handleEdit(id) {
      setCarregando(true);
      getDataResource(id);
      setId(id);
      setOpen(true);
    },
  }));

  function updatePermissao(field, value) {
    setPermissoes({
      ...permissoes,
      [field]: value,
    });
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.rootDialogo }}
        className={classes.rootDialogo}
        fullScreen
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseDialog}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          {id < 0 ? `Novo Cargo` : `Editar Cargo`}
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px 36px 20px',
            margin: '0',
          }}
        >
          {carregando ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <div>
                <div>
                  <TextInput
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    label="Descrição"
                  />
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Pessoal
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Escalas"
                      checked={permissoes.pessoal_turnos}
                      onChange={(e) =>
                        updatePermissao('pessoal_turnos', e.target.checked)
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Comprador
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Config Produtos"
                      checked={permissoes.comprador_configuracoes_produtos}
                      onChange={(e) =>
                        updatePermissao(
                          'comprador_configuracoes_produtos',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Bancos Fornecedores"
                      checked={permissoes.mais_opcoes_banco_fornecedores}
                      onChange={(e) =>
                        updatePermissao(
                          'mais_opcoes_banco_fornecedores',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Funções Funcionários Fornecedores"
                      checked={
                        permissoes.mais_opcoes_funcao_funcionario_fornecedor
                      }
                      onChange={(e) =>
                        updatePermissao(
                          'mais_opcoes_funcao_funcionario_fornecedor',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Jornal de Ofertas"
                      checked={permissoes.sistema_jornal_de_ofertas}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_jornal_de_ofertas',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Cotações Templates"
                      checked={permissoes.sistema_cotacoes_templates}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_cotacoes_templates',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Ofertas de Zerar Estoque"
                      checked={permissoes.sistema_oferta_zerar_estoque}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_oferta_zerar_estoque',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Sugestões Pré Compra"
                      checked={permissoes.sistema_sugestao_pre_compra}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_sugestao_pre_compra',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  {/*    <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Pedidos Pré Compra"
                      checked={permissoes.sistema_pedido_pre_compra}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_pedido_pre_compra',
                          e.target.checked,
                        )
                      }
                    />
                  </div> */}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Aba Comprador no Produto"
                      checked={permissoes.sistema_aba_comprador_no_produto}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_aba_comprador_no_produto',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Aba Comprador no Fornecedor"
                      checked={permissoes.sistema_aba_comprador_no_fornecedor}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_aba_comprador_no_fornecedor',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Representantes"
                      checked={permissoes.sistema_tela_representantes}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_tela_representantes',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Pedido de Compra"
                      checked={permissoes.sistema_pedido_compra}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_pedido_compra',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Ações no Pedido de Compra"
                      checked={permissoes.sistema_acoes_pedido_compra}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_acoes_pedido_compra',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Vendas
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Vendas"
                      checked={permissoes.gerencial_vendas}
                      onChange={(e) =>
                        updatePermissao('gerencial_vendas', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Devoluções"
                      checked={permissoes.gerencial_devolucoes}
                      onChange={(e) =>
                        updatePermissao(
                          'gerencial_devolucoes',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Pedidos"
                      checked={permissoes.gerencial_pedidos}
                      onChange={(e) =>
                        updatePermissao('gerencial_pedidos', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Ratoeira"
                      checked={permissoes.sistema_ratoeira}
                      onChange={(e) =>
                        updatePermissao('sistema_ratoeira', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Crédito Devoluções"
                      checked={permissoes.sistema_credito_devolucao}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_credito_devolucao',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Pedidos de Liberações de Preços"
                      checked={permissoes.sistema_pedidos_liberacoes}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_pedidos_liberacoes',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Aprovações de Pedidos de Liberações de Preços"
                      checked={permissoes.sistema_aprovacoes_pedidos_liberacoes}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_aprovacoes_pedidos_liberacoes',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Descarrego de Mercadoria"
                      checked={permissoes.sistema_descarrego}
                      onChange={(e) =>
                        updatePermissao('sistema_descarrego', e.target.checked)
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Fornecimento
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Compras"
                      checked={permissoes.gerencial_compras}
                      onChange={(e) =>
                        updatePermissao('gerencial_compras', e.target.checked)
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Bonificações"
                      checked={permissoes.sistema_compras_bonificacoes}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_compras_bonificacoes',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Compras de Insumos"
                      checked={permissoes.sistema_compras_insumos}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_compras_insumos',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Compras de Serviços"
                      checked={permissoes.sistema_compras_servicos}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_compras_servicos',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="NFEs Cientes"
                      checked={permissoes.sistema_nfe_compra}
                      onChange={(e) =>
                        updatePermissao('sistema_nfe_compra', e.target.checked)
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="NFEs Problemáticas"
                      checked={permissoes.sistema_nfe_compra_problematicas}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_nfe_compra_problematicas',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Fornecedores Mercadorias"
                      checked={permissoes.gerencial_fornecedores}
                      onChange={(e) =>
                        updatePermissao(
                          'gerencial_fornecedores',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Fornecedores Insumos"
                      checked={permissoes.fornecimento_fornecedores_insumos}
                      onChange={(e) =>
                        updatePermissao(
                          'fornecimento_fornecedores_insumos',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Fornecedores Serviços"
                      checked={permissoes.fornecimento_fornecedores_servicos}
                      onChange={(e) =>
                        updatePermissao(
                          'fornecimento_fornecedores_servicos',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Outros Contatos"
                      checked={permissoes.fornecimento_fornecedores_qualquer}
                      onChange={(e) =>
                        updatePermissao(
                          'fornecimento_fornecedores_qualquer',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Itens de Venda
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Produtos"
                      checked={permissoes.produtos_produtos}
                      onChange={(e) =>
                        updatePermissao('produtos_produtos', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Famílias"
                      checked={permissoes.produtos_familias}
                      onChange={(e) =>
                        updatePermissao('produtos_familias', e.target.checked)
                      }
                    />
                  </div>

                  {/*<div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Ofertas de Jornal"
                      checked={produtoOfertas}
                      onChange={(e) => setProdutoOfertas(e.target.checked)}
                    />
                  </div>*/}
                  {/*<div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Preços Liberados"
                      checked={produtoPrecosLiberados}
                      onChange={(e) =>
                        setProdutoPrecosLiberados(e.target.checked)
                      }
                    />
                    </div>*/}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Preços Liberados"
                      checked={permissoes.sistema_liberacao_de_precos}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_liberacao_de_precos',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Categorias"
                      checked={permissoes.produtos_categorias}
                      onChange={(e) =>
                        updatePermissao('produtos_categorias', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Baixar"
                      checked={permissoes.produtos_baixar}
                      onChange={(e) =>
                        updatePermissao('produtos_baixar', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Locais"
                      checked={permissoes.produtos_locais_setores}
                      onChange={(e) =>
                        updatePermissao(
                          'produtos_locais_setores',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Taras Balança"
                      checked={permissoes.produtos_taras}
                      onChange={(e) =>
                        updatePermissao('produtos_taras', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Impostos Produtos"
                      checked={permissoes.produtos_impostos}
                      onChange={(e) =>
                        updatePermissao('produtos_impostos', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Tabela Lucro"
                      checked={permissoes.produtos_tabela_lucro}
                      onChange={(e) =>
                        updatePermissao(
                          'produtos_tabela_lucro',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Sugestão Produtos"
                      checked={permissoes.conferencias_sugestao_produtos}
                      onChange={(e) =>
                        updatePermissao(
                          'conferencias_sugestao_produtos',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Setores"
                      checked={permissoes.gerencial_setores}
                      onChange={(e) =>
                        updatePermissao('gerencial_setores', e.target.checked)
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Conferências Caixarias"
                      checked={permissoes.sistema_conferencia_caixaria}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_conferencia_caixaria',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Conferências Grandes Pesagens"
                      checked={permissoes.sistema_conferencia_grandes_pesagens}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_conferencia_grandes_pesagens',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Histórico Precificações"
                      checked={permissoes.sistema_historico_precificacao}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_historico_precificacao',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Verbas de Produtos"
                      checked={permissoes.sistema_verbas_produtos}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_verbas_produtos',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Validades"
                      checked={permissoes.sistema_tela_validade}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_tela_validade',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Financeiro
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Dashboard"
                      checked={permissoes.financeiro_dashboard}
                      onChange={(e) =>
                        updatePermissao(
                          'financeiro_dashboard',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Sessões"
                      checked={permissoes.financeiro_sessoes}
                      onChange={(e) =>
                        updatePermissao('financeiro_sessoes', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Contas"
                      checked={permissoes.financeiro_contas}
                      onChange={(e) =>
                        updatePermissao('financeiro_contas', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Editar Contas"
                      checked={permissoes.sistema_edit_conta}
                      onChange={(e) =>
                        updatePermissao('sistema_edit_conta', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Caixas"
                      checked={permissoes.sistema_caixas_fc}
                      onChange={(e) =>
                        updatePermissao('sistema_caixas_fc', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Cartões de Crédito"
                      checked={permissoes.financeiro_cartoes_credito}
                      onChange={(e) =>
                        updatePermissao(
                          'financeiro_cartoes_credito',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Transações"
                      checked={permissoes.financeiro_transacoes}
                      onChange={(e) =>
                        updatePermissao(
                          'financeiro_transacoes',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Pix"
                      checked={permissoes.financeiro_pix}
                      onChange={(e) =>
                        updatePermissao('financeiro_pix', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Futurologia"
                      checked={permissoes.financeiro_futurologia}
                      onChange={(e) =>
                        updatePermissao(
                          'financeiro_futurologia',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Verificação Nfe"
                      checked={permissoes.financeiro_nfes}
                      onChange={(e) =>
                        updatePermissao('financeiro_nfes', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Transferências"
                      checked={permissoes.financeiro_tela_tranferencias}
                      onChange={(e) =>
                        updatePermissao(
                          'financeiro_tela_tranferencias',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Reajustes"
                      checked={permissoes.financeiro_tela_reajustes}
                      onChange={(e) =>
                        updatePermissao(
                          'financeiro_tela_reajustes',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Categorias Transações"
                      checked={permissoes.mais_opcoes_categorias_transacoes}
                      onChange={(e) =>
                        updatePermissao(
                          'mais_opcoes_categorias_transacoes',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Transações Simuladas"
                      checked={permissoes.financeiro_transacoes_simuladas}
                      onChange={(e) =>
                        updatePermissao(
                          'financeiro_transacoes_simuladas',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Vendas Faturadas"
                      checked={permissoes.financeiro_vendas_faturadas}
                      onChange={(e) =>
                        updatePermissao(
                          'financeiro_vendas_faturadas',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Contas Tabela"
                      checked={permissoes.sistema_contas_tabela}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_contas_tabela',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Transações Conferências"
                      checked={permissoes.sistema_transacoes_conferencias}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_transacoes_conferencias',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Despesas Agendamentos"
                      checked={permissoes.sistema_despesas_agendamentos}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_despesas_agendamentos',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Conferências Maquineta"
                      checked={permissoes.sistema_conferencia_maquineta}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_conferencia_maquineta',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Impostos"
                      checked={permissoes.sistema_transacoes_especiais}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_transacoes_especiais',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Malotes
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Malotes"
                      checked={permissoes.malote_malotes}
                      onChange={(e) =>
                        updatePermissao('malote_malotes', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Submalotes"
                      checked={permissoes.malote_submalotes}
                      onChange={(e) =>
                        updatePermissao('malote_submalotes', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Sangrias"
                      checked={permissoes.malote_sangrias}
                      onChange={(e) =>
                        updatePermissao('malote_sangrias', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Troco"
                      checked={permissoes.malote_trocos}
                      onChange={(e) =>
                        updatePermissao('malote_trocos', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Sobras de caixas"
                      checked={permissoes.malote_sobras}
                      onChange={(e) =>
                        updatePermissao('malote_sobras', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Sinistros"
                      checked={permissoes.malote_sinistros}
                      onChange={(e) =>
                        updatePermissao('malote_sinistros', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Conta Gerencial"
                      checked={permissoes.malote_conta_gerencial}
                      onChange={(e) =>
                        updatePermissao(
                          'malote_conta_gerencial',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Lixeira"
                      checked={permissoes.sistema_lixeira}
                      onChange={(e) =>
                        updatePermissao('sistema_lixeira', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Transportadores"
                      checked={permissoes.sistema_tela_transportadores}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_tela_transportadores',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Televendas
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Clientes"
                      checked={permissoes.gerencial_clientes}
                      onChange={(e) =>
                        updatePermissao('gerencial_clientes', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Categorias Clientes"
                      checked={permissoes.mais_opcoes_categorias_clientes}
                      onChange={(e) =>
                        updatePermissao(
                          'mais_opcoes_categorias_clientes',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Aniversariantes"
                      checked={permissoes.televendas_aniversariantes}
                      onChange={(e) =>
                        updatePermissao(
                          'televendas_aniversariantes',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Processos
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Atividades"
                      checked={permissoes.sistema_atividades_processos}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_atividades_processos',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Relatório
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Mensal"
                      checked={permissoes.relatorios_mensal}
                      onChange={(e) =>
                        updatePermissao('relatorios_mensal', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Anual"
                      checked={permissoes.relatorios_anual}
                      onChange={(e) =>
                        updatePermissao('relatorios_anual', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Produtos"
                      checked={permissoes.relatorios_produtos}
                      onChange={(e) =>
                        updatePermissao('relatorios_produtos', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Clientes"
                      checked={permissoes.relatorios_clientes}
                      onChange={(e) =>
                        updatePermissao('relatorios_clientes', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Indicadores KPI"
                      checked={permissoes.relatorios_indicadores_kpi}
                      onChange={(e) =>
                        updatePermissao(
                          'relatorios_indicadores_kpi',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Inventário"
                      checked={permissoes.produtos_inventario}
                      onChange={(e) =>
                        updatePermissao('produtos_inventario', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Cadastro Clientes"
                      checked={permissoes.relatorios_cadastramento_clientes}
                      onChange={(e) =>
                        updatePermissao(
                          'relatorios_cadastramento_clientes',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  {/*  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Produtos de um local"
                      checked={relatorioProdutosLocais}
                      onChange={(e) =>
                        setRelatorioProdutosLocais(e.target.checked)
                      }
                    />
                  </div> */}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Produtos de um colaborador"
                      checked={permissoes.relatorios_produtos_colaborador}
                      onChange={(e) =>
                        updatePermissao(
                          'relatorios_produtos_colaborador',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Fardamentos Colaboradores"
                      checked={permissoes.relatorio_colaborador_fardamento}
                      onChange={(e) =>
                        updatePermissao(
                          'relatorio_colaborador_fardamento',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Cartões"
                      checked={permissoes.relatorios_relatorio_cartoes}
                      onChange={(e) =>
                        updatePermissao(
                          'relatorios_relatorio_cartoes',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Fluxo de Caixa"
                      checked={permissoes.relatorios_relatorio_fluxo_caixa}
                      onChange={(e) =>
                        updatePermissao(
                          'relatorios_relatorio_fluxo_caixa',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Scores"
                      checked={permissoes.relatorios_relatorio_scores}
                      onChange={(e) =>
                        updatePermissao(
                          'relatorios_relatorio_scores',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Vendas Não Finalizadas"
                      checked={permissoes.sistema_vendas_nao_finalizadas}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_vendas_nao_finalizadas',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Itens Venda Não Finalizadas"
                      checked={permissoes.sistema_itens_vendas_nao_finalizadas}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_itens_vendas_nao_finalizadas',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Compras Fiscais"
                      checked={
                        permissoes.sistema_tela_relatorio_compras_fiscais
                      }
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_tela_relatorio_compras_fiscais',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Global
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Relatório Mensal"
                      checked={permissoes.global_relatorios_mensal}
                      onChange={(e) =>
                        updatePermissao(
                          'global_relatorios_mensal',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Relatório Anual"
                      checked={permissoes.global_relatorios_anual}
                      onChange={(e) =>
                        updatePermissao(
                          'global_relatorios_anual',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Relatório Produtos"
                      checked={permissoes.global_relatorios_produtos}
                      onChange={(e) =>
                        updatePermissao(
                          'global_relatorios_produtos',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Relatório Clientes"
                      checked={permissoes.global_relatorios_clientes}
                      onChange={(e) =>
                        updatePermissao(
                          'global_relatorios_clientes',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Indicadores KPI"
                      checked={permissoes.global_relatorios_indicadores_kpi}
                      onChange={(e) =>
                        updatePermissao(
                          'global_relatorios_indicadores_kpi',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Inventário"
                      checked={permissoes.global_inventario}
                      onChange={(e) =>
                        updatePermissao('global_inventario', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Cadastro Clientes"
                      checked={permissoes.global_relatorios_cadastro_clientes}
                      onChange={(e) =>
                        updatePermissao(
                          'global_relatorios_cadastro_clientes',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Produtos de um local"
                      checked={permissoes.global_relatorios_produtos_local}
                      onChange={(e) =>
                        updatePermissao(
                          'global_relatorios_produtos_local',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Produtos de um colaborador"
                      checked={
                        permissoes.global_relatorios_produtos_colaborador
                      }
                      onChange={(e) =>
                        updatePermissao(
                          'global_relatorios_produtos_colaborador',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Fardamentos Colaboradores"
                      checked={
                        permissoes.global_relatorio_colaborador_fardamento
                      }
                      onChange={(e) =>
                        updatePermissao(
                          'global_relatorio_colaborador_fardamento',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Sistema
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Meios de Pagamento"
                      checked={permissoes.mais_opcoes_meios_pagamentos}
                      onChange={(e) =>
                        updatePermissao(
                          'mais_opcoes_meios_pagamentos',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Unidades"
                      checked={permissoes.mais_opcoes_unidades}
                      onChange={(e) =>
                        updatePermissao(
                          'mais_opcoes_unidades',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Cargos"
                      checked={permissoes.mais_opcoes_cargos}
                      onChange={(e) =>
                        updatePermissao('mais_opcoes_cargos', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Usuários"
                      checked={permissoes.gerencial_adms}
                      onChange={(e) =>
                        updatePermissao('gerencial_adms', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Configurações"
                      checked={permissoes.config}
                      onChange={(e) =>
                        updatePermissao('config', e.target.checked)
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Perguntas"
                      checked={permissoes.sistema_pergunta}
                      onChange={(e) =>
                        updatePermissao('sistema_pergunta', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Categorias Perguntas"
                      checked={permissoes.sistema_categoria_pergunta}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_categoria_pergunta',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Categorias Caixas"
                      checked={permissoes.sistema_categoria_frente_caixa}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_categoria_frente_caixa',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Categorias Problemas Despesas"
                      checked={permissoes.sistema_categorias_problemas_despesas}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_categorias_problemas_despesas',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Videos Tutoriais FC"
                      checked={permissoes.sistema_videos_tutoriais}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_videos_tutoriais',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Estados Brasileiros"
                      checked={permissoes.sistema_estados_uf}
                      onChange={(e) =>
                        updatePermissao('sistema_estados_uf', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Lojas"
                      checked={permissoes.sistema_empresas}
                      onChange={(e) =>
                        updatePermissao('sistema_empresas', e.target.checked)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Tabela IBTP"
                      checked={permissoes.sistema_tabela_ibtp}
                      onChange={(e) =>
                        updatePermissao('sistema_tabela_ibtp', e.target.checked)
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Contabilidade
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Colaboradores"
                      checked={permissoes.contabilidade_colaboradores}
                      onChange={(e) =>
                        updatePermissao(
                          'contabilidade_colaboradores',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Funções Colaborador"
                      checked={permissoes.contabilidade_funcoes_colaboradores}
                      onChange={(e) =>
                        updatePermissao(
                          'contabilidade_funcoes_colaboradores',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Fardamentos"
                      checked={permissoes.contabilidade_fardamentos}
                      onChange={(e) =>
                        updatePermissao(
                          'contabilidade_fardamentos',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Tamanho Fardamentos"
                      checked={permissoes.contabilidade_tamanho_fardamento}
                      onChange={(e) =>
                        updatePermissao(
                          'contabilidade_tamanho_fardamento',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Qualidades"
                      checked={permissoes.contabilidade_qualidades}
                      onChange={(e) =>
                        updatePermissao(
                          'contabilidade_qualidades',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Defeitos"
                      checked={permissoes.contabilidade_defeitos}
                      onChange={(e) =>
                        updatePermissao(
                          'contabilidade_defeitos',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Checagens Fardamentos"
                      checked={permissoes.contabilidade_checagem_fardamento}
                      onChange={(e) =>
                        updatePermissao(
                          'contabilidade_checagem_fardamento',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Feriados"
                      checked={permissoes.contabilidade_feriados}
                      onChange={(e) =>
                        updatePermissao(
                          'contabilidade_feriados',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Descontos"
                      checked={permissoes.contabilidade_desconto_funcionario}
                      onChange={(e) =>
                        updatePermissao(
                          'contabilidade_desconto_funcionario',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Categorias Descontos"
                      checked={
                        permissoes.contabilidade_categoria_desconto_funcionario
                      }
                      onChange={(e) =>
                        updatePermissao(
                          'contabilidade_categoria_desconto_funcionario',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Grupos Colaborador"
                      checked={permissoes.sistema_grupo_colaborador}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_grupo_colaborador',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Equipes Colaborador"
                      checked={permissoes.sistema_equipe_colaborador}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_equipe_colaborador',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Codinomes"
                      checked={permissoes.sistema_codinomes_colaborador}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_codinomes_colaborador',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Departamentos"
                      checked={permissoes.sistema_departamento_colaborador}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_departamento_colaborador',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  {/*<div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Presenças"
                      checked={sistemaPontosColaborador}
                      onChange={(e) =>
                        setSistemaPontosColaborador(e.target.checked)
                      }
                    />
                    </div>*/}

                  {/*<div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Compensação de Feriados"
                      checked={sistemaCompensacaoFeriados}
                      onChange={(e) =>
                        setSistemaCompensacaoFeriados(e.target.checked)
                      }
                    />
                    </div>*/}

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Horas Extras"
                      checked={permissoes.sistema_horas_extras}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_horas_extras',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  {/*<div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Folha de Pagamentos"
                      checked={sistemaFolhaPagamentos}
                      onChange={(e) =>
                        setSistemaFolhaPagamentos(e.target.checked)
                      }
                    />
                    </div>*/}
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Development
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Features"
                      checked={permissoes.development_features}
                      onChange={(e) =>
                        updatePermissao(
                          'development_features',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Software"
                      checked={permissoes.development_softwares}
                      onChange={(e) =>
                        updatePermissao(
                          'development_softwares',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Conferências
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Freezers"
                      checked={permissoes.conferencias_freezers}
                      onChange={(e) =>
                        updatePermissao(
                          'conferencias_freezers',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Equipamentos
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Freezers"
                      checked={permissoes.equipamentos_freezers}
                      onChange={(e) =>
                        updatePermissao(
                          'equipamentos_freezers',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Coletor
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Solicitações Impressão Etiquetas"
                      checked={permissoes.coletor_solicitacao_impressao}
                      onChange={(e) =>
                        updatePermissao(
                          'coletor_solicitacao_impressao',
                          e.target.checked,
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Conferência Temperatura Freezer"
                      checked={
                        permissoes.coletor_conferencia_temperatura_freezer
                      }
                      onChange={(e) =>
                        updatePermissao(
                          'coletor_conferencia_temperatura_freezer',
                          e.target.checked,
                        )
                      }
                    />
                  </div>

                  {/* <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Solicitações Impressão Cartazes"
                      checked={permissoes.sistema_solicitacao_impressao_cartaz}
                      onChange={(e) =>
                        updatePermissao(
                          'sistema_solicitacao_impressao_cartaz',
                          e.target.checked,
                        )
                      }
                    />
                  </div> */}
                </div>
                <div
                  style={{
                    margin: '20px 0px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Desenvolvedor
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20%',
                      marginBottom: '10px',
                    }}
                  >
                    <SwitchInput
                      handleEnter={() => {}}
                      handleKey={() => {}}
                      label="Logs Relatórios"
                      checked={permissoes.sistema_logs_tasks}
                      onChange={(e) =>
                        updatePermissao('sistema_logs_tasks', e.target.checked)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '0px 36px 20px',
            margin: '0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <Button
              onClick={handleCloseDialog}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              Cancelar
            </Button>
          </div>
          <div className={classes.wrapper}>
            <Button
              onClick={handleSalvar}
              classes={{
                root: classes.btnAtions,
                disabled: classes.btnAtionsDisabled,
              }}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
            {carregando && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCategoriaTransacao;
