import React, { useRef } from 'react';
import {
  DateField,
  NumberField,
  ReferenceField,
  TextField,
  ListBase,
  ListToolbar,
  Pagination,
  Datagrid,
} from 'react-admin';

import { Card } from '@material-ui/core';

import ActionsField from './ActionsField';
import Actions from './ActionsList';
import DialogoShowVenda from './DialogoShow';
import Filter from './Filter';
import StatusField from './StatusField';

const MyList = ({ ...props }) => {
  const refDialogoShowVenda = useRef(null);

  function handleShow({ id }) {
    if (refDialogoShowVenda.current) {
      refDialogoShowVenda.current.handleOpen(id);
    }
  }

  return (
    <>
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <StatusField source="status" label="Tipo" sortable={false} />
          <TextField source="id" label="ID" />
          <DateField source="data" label="Data da Venda" showTime />
          <ReferenceField
            label="Cliente"
            source="cliente_id"
            reference="clientes"
            linkType={false}
          >
            <TextField source="nome" />
          </ReferenceField>
          <NumberField
            source="valor"
            locales="pt-BR"
            options={{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            }}
            label="Valor Total"
          />
          <NumberField
            source="lucro"
            locales="pt-BR"
            options={{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            }}
            label="Lucro"
          />
          <ActionsField
            source="lalala"
            handleShow={handleShow}
            textAlign="right"
            label="Ações"
            sortable={false}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
      <DialogoShowVenda ref={refDialogoShowVenda} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Ratoeira"
      filterDefaultValues={{
        periodo: {
          start: new Date(),
          end: new Date(),
        },
      }}
    />
  </>
);

export default List2;
