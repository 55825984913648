import React from 'react';
import {
  Filter as FilterAdmin,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  BooleanInput,
} from 'react-admin';

const Filter = (props) => (
  <FilterAdmin {...props}>
    <TextInput label="Nome" source="nome" />
    {/* <BooleanInput label="Ativos" source="ativo" alwaysOn /> */}
    <ReferenceInput
      fullWidth
      label="Cidade"
      source="cidade_id"
      reference="cidades"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      allowEmpty={false}
    >
      <AutocompleteInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
    <TextInput label="Bairro" source="bairro" />
    <TextInput label="Razão Social" source="razao_social" />
    <TextInput label="Nome Fantasia" source="nome_fantasia" />
    <BooleanInput label="Tem chave pix" source="has_key_pix" />
    <ReferenceInput
      fullWidth
      label="Representante"
      source="representante_fornecedor_id"
      reference="representantes-fornecedores"
      sort={{ field: 'nome', order: 'ASC' }}
      filterToQuery={(searchText) => ({ nome: `${searchText}` })}
      allowEmpty={false}
    >
      <AutocompleteInput optionText="nome" allowEmpty={false} />
    </ReferenceInput>
  </FilterAdmin>
);

export default Filter;
