import React from 'react';
import {
  ListBase,
  ListToolbar,
  Pagination,
  Title,
  Datagrid,
  SelectField,
  DateField,
} from 'react-admin';

import { Card, makeStyles } from '@material-ui/core';

import {
  LOG_TASK_CALCULA_RELATORIO_CLIENTE,
  LOG_TASK_CALCULA_RELATORIO_PRODUTO,
  LOG_TASK_RELATORIO_ANUAL,
  LOG_TASK_RELATORIO_MENSAL,
} from '../../utils';
import Actions from './ActionsList';
import StatusField from './Fields/StatusField';
import Filter from './Filter';

const choices = [
  { id: LOG_TASK_CALCULA_RELATORIO_CLIENTE, name: 'Relátorio Clientes' },
  { id: LOG_TASK_CALCULA_RELATORIO_PRODUTO, name: 'Relatório Produtos' },
  { id: LOG_TASK_RELATORIO_ANUAL, name: 'Relatório Anual' },
  { id: LOG_TASK_RELATORIO_MENSAL, name: 'Relatório Mensal' },
];

const useStyles = makeStyles((theme) => ({
  dataHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
}));

const PostPanel = ({ id, record, resource }) => (
  <div style={{ display: 'flex' }}>
    <div style={{ marginRight: '5px', color: 'red' }}>Mensagem de Erro:</div>
    <div dangerouslySetInnerHTML={{ __html: record.mensagem_erro }} />
  </div>
);

const MyList = ({ ...props }) => {
  const classes = useStyles();

  return (
    <>
      <Title title="Logs Relatórios" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Datagrid {...props}>
          <StatusField sortable={false} source="status" label="Status" />
          <DateField
            source="updated_at"
            label="Data"
            showTime
            sortable={false}
          />
          <SelectField
            sortable={false}
            source="task"
            label="Tarefa"
            choices={choices}
          />
        </Datagrid>
        <TransacoesPagination />
      </Card>
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      bulkActionButtons={false}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Logs Relatórios"
      sort={{ field: 'updated_at', order: 'DESC' }}
      component="div"
    />
  </>
);

export default List2;
