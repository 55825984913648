import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';

import { Box, Typography, Tooltip } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { SwitchInput } from '../../Components';
import {
  getLabelTipo,
  getValorFormatado,
} from '../../Components/Dialogos/DialogoFornecedor/utils';
import { getListAllAPI } from '../../services';
import {
  formatTelefone,
  formatCNPJ,
  formatCPF,
  formatCEP,
  formatIE,
  SECONDARY_COLOR,
  formatMoeda,
  POR_UNIDADE,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  formatPeso,
  getLabelNivelCaixaria,
  disablePeso,
  getTotal2,
  POR_PESO_FIXO,
  PRIMARY_COLOR,
  TIPO_UNIDADE_KG,
  TIPO_UNIDADE_UN,
  TIPO_UNIDADE_CX1,
  TIPO_UNIDADE_CX2,
  TIPO_UNIDADE_CX3,
  STATUS_ATIVO,
  formatPreco,
  TIPO_PEDIDO_MINIMO_COMPRA_KG,
  TIPO_PEDIDO_MINIMO_COMPRA_VALOR,
  TIPO_PEDIDO_MINIMO_COMPRA_CAIXAS,
} from '../../utils';

const paddingTableCell = '3px';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: 'black',
    whiteSpace: 'nowrap',
    border: '1px solid black',
    padding: paddingTableCell,
    fontWeight: 'bold',
    //width: '10px !important',
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    border: '1px solid black',
    padding: paddingTableCell,
    //width: '10px !important',
    /* '&:nth-of-type(odd)': {
      backgroundColor: 'white',
    },
    '&:nth-of-type(even)': {
      backgroundColor: 'grey',
    }, */
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  rootDialogo: {
    borderRadius: '25px',
  },
  btnAtions: {
    borderRadius: '25px',
    width: '150px',
  },
  btnAtionsDisabled: {
    borderRadius: '25px',
    width: '150px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  pendente: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#f44336',
    cursor: 'pointer',
  },
  confirmado: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: 'white',
    backgroundColor: '#4caf50',
    cursor: 'pointer',
  },
}));

const colunasTemplate = {
  id: {
    label: 'Nº',
    name: 'id',
    show: true,
    dica: 'Ordem',
    align: 'center',
  },
  descricao: {
    label: 'Descrição do Produto',
    name: 'descricao',
    show: true,
    dica: 'Descrição do Produto',
    align: 'left',
    hasSort: true,
    labelTotal: 'TOTAIS',
  },
  rs_kg: {
    label: 'R$/kg',
    name: 'rs_kg',
    show: true,
    dica: 'Preço do Kg',
    align: 'center',
  },
  rs_un: {
    label: 'R$/un',
    name: 'rs_un',
    show: true,
    dica: 'Preço da Unidade',
    align: 'center',
  },
  rs_cx_1: {
    label: 'R$/cx1',
    name: 'rs_cx_1',
    show: true,
    dica: 'Preço da Caixa Nível 1',
    align: 'center',
  },
  rs_cx_2: {
    label: 'R$/cx2',
    name: 'rs_cx_2',
    show: true,
    dica: 'Preço da Caixa Nível 2',
    align: 'center',
  },
  rs_cx_3: {
    label: 'R$/cx3',
    name: 'rs_cx_3',
    show: true,
    dica: 'Preço da Caixa Nível 3',
    align: 'center',
  },
  pedido: {
    label: 'Pedido',
    name: 'pedido',
    show: true,
    dica: 'Pedido feito ao Fornecedor',
    align: 'center',
    style: {
      color: 'red',
    },
    hasSort: true,
  },
  unidade_adotada_na_compra: {
    label: 'un',
    name: 'unidade_adotada_na_compra',
    show: true,
    dica: 'Unidade adotada na compra',
    align: 'center',
    hasSort: true,
  },
  subtotal: {
    label: 'Subtotal',
    name: 'subtotal',
    show: true,
    dica: 'Subtotal',
    align: 'center',
    hasSort: true,
  },
  bonificacao_em_cxs: {
    label: 'Bon cx',
    name: 'bonificacao_em_cxs',
    show: true,
    dica: 'Bonificação em Caixas',
    align: 'center',
  },
  bonificacao_subtotal: {
    label: 'Bon Subtotal',
    name: 'bonificacao_subtotal',
    show: true,
    dica: 'Bonificação Subtotal',
    align: 'center',
  },
  rs_un_mais_bonificacao: {
    label: 'R$ + Bon',
    name: 'rs_un_mais_bonificacao',
    show: true,
    dica: 'Preço Final incluindo a precificação',
    align: 'center',
  },
  peso_subtotal: {
    label: 'Peso Sub (kg)',
    name: 'peso_subtotal',
    show: false,
    dica: 'Peso Subtotal',
    align: 'center',
  },
  peso_subtotal_liquido: {
    label: 'Peso Sub (kg)',
    name: 'peso_subtotal_liquido',
    show: true,
    dica: 'Peso Subtotal',
    align: 'center',
    hasSort: true,
  },
  volume_subtotal: {
    label: 'Vol Sub (m³)',
    name: 'volume_subtotal',
    show: true,
    dica: 'Volume Subtotal',
    align: 'center',
  },
  estoque_atual: {
    label: 'Est. Atual',
    name: 'estoque_atual',
    show: true,
    dica: 'Estoque Atual',
    align: 'center',
  },
  estoque_ideal: {
    label: 'Est. Ideal',
    name: 'estoque_ideal',
    show: true,
    dica: 'Estoque Ideal calculado pelo sistema',
    align: 'center',
  },
  vtu_30: {
    label: 'VTU30',
    name: 'vtu_30',
    show: true,
    dica: 'Venda Total dos últimos 30 dias',
    align: 'center',
  },
  status: {
    label: 'Status',
    name: 'status',
    show: false,
    dica: 'Status do Item, se está inativo ou ativo',
    align: 'center',
    hasSort: true,
  },
  vmd_na_maior_unidade_possivel: {
    label: 'VMD',
    name: 'vmd_na_maior_unidade_possivel',
    show: false,
    dica: 'Venda Média Diária',
    align: 'center',
  },
  peso_padrao: {
    label: 'PP (kg)',
    name: 'peso_padrao',
    show: false,
    dica: 'Peso Padrão (Kg)',
    align: 'center',
  },
  volume_unitario: {
    label: 'VU (m³)',
    name: 'volume_unitario',
    show: false,
    dica: 'Volume Unitário de uma caixa (m³)',
    align: 'center',
  },
  peso_bruto: {
    label: 'PB (kg)',
    name: 'peso_bruto',
    show: false,
    dica: 'Peso Bruto da caixa (kg)',
    align: 'center',
  },
  regra_de_arredondamento: {
    label: 'RA',
    name: 'regra_de_arredondamento',
    show: false,
    dica: 'Regra de Arredondamento',
    align: 'center',
  },
  caixaria_nivel_1: {
    label: 'cx1',
    name: 'caixaria_nivel_1',
    show: false,
    dica: 'Caixaria nível 1',
    align: 'center',
  },
  caixaria_nivel_2: {
    label: 'cx2',
    name: 'caixaria_nivel_2',
    show: false,
    dica: 'Caixaria nível 2',
    align: 'center',
  },
  caixaria_nivel_3: {
    label: 'cx3',
    name: 'caixaria_nivel_3',
    show: false,
    dica: 'Caixaria nível 3',
    align: 'center',
  },
};

function getHeaderTabela(columns) {
  return (
    <>
      {columns.map((item, index) => (
        <>
          {item.show ? (
            <Tooltip title={item.dica}>
              <StyledTableCell
                align={item.align}
                style={{ backgroundColor: '#7371d590', cursor: 'pointer' }}
              >
                {item.label}
              </StyledTableCell>
            </Tooltip>
          ) : null}
        </>
      ))}
    </>
  );
}

function headerTotais(colunas, totais) {
  return (
    <>
      {colunas.map((item, index) => {
        const valor = totais[item.name];
        return (
          <>
            {valor !== undefined && item.show ? (
              <StyledTableCell
                align={item.align}
                style={{
                  backgroundColor: 'yellow',
                  fontSize: '22px',
                  fontWeight: 'bold',
                }}
                rowSpan={2}
              >
                {valor}
              </StyledTableCell>
            ) : item.show ? (
              <StyledTableCell
                align={item.align}
                style={
                  item.labelTotal
                    ? {
                        backgroundColor: 'yellow',
                        fontSize: '22px',
                        fontWeight: 'bold',
                      }
                    : {
                        backgroundColor: 'yellow',
                        fontSize: '22px',
                        fontWeight: 'bold',
                      }
                }
                rowSpan={2}
              >
                {item.labelTotal ? item.labelTotal : ''}
              </StyledTableCell>
            ) : null}
          </>
        );
      })}
    </>
  );
}

const colorHeader = SECONDARY_COLOR;

function Involucro({ children, field, colunas }) {
  return <>{colunas[field].show ? children : null}</>;
}

const DialogoShowVenda = forwardRef(({ handleClose = () => {} }, ref) => {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [carregando, setCarregando] = useState(false);
  const [id, setId] = useState(-1);
  const [fornecedor, setFornecedor] = useState(null);
  const [itens, setItens] = useState([]);
  const [totais, setTotais] = useState({
    pedido: 0,
    subtotal: 0,
    bonificacao_em_cxs: 0,
    bonificacao_subtotal: 0,
    peso_subtotal: 0,
    volume_subtotal: 0,
    rs_un_mais_bonificacao: 0,
    peso_subtotal_liquido: 0,
  });
  const [pasta, setPasta] = useState('-');

  const colunas = { ...colunasTemplate };

  const handleCloseDialog = () => {
    setOpen(false);
    handleClose();
  };

  function getTotaisFormatados(dados) {
    const totais = {
      pedido: 0,
      subtotal: 0,
      bonificacao_em_cxs: 0,
      bonificacao_subtotal: 0,
      peso_subtotal: 0,
      volume_subtotal: 0,
      rs_un_mais_bonificacao: 0,
      peso_subtotal_liquido: 0,
    };

    totais.pedido = dados.total_qtde;
    totais.subtotal = formatMoeda(dados.total);
    totais.bonificacao_em_cxs = dados.total_bonificado;
    totais.bonificacao_subtotal = formatMoeda(dados.total_bonificacao);
    totais.peso_subtotal = formatPeso(dados.peso_total);
    totais.volume_subtotal = `${formatPreco(dados.volume_total, 2, '')}m³`;
    totais.rs_un_mais_bonificacao = formatMoeda(
      totais.subtotal - totais.bonificacao_subtotal,
    );
    totais.peso_subtotal_liquido = formatPeso(dados.peso_total_liquido);

    return totais;
  }

  function getVtu30(item, unidade_adotada_na_compra) {
    const qtde =
      item.produto.unidade.tipo === POR_UNIDADE
        ? item.produto.unidades_vendidas_ultimos_30_dias
        : item.produto.peso_vendido_ultimos_30_dias;

    switch (unidade_adotada_na_compra) {
      case TIPO_UNIDADE_KG: {
        return qtde;
      }
      case TIPO_UNIDADE_UN: {
        const fator =
          item.produto.unidade.tipo === POR_PESO_FIXO
            ? item.produto.peso_medio
            : 1;
        return qtde / fator;
      }
      case TIPO_UNIDADE_CX1: {
        const fator =
          item.produto.unidade.tipo === POR_PESO_FIXO
            ? item.produto.peso_medio
            : 1;
        return qtde / (fator * item.caixaria_nivel_1);
      }
      case TIPO_UNIDADE_CX2: {
        const fator =
          item.produto.unidade.tipo === POR_PESO_FIXO
            ? item.produto.peso_medio
            : 1;
        return qtde / (fator * item.caixaria_nivel_1 * item.caixaria_nivel_2);
      }
      case TIPO_UNIDADE_CX3: {
        const fator =
          item.produto.unidade.tipo === POR_PESO_FIXO
            ? item.produto.peso_medio
            : 1;
        return (
          qtde /
          (fator *
            item.caixaria_nivel_1 *
            item.caixaria_nivel_2 *
            item.caixaria_nivel_3)
        );
      }
      default: {
        return Math.round(qtde);
      }
    }
  }

  function getEstoque(item, unidade_adotada_na_compra) {
    const qtde =
      item.produto.unidade.tipo === POR_UNIDADE
        ? item.produto.unidadesDisponivel
        : item.produto.pesoDisponivel;
    switch (unidade_adotada_na_compra) {
      case TIPO_UNIDADE_KG: {
        return qtde;
      }
      case TIPO_UNIDADE_UN: {
        const fator =
          item.produto.unidade.tipo === POR_PESO_FIXO
            ? item.produto.peso_medio
            : 1;
        return qtde / fator;
      }
      case TIPO_UNIDADE_CX1: {
        const fator =
          item.produto.unidade.tipo === POR_PESO_FIXO
            ? item.produto.peso_medio
            : 1;
        return qtde / (fator * item.caixaria_nivel_1);
      }
      case TIPO_UNIDADE_CX2: {
        const fator =
          item.produto.unidade.tipo === POR_PESO_FIXO
            ? item.produto.peso_medio
            : 1;
        return qtde / (fator * item.caixaria_nivel_1 * item.caixaria_nivel_2);
      }
      case TIPO_UNIDADE_CX3: {
        const fator =
          item.produto.unidade.tipo === POR_PESO_FIXO
            ? item.produto.peso_medio
            : 1;
        return (
          qtde /
          (fator *
            item.caixaria_nivel_1 *
            item.caixaria_nivel_2 *
            item.caixaria_nivel_3)
        );
      }
      default: {
        return qtde;
      }
    }
  }

  function formatNegativo(value) {
    return `- (${value})`;
  }

  function getTextEstoque(item, unidades2, peso) {
    const qtde =
      item.produto.unidade.tipo === POR_UNIDADE ||
      item.produto.unidade.tipo === POR_PESO_FIXO
        ? unidades2
        : peso;

    const isNegativa = qtde < 0;

    const pesoUtilizadasPositivas = isNegativa ? qtde * -1 : qtde;

    const objQtde = [];

    let caixaMae = 0;

    let resto1 = pesoUtilizadasPositivas;

    if (item.caixaria_nivel_3) {
      caixaMae = parseInt(
        pesoUtilizadasPositivas /
          (item.caixaria_nivel_3 *
            item.caixaria_nivel_2 *
            item.caixaria_nivel_1),
        10,
      );

      objQtde.push({
        qtde: caixaMae,
        label: `${caixaMae}cx3`,
      });

      resto1 =
        pesoUtilizadasPositivas %
        (item.caixaria_nivel_3 * item.caixaria_nivel_2 * item.caixaria_nivel_1);
    }

    let caixetas = 0;

    let resto2 = pesoUtilizadasPositivas;

    if (item.caixaria_nivel_2) {
      caixetas = parseInt(
        resto1 / (item.caixaria_nivel_2 * item.caixaria_nivel_1),
        10,
      );

      objQtde.push({
        qtde: caixetas,
        label: `${caixetas}cx2`,
      });

      resto2 = resto1 % (item.caixaria_nivel_2 * item.caixaria_nivel_1);
    }

    let caixas = 0;

    let unidades = pesoUtilizadasPositivas;

    if (item.caixaria_nivel_1) {
      caixas = parseInt(resto2 / item.caixaria_nivel_1, 10);

      objQtde.push({
        qtde: caixas,
        label: `${caixas}cx1`,
      });

      unidades = resto2 % item.caixaria_nivel_1;

      if (unidades) {
        if (item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
          objQtde.push({
            qtde: unidades,
            label: `${formatPreco(unidades, 3, '')}`,
          });
        } else {
          objQtde.push({
            qtde: unidades,
            label: `${unidades}un`,
          });
        }
      }
    }

    let labelRetorno = objQtde
      .filter((item) => item.qtde > 0)
      .map((item) => item.label)
      .join(' + ')
      .trim();

    labelRetorno = labelRetorno
      ? isNegativa
        ? formatNegativo(labelRetorno)
        : labelRetorno
      : '-';

    return labelRetorno;
  }

  function getEstoqueLabel(item, unidade_adotada_na_compra, unidades, peso) {
    switch (unidade_adotada_na_compra) {
      case TIPO_UNIDADE_KG: {
        return peso ? `${formatPreco(peso, 0, '')}kg` : '-';
      }
      case TIPO_UNIDADE_UN: {
        return unidades ? `${unidades}un` : '-';
      }
      case TIPO_UNIDADE_CX1: {
        return getTextEstoque(
          {
            ...item,
            caixaria_nivel_1: item.caixaria_nivel_1,
            caixaria_nivel_2: 0,
            caixaria_nivel_3: 0,
          },
          unidades,
          peso,
        );
      }
      case TIPO_UNIDADE_CX2: {
        return getTextEstoque(
          {
            ...item,
            caixaria_nivel_1: item.caixaria_nivel_1,
            caixaria_nivel_2: item.caixaria_nivel_2,
            caixaria_nivel_3: 0,
          },
          unidades,
          peso,
        );
      }
      case TIPO_UNIDADE_CX3: {
        return getTextEstoque(
          {
            ...item,
            caixaria_nivel_1: item.caixaria_nivel_1,
            caixaria_nivel_2: item.caixaria_nivel_2,
            caixaria_nivel_3: item.caixaria_nivel_3,
          },
          unidades,
          peso,
        );
      }
      default: {
        return '-';
      }
    }
  }

  function getPrecos(dados) {
    const valor = dados.unitario;
    const item = dados;
    switch (dados.tipo_unidade_preco) {
      case TIPO_UNIDADE_KG: {
        const rs_kg = valor;
        let fator = 1;
        if (item.produto.unidade.tipo === POR_PESO_FIXO)
          fator = item.produto.peso_medio;
        let rs_un = valor * fator;
        if (item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE)
          rs_un = 0;
        const rs_cx_1 = rs_kg * fator * item.caixaria_nivel_1;

        const rs_cx_2 =
          rs_kg * fator * item.caixaria_nivel_1 * item.caixaria_nivel_2;

        const rs_cx_3 =
          rs_kg *
          fator *
          item.caixaria_nivel_1 *
          item.caixaria_nivel_2 *
          item.caixaria_nivel_3;

        return {
          rs_kg,
          rs_un,
          rs_cx_1,
          rs_cx_2,
          rs_cx_3,
        };
      }
      case TIPO_UNIDADE_UN: {
        let fator = 1;
        if (item.produto.unidade.tipo === POR_PESO_FIXO)
          fator = item.produto.peso_medio;
        let rs_kg = valor / fator;

        const rs_un = valor;
        const rs_cx_1 = rs_kg * fator * item.caixaria_nivel_1;

        const rs_cx_2 =
          rs_kg * fator * item.caixaria_nivel_1 * item.caixaria_nivel_2;

        const rs_cx_3 =
          rs_kg *
          fator *
          item.caixaria_nivel_1 *
          item.caixaria_nivel_2 *
          item.caixaria_nivel_3;

        if (item.produto.unidade.tipo === POR_UNIDADE) rs_kg = 0;

        return {
          rs_kg,
          rs_un,
          rs_cx_1,
          rs_cx_2,
          rs_cx_3,
        };
      }
      case TIPO_UNIDADE_CX1: {
        let fator = 1;
        if (item.produto.unidade.tipo === POR_PESO_FIXO)
          fator = item.produto.peso_medio;
        let rs_kg = valor / (fator * item.caixaria_nivel_1);

        let rs_un = valor / item.caixaria_nivel_1;

        if (item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE)
          rs_un = 0;
        const rs_cx_1 = valor;

        const rs_cx_2 =
          rs_kg * fator * item.caixaria_nivel_1 * item.caixaria_nivel_2;

        const rs_cx_3 =
          rs_kg *
          fator *
          item.caixaria_nivel_1 *
          item.caixaria_nivel_2 *
          item.caixaria_nivel_3;

        if (item.produto.unidade.tipo === POR_UNIDADE) rs_kg = 0;

        return {
          rs_kg,
          rs_un,
          rs_cx_1,
          rs_cx_2,
          rs_cx_3,
        };
      }
      case TIPO_UNIDADE_CX2: {
        let fator = 1;
        if (item.produto.unidade.tipo === POR_PESO_FIXO)
          fator = item.produto.peso_medio;
        let rs_kg =
          valor / (fator * item.caixaria_nivel_1 * item.caixaria_nivel_2);

        let rs_un = valor / (item.caixaria_nivel_1 * item.caixaria_nivel_2);

        if (item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE)
          rs_un = 0;

        const rs_cx_1 = valor / item.caixaria_nivel_2;

        const rs_cx_2 = valor;

        const rs_cx_3 =
          rs_kg *
          fator *
          item.caixaria_nivel_1 *
          item.caixaria_nivel_2 *
          item.caixaria_nivel_3;

        if (item.produto.unidade.tipo === POR_UNIDADE) rs_kg = 0;

        return {
          rs_kg,
          rs_un,
          rs_cx_1,
          rs_cx_2,
          rs_cx_3,
        };
      }
      case TIPO_UNIDADE_CX3: {
        let fator = 1;
        if (item.produto.unidade.tipo === POR_PESO_FIXO)
          fator = item.produto.peso_medio;
        let rs_kg =
          valor /
          (fator *
            item.caixaria_nivel_1 *
            item.caixaria_nivel_2 *
            item.caixaria_nivel_3);

        let rs_un =
          valor /
          (item.caixaria_nivel_1 *
            item.caixaria_nivel_2 *
            item.caixaria_nivel_3);

        if (item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE)
          rs_un = 0;

        const rs_cx_1 = valor / (item.caixaria_nivel_2 * item.caixaria_nivel_3);

        const rs_cx_2 = valor / item.caixaria_nivel_3;

        const rs_cx_3 = valor;

        if (item.produto.unidade.tipo === POR_UNIDADE) rs_kg = 0;
        return {
          rs_kg,
          rs_un,
          rs_cx_1,
          rs_cx_2,
          rs_cx_3,
        };
      }
      default:
        return { rs_kg: 0, rs_un: 0, rs_cx_1: 0, rs_cx_2: 0, rs_cx_3: 0 };
    }
  }

  function getPrecoParaCalculos({
    rs_kg,
    rs_un,
    rs_cx_1,
    rs_cx_2,
    rs_cx_3,
    unidade_adotada_na_compra,
  }) {
    switch (unidade_adotada_na_compra) {
      case TIPO_UNIDADE_KG:
        return rs_kg;
      case TIPO_UNIDADE_UN:
        return rs_un;
      case TIPO_UNIDADE_CX1:
        return rs_cx_1;
      case TIPO_UNIDADE_CX2:
        return rs_cx_2;
      case TIPO_UNIDADE_CX3:
        return rs_cx_3;
      default:
        return rs_kg;
    }
  }

  function getFatorCaixariaUnidadeAdotadaNaCompra(
    item,
    unidade_adotada_na_compra,
  ) {
    switch (unidade_adotada_na_compra) {
      case TIPO_UNIDADE_KG: {
        return 1;
      }
      case TIPO_UNIDADE_UN: {
        return 1;
      }
      case TIPO_UNIDADE_CX1: {
        return item.caixaria_nivel_1;
      }
      case TIPO_UNIDADE_CX2: {
        return item.caixaria_nivel_1 * item.caixaria_nivel_2;
      }
      case TIPO_UNIDADE_CX3: {
        return (
          item.caixaria_nivel_1 * item.caixaria_nivel_2 * item.caixaria_nivel_3
        );
      }
      default: {
        return 0;
      }
    }
  }

  function getPeso(item, pedido, unidade_adotada_na_compra) {
    const fator = getFatorCaixariaUnidadeAdotadaNaCompra(
      item,
      unidade_adotada_na_compra,
    );
    switch (item.unidade.tipo) {
      case POR_UNIDADE:
        return pedido * fator * item.peso_padrao_na_compra;
      case POR_PESO_FIXO:
        return pedido * fator * item.peso_medio;
      case POR_PESO_VARIAVEL_SEM_UNIDADE:
        return pedido * fator;
      default:
        return 0;
    }
  }

  function getItensFormatados(dados) {
    const itensFormatados = [];
    for (let i = 0; i < dados.itens.length; i++) {
      const item = { ...dados.itens[i], ...dados.itens[i].produto };
      const prazo_medio_pagamento = dados.fornecedor.prazo_medio_pagamento;
      /////////////
      item.caixaria_nivel_1 =
        item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
          ? item.produto.peso_caixa_nivel_1
          : item.produto.unidades_caixa_nivel_1;
      item.caixaria_nivel_2 = item.produto.unidades_caixa_nivel_2;
      item.caixaria_nivel_3 = item.produto.unidades_caixa_nivel_3;

      const { rs_kg, rs_un, rs_cx_1, rs_cx_2, rs_cx_3 } = getPrecos(item);

      const unidade_preco_editado = item.tipo_unidade_preco;

      const unidade_adotada_na_compra = item.tipo_unidade_qtde;

      const regra_de_arredondamento =
        item.produto.arredondamento_no_pedido_de_compra;

      const pedido = item.qtde;

      const estoque_atual = getEstoque(item, unidade_adotada_na_compra);

      const vtu_30 = getVtu30(item, unidade_adotada_na_compra);

      const vmd_na_maior_unidade_possivel = vtu_30 / 30;

      const estoque_ideal =
        vmd_na_maior_unidade_possivel * prazo_medio_pagamento;

      const precoParaCalculos = getPrecoParaCalculos({
        rs_kg,
        rs_un,
        rs_cx_1,
        rs_cx_2,
        rs_cx_3,
        unidade_adotada_na_compra,
      });

      let bonificacao_em_cxs = 0;

      if (item.produto.ativar_bonificacao) {
        bonificacao_em_cxs = pedido / regra_de_arredondamento;
      }

      const bonificacao_subtotal = bonificacao_em_cxs * precoParaCalculos;

      const volume_unitario = item.produto.volume_unitario_vu;

      const peso_bruto = item.produto.peso_unitario_pu;

      const peso_subtotal = pedido * peso_bruto;

      const volume_subtotal = pedido * volume_unitario;

      const subtotal = pedido * precoParaCalculos;

      const peso_subtotal_liquido = getPeso(
        item,
        pedido,
        unidade_adotada_na_compra,
      );

      const rs_un_mais_bonificacao =
        (pedido * precoParaCalculos) / (pedido + bonificacao_em_cxs);

      const is_estoque_negativo =
        item.produto.status !== STATUS_ATIVO
          ? true
          : estoque_atual < 0
          ? true
          : false;
      /////////////
      const estoque_ideal_label = estoque_ideal
        ? `${formatPreco(estoque_ideal, 0, '')}${unidade_adotada_na_compra}`
        : '-';

      const estoque_atual_label = getEstoqueLabel(
        item,
        unidade_adotada_na_compra,
        item.produto.unidadesDisponivel,
        item.produto.pesoDisponivel,
      );

      const vtu_30_label = getEstoqueLabel(
        item,
        unidade_adotada_na_compra,
        item.produto.unidades_vendidas_ultimos_30_dias,
        item.produto.peso_vendido_ultimos_30_dias,
      );

      const vmd_na_maior_unidade_possivel_label = vtu_30
        ? `${formatPreco(vtu_30 / 30, 2, '')}${unidade_adotada_na_compra}`
        : '-';
      /////////////

      itensFormatados.push({
        id: item.id,
        descricao: item.produto.nome,
        rs_kg:
          item.produto.unidade.tipo === POR_UNIDADE ? '-' : formatMoeda(rs_kg),
        rs_un:
          item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
            ? '-'
            : formatMoeda(rs_un),
        rs_cx_1: item.caixaria_nivel_1 <= 0 ? '-' : formatMoeda(rs_cx_1),
        rs_cx_2: item.caixaria_nivel_1 <= 0 ? '-' : formatMoeda(rs_cx_2),
        rs_cx_3: item.caixaria_nivel_1 <= 0 ? '-' : formatMoeda(rs_cx_3),
        pedido, //ok
        unidade_preco_editado, //ok
        unidade_adotada_na_compra, //ok
        subtotal: formatMoeda(subtotal), //ok
        bonificacao_em_cxs: bonificacao_em_cxs ? bonificacao_em_cxs : '-', //ok
        bonificacao_subtotal: bonificacao_subtotal
          ? formatMoeda(bonificacao_subtotal)
          : '-',
        rs_un_mais_bonificacao: bonificacao_subtotal
          ? formatMoeda(rs_un_mais_bonificacao)
          : '-',
        peso_subtotal: peso_subtotal ? formatPeso(peso_subtotal) : '-',
        volume_subtotal: volume_subtotal
          ? `${formatPreco(volume_subtotal, 2, '')}m³`
          : '-',
        estoque_atual: estoque_atual_label,
        estoque_ideal: estoque_ideal_label,
        vtu_30: vtu_30_label,
        vmd_na_maior_unidade_possivel: vmd_na_maior_unidade_possivel_label,
        peso_padrao: formatPreco(item.produto.peso_medio, 3, ''),
        volume_unitario: formatPreco(item.produto.volume_unitario, 2, ''),
        peso_bruto: formatPreco(item.produto.peso_bruto, 3, ''),
        regra_de_arredondamento,
        caixaria_nivel_1: item.caixaria_nivel_1,
        caixaria_nivel_2: item.caixaria_nivel_2,
        caixaria_nivel_3: item.caixaria_nivel_3,
        styles: {
          opacity: is_estoque_negativo ? 0.5 : 1,
        },
        status: item.produto.status === 'inativo' ? 'Inativo' : '',
        //
        show_rs_kg_original:
          item.produto.unidade.tipo === POR_UNIDADE ? false : true,
        show_rs_un_original:
          item.produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
            ? false
            : true,
        show_rs_cx_1_original: item.caixaria_nivel_1 <= 0 ? false : true,
        show_rs_cx_2_original: item.caixaria_nivel_2 <= 0 ? false : true,
        show_rs_cx_3_original: item.caixaria_nivel_3 <= 0 ? false : true,
        peso_subtotal_liquido: peso_subtotal_liquido
          ? formatPeso(peso_subtotal_liquido)
          : '-',
      });
    }
    return itensFormatados;
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI(
        'pedidos-compras',
        ['id', 'asc'],
        { id: [id] },
        ['fornecedor.cidade.estado', 'itens.produto.unidade', 'pasta'],
      );
      if (data.data.length > 0) {
        setItens(getItensFormatados(data.data[0]));
        setTotais(getTotaisFormatados(data.data[0]));
        setFornecedor(data.data[0].fornecedor);
        setPasta(data.data[0].pasta ? data.data[0].pasta.nome : '-');
        setId(id);
      } else {
        throw 'Este Pedido de Compra não existe!';
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: 'error',
      });
      handleCloseDialog(-1);
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(id) {
      setOpen(true);
      getDataResource(id);
    },
  }));

  function getShows(itensFormatados) {
    const shows = {
      rs_kg: false,
      rs_un: false,
      rs_cx_1: false,
      rs_cx_2: false,
      rs_cx_3: false,
      bonificacao_em_cxs: false,
      bonificacao_subtotal: false,
      rs_un_mais_bonificacao: false,
      peso_subtotal: false,
      volume_subtotal: false,
    };

    for (let i = 0; i < itensFormatados.length; i++) {
      const item = itensFormatados[i];
      if (item.show_rs_kg_original) shows.rs_kg = true;
      if (item.show_rs_un_original) shows.rs_un = true;
      if (item.show_rs_cx_1_original) shows.rs_cx_1 = true;
      if (item.show_rs_cx_2_original) shows.rs_cx_2 = true;
      if (item.show_rs_cx_3_original) shows.rs_cx_3 = true;
      if (item.bonificacao_em_cxs !== '-') {
        shows.bonificacao_em_cxs = true;
        shows.bonificacao_subtotal = true;
        shows.rs_un_mais_bonificacao = true;
      }

      if (item.peso_subtotal !== '-') shows.peso_subtotal = true;
      if (item.volume_subtotal !== '-') shows.volume_subtotal = true;
    }

    return shows;
  }

  const showsColunas = getShows(itens);

  const valuesChows = Object.keys(showsColunas);

  for (let i = 0; i < valuesChows.length; i++) {
    const key = valuesChows[i];
    colunas[key].show = showsColunas[key]
      ? colunas[key].show
      : showsColunas[key];
  }

  function getLabelPedidoMinimo(fornecedor) {
    switch (fornecedor.tipo_pedido_minimo_de_compra) {
      case TIPO_PEDIDO_MINIMO_COMPRA_KG:
        return formatPeso(fornecedor.pedido_minimo_de_compra);
      case TIPO_PEDIDO_MINIMO_COMPRA_CAIXAS:
        return `${Math.round(fornecedor.pedido_minimo_de_compra)} Caixas`;
      case TIPO_PEDIDO_MINIMO_COMPRA_VALOR:
        return formatMoeda(fornecedor.pedido_minimo_de_compra);
      default:
        return '';
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen
    >
      <DialogTitle
        id="form-dialog-title"
        onClose={handleCloseDialog}
        style={{
          backgroundColor: SECONDARY_COLOR,
          color: 'white',
        }}
      >{`Pedido de Compra n° ${id}`}</DialogTitle>
      <DialogContent dividers>
        <div style={{ height: '100%' }}>
          <Box
            display={{ xs: 'block', sm: 'flex' }}
            flexDirection="column"
            marginBottom="30px"
            height="100%"
          >
            {carregando ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '300px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div>
                {fornecedor && (
                  <Box display="flex" style={{ gap: '10px' }}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      flex={1}
                      style={{
                        border: `1px solid ${PRIMARY_COLOR}`,
                        marginLeft: '0px',
                        padding: '5px',
                        borderRadius: '5px',
                        marginBottom: '10px',
                      }}
                    >
                      <Box
                        marginLeft="10px"
                        display="flex"
                        flexDirection="column"
                        flex={1}
                      >
                        <Box
                          marginLeft="0px"
                          fontSize="15px"
                          fontWeight="bold"
                          display="flex"
                          flex={1}
                        >
                          <p
                            className={classes.textOverflow}
                            style={{
                              marginBottom: '0px',
                              color: colorHeader,
                              marginRight: '10px',
                            }}
                          >
                            Responsável:
                          </p>
                          {fornecedor.nome}
                        </Box>
                        <Box
                          marginLeft="0px"
                          fontSize="15px"
                          fontWeight="bold"
                          display="flex"
                          flex={1}
                        >
                          <p
                            className={classes.textOverflow}
                            style={{
                              marginBottom: '0px',
                              color: colorHeader,
                              marginRight: '10px',
                            }}
                          >
                            Razão Social:
                          </p>
                          {fornecedor.razao_social}
                        </Box>
                        <Box
                          marginLeft="0px"
                          fontSize="15px"
                          fontWeight="bold"
                          display="flex"
                          flex={1}
                        >
                          <p
                            className={classes.textOverflow}
                            style={{
                              marginBottom: '0px',
                              color: colorHeader,
                              marginRight: '10px',
                            }}
                          >
                            CPF:
                          </p>
                          {formatCPF(fornecedor.cpf)}
                        </Box>
                        <Box
                          marginLeft="0px"
                          fontSize="15px"
                          fontWeight="bold"
                          display="flex"
                          flex={1}
                        >
                          <p
                            className={classes.textOverflow}
                            style={{
                              marginBottom: '0px',
                              color: colorHeader,
                              marginRight: '10px',
                            }}
                          >
                            CNPJ:
                          </p>
                          {formatCNPJ(fornecedor.cnpj)}
                        </Box>
                        <Box
                          marginLeft="0px"
                          fontSize="15px"
                          fontWeight="bold"
                          display="flex"
                          flex={1}
                        >
                          <p
                            className={classes.textOverflow}
                            style={{
                              marginBottom: '0px',
                              color: colorHeader,
                              marginRight: '10px',
                            }}
                          >
                            Prazo Médio de Pagamento:
                          </p>
                          {`${fornecedor.prazo_medio_pagamento} dias`}
                        </Box>
                        <Box
                          marginLeft="0px"
                          fontSize="15px"
                          fontWeight="bold"
                          display="flex"
                          flex={1}
                        >
                          <p
                            className={classes.textOverflow}
                            style={{
                              marginBottom: '0px',
                              color: colorHeader,
                              marginRight: '10px',
                            }}
                          >
                            Pasta:
                          </p>
                          {pasta}
                        </Box>
                      </Box>
                      <Box
                        marginLeft="10px"
                        display="flex"
                        flexDirection="column"
                        flex={1}
                      >
                        <Box
                          marginLeft="0px"
                          fontSize="15px"
                          fontWeight="bold"
                          display="flex"
                          flex={1}
                          justifyContent="flex-start"
                        >
                          <p
                            className={classes.textOverflow}
                            style={{
                              marginBottom: '0px',
                              color: colorHeader,
                              marginRight: '10px',
                            }}
                          >
                            Nome Fantasia:
                          </p>
                          {fornecedor.nome_fantasia}
                        </Box>
                        <Box
                          marginLeft="0px"
                          fontSize="15px"
                          fontWeight="bold"
                          display="flex"
                          flex={1}
                          justifyContent="flex-start"
                        >
                          <p
                            className={classes.textOverflow}
                            style={{
                              marginBottom: '0px',
                              color: colorHeader,
                              marginRight: '10px',
                            }}
                          >
                            Bairro:
                          </p>
                          {fornecedor.bairro}
                        </Box>
                        <Box
                          marginLeft="0px"
                          fontSize="15px"
                          fontWeight="bold"
                          display="flex"
                          flex={1}
                          justifyContent="flex-start"
                        >
                          <p
                            className={classes.textOverflow}
                            style={{
                              marginBottom: '0px',
                              color: colorHeader,
                              marginRight: '10px',
                            }}
                          >
                            Cidade:
                          </p>
                          {fornecedor.cidade
                            ? `${fornecedor.cidade.nome} - ${fornecedor.cidade.estado.sigla}`
                            : ''}
                        </Box>
                        <Box
                          marginLeft="0px"
                          fontSize="15px"
                          fontWeight="bold"
                          display="flex"
                          flex={1}
                          justifyContent="flex-start"
                        >
                          <p
                            className={classes.textOverflow}
                            style={{
                              marginBottom: '0px',
                              color: colorHeader,
                              marginRight: '10px',
                            }}
                          >
                            Logradouro:
                          </p>
                          {fornecedor.logradouro}
                        </Box>
                        <Box
                          marginLeft="0px"
                          fontSize="15px"
                          fontWeight="bold"
                          display="flex"
                          flex={1}
                          justifyContent="flex-start"
                        >
                          <p
                            className={classes.textOverflow}
                            style={{
                              marginBottom: '0px',
                              color: colorHeader,
                              marginRight: '10px',
                            }}
                          >
                            Pedido Mínimo:
                          </p>
                          {getLabelPedidoMinimo(fornecedor)}
                        </Box>
                        <Box
                          marginLeft="0px"
                          fontSize="15px"
                          fontWeight="bold"
                          display="flex"
                          flex={1}
                          justifyContent="flex-start"
                        >
                          <p
                            className={classes.textOverflow}
                            style={{
                              marginBottom: '0px',
                              color: colorHeader,
                              marginRight: '10px',
                            }}
                          >
                            Tempo de Entrega:
                          </p>
                          {`${fornecedor.tempo_entrega} dias`}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                <div>
                  <Table aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        {headerTotais(
                          Object.values(colunas).map((item) => item),
                          totais,
                        )}
                      </TableRow>
                      <TableRow />
                      <TableRow>
                        {getHeaderTabela(
                          Object.values(colunas).map((item) => item),
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itens.map(
                        (
                          {
                            id,
                            descricao,
                            rs_kg,
                            rs_un,
                            rs_cx_1,
                            rs_cx_2,
                            rs_cx_3,
                            pedido,
                            unidade_preco_editado,
                            unidade_adotada_na_compra,
                            subtotal,
                            bonificacao_em_cxs,
                            bonificacao_subtotal,
                            rs_un_mais_bonificacao,
                            peso_subtotal,
                            volume_subtotal,
                            estoque_atual,
                            estoque_ideal,
                            vtu_30,
                            vmd_na_maior_unidade_possivel,
                            peso_padrao,
                            volume_unitario,
                            peso_bruto,
                            regra_de_arredondamento,
                            caixaria_nivel_1,
                            caixaria_nivel_2,
                            caixaria_nivel_3,
                            styles,
                            status,
                            peso_subtotal_liquido,
                          },
                          index2,
                        ) => (
                          <TableRow style={styles} key={id}>
                            <StyledTableCell align="center">
                              {index2 + 1}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {descricao}
                            </StyledTableCell>
                            <Involucro field="rs_kg" colunas={colunas}>
                              <StyledTableCell
                                align="center"
                                style={
                                  unidade_preco_editado === TIPO_UNIDADE_KG
                                    ? { backgroundColor: '#88df5973' }
                                    : {}
                                }
                              >
                                {formatMoeda(rs_kg)}
                              </StyledTableCell>
                            </Involucro>
                            <Involucro field="rs_un" colunas={colunas}>
                              <StyledTableCell
                                align="center"
                                style={
                                  unidade_preco_editado === TIPO_UNIDADE_UN
                                    ? { backgroundColor: '#88df5973' }
                                    : {}
                                }
                              >
                                {formatMoeda(rs_un)}
                              </StyledTableCell>
                            </Involucro>
                            <Involucro field="rs_cx_1" colunas={colunas}>
                              <StyledTableCell
                                align="center"
                                style={
                                  unidade_preco_editado === TIPO_UNIDADE_CX1
                                    ? { backgroundColor: '#88df5973' }
                                    : {}
                                }
                              >
                                {formatMoeda(rs_cx_1)}
                              </StyledTableCell>
                            </Involucro>
                            <Involucro field="rs_cx_2" colunas={colunas}>
                              <StyledTableCell
                                align="center"
                                style={
                                  unidade_preco_editado === TIPO_UNIDADE_CX2
                                    ? { backgroundColor: '#88df5973' }
                                    : {}
                                }
                              >
                                {formatMoeda(rs_cx_2)}
                              </StyledTableCell>
                            </Involucro>
                            <Involucro field="rs_cx_3" colunas={colunas}>
                              <StyledTableCell
                                align="center"
                                style={
                                  unidade_preco_editado === TIPO_UNIDADE_CX3
                                    ? { backgroundColor: '#88df5973' }
                                    : {}
                                }
                              >
                                {formatMoeda(rs_cx_3)}
                              </StyledTableCell>
                            </Involucro>
                            <StyledTableCell
                              align="center"
                              style={{
                                color: 'red',
                                fontWeight: 'bold',
                                fontSize: '20px',
                              }}
                            >
                              {pedido}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              {unidade_adotada_na_compra}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {formatMoeda(subtotal)}
                            </StyledTableCell>
                            <Involucro
                              field="bonificacao_em_cxs"
                              colunas={colunas}
                            >
                              <StyledTableCell align="center">
                                {bonificacao_em_cxs}
                              </StyledTableCell>
                            </Involucro>
                            <Involucro
                              field="bonificacao_subtotal"
                              colunas={colunas}
                            >
                              <StyledTableCell align="center">
                                {bonificacao_subtotal}
                              </StyledTableCell>
                            </Involucro>
                            <Involucro
                              field="bonificacao_subtotal"
                              colunas={colunas}
                            >
                              <StyledTableCell align="center">
                                {rs_un_mais_bonificacao}
                              </StyledTableCell>
                            </Involucro>
                            <Involucro
                              field="peso_subtotal_liquido"
                              colunas={colunas}
                            >
                              <StyledTableCell align="center">
                                {peso_subtotal_liquido}
                              </StyledTableCell>
                            </Involucro>
                            <Involucro field="peso_subtotal" colunas={colunas}>
                              <StyledTableCell align="center">
                                {peso_subtotal}
                              </StyledTableCell>
                            </Involucro>

                            <Involucro
                              field="volume_subtotal"
                              colunas={colunas}
                            >
                              <StyledTableCell align="center">
                                {volume_subtotal}
                              </StyledTableCell>
                            </Involucro>
                            <StyledTableCell align="center">
                              {estoque_atual}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {estoque_ideal}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {vtu_30}
                            </StyledTableCell>
                            {/*  <StyledTableCell align="center">
                              {status}
                            </StyledTableCell> */}
                            {/* <StyledTableCell align="center">
                              {vmd_na_maior_unidade_possivel}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {peso_padrao}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {volume_unitario}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {peso_bruto}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {regra_de_arredondamento}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {caixaria_nivel_1}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {caixaria_nivel_2}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {caixaria_nivel_3}
                            </StyledTableCell> */}
                          </TableRow>
                        ),
                      )}
                    </TableBody>
                  </Table>
                </div>
              </div>
            )}
          </Box>
        </div>
      </DialogContent>
    </Dialog>
  );
});

export default DialogoShowVenda;
